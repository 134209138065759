import Common from 'Common';
import {Injectable, Inject} from '@angular/core';
import {APP_CONFIG, AppConfig} from 'config';
import {clearIDB} from './Databases/IDB';
import {logger, core} from 'helpers';
import OnlineStatusService from './online-status.service';
import UserService from 'user.service';
import ResourceService from './resource.service';
import StateService from 'state.service';
import SyncMethodsService from './Infrastructure/sync-methods.service';
import {TranslateService} from 'translate.service';
import {EventBusService} from 'event-bus.service';
import {SocketService} from './socket.service';
import startFileSync from './update-files.phonegap';
import DatabaseManager, { DatabaseManagerToken } from './Databases/DatabaseManager';

@Injectable()
export default class SynchronizeService {

    progressStep = 0;
    syncProgressSteps: {
        promise?: () => Promise<any>;
        method?: (compact: any, first: any, rowsToGet: any) => Promise<any>;
        weight: number;
        onlyElectron?: boolean;
        offerModuleAccess?: boolean
    }[] = [
        {
            promise: this.UserService.synchronizeUser.bind(this.UserService),
            weight: 1
        },
        {
            method: this.synchronizeSettings,
            weight: 0.5
        },
        {
            method: this.synchronizeConfiguratorsData,
            weight: 2.5,
            offerModuleAccess: true
        },
        {
            method: this.synchronizeLayouts,
            weight: 0.5,
            offerModuleAccess: true
        },
        {
            method: this.synchronizePrices,
            weight: 0.5,
            offerModuleAccess: true
        },
        {
            method: this.synchronizeCustomPrices,
            weight: 0.5,
            offerModuleAccess: true
        },
        {
            method: this.synchronizeDiscountsAndMultipliers,
            weight: 1,
            offerModuleAccess: true
        },
        {
            method: this.synchronizeClients,
            weight: 1,
            offerModuleAccess: true
        },
        {
            method: this.synchronizeOffers,
            weight: 2,
            offerModuleAccess: true
        },
        {
            method: this.synchronizeAdditionals,
            weight: 1,
            offerModuleAccess: true
        },
        {
            method: this.synchronizeTranslations,
            weight: 1
        },
        {
            method: this.synchronizeUsers,
            weight: 0.5
        },
        {
            method: this.synchronizeApplicationNumber,
            weight: 0.5,
            onlyElectron: true,
        }
    ];

    updateProgressSteps: {
        promise?: () => Promise<any>;
        method?: (compact: any, first: any) => Promise<any>;
        weight: number;
    }[] = [
        {
            promise: this.synchronizeFiles,
            weight: 1
        }
    ];
    intervalProgress;
    liveSyncProgress = false;
    liveSyncUserProgress = false;
    progressBar: {
        percent: number;
        striped: boolean;
        speed?: number
     } = {
        percent: 0,
        striped: false
    };

    constructor(
        private OnlineStatusService: OnlineStatusService,
        private ResourceService: ResourceService,
        private SyncMethodsService: SyncMethodsService,
        private UserService: UserService,
        private StateService: StateService,
        private TranslateService: TranslateService,
        private EventBusService: EventBusService,
        @Inject(APP_CONFIG) private config: AppConfig,
        private SocketService: SocketService,
        @Inject(DatabaseManagerToken) private databaseManager: DatabaseManager
    ) {
        this.connectWithSocket();

        this.EventBusService.subscribeWithoutConfiguration(['connected', 'changedOffer'], data => {
            this.onChangedOffer();
        });
    }

    startProgress(allSteps, repeat = false) {
        this.progressStep = 0;
        if (!repeat) {
            this.progressBar.percent = 0;
            this.progress(allSteps);
        }
        this.EventBusService.post({key: 'progress', value: null});
    }

    nextStep(allSteps, stepIndex) {
        const steps = allSteps.filter(step => !step.onlyElectron || this.config.isNative);
        const weightsSum = steps.reduce((prev, cur) => prev + cur.weight, 0);
        const tick = Math.round(100 / weightsSum);
        if (stepIndex < steps.length - 1) {
            const newProgressPercent = steps.slice(0, stepIndex + 1).reduce((prev, cur) => prev + tick * cur.weight, 0);
            if (newProgressPercent > this.progressBar.percent) {
                this.progressBar.striped = false;
                this.progressBar.percent = newProgressPercent;
            }
        } else {
            clearInterval(this.intervalProgress);
            this.progressBar.percent = 100;
        }

        this.progressStep = stepIndex + 1;
        this.EventBusService.post({key: 'progress', value: null});
    }

    progress(allSteps) {
        const tick = Math.round(100 / allSteps.length);
        this.intervalProgress = setInterval(() => {
            if (this.progressStep + 1 < allSteps.length && this.progressBar.percent + 0.5 < this.progressBar.percent + tick * allSteps[this.progressStep + 1].weight) {
                this.progressBar.percent = this.progressBar.percent + 0.5;
                this.EventBusService.post({key: 'progress', value: null});
            }
        }, 2000);
    }

    synchronizeAdditionals(compact) {
        return new Promise((resolve, reject) => {
            this.SyncMethodsService.oneWayXHR('Additional', this.ResourceService.getAdditionals.bind(this.ResourceService), ['additionals'], compact).then((res) => {
                resolve(res);
            }).catch((err) => {
                if (err.name !== 'SyncError') {
                    this.databaseIsCorupted(err);
                }
                logger.error(err);
                reject(err);
            });
        });
    }

    synchronizePrices(compact) {
        return new Promise((resolve, reject) => {
            this.SyncMethodsService.oneWayXHR('Prices', this.ResourceService.getPrices.bind(this.ResourceService), ['windowPrices', 'rollerPrices', 'rollerPricesData', 'currencies'], compact).then((res) => {
                resolve(res);
            }).catch((err) => {
                if (err.name !== 'SyncError') {
                    this.databaseIsCorupted(err);
                }
                logger.error(err);
                reject(err);
            });
        });
    }

    synchronizeConfiguratorsData(compact) {
        return new Promise((resolve, reject) => {
            this.SyncMethodsService.oneWayXHR('Configurators', this.ResourceService.getConfiguratorsData.bind(this.ResourceService), ['configsData'], compact).then((res) => {
                resolve(res);
            }).catch((err) => {
                if (err.name !== 'SyncError') {
                    this.databaseIsCorupted(err);
                }
                logger.error(err);
                reject(err);
            });
        });
    }

    synchronizeLayouts(compact) {
        return new Promise((resolve, reject) => {
            this.SyncMethodsService.oneWayXHR('Layouts', this.ResourceService.getLayouts.bind(this.ResourceService), ['sashesLayouts'], compact).then((res) => {
                resolve(res);
            }).catch((err) => {
                if (err.name !== 'SyncError') {
                    this.databaseIsCorupted(err);
                }
                logger.error(err);
                reject(err);
            });
        });
    }

    synchronizeClients(compact, mode, rowsToGet) {
        return new Promise((resolve, reject) => {
            this.SyncMethodsService.twoWaySocket(mode, ['Client'], 'clients', compact, rowsToGet, {}).then((res) => {
                resolve(res);
            }).catch((err) => {
                this.databaseIsCorupted(err);
                logger.error(err);
                reject(err);
            });
        });
    }

    synchronizeOffers(compact, mode, rowsToGet) {
        return new Promise((resolve, reject) => {
            this.SyncMethodsService.twoWaySocket(mode, ['Offer', 'Position', 'PositionAttachment', 'OfferAttachment'], 'offers', compact, rowsToGet, {
                dealer_offer_id: this.StateService.getKey('offer_id')
            }).then((res) => {
                resolve(res);
            }).catch((err) => {
                this.databaseIsCorupted(err);
                logger.error(err);
                reject(err);
            });
        });
    }

    synchronizeCustomPrices(compact) {
        return new Promise((resolve, reject) => {
            this.SyncMethodsService.oneWayXHR('CustomPrices', this.ResourceService.getCustomPrices.bind(this.ResourceService), ['customPrices'], compact).then((res) => {
                resolve(res);
            }).catch((err) => {
                if (err.name !== 'SyncError') {
                    this.databaseIsCorupted(err);
                }
                logger.error(err);
                reject(err);
            });
        });
    }

    synchronizeDiscountsAndMultipliers(compact) {
        return new Promise((resolve, reject) => {
            this.SyncMethodsService.oneWayXHR('DiscountsAndMultipliers', this.ResourceService.getDiscountsAndMultipliers.bind(this.ResourceService), ['offerDiscounts', 'multipliers', 'buyDiscounts', 'saleDiscounts'], compact).then((res) => {
                resolve(res);
            }).catch((err) => {
                if (err.name !== 'SyncError') {
                    this.databaseIsCorupted(err);
                }
                logger.error(err);
                reject(err);
            });
        });
    }

    synchronizeSettings(compact) {
        return new Promise((resolve, reject) => {
            this.SyncMethodsService.oneWayXHR('Settings', this.ResourceService.getSettings.bind(this.ResourceService), ['settings'], compact).then((res) => {
                resolve(res);
            }).catch((err) => {
                if (err.name !== 'SyncError') {
                    this.databaseIsCorupted(err);
                }
                logger.error(err);
                reject(err);
            });
        });
    }

    synchronizeUsers(compact) {
        return new Promise((resolve, reject) => {
            this.SyncMethodsService.oneWayXHR('Users', this.ResourceService.getUsers.bind(this.ResourceService), ['users'], compact).then((res) => {
                resolve(res);
            }).catch((err) => {
                if (err.name !== 'SyncError') {
                    this.databaseIsCorupted(err);
                }
                logger.error(err);
                reject(err);
            });
        });
    }

    synchronizeApplicationNumber() {
        return new Promise((resolve, reject) => {
            let applicationNumber: any = localStorage.getItem('applicationNumber');
            if (!applicationNumber || Common.isNumber(applicationNumber)) {
                applicationNumber = {};
            }
            applicationNumber = core.parseJson(applicationNumber);
            const user = this.UserService.get();
            if (!applicationNumber[user.id]) {
                this.ResourceService.getApplicationNumber().then((res) => {
                    applicationNumber[user.id] = res.applicationNumber;
                    localStorage.setItem('applicationNumber', core.stringJson(applicationNumber));
                    resolve();
                }).catch((err) => {
                    logger.error(err);
                    reject();
                });
            }
            resolve();
        });
    }

    synchronizeFiles() {
        return new Promise((resolve, reject) => {
            if (this.config.isElectron) {
                window.ipc.send('sync-files:start', this.onceUpdated);
                window.ipc.once('sync-files:synced', () => {
                    resolve();
                });
                window.ipc.on('sync-files:error', (err) => {
                    reject(err);
                });
                window.ipc.on('error', (err) => {
                    reject(err);
                });
                window.ipc.on('sync-files:progress', (e, progress) => {
                    this.progressBar.percent = Math.round(progress.percent * 100);
                    this.progressBar.speed = Math.round(progress.speed);
                });
            } else {
                startFileSync(this.config.EnvConfig.remoteHost, this.onceUpdated, (progress) => {
                    this.progressBar.percent = Math.round(progress.percent * 100);
                    this.progressBar.speed = Math.round(progress.speed);
                }).then(() => {
                    resolve();
                })
                .catch(err => reject(err));
            }
        });
    }

    synchronizeTranslations(compact) {
        return new Promise((resolve, reject) => {
            this.SyncMethodsService.oneWayXHR('Translations', this.ResourceService.getTranslations.bind(this.ResourceService), ['translations'], compact).then((res) => {
                resolve(res);
            }).catch((err) => {
                if (err.name !== 'SyncError') {
                    this.databaseIsCorupted(err);
                }
                logger.error(err);
                reject(err);
            });
        });
    }


    async synchronizeFirst(repeat = false) {
        const user = await this.UserService.get();
        return this.synchronizeStepped(repeat, this.syncProgressSteps, 'lastSync' + '_' + user.id);
    }

    async update(repeat = false) {
        return this.synchronizeStepped(repeat, this.updateProgressSteps, 'lastUpdate', !this.config.isNative);
    }

    async synchronizeStepped(repeat = false, steps, lastName, skip = false) {
        this.startProgress(repeat);
        if (repeat) {
            this.progressBar.striped = true;
        }
        try {
            const online = await this.OnlineStatusService.checkOnline();
            const offerModuleAccess = this.UserService.offerModuleAccess;
            let lastSync = localStorage.getItem(lastName);
            if (!online && lastSync || skip) {
                return;
            } else if (online) {
                for (const index in steps) {
                    const step = steps[index];
                    if (
                        (!step.onlyElectron || this.config.isNative)
                        && (!step.offerModuleAccess || offerModuleAccess)
                    ) {
                        if (step.promise) {
                            await (step.promise.bind(this))();
                        } else {
                            await this.SyncMethodsService.once(step.method.bind(this));
                        }
                        this.nextStep(steps, Number(index));
                    }
                }
                localStorage.setItem(lastName, Date.now() + '');
                return;
            } else {
                throw new Error();
            }
        } catch (err) {
            if (Common.isDefined(err.code) && err.name !== 'SyncError') {
                // $rootScope.showInfo(this.TranslateService.instant('SYNCHRONIZE|Wystąpił błąd synchronizacji...'), null, null, 2000);
                alert(this.TranslateService.instant('SYNCHRONIZE|Wystąpił błąd synchronizacji...'));
                clearIDB();
            }
            logger.error(err);
            throw err;
        }
    }

    synchronizeLive() {
        const offerModuleAccess = this.UserService.offerModuleAccess;
        if (!this.liveSyncProgress && offerModuleAccess) {
            this.SyncMethodsService.liveOneWay(this.synchronizeDiscountsAndMultipliers.bind(this), 'discounts');
            this.SyncMethodsService.liveTwoWay(this.synchronizeClients.bind(this), 'clients', ['updatedClient']);
            this.SyncMethodsService.liveTwoWay(this.synchronizeOffers.bind(this), 'offers', ['modifiedOffer'], ['changedOffer']);
            this.SyncMethodsService.liveOneWay(this.synchronizeAdditionals.bind(this), 'additionals');
            this.liveSyncProgress = true;
        }
    }

    synchronizeLiveUser() {
        if (!this.liveSyncUserProgress) {
            this.SyncMethodsService.liveXHR(this.UserService.synchronizeUser.bind(this.UserService));
            this.liveSyncUserProgress = true;
        }
    }

    connectWithSocket() {
        const socket = this.SocketService.socket;
        this.SyncMethodsService.setSocket(socket);
    }

    databaseIsCorupted(err) {
        if (~~err.code === 11) {
            if (core.isWorker()) {
                (<any>self).postMessage({subject: 'iDBQuestion', code: err.code});
            } else if (err.name !== 'SyncError') {
                alert(this.TranslateService.instant('SYNCHRONIZE|Wystąpił błąd synchronizacji...'));
                clearIDB();
            }
        } else if (~~err.status === 500 && !core.isWorker()) {
            alert(this.TranslateService.instant('SYNCHRONIZE|Sprawdź czy masz miejsce na dysku lub przeglądarka nie jest włączona w trybie incognito.'));
        }
    }

    refreshIDB() {
        let version = localStorage.getItem('iccDbVersion');
        if (this.config.EnvConfig.iccDbVersion != null && Number(version) !== this.config.EnvConfig.iccDbVersion) {
            clearIDB();
            localStorage.setItem('iccDbVersion', this.config.EnvConfig.iccDbVersion);
        }
    }

    get synced() {
        const user = this.UserService.get();
        const expTime = 14 * 24 * 3600 * 1000; // 14 dni
        let synced = true;
        let lastSync = localStorage.getItem('lastSync' + '_' + user.id);
        synced = synced && lastSync != null && Number(lastSync) + expTime > Date.now();
        if (this.config.isNative) {
            let lastUpdate = localStorage.getItem('lastUpdate');
            synced = synced && lastUpdate != null && Number(lastUpdate) + expTime > Date.now();
        }
        return synced;
    }

    get onceUpdated() {
        const user = this.UserService.get();
        const expTime = 14 * 24 * 3600 * 1000; // 14 dni
        let lastUpdate = localStorage.getItem('lastUpdate');
        return lastUpdate != null && Number(lastUpdate) + expTime > Date.now() || !this.config.isNative;
    }

    onChangedOffer() {
        if (this.StateService.state) {
            this.SocketService.socket.emit('changedOffer', this.StateService.state.offer_id);
        }
    }
}
