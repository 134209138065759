import angular from 'angular';

/**
 * Fabryka systemow bram garazowych
 * @param {object} $rootScope               Angular Root Scope
 * @param {object} $sce                     Sce
 * @param {object} $uibModal                   Dostarcza obsługę okien modalnych
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} CurConfService           Bieżaca konfiguracja
 * @param {object} ConfiguratorsDataService Fabryka danych konfiguracji
 * @param {object} StepFactory              Fabryka kroków
 * @param {object} PriceService             Liczenie cen
 * @param {object} AccessoriesFactory       Fabryka akcesoriów
 * @param {object} MosquitoTypesFactory     Fabryka typów siatek
 * @param {object} MosquitoMontagesFactory  Fabryka montażu siatek
 * @param {object} MosquitoColorsFactory    Fabryka kolorów siatek
 * @param {object} Core                     Core
 */
export default function SystemsFactory(
    $rootScope,
    $sce,
    $uibModal,
    ConfigurationsService,
    CurConfService,
    ConfiguratorsDataService, // jshint ignore:line
    StepFactory,
    PriceService,
    AccessoriesFactory,
    MosquitoTypesFactory,
    MosquitoMontagesFactory,
    MosquitoColorsFactory,
    Core,
    EventBusService
) {
    'ngInject';

    var factory = {
        systems: [],
        loadedData: false,
        getSystems,
        selectSystem,
        setDefaultValues,
        getMosquitoDimensions,
        valid,
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration(['initializedConfigurator'], () => init());

    return factory;

    /**
     * Funkcja wywołana po zaladowaniu konfiguracji
     */
    function init() {
        if (CurConfService.conf == 'mosquito') {
            factory.systems = getSystems();
            if (angular.isUndefined(ConfigurationsService.conf.Current.System.id)) {
                factory.setDefaultValues();
            }

            if (
                angular.isArray(
                    ConfiguratorsDataService.data.mosquitoTypes[
                        ConfigurationsService.conf.Current.System.id
                    ]
                )
                && ConfiguratorsDataService.data.mosquitoTypes[
                    ConfigurationsService.conf.Current.System.id
                ].length
            ) {
                StepFactory.enable('types');
            } else {
                StepFactory.disable('types');
            }

            factory.loadedData = true;
        } else {
            return;
        }
    }

    /**
     * Pobranie systemow garazowych
     */
    function getSystems() {
        return ConfiguratorsDataService.data.mosquitoLines.map((e) => {
            return angular.extend(e, { content: $sce.trustAsHtml(e.content) });
        });
    }

    /**
     * Wybor systemu
     * @param  {object}  system   Wybrany system
     * @param  {boolean} nextStep Czy przejsc do nastepnego kroku
     * @param  {boolean} price    Czy przeliczyc ceny
     */
    function selectSystem(system, nextStep, price) {
        if (CurConfService.conf != 'mosquito') {
            return;
        }
        ConfigurationsService.conf.Current.System = system;
        ConfigurationsService.conf.Default.System = system;

        ConfigurationsService.conf.Current.Name = system.name;
        ConfigurationsService.conf.Default.Name = system.name;

        MosquitoTypesFactory.setDefaultValues();
        AccessoriesFactory.findAccessories();
        MosquitoMontagesFactory.setDefaultValues();
        MosquitoColorsFactory.loadColorsBySystem(
            system.id,
            ConfigurationsService.conf.Current.Type.id
        );

        if (nextStep) {
            StepFactory.goToNextStep(true);
        }
        if (price) {
            PriceService.count();
        }

        if (
            angular.isArray(
                ConfiguratorsDataService.data.mosquitoTypes[
                    ConfigurationsService.conf.Current.System.id
                ]
            )
            && ConfiguratorsDataService.data.mosquitoTypes[
                ConfigurationsService.conf.Current.System.id
            ].length
        ) {
            StepFactory.enable('types');
        } else {
            StepFactory.disable('types');
        }

        if (
            ConfiguratorsDataService.data.mosquitoWindowLines[
                ConfigurationsService.conf.Current.System.id
            ]
            || (ConfigurationsService.conf.Current.Type
                && ConfigurationsService.conf.Current.Type.id
                && (ConfiguratorsDataService.data.mosquitoMontages.some(
                    el => el.types.indexOf(ConfigurationsService.conf.Current.Type.id) > -1
                )
                    || ConfigurationsService.conf.Current.Type.profile_position
                    || ConfigurationsService.conf.Current.Type.muntin_position))
        ) {
            StepFactory.enable('montages');
        } else {
            StepFactory.disable('montages');
        }
    }

    /**
     * Ustawienie domyslnych wartosci po uruchomieniu
     */
    function setDefaultValues() {
        factory.selectSystem(factory.systems[0]);
    }

    /**
     * Funkcja obliczająca wymiary siatki dla wybranej kwatery
     * @param  {object} sash  Skrzydło
     */
    function getMosquitoDimensions(sash) {
        const overlaps = {};

        for (const i in sash.nearMullions) {
            if (sash.nearMullions[i] === -1) {
                const frame = ConfigurationsService.conf.Current.Frames.find(
                    f => f.id === sash.frameId
                );
                const profile = Core.fIdO(
                    ConfigurationsService.conf.Current.UsedProfiles,
                    frame.profileId
                );
                overlaps[i] = profile ? profile.width : 0;
            } else {
                const mullion = Core.fIdO(
                    ConfigurationsService.conf.Current.Mullions,
                    sash.nearMullions[i]
                );
                const profile = Core.fIdO(
                    ConfigurationsService.conf.Current.UsedProfiles,
                    mullion.profileId
                );
                overlaps[i] = profile ? profile.width / 2 : 0;
            }
        }

        return {
            // dodatkowo plus 12 mm na kazda strone
            width: Math.floor(sash.shape.width - overlaps.left - overlaps.right + 24),
            height: Math.floor(sash.shape.height - overlaps.top - overlaps.bottom + 24),
        };
    }

    function valid() {
        let available = false;
        for (const key in ConfiguratorsDataService.data.mosquitoWindowLines) {
            if (
                ConfiguratorsDataService.data.mosquitoWindowLines[key].some(
                    el => el.window_line_id == ConfigurationsService.conf.Current.System.id
                )
            ) {
                available = true;
                break;
            }
        }

        if (available) {
            StepFactory.enable('mosquito');
        } else {
            StepFactory.disable('mosquito');
            ConfigurationsService.conf.Current.Sashes.forEach(sash => (sash.mosquito = null));
        }
    }
}
