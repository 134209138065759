import { Injectable, Inject } from '@angular/core';
import { core, logger } from 'helpers';
import { EventBusService } from 'event-bus.service';
import StepFactory from 'configurator/steps/StepFactory';
import { LoadedConfiguratorsDataValue } from 'configurators/configurators-data.service';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import DoorActiveConfiguration from 'configurations/DoorActiveConfiguration';
import Common from 'Common';
import PriceColorsService from 'price/price-colors.service';
import GlazingBeadsService from 'glazing-beads.service';
import { IssuesService, IssueLevel } from 'issues.service';
import { ValidationService } from 'configurators/validation.service';
import { BondedGlazingService } from './bonded-glazing.service';
import ColorMappingService from 'configurator/colors/ColorsMappingService';
import { APP_CONFIG, AppConfig } from 'config';
import DependenciesService from 'dependencies/dependencies.service';
import { TranslateService } from 'translate.service';
import GlazingSizesService from 'configurators/glazing-sizes.service';
import { fixCustomFillings } from 'fix-glazing-units';
import { Glass } from 'configurations/parts/window/Glass';
import { InterPaneSpace } from 'configurations/parts/window/InterPaneSpace';

@Injectable()
export class FillingsService {
    fillings = [];
    fillingsCategories = [];
    glassColors = [];
    modalGlassTypeData = {
        glassTab: 'glazing',
        selectedSecurity: null,
        selectedOrnament: null,
        selectedUg: null,
        selectedRw: null,
        selectedCategory: null,
    };
    hasGlasses = false;
    private loadedData = false;
    private allFillings = [];
    private allColors = [];
    private allColorsRals = [];
    private windowLineColors = [];
    private decoPanelRestrictiveDimensions = {
        minWidth: 0,
        maxWidth: Number.POSITIVE_INFINITY,
        minHeight: 0,
        maxHeight: Number.POSITIVE_INFINITY,
    };
    private glasses: Glass[] = [];
    private interPaneSpaces: InterPaneSpace[] = [];

    constructor(
        @Inject('$rootScope') private $rootScope,
        private eventBusService: EventBusService,
        private stepFactory: StepFactory,
        @Inject('InfoFactory') private infoFactory,
        @Inject('$uibModal') private $uibModal: ng.ui.bootstrap.IModalService,
        private PriceColorsService: PriceColorsService,
        private glazingBeadsService: GlazingBeadsService,
        private issuesService: IssuesService,
        private validationService: ValidationService,
        private bondedGlazingService: BondedGlazingService,
        private colorMappingService: ColorMappingService,
        @Inject(APP_CONFIG) private config: AppConfig,
        private translateService: TranslateService,
        private glazingSizesService: GlazingSizesService
    ) {
        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>(
            'loadedConfiguratorsData',
            data => {
                this.loadFillings(data.value);
                this.decoPanelRestrictiveDimensions = data.value.panelsSettings;
                this.loadedData = true;
                this.hasGlasses = !!(
                    data.value.glasses
                    && data.value.glasses.length
                    && data.value.interPaneSpaces
                    && data.value.interPaneSpaces.length
                );
            }
        );

        this.eventBusService.subscribe('*', data =>
            this.glazingSizesService.count(data.activeConfiguration)
        );

        this.eventBusService.subscribe('setSystem', data => {
            try {
                this.loadMatchingFillings(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('setConstructionColor', data => {
            try {
                if (WindowActiveConfiguration.is(data.activeConfiguration)) {
                    this.loadMatchingFillings(data.activeConfiguration);
                }
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('loadedGlazingBeads', data => {
            try {
                this.loadMatchingFillings(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('loadedFillings', data => {
            try {
                this.validateFillingsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('changedSashes', data => {
            try {
                this.validateFillingsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('changedFillings', data => {
            try {
                this.validateFillingsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe<any>('setFrameProfile', data => {
            try {
                this.validateFillingsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });
        this.eventBusService.subscribe<any>('setSashProfile', data => {
            try {
                this.validateFillingsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });
        this.eventBusService.subscribe<any>('setMullionProfile', data => {
            try {
                this.validateFillingsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe<any>('validatedProfiles', data => {
            try {
                this.validateFillingsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('setGlazingInSash', (data) => {
            try {
                this.checkSingleFilling(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
                this.validateFillings(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });
    }

    setFillingInAllSashes(conf: WindowActiveConfiguration, newFilling, isDefault = false) {
        conf.Sashes.forEach(sash => {
            this.setFillingInSash(sash, sash, newFilling, conf, isDefault);
            sash.intSashes.forEach(field => {
                this.setFillingInSash(field, sash, newFilling, conf, isDefault);
            });
        });
    }

    setFillingInFilteredSashes(
        conf: WindowActiveConfiguration,
        newFilling,
        isDefault = false,
        filter: (sash) => boolean = () => true
    ) {
        conf.Sashes.filter(filter).forEach(sash => {
            this.setFillingInSash(sash, sash, newFilling, conf, isDefault);
            sash.intSashes.forEach(field => {
                this.setFillingInSash(field, sash, newFilling, conf, isDefault);
            });
        });
    }

    setFillingInSash(field, sash, newFilling, conf, isDefault = false) {
        if (!newFilling || !newFilling.id) {
            return;
        }
        newFilling = core.copy(newFilling);
        newFilling.isDefault = isDefault;
        field.glazing = newFilling;
        if (
            field.id === sash.id
            && field.intSashes.length === 1
            && field.intSashes[0].glazing.id !== newFilling.id
        ) {
            this.setFillingInSash(field.intSashes[0], sash, newFilling, conf, isDefault);
        }
        if (field.id !== sash.id && sash.glazing.id !== newFilling.id) {
            this.setFillingInSash(sash, sash, newFilling, conf, isDefault);
        }
        this.setDecoPanelDefaults(field, sash, conf);
        this.eventBusService.post({
            key: 'setGlazingInSash',
            value: {
                glazingId: newFilling.id,
                sashId: sash,
            },
        });
    }

    setDefaultFillingInSash(field, sash, conf: WindowActiveConfiguration, defaultConf) {
        const defaultFilling = this.getDefaultFillingInSash(field, sash, conf, defaultConf);
        this.setFillingInSash(field, sash, defaultFilling, conf, true);
    }

    getDefaultFillingInSash(field, sash, conf: WindowActiveConfiguration, defaultConf) {
        const matchingFillings = this.getMatchingFillingsInSash(field, sash, conf);
        const sameGlazingDifferentThickness =
            sash.glazing
            && sash.glazing.id
            && matchingFillings.find(
                o => Number(o.id.split('.')[0]) === Number(sash.glazing.id.split('.')[0])
            );
        const defaultGlazingForSystem = matchingFillings.find(
            o => Number(o.id.split('.')[0]) === Number(conf.System.default_glass_type_id)
        );
        let defaultGlazing;
        let filling;

        if (!filling && sash.type.type === 'F' && field.glazing.type === 'deco_panels') {
            filling = core.copy(defaultGlazingForSystem);
        }
        if (!filling && sameGlazingDifferentThickness) {
            filling = core.copy(sameGlazingDifferentThickness);
        }
        if (!filling && defaultConf.Glazing && defaultConf.Glazing.id) {
            defaultGlazing = core.fIdO(matchingFillings, defaultConf.Glazing.id);
        }
        if (!filling && defaultGlazing) {
            filling = core.copy(defaultGlazing);
        }
        if (!filling && defaultGlazingForSystem) {
            filling = core.copy(defaultGlazingForSystem);
        }
        if (!filling) {
            filling = core.copy(matchingFillings[0]);
        }

        if (
            filling
            && field.id !== sash.id
            && sash.glazing.id === filling.id
            && sash.glazing.selectedColor
        ) {
            filling.selectedColor = core.copy(sash.glazing.selectedColor);
        }

        return filling;
    }

    setDecoPanelDefaults(field, sash, conf) {
        if (sash.glazing.type !== 'deco_panels') {
            sash.panelGlazing = {};
        } else {
            if (this.isValidPanelType(sash)) {
                this.setDefaultPanelGlazingInSash(sash);
            } else {
                this.setDefaultPanelTypeInSash(sash);
            }
            if (!this.isValidPanelGlazing(sash)) {
                this.setDefaultPanelGlazingInSash(sash);
            }
            this.setDefaultDecoFillingColors(conf, field.glazing);
        }
    }

    setPanelGlazingInSash(sash, panelGlazing) {
        sash.panelGlazing = core.copy(panelGlazing);
    }

    setDefaultPanelGlazingInSash(sash) {
        const defaultPanelGlazing = this.getDefaultPanelGlazing(sash);
        this.setPanelGlazingInSash(sash, defaultPanelGlazing);
    }

    setPanelTypeInSash(sash, panelType) {
        sash.panelType = panelType;
    }

    setDefaultPanelTypeInSash(sash) {
        const defaultPanelType = this.getMatchingPanelTypesForSash(sash)[0];
        this.setPanelTypeInSash(sash, defaultPanelType);
    }

    getDefaultPanelGlazing(sash) {
        const matchingPanelGlazings = this.getMatchingPanelGlazingsForSash(sash);
        return matchingPanelGlazings[0];
    }

    setPanelOptionsInAllSashes(conf: WindowActiveConfiguration, newGlazing, panelType) {
        conf.Sashes.forEach(sash => {
            this.setPanelGlazingInSash(sash, newGlazing);
            this.setPanelTypeInSash(sash, panelType);
        });
    }

    loadFillings(data) {
        this.allFillings = core.copy(data.fillings) || [];
        this.windowLineColors = core.copy(data.windowColors) || [];
        this.allColors = core.copy(data.windowColorsAll) || [];
        this.allColorsRals = core.copy(data.windowColorRals) || [];
        this.fillingsCategories = core.copy(data.fillingsCategory) || [];
        this.glassColors = core.copy(data.glassColors) || [];
        this.glasses = core.copy(data.glasses) || [];
        this.interPaneSpaces = core.copy(data.interPaneSpaces) || [];
    }

    saveCustomFilling(filling) {
        this.fillings.unshift(filling);
        this.allFillings.unshift(filling);
        const customFillings = core.parseJson(localStorage.getItem('customFillings') || '[]');
        customFillings.push(filling);
        localStorage.setItem('customFillings', JSON.stringify(customFillings));
    }

    loadMatchingFillings(conf: WindowActiveConfiguration, validate = true) {
        this.validationService.indeterminate(conf, 'loadedFillings');
        if (this.validationService.isValidElements(conf, ['system', 'colors']) && this.loadedData) {
            if (conf.CustomFillings) {
                conf.CustomFillings = fixCustomFillings(conf.CustomFillings, this.glasses, this.interPaneSpaces);
                conf.CustomFillings.forEach(customFilling => {
                    if (!this.allFillings.some(el => el.id === customFilling.id)) {
                        this.allFillings.unshift(customFilling);
                    }
                });
            }
            this.fillings = this.allFillings.filter(filling => {
                const systemTypes = filling.system_types;
                const systemIds = filling.system_ids;

                return (
                    (filling.conf.length > 0
                        || filling.type === 'panels_glazing'
                        || filling.type === 'deco_panels')
                    && (filling.type === 'panels_glazing'
                        || (Common.isArray(systemTypes)
                            && Common.isArray(systemIds)
                            && systemIds.indexOf(conf.System.id) > -1
                            && systemTypes.indexOf(conf.System.type) > -1))
                );
            });
            if (validate) {
                if (this.fillings.length === 0) {
                    this.validationService.invalid(conf, 'loadedFillings');
                    this.issuesService.registerDataProblem(
                        'no-matching-fillings',
                        'Brak pasujących wypełnień',
                        conf,
                        {
                            level: IssueLevel.FATAL,
                            extra: {
                                systemId: conf.System.id,
                                systemName: conf.System.name,
                                systemType: conf.System.type
                            }
                        }
                    );
                } else {
                    this.validationService.valid(conf, 'loadedFillings');
                    this.issuesService.unregister('no-matching-fillings', conf);
                }
                this.eventBusService.post({
                    key: 'loadedFillings',
                    value: this.fillings,
                });
            }
        }
    }

    validateFillingsAndFixIssues(conf: WindowActiveConfiguration, defaultConf) {
        this.validationService.indeterminate(conf, 'fillings');
        if (
            this.validationService.isValidElements(conf, [
                'frameProfiles',
                'sashesProfiles',
                'loadedFillings',
                'loadedGlazingBeads',
                'sashes',
            ])
        ) {
            conf.Issues = conf.Issues.filter(
                i => i.key.indexOf(`no-matching-fillings-in-sash`) === -1
            );
            const pauseId = this.eventBusService.pause(['setGlazingInSash']);
            let valid = true;
            try {
                conf.Sashes.forEach(sash => {
                    if (!this.validFillingInSash(sash, sash, conf)) {
                        this.setDefaultFillingInSash(sash, sash, conf, defaultConf);
                        valid = false;
                    }
                    sash.intSashes.forEach(field => {
                        if (!this.validFillingInSash(field, sash, conf)) {
                            this.setDefaultFillingInSash(field, sash, conf, defaultConf);
                            valid = false;
                        }
                    });
                });
                if (valid) {
                    this.validationService.valid(conf, 'fillings');
                    this.issuesService.unregister('invalid-fillings', conf);
                } else {
                    this.validationService.invalid(conf, 'fillings');
                }
            } finally {
                this.eventBusService.resume(['setGlazingInSash'], pauseId);
            }
        }
    }

    validateFillings(conf: WindowActiveConfiguration, defaultConf) {
        this.validationService.indeterminate(conf, 'fillings');
        if (
            this.validationService.isValidElements(conf, [
                'frameProfiles',
                'sashesProfiles',
                'loadedFillings',
                'loadedGlazingBeads',
                'sashes',
            ])
        ) {
            const valid = conf.Sashes.every(
                sash =>
                    this.validFillingInSash(sash, sash, conf)
                    && sash.intSashes.every(field => this.validFillingInSash(field, sash, conf))
            );
            if (!valid) {
                this.validationService.invalid(conf, 'fillings');
                this.issuesService.simpleRegister(
                    'invalid-fillings',
                    'Niepoprawne wypełnienia!',
                    this.translateService.instant('WINDOW|Niepoprawne wypełnienia!'),
                    conf,
                    {
                        level: IssueLevel.ERROR,
                        logLevel: IssueLevel.INFO,
                        blockStepsAfter: 'glazing',
                    }
                );
            } else {
                this.validationService.valid(conf, 'fillings');
                this.issuesService.unregister('invalid-fillings', conf);
            }
            this.eventBusService.post({
                key: 'validatedFillings',
                value: valid,
            });
        }
    }

    validFillingInSash(field, sash, conf: WindowActiveConfiguration) {
        if (
            !sash.glazing
            || !sash.glazing.id
            || (sash.type.type === 'F' && field.glazing.type === 'deco_panels')
        ) {
            return false;
        }
        const matchingThickness = this.glazingBeadsService.getMatchingFillingThicknessInSash(
            field,
            sash,
            conf
        );
        const filling = field.glazing;

        const systemTypes = filling.system_types;
        const systemIds = filling.system_ids;

        return (
            ((filling.type === 'deco_panels'
                && (field.intSashes.length
                    || this.isDecoPanelMatchToDimensions(filling, field, sash)))
                || (filling.type !== 'deco_panels'
                    && filling.type !== 'panels_glazing'
                    && matchingThickness.some(
                        range =>
                            Math.round(Number(filling.thickness_mm)) >= range.min
                            && Math.round(Number(filling.thickness_mm)) <= range.max
                    )
                    && (((((conf as DoorActiveConfiguration).Model
                        && (conf as DoorActiveConfiguration).Model.standard_fills)
                        || ['DRA', 'DRP', 'DOA', 'DOP'].indexOf(sash.type.type) === -1)
                        && filling.conf.indexOf('window') > -1)
                        || filling.conf.indexOf('door') > -1)))
            && Common.isArray(systemTypes)
            && Common.isArray(systemIds)
            && systemIds.indexOf(conf.System.id) > -1
            && systemTypes.indexOf(conf.System.type) > -1
        );
    }

    checkSingleFilling(conf: WindowActiveConfiguration, defaultConf: any) {
        let isSingleFilling = true;
        let isSingleFillingInWindowSashes = true;
        let isSingleFillingInDoorActiveSashes = true;
        let isSingleFillingInDoorPassiveSashes = true;
        let firstFillingId = null;
        let firstFillingInWindowSashesId = null;
        let firstFillingInDoorActiveSashesId = null;
        let firstFillingInDoorPassiveSashesId = null;

        conf.Sashes.forEach((sash, i) => {
            let isSingleFillingInSash = true;
            let firstFillingIdInSash = sash.glazing.id;

            isSingleFillingInSash = sash.intSashes.every((intSash, j) => {
                if (j === 0) {
                    firstFillingIdInSash = intSash.glazing.id;
                }
                return firstFillingIdInSash === intSash.glazing.id;
            });

            if (i === 0) {
                firstFillingId = firstFillingIdInSash;
            }

            if (sash.type.type === 'DRA' || sash.type.type === 'DOA') {
                if (!firstFillingInDoorActiveSashesId) {
                    firstFillingInDoorActiveSashesId = firstFillingIdInSash;
                }
                isSingleFillingInDoorActiveSashes =
                    isSingleFillingInDoorActiveSashes
                    && isSingleFillingInSash
                    && firstFillingInDoorActiveSashesId === firstFillingIdInSash;
            } else if (sash.type.type === 'DRP' || sash.type.type === 'DOP') {
                if (!firstFillingInDoorPassiveSashesId) {
                    firstFillingInDoorPassiveSashesId = firstFillingIdInSash;
                }
                isSingleFillingInDoorPassiveSashes =
                    firstFillingInDoorPassiveSashesId
                    && isSingleFillingInSash
                    && firstFillingInDoorPassiveSashesId === firstFillingIdInSash;
            } else {
                if (!firstFillingInWindowSashesId) {
                    firstFillingInWindowSashesId = firstFillingIdInSash;
                }
                isSingleFillingInWindowSashes =
                    isSingleFillingInWindowSashes
                    && isSingleFillingInSash
                    && firstFillingInWindowSashesId === firstFillingIdInSash;
            }

            sash.oneGlazing = isSingleFillingInSash;
            isSingleFilling =
                isSingleFilling && isSingleFillingInSash && firstFillingId === firstFillingIdInSash;
        });

        conf.OneGlazing = isSingleFilling;
        defaultConf.OneGlazing = isSingleFilling;
        if (isSingleFilling) {
            defaultConf.Glazing = { id: firstFillingId };
        }
        conf.OneFilling = {
            window: false,
            doorActive: false,
            doorPassive: false,
        };
        if (isSingleFillingInWindowSashes) {
            conf.OneFilling.window = firstFillingInWindowSashesId;
        }
        if (isSingleFillingInDoorActiveSashes) {
            conf.OneFilling.doorActive = firstFillingInDoorActiveSashesId;
        }
        if (isSingleFillingInDoorPassiveSashes) {
            conf.OneFilling.doorPassive = firstFillingInDoorPassiveSashesId;
        }
    }

    openModal(field, conf: WindowActiveConfiguration, defaultConf: WindowActiveConfiguration, sash?) {
        let matchingFillings = [];
        let selectedFilling = null;
        let bondedGlazing = false;
        let noDivInSash = true;
        if (typeof field === 'undefined' || field === 'default') {
            matchingFillings = this.getMatchingFillings(conf);
            selectedFilling = conf.Sashes[0].glazing.id;
            bondedGlazing = conf.Sashes[0].bondedGlazing;
        } else if (field === 'window') {
            matchingFillings = this.getMatchingFillingsForWindowSashes(conf);
            selectedFilling = conf.OneFilling.window;
            bondedGlazing = conf.OneBondedGlazingPerSash.window;
        } else if (field === 'doorActive') {
            matchingFillings = this.getMatchingFillingsForDoorSashes(conf, true);
            selectedFilling =
                conf.OneFilling.doorActive
                || conf.Sashes.find(s => ['DRA', 'DOA'].includes(s.type.type)).glazing.id;
            bondedGlazing = conf.OneBondedGlazingPerSash.doorActive;
            noDivInSash = conf.Sashes.filter(s => ['DRA', 'DOA'].includes(s.type.type)).every(
                s => s.intSashes.length < 2
            );
        } else if (field === 'doorPassive') {
            matchingFillings = this.getMatchingFillingsForDoorSashes(conf, false);
            selectedFilling =
                conf.OneFilling.doorPassive
                || conf.Sashes.find(s => ['DRP', 'DOP'].includes(s.type.type)).glazing.id;
            bondedGlazing = conf.OneBondedGlazingPerSash.doorPassive;
            noDivInSash = conf.Sashes.filter(s => ['DRP', 'DOP'].includes(s.type.type)).every(
                s => s.intSashes.length < 2
            );
        } else {
            matchingFillings = this.getMatchingFillingsInSash(field, sash, conf);
            selectedFilling = field.glazing.id;
            bondedGlazing = field.bondedGlazing;
        }

        const modalInstance = this.$uibModal.open({
            templateUrl: 'modalGlazing.html',
            controller: 'ModalGlazingCtrl as mglazing',
            resolve: {
                fillings: () => matchingFillings,
                fillingsCategories: () => this.fillingsCategories,
                glassColors: () => this.glassColors,
                modalData: () => this.modalGlassTypeData,
                selGlassType: () => this.getFillingFromId(selectedFilling),
                selectFor: () => sash || field,
                noDivInSash: () => noDivInSash,
                hasGlasses: () => this.hasGlasses,
                bondedGlazing: () => bondedGlazing,
                b2c: () =>
                    !this.$rootScope.user
                    || !this.$rootScope.user.access
                    || this.$rootScope.user.access === 'klient',
            },
        });

        modalInstance.result.then(selectedData => {
            if (selectedData) {
                if (selectedData.glass.type === 'pvc_panels') {
                    selectedData.glass.selectedColor = core.copy(conf.Colors);
                }

                if (typeof field !== 'undefined' && Common.isString(field) && field !== 'default') {
                    this.validateDecoSashes(conf, selectedData.glass);
                    this.setFillingInFilteredSashes(
                        conf,
                        selectedData.glass,
                        false,
                        this.getFilterForField(field)
                    );
                } else if (typeof sash === 'undefined' || Common.isString(sash)) {
                    this.validateDecoSashes(conf, selectedData.glass);
                    this.setFillingInAllSashes(conf, selectedData.glass);
                } else {
                    this.validateDecoFilling(sash || field, selectedData.glass, conf);
                    this.setFillingInSash(field, sash, selectedData.glass, conf);
                }
                if (
                    selectedData.glass.custom
                    && typeof core.fId(conf.CustomFillings, selectedData.glass.id) === 'undefined'
                ) {
                    conf.CustomFillings.push(selectedData.glass);
                    this.clearCustomFillings(conf, defaultConf);
                }

                if (typeof sash === 'undefined' || Common.isString(sash)) {
                    this.bondedGlazingService.setBondedGlazingInAllSashes(
                        conf,
                        selectedData.bondedGlazing
                    );
                } else {
                    this.bondedGlazingService.setBondedGlazingInSash(
                        field,
                        sash,
                        selectedData.bondedGlazing
                    );
                }

                this.modalGlassTypeData.glassTab = selectedData.glassTab;
                this.modalGlassTypeData.selectedSecurity = selectedData.selectedSecurity;
                this.modalGlassTypeData.selectedOrnament = selectedData.selectedOrnament;
                this.modalGlassTypeData.selectedUg = selectedData.selectedUg;
                this.modalGlassTypeData.selectedRw = selectedData.selectedRw;
                if (this.config.IccConfig.Configurators.dependencies) {
                    this.eventBusService.post({ key: 'processDependencies', value: null });
                }
                this.eventBusService.post({
                    key: 'changedFillings',
                    value: {},
                });
            }
        });

        modalInstance.closed.then(() => {
            if (this.config.IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    validateDecoSashes(conf, filling) {
        conf.Sashes.reduce((sashes, sash) => sashes.concat([sash], sash.intSashes), []).map(sash =>
            this.validateDecoFilling(sash, filling, conf)
        );
    }

    validateDecoFilling(field, filling, conf: WindowActiveConfiguration) {
        if (filling.type === 'deco_panels') {
            const valid =
                field.selectedColor
                && field.selectedColor.sash
                && filling.color_groups_ids
                    .map(Number)
                    .some(
                        groupId =>
                            field.selectedColor.sash.inner.color_groups_ids
                                .map(Number)
                                .indexOf(groupId) > -1
                    );
            if (!valid) {
                this.setDefaultDecoFillingColors(conf, filling);
                if (field.selectedColor && field.selectedColor.sash) {
                    this.infoFactory.openWarning(
                        'Przywrócono kolor domyślny panelu dekoracyjnego!'
                    );
                }
                this.eventBusService.post({
                    key: 'changedFillings',
                    value: {},
                });
            }
        }
    }

    clearCustomFillings(conf: WindowActiveConfiguration, defaultConf: WindowActiveConfiguration) {
        const ids = [];

        conf.Sashes.forEach(sash => {
            if (sash.intSashes.length) {
                sash.intSashes.forEach(isash => {
                    if (
                        isash.glazing.custom
                        && typeof core.fId(ids, isash.glazing.id) === 'undefined'
                    ) {
                        ids.push(isash.glazing.id);
                    }
                });
            } else {
                if (sash.glazing.custom && typeof core.fId(ids, sash.glazing.id) === 'undefined') {
                    ids.push(sash.glazing.id);
                }
            }
        });

        conf.CustomFillings
            = fixCustomFillings(conf.CustomFillings.filter(el => ids.indexOf(el.id) > -1), this.glasses, this.interPaneSpaces);
        defaultConf.CustomFillings = core.copy(conf.CustomFillings);
    }

    openModalPanelOptions(field, conf: WindowActiveConfiguration, sash?) {
        let panelGlazings = [];
        let filling = {};
        if (typeof field === 'undefined' || field === 'default') {
            panelGlazings = this.getMatchingPanelGlazings(conf);
            sash = conf.Sashes[0];
            filling = conf.Sashes[0].glazing;
        } else if (field === 'doorActive') {
            panelGlazings = this.getMatchingPanelGlazingsForDoorSashes(conf, true);
            sash = conf.Sashes.filter(s => s.type.type === 'DRA' || s.type.type === 'DOA')[0];
            filling = sash.glazing;
        } else if (field === 'doorPassive') {
            panelGlazings = this.getMatchingPanelGlazingsForDoorSashes(conf, false);
            sash = conf.Sashes.filter(s => s.type.type === 'DRP' || s.type.type === 'DOP')[0];
            filling = conf.OneFilling.doorPassive;
        } else {
            panelGlazings = this.getMatchingPanelGlazingsForSash(field);
            filling = field.glazing;
        }

        this.$uibModal
            .open({
                templateUrl: 'modalDecoPanelOptions.html',
                controller: 'ModalDecoPanelOptionsCtrl as $ctrl',
                resolve: {
                    fillings: () => panelGlazings,
                    fillingsCategories: () => this.fillingsCategories,
                    modalData: () => this.modalGlassTypeData,
                    selGlazing: () => sash.panelGlazing,
                    panelType: () => sash.panelType,
                    filling: () => filling,
                    filteredFillings: () =>
                        this.allFillings.filter(
                            el => el.id.split('.')[0] === sash.glazing.id.split('.')[0]
                        ),
                },
            })
            .result.then(selectedData => {
                if (selectedData) {
                    selectedData.filling.selectedColor = sash.intSashes[0].glazing.selectedColor;
                    selectedData.filling.selectedColorSecond =
                        sash.intSashes[0].glazing.selectedColorSecond;
                    this.setFillingInSash(sash, sash, selectedData.filling, conf);
                    if (typeof sash === 'undefined' || sash === 'default') {
                        this.setPanelOptionsInAllSashes(
                            conf,
                            selectedData.selGlazing,
                            selectedData.panelType
                        );
                    } else {
                        this.setPanelGlazingInSash(sash, selectedData.selGlazing);
                        this.setPanelTypeInSash(sash, selectedData.panelType);
                    }
                }
            });
    }

    getMatchingFillings(conf: WindowActiveConfiguration) {
        return this.getMatchingFillingsFor(conf, () => true);
    }

    private setDefaultDecoFillingColors(conf: WindowActiveConfiguration, filling: any) {
        const firstColor = {
            frame: {
                inner: null,
                outer: null,
                core: null,
                alushell: {},
            },
            sash: {
                inner: null,
                outer: null,
                core: null,
                alushell: {},
            },
        };
        const secondColor = {
            frame: {
                inner: null,
                outer: null,
                core: null,
                alushell: {},
            },
            sash: {
                inner: null,
                outer: null,
                core: null,
                alushell: {},
            },
        };

        firstColor.frame.inner = this.getDefaultDecoFillingColor(conf, filling, 'inner');
        firstColor.frame.outer = this.getDefaultDecoFillingColor(conf, filling, 'outer');
        firstColor.frame.core = core.copy(conf.Colors.sash.core);
        firstColor.sash.inner = firstColor.frame.inner;
        firstColor.sash.outer = firstColor.frame.outer;
        firstColor.sash.core = core.copy(conf.Colors.sash.core);
        filling.selectedColor = firstColor;
        if (filling.available_second_color) {
            secondColor.frame.inner = this.getDefaultDecoFillingColor(
                conf,
                filling,
                'inner',
                'second'
            );
            secondColor.frame.outer = this.getDefaultDecoFillingColor(
                conf,
                filling,
                'outer',
                'second'
            );
            secondColor.frame.core = core.copy(conf.Colors.sash.core);
            secondColor.sash.inner = secondColor.frame.inner;
            secondColor.sash.outer = secondColor.frame.outer;
            secondColor.sash.core = core.copy(conf.Colors.sash.core);
            filling.selectedColorSecond = secondColor;
        }
    }

    private getDefaultDecoFillingColor(
        conf: WindowActiveConfiguration,
        filling,
        side: 'outer' | 'inner',
        which = 'first'
    ) {
        const availColors = this.allColors
            .concat(this.allColorsRals)
            .filter(
                color =>
                    color.groups
                    && filling.color_groups_ids
                        .map(Number)
                        .some(c => color.groups.map(Number).indexOf(~~c) > -1)
            );
        const windowColorId = this.colorMappingService.getWindowColorId(conf, side);
        const windowColorRal = this.colorMappingService.getWindowColorId(conf, side, 'RAL');
        const matchedColors = this.colorMappingService.getColors(
            windowColorId,
            windowColorRal ? 'ral' : 'window',
            'window'
        );
        const matchedColorsRal = this.colorMappingService.getColors(
            windowColorId,
            windowColorRal ? 'ral' : 'window',
            'ral'
        );
        const windowColors = matchedColors
            .map(m => availColors.filter(c => Number(c.id) === Number(m) && !c.RAL)[0])
            .filter(m => m);
        const windowColorsRal = matchedColorsRal
            .map(m => availColors.filter(c => Number(c.id) === Number(m) && c.RAL)[0])
            .filter(m => m);

        const defaultColors = JSON.parse(filling.default_colors);
        const fillingDefaultColor = defaultColors
            ? core.fIdO(this.allColors, defaultColors[which].colorId)
            : null;
        let color;
        if (Common.isArray(windowColors) && Common.isDefined(windowColors[0])) {
            if (windowColors[0].type === 'white') {
                return {};
            }
            color = core.copy(windowColors[0]);
        } else if (Common.isArray(windowColorsRal) && Common.isDefined(windowColorsRal[0])) {
            color = core.copy(windowColorsRal[0]);
        } else if (fillingDefaultColor) {
            color = fillingDefaultColor;
        } else if (Common.isArray(availColors) && Common.isDefined(availColors[0])) {
            color = core.copy(availColors[0]);
        } else {
            return {};
        }
        color.isDefault = true;
        return color;
    }

    private getFilterForField(field) {
        let sashFilter = sash => true;
        if (field === 'window') {
            sashFilter = sash => ['DRA', 'DRP', 'DOA', 'DRP'].indexOf(sash.type.type) === -1;
        } else if (field === 'doorActive') {
            sashFilter = sash => ['DRA', 'DOA'].indexOf(sash.type.type) > -1;
        } else if (field === 'doorPassive') {
            sashFilter = sash => ['DRP', 'DOP'].indexOf(sash.type.type) > -1;
        }

        return sashFilter;
    }

    private getMatchingFillingsForWindowSashes(conf: WindowActiveConfiguration) {
        return this.getMatchingFillingsFor(conf, this.getFilterForField('window'));
    }

    private getMatchingFillingsForDoorSashes(conf: WindowActiveConfiguration, active = true) {
        return this.getMatchingFillingsFor(
            conf,
            this.getFilterForField(active ? 'doorActive' : 'doorPassive')
        );
    }

    private getMatchingFillingsFor(conf: WindowActiveConfiguration, filter: (sash) => boolean) {
        let matchingFillings = [];
        (conf.Sashes || []).filter(filter).forEach(sash => {
            sash.intSashes.forEach(intSash => {
                matchingFillings = this.getCommonFillingsInSash(
                    matchingFillings,
                    intSash,
                    sash,
                    conf
                );
            });
            if (sash.intSashes.length === 0) {
                matchingFillings = this.getCommonFillingsInSash(matchingFillings, sash, sash, conf);
            }
        });
        return matchingFillings;
    }

    private getMatchingFillingsInSash(
        field,
        sash,
        conf: WindowActiveConfiguration | DoorActiveConfiguration
    ) {
        if (
            this.validationService.isValidElements(conf, [
                'frameProfiles',
                'sashesProfiles',
                'loadedFillings',
                'loadedGlazingBeads',
                'sashes',
            ])
        ) {
            const matchingThickness = this.glazingBeadsService.getMatchingFillingThicknessInSash(
                field,
                sash,
                conf
            );

            const matchingFillings = this.fillings.filter(
                filling =>
                    (filling.type === 'deco_panels'
                        && this.isDecoPanelMatchToDimensions(filling, field, sash))
                    || (filling.type !== 'deco_panels'
                        && filling.type !== 'panels_glazing'
                        && matchingThickness.some(
                            range =>
                                Math.round(Number(filling.thickness_mm)) >= range.min
                                && Math.round(Number(filling.thickness_mm)) <= range.max
                        )
                        && (((((conf as DoorActiveConfiguration).Model
                            && (conf as DoorActiveConfiguration).Model.standard_fills)
                            || ['DRA', 'DRP', 'DOA', 'DRP', 'FD', 'FDO'].indexOf(sash.type.type)
                                === -1)
                            && filling.conf.indexOf('window') > -1)
                            || filling.conf.indexOf('door') > -1))
            );
            if (matchingFillings.length === 0) {
                this.issuesService.registerDataProblem(
                    `no-matching-fillings-in-sash-${field.id}-${sash.id}`,
                    'Brak pasujących wypełnień',
                    conf,
                    {
                        level: IssueLevel.FATAL,
                        extra: {
                            matchingThickness: core.stringJson(matchingThickness),
                            sashType: sash.type.type,
                            systemId: conf.System.id,
                            systemName: conf.System.name,
                            systemType: conf.System.type
                        }
                    }
                );
            } else {
                this.issuesService.unregister(
                    `no-matching-fillings-in-sash-${field.id}-${sash.id}`,
                    conf
                );
            }
            return matchingFillings;
        }
        return [];
    }

    private isDecoPanelMatchToDimensions(filling, field, sash) {
        const panelDimensions = this.getPanelDimensions(field, sash);
        const panelWidthMin =
            filling.panel_width_min || this.decoPanelRestrictiveDimensions.minWidth;
        const panelHeightMin =
            filling.panel_height_min || this.decoPanelRestrictiveDimensions.minHeight;
        const panelWidthMax =
            filling.panel_width_max || this.decoPanelRestrictiveDimensions.maxWidth;
        const panelHeightMax =
            filling.panel_height_max || this.decoPanelRestrictiveDimensions.maxHeight;
        return (
            parseFloat(panelWidthMin) <= panelDimensions.width
            && parseFloat(panelWidthMax) >= panelDimensions.width
            && parseFloat(panelHeightMin) <= panelDimensions.height
            && parseFloat(panelHeightMax) >= panelDimensions.height
        );
    }

    private getPanelDimensions(field, sash) {
        let panelWidth = sash.shape.width;
        let panelHeight = sash.shape.height;
        const frameSizeMap = {
            Outer: 'widthOut',
            Double: 'widthOut',
            Inner: 'width',
            Inset: null,
        };
        if (frameSizeMap[sash.panelType]) {
            panelWidth =
                field.glazingSizes.width
                + (sash.frame.right[frameSizeMap[sash.panelType]] * 2 || 0);
            panelHeight =
                field.glazingSizes.height
                + (sash.frame.right[frameSizeMap[sash.panelType]] * 2 || 0);
        } else {
            panelWidth = field.glazingSizes.width;
            panelHeight = field.glazingSizes.height;
        }
        return {
            width: panelWidth,
            height: panelHeight,
        };
    }

    private getMatchingPanelGlazings(conf: WindowActiveConfiguration) {
        return this.getMatchingPanelGlazingsFor(
            conf,
            sash => ['DRA', 'DRP', 'DOA', 'DOP'].indexOf(sash.type.type) > -1
        );
    }

    private getMatchingPanelGlazingsForDoorSashes(conf: WindowActiveConfiguration, active = true) {
        const doorType = active ? ['DRA', 'DOA'] : ['DRP', 'DOP'];
        return this.getMatchingPanelGlazingsFor(
            conf,
            sash => doorType.indexOf(sash.type.type) > -1
        );
    }

    private getMatchingPanelGlazingsFor(
        conf: WindowActiveConfiguration,
        filter: (sash) => boolean
    ) {
        let matchingFillings = [];
        conf.Sashes.filter(filter).forEach(sash => {
            sash.intSashes.forEach(intSash => {
                matchingFillings = this.getCommonPanelGlazingsInSash(matchingFillings, intSash);
            });
            if (sash.intSashes.length === 0) {
                matchingFillings = this.getCommonPanelGlazingsInSash(matchingFillings, sash);
            }
        });
        return matchingFillings;
    }

    private getMatchingPanelGlazingsForSash(field) {
        return this.fillings.filter(
            filling =>
                filling.type === 'panels_glazing'
                && field.glazing.panel_glazings_ids.map(Number).indexOf(parseInt(filling.id)) > -1
        );
    }
    private getMatchingPanelTypesForSash(sash) {
        const panelTypes = {
            Inset: 'panel_type_inset',
            Outer: 'panel_type_outer',
            Double: 'panel_type_double',
            Inner: 'panel_type_inner',
        };
        return Object.keys(panelTypes).reduce((prev, type) => {
            if (sash.glazing.panel_type === panelTypes[type]) {
                prev.push(type);
            }
            return prev;
        }, []);
    }

    private getCommonFillingsInSash(
        matchingFillings,
        field,
        sash,
        conf: WindowActiveConfiguration
    ) {
        if (matchingFillings.length === 0) {
            matchingFillings = this.getMatchingFillingsInSash(field, sash, conf);
        } else {
            matchingFillings = this.getCommonFillings(
                matchingFillings,
                this.getMatchingFillingsInSash(field, sash, conf)
            );
        }
        return matchingFillings;
    }

    private getCommonFillings(list1: any[], list2: any[]) {
        return list1.filter(function(a) {
            return this.has(a.id);
        }, list2.reduce((hash, b) => hash.add(b.id), new Set()));
    }

    private getCommonPanelGlazingsInSash(matchingFillings, field) {
        if (matchingFillings.length === 0) {
            matchingFillings = this.getMatchingPanelGlazingsForSash(field);
        } else {
            matchingFillings = this.getCommonFillings(
                matchingFillings,
                this.getMatchingPanelGlazingsForSash(field)
            );
        }
        return matchingFillings;
    }

    private isValidPanelType(sash) {
        return this.getMatchingPanelTypesForSash(sash).indexOf(sash.panelType) > -1;
    }

    private isValidPanelGlazing(sash) {
        return (
            !sash.panelGlazing
            || this.getMatchingPanelGlazingsForSash(sash)
                .map(f => Number(f.id))
                .indexOf(Number(sash.panelGlazing.id)) === -1
        );
    }

    private getFillingFromId(id: string) {
        return this.allFillings.find(filling => filling.id === id);
    }
}
