import { Injectable, Inject } from '@angular/core';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import { EventBusService } from 'event-bus.service';
import { LoadedConfiguratorsDataValue } from 'configurators/configurators-data.service';
import { ActiveSash } from 'layout/active-sash';
import { core } from 'helpers';
import Common from 'Common';
import { AlignmentsService } from './alignments.service';
import ParametersService from 'configurators/parameters.service';
import PriceService from 'price/price.service';
import ProfilesService from 'profiles.service';
import { TranslateService } from 'translate.service';
import { ValidationService } from 'configurators/validation.service';
import { IssuesService, IssueLevel } from 'issues.service';
import BrowserShapeService from './shape.service';
import { IccSashType } from 'data-types';
import { SashesLayoutService } from './sashes-layout.service';
import { SplitFrameService } from './split-frame.service';
import { FixSashThresholdService } from './fix-sash-threshold.service';

@Injectable()
export class SashTypesService {
    sashTypes: IccSashType[] = [];

    constructor(
        private eventBusService: EventBusService,
        private alignmentsService: AlignmentsService,
        @Inject('WarrantyFactory') private warrantyFactory,
        private parametersService: ParametersService,
        private priceService: PriceService,
        @Inject('GlazingFactory') private glazingFactory,
        private profilesService: ProfilesService,
        private translateService: TranslateService,
        private validationService: ValidationService,
        private issuesService: IssuesService,
        private shapeService: BrowserShapeService,
        @Inject('ConstructionLimitationFactory') private constructionLimitationFactory,
        @Inject('HandlesFactory') private handlesFactory,
        private sashesLayoutService: SashesLayoutService,
        private splitFrameService: SplitFrameService,
        private fixSashThresholdService: FixSashThresholdService
    ) {
        this.eventBusService.subscribeWithoutConfiguration<LoadedConfiguratorsDataValue>(
            'loadedConfiguratorsData',
            data => this.init(data.value)
        );

        this.eventBusService.subscribe(['setSystem', 'loadedProfiles'], data => {
            this.setSashesTypes(data.activeConfiguration as WindowActiveConfiguration);
        });
    }

    /**
     * Zmiana niedostępnych typów kwater po zmianie systemu
     *
     * @param {WindowActiveConfiguration} conf Wybrana konfiguracja
     */
    setSashesTypes(conf: WindowActiveConfiguration) {
        if (!this.validationService.isValidElements(conf, ['system', 'loadedProfiles'])) {
            return;
        }
        const compatibleTypes = {
            // 'K': 'OKL',
            D: 'OD',
            DS: 'ODS',
            DSH: 'ODSH',
            DRA: 'DOA',
            DRP: 'DOP',
        };

        const defaultSashType = this.sashTypes.find(el => el.type === 'F');

        const indexes = [];

        conf.Sashes.forEach(sash => {
            let options = ['bottom'];
            if (sash.nearMullions.bottom > -1) {
                const bottomSashes = conf.Mullions.find(el => el.id === sash.nearMullions.bottom)
                    .multiAlignBottom;

                if (sash.type.type === 'SD' || bottomSashes.some(el => el.type.type === 'SU')) {
                    options = ['bottom_in_top_sash'];
                }
            } else if (sash.nearMullions.top > -1) {
                const topSashes = conf.Mullions.find(el => el.id === sash.nearMullions.top)
                    .multiAlignTop;

                if (sash.type.type === 'SU' || topSashes.some(el => el.type.type === 'SD')) {
                    options = ['bottom_in_bottom_sash'];
                }
            }
            const profiles = this.profilesService.getFilteredProfiles(conf, 'sash', {
                and: options,
            });

            const innerProfiles = profiles.some(el => !el.options.includes('outward_opening'));
            const outerProfiles = profiles.some(el => el.options.includes('outward_opening'));

            let index: number;
            let type: string;
            let valid = true;

            if (!sash.type.out_open && !innerProfiles) {
                index = Object.keys(compatibleTypes).indexOf(sash.type.type);
                type = index !== -1 ? Object.values(compatibleTypes)[index] : null;
                valid = false;
            }

            if (sash.type.out_open && !outerProfiles) {
                index = Object.values(compatibleTypes).indexOf(sash.type.type);
                type = index !== -1 ? Object.keys(compatibleTypes)[index] : null;
                valid = false;
            }

            if (!valid) {
                const sashType = this.sashTypes.find(
                    el => el.type === type && el.handle_position === sash.type.handle_position
                );

                this.setType(sash, sashType || defaultSashType, conf);

                indexes.push(sash.index);
            }
        });

        if (indexes.length) {
            this.issuesService.simpleRegister(
                'fixSashTypeAfterSetSystem',
                `Zaktualizowano typy skrzydeł w podanych kwaterach. Powód: brak pasujących profili.`,
                this.translateService.instant(
                    'WINDOW|Zaktualizowano typy skrzydeł w podanych kwaterach: {indexes}. Powód: brak pasujących profili.',
                    {
                        indexes: indexes.join(', '),
                    }
                ),
                conf,
                {
                    logLevel: IssueLevel.NONE,
                    extra: {
                        indexes
                    }
                }
            );
        }
    }

    /**
     * Ustawia typ skrzydła.
     *
     * @param {Sash} sash Skrzydło.
     * @param {object} type Typ skrzydła.
     */
    setType(sash: ActiveSash, type: IccSashType, conf: WindowActiveConfiguration) {
        sash.type = core.copy(type);

        if (Common.isDefined(sash.type) && sash.type.type === 'F') {
            sash.intSashes = [];
            sash.intMullions = [];
            sash.intAlignments = [];
            sash.intEdgeSashes = { top: [], bottom: [], left: [], right: [] };
        }

        if (
            (Common.isUndefined(sash.intSashes) || Common.isUndefined(sash.intSashes[0]))
            && sash.type.type !== 'F'
        ) {
            sash = this.sashesLayoutService.createInternalSash(sash, conf);
        }

        this.checkSashesType(conf);
        this.eventBusService.post({
            key: 'changedSashes',
            value: {},
        });
        this.fixSashThresholdService.validateThresholdAlignmentsAndFixIssues(conf);
        this.splitFrameService.automaticallySplitFrames(conf);
        this.shapeService.setShapes(conf);
        this.handlesFactory.checkIsOneHandleAndAllHasHandle(conf, true);
        this.handlesFactory.refreshTypes(conf);

        this.constructionLimitationFactory.findReinforcement(conf);
        this.priceService.count();
        this.parametersService.count(conf);
        this.warrantyFactory.check(conf);
        conf.Layout.changed = true;

        this.eventBusService.post({
            key: 'icc-redraw',
            value: 'frame',
        });
    }

    /**
     * Aktualizuje typy skrzydeł w konstrukcji.
     */
    refreshTypes(conf: WindowActiveConfiguration) {
        for (let i = conf.Sashes.length - 1; i >= 0; i--) {
            let sash = conf.Sashes[i];

            if (Common.isDefined(sash.type) && sash.type.type === 'F') {
                sash.intSashes = [];
                sash.intMullions = [];
                sash.intEdgeSashes = {
                    top: [],
                    bottom: [],
                    left: [],
                    right: [],
                };
            }

            if (
                (Common.isUndefined(sash.intSashes) || Common.isUndefined(sash.intSashes[0]))
                && sash.type.type !== 'F'
            ) {
                sash = this.sashesLayoutService.createInternalSash(sash, conf);
            }
        }
        this.checkSashesType(conf);
    }

    /**
     * Sprawdza użyte typy skrzydeł w konstrukcji.
     */
    checkSashesType(conf: WindowActiveConfiguration) {
        let allFix = true;
        let allFixSash = true;
        let allFunc = true;

        conf.OwnedSashesTypes = {
            window: false,
            doorActive: false,
            doorPassive: false,
        };
        for (let i = conf.Sashes.length - 1; i >= 0; i--) {
            const sash = conf.Sashes[i];

            if (Common.isDefined(sash.type)) {
                if (sash.type.type === 'F' || sash.type.type === 'FF' || sash.type.type === 'OFF') {
                    allFunc = false;
                    if (sash.type.type === 'F') {
                        allFixSash = false;
                    } else {
                        allFix = false;
                    }
                } else {
                    allFix = false;
                    allFixSash = false;
                }

                if (sash.type.type === 'DRA' || sash.type.type === 'DOA') {
                    conf.OwnedSashesTypes.doorActive = true;
                } else if (sash.type.type === 'DRP' || sash.type.type === 'DOP') {
                    conf.OwnedSashesTypes.doorPassive = true;
                } else {
                    conf.OwnedSashesTypes.window = true;
                }
            }
        }
        if (allFix) {
            conf.SashesType = 'Fix';
        } else if (allFixSash) {
            conf.SashesType = 'FixSash';
        } else if (allFunc) {
            conf.SashesType = 'Func';
        } else {
            conf.SashesType = 'Mix';
        }
    }

    /**
     * Funkcja inicjujaca
     */
    private init(data: LoadedConfiguratorsDataValue) {
        this.sashTypes = data.sashTypes;
    }
}
