import Common from '../../../common/Common';
import { core } from 'helpers';
const pdfOptionsTemplate = require<string>('ngtemplate-loader!./pdf_options/pdf_options.html');

export default class PdfService {
    constructor(
        private $rootScope,
        private $timeout,
        private $httpParamSerializer,
        private ProjectName,
        private EnvConfig,
        private $uibModal,
        private $translate,
        private IccConfig,
        private isElectron,
        private InfoFactory,
        private DatabaseManager,
        private translateFilter,
        private TokenService,
        private LangService,
        private OnlineStatusService,
        private PositionsFactory
    ) {
        'ngInject';
    }

    /**
     * Otwieranie widoku pdf
     */
    openPdf(offerId, options, isOrder = false) {
        if (this.isElectron) {
            this.openPdfOffline(offerId, options, isOrder);
        } else {
            this.openPdfOnline(offerId, options);
        }
    }

    async openPdfOffline(
        offerId,
        {
            lang,
            orderPreview = false,
            completion = false,
            prices = true,
            word = false,
            extended = false,
            discounts = true,
            hideLegalNotices = false,
            extendedDrawDimensions = false,
            notValuatedPositionsList = '',
            client = true,
            measurements = false,
        }: OfferOptions,
        isOrder = false
    ) {
        orderPreview =
            this.$rootScope.user.access === 'producent' && this.IccConfig.PDF.producerOfferLikeOrder
                ? true
                : orderPreview;
        lang = Common.isDefined(lang) && lang.length ? lang : this.$rootScope.curLang;
        const fileName = await this.getFilename(offerId);
        if (await this.PositionsFactory.checkOfferPositions(offerId)) {
            this.InfoFactory.showInfo(
                this.$translate.instant('OFFER|Trwa generowanie'),
                null,
                null,
                false
            );
            this.$rootScope.$apply();
            window.ipc.send('pdf', {
                offerId,
                fileName,
                query: {
                    orderPreview,
                    completion,
                    prices,
                    word,
                    extended,
                    discounts,
                    hideLegalNotices,
                    extendedDrawDimensions,
                    project: this.ProjectName,
                    notValuatedPositionsList,
                    client,
                    measurements,
                },
                lang,
                project: this.ProjectName,
                params: {
                    id: offerId,
                    lang,
                },
                IccConfig: this.IccConfig,
                EnvConfig: this.EnvConfig,
                base: `icc-res://${location.hostname}`,
            });
            window.ipc.once('pdf-generated', event => {
                this.InfoFactory.hideInfo();
                this.$rootScope.$apply();
            });
        } else {
            if (isOrder) {
                this.$rootScope.showInfo(
                    this.$translate.instant(
                        'OFFER|Nie można pobrać PDF, ponieważ nie masz zsynchronizowanych danych tego zamówienia.'
                    ),
                    null
                );
                this.$rootScope.$apply();
            } else {
                this.$rootScope.showInfo(
                    this.$translate.instant(
                        'OFFER|Nie można pobrać PDF, ponieważ nie masz zsynchronizowanych danych tej oferty.'
                    ),
                    null
                );
                this.$rootScope.$apply();
            }
        }
    }

    async getFilename(offerId) {
        const offer = await this.DatabaseManager.get('Offer').get(offerId);
        return [
            ~~offer.order
                ? this.translateFilter('OFFER|Zamówienie')
                : this.translateFilter('OFFER|Oferta'),
            '-',
            offer.key ? core.parseJson(offer.key).join('') : offer.id,
            '.pdf',
        ]
            .join('')
            .replace(/\//g, '-');
    }

    openDoc(offerId, { lang }: OfferOptions) {
        if (this.OnlineStatusService.getStatus()) {
            lang = Common.isDefined(lang) && lang.length ? lang : this.$rootScope.curLang;
            const address = `${location.protocol}//${
                location.hostname
            }/word/words/generate/${offerId}?`;
            const queryParams = this.$httpParamSerializer({
                lang,
                project: this.ProjectName,
                token: this.TokenService.getToken(),
            });
            if (this.isElectron) {
                window.ipc.send('download-file', address + queryParams);
            } else {
                window.open(address + queryParams, '_blank');
            }
        } else {
            this.InfoFactory.showInfo(
                this.$translate.instant(
                    'OFFER|Nie można pobrać dokumentu Word ponieważ jesteś offline'
                ),
                null,
                null,
                false
            );
        }
    }

    openPdfOptions(offer, extendedPdf) {
        this.$uibModal
            .open({
                templateUrl: pdfOptionsTemplate,
                controller: 'ModalPdfOptionsCtrl as $ctrl',
                windowClass: 'pdfOptions',
                resolve: {
                    userGroup: () => (this.$rootScope.user ? this.$rootScope.user.access : ''),
                    extendedPdf: () => extendedPdf,
                    order: () => Boolean(Number(offer.order)),
                    user: () => (this.$rootScope.user ? this.$rootScope.user : {}),
                },
            })
            .result.then(options => {
                if (options) {
                    if (options.word) {
                        this.openDoc(offer.tmp_id, options);
                    } else {
                        this.openPdf(offer.tmp_id, options);
                    }
                }
            });
    }

    private openPdfOnline(
        offerId,
        {
            lang,
            orderPreview = false,
            completion = false,
            prices = true,
            word = false,
            extended = false,
            discounts = true,
            hideLegalNotices = false,
            extendedDrawDimensions = false,
            notValuatedPositionsList = '',
            client = true,
            measurements = false,
            showUw = true,
            showSw = true,
            showWeight = true,
            showCircuit = true,
            showArea = true,
        }: OfferOptions
    ) {
        orderPreview =
            this.$rootScope.user.access === 'producent' && this.IccConfig.PDF.producerOfferLikeOrder
                ? true
                : orderPreview;
        lang = Common.isDefined(lang) && lang.length ? lang : this.LangService.getLang();
        const address = `${this.EnvConfig.remoteHost
            || location.protocol + '//' + location.hostname}/api/pdf/${lang}/${offerId}?`;
        const queryParams = this.$httpParamSerializer({
            orderPreview,
            completion,
            prices,
            word,
            extended,
            discounts,
            hideLegalNotices,
            extendedDrawDimensions,
            project: this.ProjectName,
            notValuatedPositionsList,
            client,
            measurements,
            showUw,
            showSw,
            showWeight,
            showCircuit,
            showArea,
            token: this.TokenService.getToken(),
        });
        window.open(address + queryParams, '_blank');
    }
}
