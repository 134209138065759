import angular from 'angular';

import Accessories from './accessories/accessories.module.ajs';
import ColorsModule from './colors/colors.module.ajs';
import DimensionsModule from './dimensions/dimensions.module.ajs';
import GlazingsModule from './glazings/glazings.module.ajs';
import HandlesModule from './handles/handles.module.ajs';
import MuntinsModule from './muntins/muntins.module.ajs';
import Sashes from './sashes/sashes';
import SystemsModule from './systems/systems.module.ajs';
import ProfilesModule from './profiles/profiles.module.ajs';

import MosquitoCtrl from './MosquitoCtrl';
import RollerShutterCtrl from './RollerShutterCtrl';

export default angular.module('icc.configurator.steps.window', [
    Accessories,
    ColorsModule,
    DimensionsModule,
    GlazingsModule,
    HandlesModule,
    MuntinsModule,
    Sashes,
    SystemsModule,
    ProfilesModule
])
    .controller('MosquitoCtrl', MosquitoCtrl)
    .controller('RollerShutterCtrl', RollerShutterCtrl)
    .name;
