import { Injectable, Inject } from '@angular/core';
import Common from 'Common';
import { EventBusService } from 'event-bus.service';
import { core } from 'helpers';
import { LoadedConfiguratorsDataValue } from 'configurators/configurators-data.service';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import { IssuesService, IssueLevel } from 'issues.service';
import { ValidationService } from 'configurators/validation.service';
import ColorsMappingService from 'configurator/colors/ColorsMappingService';
import { APP_CONFIG, AppConfig } from 'config';
import { TranslateService } from 'translate.service';

@Injectable()
export class MuntinColorsService {
    colorRals = [];
    colors = [];
    colorGroups = [];
    woodType = 0;
    data = null;

    constructor(
        @Inject('$uibModal') private $uibModal: ng.ui.bootstrap.IModalService,
        private eventBusService: EventBusService,
        private issuesService: IssuesService,
        private validationService: ValidationService,
        private colorMappingService: ColorsMappingService,
        @Inject(APP_CONFIG) private config: AppConfig,
        private translateService: TranslateService,
    ) {
        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>(
            'loadedConfiguratorsData',
            data => {
                this.loadColors(data.value, data.activeConfiguration as WindowActiveConfiguration);
            }
        );

        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>('setDefaultColors', data => {
            this.setWood(data.activeConfiguration as WindowActiveConfiguration);
            this.validateColorsAndFixIsses(data.activeConfiguration as WindowActiveConfiguration);
        });

        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>(
            'setConstructionColor',
            data => {
                if (WindowActiveConfiguration.is(data.activeConfiguration)) {
                    this.setMatchingColorsIfDefault(data.activeConfiguration);
                }
            }
        );

        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>('setSystem', data => {
            this.validateColorsAndFixIsses(data.activeConfiguration as WindowActiveConfiguration);
        });

        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>('setMuntinType', data => {
            this.validateColorsAndFixIsses(data.activeConfiguration as WindowActiveConfiguration);
        });

        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>('setMuntinColor', data => {
            this.validateColors(data.activeConfiguration as WindowActiveConfiguration);
        });
    }

    getMuntinColors(muntinType) {
        return this.colors
            .concat(this.colorRals)
            .filter(
                color =>
                    color.groups
                    && color.groups
                        .map(Number)
                        .some(
                            groupId =>
                                muntinType.colorGroups
                                && muntinType.colorGroups.map(Number).includes(groupId)
                        )
                    && this.filterColorByWoodType(color)
            );
    }

    getMuntinColorGroups(muntinType) {
        return this.colorGroups.filter(
            colorGroup =>
                muntinType.colorGroups
                && muntinType.colorGroups.map(Number).includes(Number(colorGroup.id))
        );
    }

    /**
     * Funkcja okna modal kolorów
     * @param  {object} conf Konfiguracja
     * @param  {object} side Strona
     */
    openModalColor(conf, side) {
        const modalInstance = this.$uibModal.open({
            templateUrl: 'modalMuntinsColor.html',
            controller: 'ModalMuntinsColorCtrl as mmuntinscolor',
            resolve: {
                barWidth: () => conf.MuntinsData.rWidth,
                colors: () => this.getMuntinColors(conf.MuntinsData.type),
                colorGroups: () => this.getMuntinColorGroups(conf.MuntinsData.type),
                selColor: () => {
                    switch (side) {
                        case 'out':
                            return conf.MuntinsData.colorOut;
                        case 'in':
                            return conf.MuntinsData.color;
                        default:
                            return conf.MuntinsData.color;
                    }
                },
                muntinType: () => conf.MuntinsData.type.type,
            },
        });

        modalInstance.result.then(selectedColor => {
            if (Common.isDefined(selectedColor)) {
                this.setMuntinsColor(conf, selectedColor, side);
            }
        });

        modalInstance.closed.then(() => {
            if (this.config.IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'glazingSashes',
                });
            }
        });

        if (IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'color',
            });
        }
    }

    /**
     * Funkcja ustawiania domyślnego koloru
     * @param {object} conf Konfiguracja
     */
    setDefaultColors(conf) {
        this.setMuntinsColor(conf, this.getDefaultMuntinColor(conf, 'inner'), 'in');
        this.setMuntinsColor(conf, this.getDefaultMuntinColor(conf, 'outer'), 'out');
    }

    /**
     * Funkcja ustawiania koloru szprosów
     * @param {object} conf  Konfiguracja
     * @param {object} color Kolor
     * @param {object} side  Strona
     */
    setMuntinsColor(conf, color, side) {
        color = { ...color };
        if (
            (color.color === null || color.color === '')
            && (color.color_img === null || color.color_img === '')
        ) {
            color.color = 'FFFFFF';
        }

        if (['Bilateral', 'White', 'Cream'].indexOf(conf.ColorType) > -1 && side === 'in') {
            conf.MuntinsData.colorOut = color;
            conf.MuntinsData.color = color;
        } else {
            if (side === 'out') {
                conf.MuntinsData.colorOut = color;
            } else {
                conf.MuntinsData.color = color;
            }
        }
        this.eventBusService.post({
            key: 'setMuntinColor',
            value: {
                color,
            },
        });
    }

    validColors(conf: WindowActiveConfiguration) {
        const availColors = this.getMuntinColors(conf.MuntinsData.type);
        const innerColorAvailable = availColors.some(
            el => el.id === conf.MuntinsData.color.id && el.RAL === conf.MuntinsData.color.RAL
        );
        const outerColorAvailable = availColors.some(
            el => el.id === conf.MuntinsData.colorOut.id && el.RAL === conf.MuntinsData.colorOut.RAL
        );
        return innerColorAvailable && outerColorAvailable;
    }

    setMatchingColorsIfDefault(conf: WindowActiveConfiguration) {
        if (conf.MuntinsData.color.isDefault) {
            this.setMuntinsColor(conf, this.getDefaultMuntinColor(conf, 'inner'), 'in');
        }
        if (conf.MuntinsData.colorOut.isDefault) {
            this.setMuntinsColor(conf, this.getDefaultMuntinColor(conf, 'outer'), 'out');
        }
        if (
            conf.HasMuntins
            && ['Bilateral', 'White', 'Cream'].indexOf(conf.ColorType) > -1
            && !conf.HasAlushell
        ) {
            this.setMuntinsColor(conf, conf.MuntinsData.color, 'out');
        }
    }

    validateColors(conf: WindowActiveConfiguration) {
        this.validationService.indeterminate(conf, 'muntinsColors');
        if (this.validationService.isValidElements(conf, ['system', 'loadedMuntinsColors'])) {
            const valid = this.validColors(conf);
            if (!valid) {
                this.validationService.invalid(conf, 'muntinsColors');
                this.issuesService.simpleRegister(
                    'invalid-muntins-colors',
                    'Niepoprawne kolory szprosów',
                    this.translateService.instant('WINDOW|Niepoprawne kolory szprosów'),
                    conf,
                    {
                        level: IssueLevel.ERROR,
                        logLevel: IssueLevel.WARN,
                        blockStepsAfter: null,
                    }
                );
            } else {
                this.validationService.valid(conf, 'muntinsColors');
                this.issuesService.unregister('invalid-muntins-colors', conf);
            }
        }
    }

    validateColorsAndFixIsses(conf: WindowActiveConfiguration) {
        this.validationService.indeterminate(conf, 'muntinsColors');
        if (
            this.data
            && !this.colorGroups.length
            && !this.colorRals.length
            && !this.colors.length
        ) {
            this.loadColors(this.data, conf);
        }
        if (
            this.validationService.isValidElements(conf, ['system', 'loadedMuntinsColors'])
            && conf.MuntinsData.type
            && conf.MuntinsData.type.id
        ) {
            const pauseId = this.eventBusService.pause(['setMuntinColor']);
            try {
                if (!this.validColors(conf)) {
                    this.validationService.invalid(conf, 'muntinsColors');
                    this.setDefaultColors(conf);
                } else {
                    this.validationService.valid(conf, 'muntinsColors');
                }
            } finally {
                this.eventBusService.resume(['setMuntinColor'], pauseId);
            }
        }
    }

    private loadColors(data, conf: WindowActiveConfiguration) {
        this.data = data;
        if (!conf) {
            return;
        }
        this.validationService.indeterminate(conf, 'loadedMuntinsColors');

        if ((data.windowColorRals || data.windowColorsAll) && data.windowColorGroups) {
            this.colorRals = data.windowColorRals || [];
            this.colors = data.windowColorsAll || [];
            this.colorGroups = data.windowColorGroups || [];
            this.setWood(conf);

            this.eventBusService.post({
                key: 'loadedMuntinsColors',
                value: {
                    colorRals: this.colorRals,
                    colors: this.colors,
                    colorGroups: this.colorGroups,
                },
            });

            this.validationService.valid(conf, 'loadedMuntinsColors');
        } else {
            this.validationService.invalid(conf, 'loadedMuntinsColors');
        }
    }

    private setWood(conf: WindowActiveConfiguration) {
        this.woodType = conf.Wood ? ~~conf.Wood.id : 0;
    }

    private filterColorByWoodType(color) {
        return color.wood_type == null || this.woodType === ~~color.wood_type;
    }

    private getDefaultMuntinColor(conf: WindowActiveConfiguration, side: 'outer' | 'inner') {
        const availColors = this.getMuntinColors(conf.MuntinsData.type);
        const windowColorId = this.colorMappingService.getWindowColorId(conf, side);
        const windowColorRal = this.colorMappingService.getWindowColorId(conf, side, 'RAL');
        const matchedColors = this.colorMappingService.getColors(
            windowColorId,
            windowColorRal ? 'ral' : 'window',
            'window'
        );
        const matchedColorsRal = this.colorMappingService.getColors(
            windowColorId,
            windowColorRal ? 'ral' : 'window',
            'ral'
        );
        const windowColors = matchedColors
            .map(m => availColors.filter(c => Number(c.id) === Number(m) && !c.RAL)[0])
            .filter(m => m);
        const windowColorsRal = matchedColorsRal
            .map(m => availColors.filter(c => Number(c.id) === Number(m) && c.RAL)[0])
            .filter(m => m);
        let color;
        if (Common.isArray(windowColors) && Common.isDefined(windowColors[0])) {
            color = core.copy(windowColors[0]);
        } else if (Common.isArray(windowColorsRal) && Common.isDefined(windowColorsRal[0])) {
            color = core.copy(windowColorsRal[0]);
        } else if (Common.isArray(availColors) && Common.isDefined(availColors[0])) {
            color = core.copy(availColors[0]);
        } else {
            return null;
        }
        color.isDefault = true;
        return color;
    }
}
