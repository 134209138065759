import {NgModule} from '@angular/core';

import {SystemsModule} from 'configurator/steps/window/systems/systems.module';
import {ProfilesModule} from 'configurator/steps/window/profiles/profiles.module';
import {GlazingsModule} from 'configurator/steps/window/glazings/glazings.module';
import {MuntinsModule} from 'configurator/steps/window/muntins/muntins.module';
import {ColorsModule} from 'colors/colors.module';
import {DimensionsModule} from './dimensions/dimensions.module';
import {HandlesModule} from './handles/handles.module';
import {AccessoriesModule} from './accessories/accessories.module';

@NgModule({
    imports: [
        SystemsModule,
        ProfilesModule,
        GlazingsModule,
        MuntinsModule,
        HandlesModule,
        DimensionsModule,
        ColorsModule,
        AccessoriesModule,
    ],
    exports: [],
    declarations: [],
    providers: [],
})
export class WindowModule { }
