import angular from 'angular';
import {logger} from 'helpers';
import { IssueLevel } from 'issues.service';

/**
 *  @ngdoc service
 *  @name ICC.Configurators.ColorsFactory
 *  @param {Service}  $rootScope               Angular $rootScope
 *  @param {Service}  $uibModal                Angular $uibModal
 *  @param {Service}  $filter                  Angular $filter
 *  @param {Service}  $location                Angular $location
 *  @param {Service}  IccConfig                Konfiguracja systemu
 *  @param {Service}  ConfigurationsService    ConfigurationsService
 *  @param {Service}  ConfiguratorsDataService ConfiguratorsDataService
 *  @param {Service}  CurConfService           CurConfService
 *  @param {Service}  DrawDataService          DrawDataService
 *  @param {Service}  IssuesService            IssuesService
 *  @param {Service}  PriceService             PriceService
 *  @param {Service}  Core                     Core
 *  @param {Service}  RollerColorsFactory      RollerColorsFactory
 *  @param {Service}  GlazingFactory           GlazingFactory
 *
 *  @description
 *  Zawiera funkcje do zarządzania kolorami okna, drzwi zewn. i drzwi tarasowych.
 */
export default function ColorsFactory($rootScope, $uibModal, $filter, $location, IccConfig, ConfigurationsService, ConfiguratorsDataService, // jshint ignore:line
                       CurConfService, DrawDataService, ConstructionLimitationFactory, HandlesFactory,
                       IssuesService, PriceService, Core, RollerColorsFactory, GlazingFactory, ColorsDefaultsService, EventBusService,
                    ValidationService, InfoFactory, MuntinColorsService, FillingsService) {
    'ngInject';

    /**
     * Aktywne w tych konfiguratorach.
     * @type {Array}
     */
    var configurators = ['window', 'hs', 'door', 'folding_door'];

    var factory = {
        /**
         * Kolory RAL
         * @memberof ColorsFactory
         * @type {Array}
         */
        windowColorRals: [],
        /**
         * Kolory konstrukcji, np. okleiny, lazury
         * @memberof ColorsFactory
         * @type {Array}
         */
        windowColors   : [],
        /**
         * Rodzaje drewna
         * @memberof ColorsFactory
         * @type {Array}
         */
        woods          : [],
        /**
         * Czy dane zostały załadowane
         * @memberof ColorsFactory
         * @type {Boolean}
         */
        loadedData     : false,

        setColorType,
        setColor,
        setColorsSashExt,
        setDefaults,
        openModalColor,
        openModalWood,
        validate,
        loadColorsBySystem,
        setDefaultColorTypeForColors,
        setDefaultColors,
        setDefaultColorTypeForSystem,
        setDefaultWood,
        loadColorsByWood,
        loadData,
        clearAlushellColor,
        setAlushellColor,
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    EventBusService.subscribeWithoutConfiguration('reloadColorsData', () => {
        loadColorsBySystem();
        loadColorsByWood();
    });

    return factory;

    /**
     * Inicjalizuje działanie ColorsFactory.
     * @private
     */
    function init() {
        if (configurators.indexOf(CurConfService.conf) === -1) {
            return;
        }
        if (angular.isUndefined(ConfigurationsService.conf)) {
            return;
        }
        IssuesService.addValidateFunction(factory.validate);
        loadData();
        setDefaults();
        factory.loadedData = true;
    }

    /**
     * Ładuje dane
     */
    function loadData() {
        factory.windowColorRals = ConfiguratorsDataService.data.windowColorRals || [];
        if (angular.isArray(ConfiguratorsDataService.data.woodTypes)) {
            factory.woods = ConfiguratorsDataService.data.woodTypes;
        }
    }

    /**
     * Ustawia domyślne kolory i rodzaj drewna po uprzednim przefiltrowaniu ich po systemie.
     * @memberof ColorsFactory
     */
    function setDefaults() {
        if (!angular.isObject(ConfigurationsService.conf.Current.System) || angular.isUndefined(ConfigurationsService.conf.Current.System.id)) {
            return;
        }
        hasSystemAlushell();
        setDefaultWood();
        loadColorsBySystem();
        setDefaultColorTypeForSystem();
        loadColorsByWood();
        setDefaultColors();
        setDefaultColorTypeForColors();

        if (IccConfig.Configurators.alushell
            && ConfigurationsService.conf.Current.System.alushell
            && ConfigurationsService.conf.Current.System.alushell_active
        ) {
            ConfigurationsService.conf.Current.HasAlushell = true;
        }
        PriceService.count();

        EventBusService.post({
            key  : 'setDefaultColors',
            value: {
                windowColors: factory.windowColors,
                windowColorRals: factory.windowColorRals,
                woods: factory.woods,
            },
        });
    }

    /**
     * Ustawia domyslne kolory po zmianie rodzaju drewna (po odpowiednim ich przefiltrowaniu).
     * @private
     */
    function setDefaultsOnChangeWoodType(setDrawing = true, conf = ConfigurationsService.conf.Current, confDefault = ConfigurationsService.conf.Default, filterColors) {
        if (['Opaque', 'Transparent'].indexOf(conf.ColorType) > -1 || IccConfig.Configurators.woodBicolor) {
            loadColorsBySystem(filterColors);
            loadColorsByWood(conf);
            setDefaultColors(setDrawing, conf, confDefault);
        }
    }

    /**
     * Ładuje kolory przefiltrowane do wybranego systemu.
     * @private
     */
    function loadColorsBySystem(filterColors) {
        if (angular.isUndefined(ConfigurationsService.conf)) {
            return;
        }

        var allColors = filterColors
            ? ConfiguratorsDataService.data.windowColorsAll.filter(filterColors)
            : Core.copy(ConfiguratorsDataService.data.windowColors[ConfigurationsService.conf.Current.System.id]);

        const colorGroups = getColorsGroups(filterColors, allColors);
        // sortuj kolory względem grup i nazw
        allColors = allColors.sort((a, b) => {
            if (angular.isUndefined(a.groups) || a.groups === null) {
                return 1;
            } else if (angular.isUndefined(b.groups) || b.groups === null) {
                return -1;
            }
            var pos = 0, x = 0;
            // najpierw sortuj względem grup
            var compareE1 = 9999999;
            for (; x < a.groups.length; ++x) {
                pos = colorGroups.indexOf(a.groups[x]);
                if (pos > -1 && compareE1 > pos) {
                    compareE1 = pos;
                }
            }
            var compareE2 = 9999999;
            for (x = 0; x < b.groups.length; ++x) {
                pos = colorGroups.indexOf(b.groups[x]);
                if (pos > -1 && compareE2 > pos) {
                    compareE2 = pos;
                }
            }
            if (compareE1 < compareE2) {
                return -1;
            } else if (compareE1 > compareE2) {
                return 1;
            } else {
                return a.order - b.order;
            }
        });

        Core.clear(factory.windowColors);
        Core.rewriteArray(factory.windowColors, allColors);
        allColors = null;
    }

    function getColorsGroups(filterColors, allColors) {
        // pobierz grupy kolorów z dostępnych kolorów
        var groups = [];
        for (let x = 0; x < allColors.length; ++x) {
            if (angular.isUndefined(allColors[x].groups) || allColors[x].groups === null) {
                continue;
            }
            for (var y = 0; y < allColors[x].groups.length; ++y) {
                if (groups.indexOf(allColors[x].groups[y]) < 0) {
                    groups.push(allColors[x].groups[y]);
                }
            }
        }
        // pobierz grupy kolorów dla danej linii (sprawdź czy grupa kolorów dla linii nie jest pusta)
        var colorGroups = ConfiguratorsDataService.data.windowColorGroups.filter((el) => {
            var val = angular.isArray(el.systems) && angular.isArray(groups)
                    && groups.indexOf(el.id) > -1
                    && (filterColors
                        || ConfigurationsService.conf.Current.System
                        && el.systems.indexOf(ConfigurationsService.conf.Current.System.id) > -1
                        && el.target.indexOf('show') > -1
                        && (
                            ConfigurationsService.conf.Current.System.type != 'wood'
                            || angular.isArray(el.woodTypes)
                                && el.woodTypes.indexOf(ConfigurationsService.conf.Current.Wood.id) > -1
                        )
                    );
            return val;
        });
        for (let x = 0; x < colorGroups.length; ++x) {
            colorGroups[x] = colorGroups[x].id;
        }
        return colorGroups;
    }

    /**
     * Ładuje kolory przefiltrowane wg wybranego rodzaju drewna.
     * @private
     */
    function loadColorsByWood(conf = ConfigurationsService.conf.Current) {
        if (angular.isUndefined(conf)) {
            return;
        }
        var allColors = Core.copy(factory.windowColors);
        Core.clear(factory.windowColors);
        for (var i = 0; i < allColors.length; i++) {
            var color = allColors[i];
            if (conf.ColorType == 'Transparent' || IccConfig.Configurators.woodBicolor) {
                if ((IccConfig.Configurators.woodBicolor && !color.wood_type) || color.wood_type == conf.Wood.id) {
                    factory.windowColors.push(color);
                }
            } else {
                if (isNaN(parseInt(color.wood_type))) {
                    factory.windowColors.push(color);
                }
            }
        }
    }

    /**
     * Ustawia domyślny rodzaj drewna.
     * @private
     */
    function setDefaultWood(conf = ConfigurationsService.conf.Current, confDefault = ConfigurationsService.conf.Default) {
        if (conf.System.type == 'wood') {
           if (angular.isArray(factory.woods) && factory.woods.length > 0
            && angular.isUndefined(Core.fId(factory.woods, conf.Wood.id))) {
                conf.Wood = Core.copy(factory.woods[0]);
                confDefault.Wood = Core.copy(factory.woods[0]);
            }
        } else {
            conf.Wood = {};
            confDefault.Wood = {};
        }
    }

    /**
     * Ustawia domyslne kolory
     * @private
     */
    function setDefaultColors(setDrawing = true, conf = ConfigurationsService.conf.Current, confDefault = ConfigurationsService.conf.Default, filterColors = null) {
        if (angular.isObject(conf.Colors) || angular.isObject(confDefault.Colors)) {
            if (angular.isObject(confDefault.Colors)) {
                conf.Colors = Core.copy(confDefault.Colors);
            }
            var colors = conf.Colors;
            var colorsDefault = confDefault.Colors;
            if (angular.isObject(colors.frame) && angular.isObject(colors.sash)) {
                var allColors = factory.windowColors;
                if (conf.System.type != 'wood'
                    || conf.ColorType != 'Transparent') {
                    allColors = allColors.concat(factory.windowColorRals);
                }
                const pauseId = EventBusService.pause(['setConstructionColor', 'changedSashes']);
                try {
                    const colorGroups = getColorsGroups(filterColors, allColors);
                    if (!colors.frame.outer || angular.isUndefined(Core.fId(allColors, [colors.frame.outer.id, colors.frame.outer.RAL], ['id', 'RAL'])) || !colorGroups.some(group => colors.frame.outer.groups.includes(group))) {
                        colors.frame.outer = {};
                        colorsDefault.frame.outer = {};
                        setColorSide(colors.frame.outer, getDefaultColorForSide('outer', 'frame', true, conf), undefined, 'outer', 'frame', conf, true);
                        setColorSide(colorsDefault.frame.outer, getDefaultColorForSide('outer', 'frame', true, conf), undefined, 'outer', 'frame', conf, true);
                    }
                    if (!colors.frame.inner || angular.isUndefined(Core.fId(allColors, [colors.frame.inner.id, colors.frame.inner.RAL], ['id', 'RAL'])) || !colorGroups.some(group => colors.frame.inner.groups.includes(group))) {
                        colors.frame.inner = {};
                        colorsDefault.frame.inner = {};
                        setColorSide(colors.frame.inner, getDefaultColorForSide('inner', 'frame', true, conf), undefined, 'inner', 'frame', conf, true);
                        setColorSide(colorsDefault.frame.inner, getDefaultColorForSide('inner', 'frame', true, conf), undefined, 'inner', 'frame', conf, true);
                    }
                    if (!colors.frame.core || angular.isUndefined(Core.fId(allColors, [colors.frame.core.id, colors.frame.core.RAL], ['id', 'RAL']))) {
                        colors.frame.core = {};
                        colorsDefault.frame.core = {};
                        setColorSide(colors.frame.core, getDefaultColorForSide('core', 'frame', false,  conf), undefined, 'core', 'frame', conf, true);
                        setColorSide(colorsDefault.frame.core, getDefaultColorForSide('core', 'frame', false,  conf), undefined, 'core', 'frame', conf, true);
                    }

                    if (!colors.sash.outer || angular.isUndefined(Core.fId(allColors, [colors.sash.outer.id, colors.sash.outer.RAL], ['id', 'RAL'])) || !colorGroups.some(group => colors.sash.outer.groups.includes(group))) {
                        colors.sash.outer = {};
                        colorsDefault.sash.outer = {};
                        setColorSide(colors.sash.outer, getDefaultColorForSide('outer', 'sash', true, conf), undefined, 'outer', 'sash', conf, true);
                        setColorSide(colorsDefault.sash.outer, getDefaultColorForSide('outer', 'sash', true, conf), undefined, 'outer', 'sash', conf, true);
                    }
                    if (!colors.sash.inner || angular.isUndefined(Core.fId(allColors, [colors.sash.inner.id, colors.sash.inner.RAL], ['id', 'RAL'])) || !colorGroups.some(group => colors.sash.inner.groups.includes(group))) {
                        colors.sash.inner = {};
                        colorsDefault.sash.inner = {};
                        setColorSide(colors.sash.inner, getDefaultColorForSide('inner', 'sash', true, conf), undefined, 'inner', 'sash', conf, true);
                        setColorSide(colorsDefault.sash.inner, getDefaultColorForSide('inner', 'sash', true, conf), undefined, 'inner', 'sash', conf, true);
                    }
                    if (!colors.sash.core || angular.isUndefined(Core.fId(allColors, [colors.sash.core.id, colors.sash.core.RAL], ['id', 'RAL']))) {
                        colors.sash.core = {};
                        colorsDefault.sash.core = {};
                        setColorSide(colors.sash.core, getDefaultColorForSide('core', 'sash', false, conf), undefined, 'core', 'sash', conf, true);
                        setColorSide(colorsDefault.sash.core, getDefaultColorForSide('core', 'sash', false, conf), undefined, 'core', 'sash', conf, true);
                    }

                    if (conf.System.alushell) {
                        if (angular.isUndefined(Core.fId(allColors, [colors.frame.alushell.id, colors.frame.alushell.RAL], ['id', 'RAL']))
                            && angular.isUndefined(Core.fId(factory.windowColorRals, [colors.frame.alushell.id, colors.frame.alushell.RAL], ['id', 'RAL']))
                        ) {
                            setColorSide(colors.frame.alushell, getDefaultColorForSide('alushell', 'frame', false, conf), undefined, 'alushell', 'frame', conf, true);
                            setColorSide(colorsDefault.frame.alushell, getDefaultColorForSide('alushell', 'frame', false, conf), undefined, 'alushell', 'frame', conf, true);
                        }
                        if (angular.isUndefined(Core.fId(allColors, [colors.sash.alushell.id, colors.sash.alushell.RAL], ['id', 'RAL']))
                            && angular.isUndefined(Core.fId(factory.windowColorRals, [colors.sash.alushell.id, colors.sash.alushell.RAL], ['id', 'RAL']))
                        ) {
                            setColorSide(colors.sash.alushell, getDefaultColorForSide('alushell', 'sash', false, conf), undefined, 'alushell', 'sash', conf, true);
                            setColorSide(colorsDefault.sash.alushell, getDefaultColorForSide('alushell', 'sash', false, conf), undefined, 'alushell', 'sash', conf, true);
                        }
                    }
                    if (conf.valid != null) {
                        ValidationService.valid(conf, 'colors');
                    }
                } finally {
                    EventBusService.resume(['setConstructionColor', 'changedSashes'], pauseId);
                }
            }
            colors = null;
        }
    }

    /**
     * Ustawia domyślny układ kolorów.
     * @private
     */
    function setDefaultColorTypeForSystem(conf = ConfigurationsService.conf.Current, confDefault = ConfigurationsService.conf.Default) {
        if (angular.isDefined(confDefault.ColorType) && confDefault.ColorType !== null) {
            conf.ColorType = confDefault.ColorType;
        }
        var winTypeLineId =  conf.System.type;
        var pvcTypes = ['White', 'Cream', 'Outer', 'Inner', 'Bilateral', '3D', 'Bicolor'];
        var aluTypes = ['Bilateral', 'Bicolor'];
        var woodTypes = ['Opaque', 'Transparent'];
        if (winTypeLineId == 'pvc'
            && pvcTypes.indexOf(conf.ColorType) === -1) {
            var visColor = getColorFromVisualizer();
            if (visColor) {
                conf.ColorType = 'Bilateral';
                confDefault.ColorType = 'Bilateral';
            } else {
                conf.ColorType = 'White';
                confDefault.ColorType = 'White';
            }
        } else if (aluTypes.indexOf(conf.ColorType) === -1
            && (winTypeLineId == 'alu' || winTypeLineId == 'wood' && IccConfig.Configurators.woodBicolor)) {
            conf.ColorType = 'Bilateral';
            confDefault.ColorType = 'Bilateral';
        } else if (winTypeLineId == 'wood'
            && woodTypes.indexOf(conf.ColorType) === -1
            && !IccConfig.Configurators.woodBicolor) {
            conf.ColorType = 'Transparent';
            confDefault.ColorType = 'Transparent';
        }
    }

    /**
     * Ustawia układ kolorów na podstawie ustawionych kolorów, jeśli wybrany nie pasuje.
     * @private
     */
    function setDefaultColorTypeForColors(conf = ConfigurationsService.conf.Current, confDefault = ConfigurationsService.conf.Default) {
        if (angular.isObject(conf.Colors) || angular.isObject(confDefault.Colors)) {
            var colors = conf.Colors;
            // if (angular.isObject(confDefault.Colors)) {
            //     colors = confDefault.Colors;
            // }
            var winTypeLineId = conf.System.type;

            if (winTypeLineId != 'wood') {
                var outer = ColorsDefaultsService.getColorValue(colors, 'outer');
                var inner = ColorsDefaultsService.getColorValue(colors, 'inner');
                var core = ColorsDefaultsService.getColorValue(colors, 'core');

                if (ColorsDefaultsService.getDefaultColorType('White', 'outer') == outer
                    && ColorsDefaultsService.getDefaultColorType('White', 'inner') == inner
                    && ColorsDefaultsService.getDefaultColorType('White', 'core') == core
                    || outer === 'WHITE' && inner === 'WHITE'
                ) {
                    conf.ColorType = 'White';
                    confDefault.ColorType = 'White';
                } else if (ColorsDefaultsService.getDefaultColorType('Cream', 'outer') == outer
                    && ColorsDefaultsService.getDefaultColorType('Cream', 'inner') == inner
                    && ColorsDefaultsService.getDefaultColorType('Cream', 'core') == core
                ) {
                    conf.ColorType = 'Cream';
                    confDefault.ColorType = 'Cream';
                } else if (['WHITE', ColorsDefaultsService.getDefaultColorType('Outer', 'outer')].includes(outer)
                    && ColorsDefaultsService.getDefaultColorType('Outer', 'inner') == inner
                    && ColorsDefaultsService.getDefaultColorType('Outer', 'core') == core
                ) {
                    conf.ColorType = 'Outer';
                    confDefault.ColorType = 'Outer';
                } else if (ColorsDefaultsService.getDefaultColorType('Inner', 'outer') == outer
                    && ['WHITE', ColorsDefaultsService.getDefaultColorType('Inner', 'inner')].includes(inner)
                    && ColorsDefaultsService.getDefaultColorType('Inner', 'core') == core
                ) {
                    conf.ColorType = 'Inner';
                    confDefault.ColorType = 'Inner';
                } else if ((ColorsDefaultsService.getDefaultColorType('Bilateral', 'outer') == outer
                    && ColorsDefaultsService.getDefaultColorType('Bilateral', 'inner') == inner
                    || outer == inner)
                    && colors.frame.outer.id === colors.frame.inner.id
                ) {
                    conf.ColorType = 'Bilateral';
                    confDefault.ColorType = 'Bilateral';
                } else {
                    conf.ColorType = 'Bicolor';
                    confDefault.ColorType = 'Bicolor';
                }
            }
        }
    }

    /**
     * Zwraca domyślny kolor dla danego miejsca w konstrukcji
     * @private
     * @param  {string} type                 Strona konstrukcji (outer, inner, core, alushell)
     * @param  {string} place                Miejsce - skrzydło, rama
     * @param  {bool}   [visualizator=false] Czy uwzględnić kolor z wizualizatora
     * @return {object}                      Kolor
     */
    function getDefaultColorForSide(type, place, visualizator, conf = ConfigurationsService.conf.Current, filterColors = () => true) {
        if (!angular.isArray(factory.windowColors) && !angular.isArray(factory.windowColorRals)) {
            logger.error(new Error('Nie załadowane kolory'));
            return {};
        }
        var visColor = getColorFromVisualizer();
        const coreColorInBilateralAlwaysAsDefault = IccConfig.Configurators.window.coreColorInBilateralAlwaysAsDefault;
        const defaultValue = ColorsDefaultsService.getDefaultColorType(conf.ColorType, type, coreColorInBilateralAlwaysAsDefault);
        if (defaultValue) {
            var side = [place[0].toUpperCase() + '|' + type[0].toUpperCase()];
            if (['Bilateral', '3D', 'Transparent', 'Opaque'].indexOf(conf.ColorType) > -1 && ['inner', 'outer'].indexOf(type) > -1) {
                side = [place[0].toUpperCase() + '|B'];
            }
            if (conf.ColorType === 'Bicolor' && ['inner', 'outer'].indexOf(type) > -1) {
                side = [place[0].toUpperCase() + '|D' + type[0].toUpperCase()];
            }
            var colors = [];
            var whites = [];
            var creams = [];
            var visColors = [];
            var allColors = factory.windowColors;
            if (conf.System.type != 'wood'
                || conf.ColorType != 'Transparent') {
                allColors = allColors.concat(factory.windowColorRals);
            }
            allColors = allColors.filter(filterColors);
            for (var i = 0; i < allColors.length; i++) {
                if ((angular.isArray(allColors[i].sides) && side.every(s => allColors[i].sides.indexOf(s) > -1)) || allColors[i].RAL) {
                    if (allColors[i].type == 'white') {
                        whites.push(allColors[i]);
                    } else if (allColors[i].type == 'cream') {
                        creams.push(allColors[i]);
                    } else if (visColor && angular.isString(allColors[i].color)){
                        if (allColors[i].color.toUpperCase() == visColor.toUpperCase() || allColors[i].color_img == visColor) {
                            visColors.push(allColors[i]);
                        }
                    } else {
                        colors.push(allColors[i]);
                    }
                }
            }
            if (visualizator && visColor && angular.isDefined(visColors[0])) {
                return visColors[0];
            }
            if ((defaultValue === 'WHITE'
                    || (defaultValue !== 'NONE' && conf.System.type == 'alu'))
                && angular.isDefined(whites[0])
            ) {
                return whites[0];
            }
            if (defaultValue === 'CREAM' && angular.isDefined(creams[0])) {
                return creams[0];
            }
            if (defaultValue !== 'NONE' && angular.isDefined(colors[0])) {
                return colors[0];
            }
            return {};
        }
        return {};
    }

    /**
     * Definiuje na podstawie cennika, że dla wybranego systemu (nie) jest dostepna nakładka aluminiowa.
     * @private
     * @return {Boolean} Dostępność nakładki
     */
    function hasSystemAlushell() {
        var winLineId = ConfigurationsService.conf.Current.System.id;
        if ((angular.isObject(ConfiguratorsDataService.data.pricesDepends)
            && angular.isObject(ConfiguratorsDataService.data.pricesDepends[winLineId + '|n']))
                || (angular.isDefined(ConfigurationsService.conf.Current.System.alushell_factor)
                    && ConfigurationsService.conf.Current.System.alushell_factor !== null)
        ) {
            var prices = ConfiguratorsDataService.data.pricesDepends[winLineId + '|n'];
            if (angular.isDefined(prices)
                || (angular.isDefined(ConfigurationsService.conf.Current.System.alushell_factor)
                    && ConfigurationsService.conf.Current.System.alushell_factor !== null)) {
                ConfigurationsService.conf.Current.System.alushell = true;
                ConfigurationsService.conf.Default.System.alushell = true;
                return true;
            }
        }

        ConfigurationsService.conf.Current.System.alushell = false;
        ConfigurationsService.conf.Default.System.alushell = false;

        if (ConfigurationsService.conf.Current.HasAlushell) {
            ConfigurationsService.conf.Current.HasAlushell = false;
        }
        return false;
    }

    /**
     * Ustawia kolor dla danego miejsca w danym obiekcie
     * @private
     * @param {object}     object Stary kolor
     * @param {object}     color  Nowy kolor
     * @param {string|int} type   Grupa koloru
     * @param {string}     side   Strona konstrukcji (outer, inner, core, alushell)
     * @param {string}     place  Miejsce - skrzydło, rama
     */
    function setColorSide(object, color, type, side, place, conf = ConfigurationsService.conf.Current, isDefault = false) {
        if (angular.isDefined(color)) {
            object.id        = color.id;
            object.alfa      = color.alfa;
            object.color     = color.color;
            object.color_img = color.color_img;
            object.name      = color.name;
            object.code      = color.code;
            object.RAL       = color.RAL;
            object.type      = color.type;
            object.types     = color.types;
            object.groups    = color.groups;
            object.sides     = color.sides;
            object.isDefault = isDefault;

            var sideArr = [];
            if (side !== 'outer') {
                sideArr = [place[0].toUpperCase() + '|' + side[0].toUpperCase()];
                if (['Bicolor'].indexOf(conf.ColorType) > -1 && side === 'inner') {
                    sideArr = [place[0].toUpperCase() + '|D' + side[0].toUpperCase()];
                }
            } else {
                if (['Bilateral', '3D', 'Transparent', 'Opaque'].indexOf(conf.ColorType) > -1) {
                    sideArr = [place[0].toUpperCase() + '|B'];
                } else {
                    sideArr = [place[0].toUpperCase() + '|' + side[0].toUpperCase()];
                    if (['Bicolor'].indexOf(conf.ColorType) > -1) {
                        sideArr = [place[0].toUpperCase() + '|D' + side[0].toUpperCase()];
                    }
                }
            }
            if (angular.isUndefined(type) && angular.isArray(color.groups) && color.groups.length > 0) {
                var groups = ConfiguratorsDataService.data.windowColorGroups.filter(el => {
                    var test = true;
                    if (angular.isArray(el.sides)) {
                        for (var i = 0; i < sideArr.length; i++) {
                            if (el.sides.indexOf(sideArr[i]) === -1) {
                                test = test && false;
                            }
                        }
                    }
                    if (angular.isArray(el.systems)
                        && el.systems.indexOf(conf.System.id) > -1
                        && color.groups.indexOf(el.id) > -1
                        && el.target.indexOf('show') > -1
                        && (
                            conf.System.type != 'wood'
                            || angular.isArray(el.woodTypes)
                                && el.woodTypes.indexOf(conf.Wood.id) > -1
                        )
                        && test) {
                        return true;
                    }
                    return false;
                }).map(el => el.id);
                if (groups.length > 0) {
                    type = groups[0];
                }

            }
            if (type == 'none') {
                type = undefined;
            }
            object.group = type;
            if (conf.type && conf.type != 'complementary_goods' && conf.type != 'accessory') {
                ValidationService.valid(conf, 'colors');
                EventBusService.post({
                    key: 'setConstructionColor',
                    value: {}
                });
            }
        }
    }

    /**
     * Ustawia domyślny kolor do wybranego układu kolorów, dla danej strony konstrukcji
     * @private
     * @param {string} side Strona konstrukcji (outer, inner, core, alushell)
     */
    function setDefaultColorForType(side, conf = ConfigurationsService.conf.Current, confDefault = ConfigurationsService.conf.Default, filterColors) {
        var type = conf.ColorType;
        var colors = conf.Colors;
        var colorsDefault = confDefault.Colors;
        const coreColorInBilateralAlwaysAsDefault = IccConfig.Configurators.window.coreColorInBilateralAlwaysAsDefault;
        const defaultColorType = ColorsDefaultsService.getDefaultColorType(type, side, coreColorInBilateralAlwaysAsDefault);
        if (defaultColorType) {
            const pauseId = EventBusService.pause(['setConstructionColor']);
            try {
                switch (defaultColorType) {
                    case 'NONE':
                        if (Object.keys(colors.frame[side]).length > 0
                            || Object.keys(colors.sash[side]).length > 0) {
                            setColorSide(colors.frame[side], getDefaultColorForSide(side, 'frame', false, conf, filterColors), undefined, side, 'frame', conf, true);
                            setColorSide(colorsDefault.frame[side], getDefaultColorForSide(side, 'frame', false, conf, filterColors), undefined, side, 'frame', conf, true);
                            setColorSide(colors.sash[side], getDefaultColorForSide(side, 'sash', false, conf, filterColors), undefined, side, 'sash', conf, true);
                            setColorSide(colorsDefault.sash[side], getDefaultColorForSide(side, 'sash', false, conf, filterColors), undefined, side, 'sash', conf, true);
                        }
                        break;
                    case 'WHITE':
                        if (colors.frame[side].type != 'white' || colors.sash[side].type != 'white') {
                            setColorSide(colors.frame[side], getDefaultColorForSide(side, 'frame', false, conf, filterColors), undefined, side, 'frame', conf, true);
                            setColorSide(colorsDefault.frame[side], getDefaultColorForSide(side, 'frame', false, conf, filterColors), undefined, side, 'frame', conf, true);
                            setColorSide(colors.sash[side], getDefaultColorForSide(side, 'sash', false, conf, filterColors), undefined, side, 'sash', conf, true);
                            setColorSide(colorsDefault.sash[side], getDefaultColorForSide(side, 'sash', false, conf, filterColors), undefined, side, 'sash', conf, true);
                        }
                        break;
                    case 'CREAM':
                        if (colors.frame[side].type != 'cream' || colors.sash[side].type != 'cream') {
                            setColorSide(colors.frame[side], getDefaultColorForSide(side, 'frame', false, conf, filterColors), undefined, side, 'frame', conf, true);
                            setColorSide(colorsDefault.frame[side], getDefaultColorForSide(side, 'frame', false, conf, filterColors), undefined, side, 'frame', conf, true);
                            setColorSide(colors.sash[side], getDefaultColorForSide(side, 'sash', false, conf, filterColors), undefined, side, 'sash', conf, true);
                            setColorSide(colorsDefault.sash[side], getDefaultColorForSide(side, 'sash', false, conf, filterColors), undefined, side, 'sash', conf, true);
                        }
                        break;
                    case 'COLOR':
                        if (['Bilateral', '3D', 'Transparent', 'Opaque'].indexOf(type) > -1 && ['outer', 'inner'].indexOf(side) > -1) {
                            if (side === 'outer'
                                && (!angular.isString(colors.frame.outer.type)
                                    || ['white', 'cream'].indexOf(colors.frame.outer.type) > -1
                                    || colors.frame.outer.sides && colors.frame.outer.sides.indexOf('F|B') === -1
                                )
                            ) {
                                if (angular.isString(colors.frame.inner.type)
                                    && colors.frame.inner.type != 'white'
                                    && colors.frame.inner.type != 'cream'
                                    && colors.frame.inner.sides && colors.frame.inner.sides.indexOf('F|B') > -1
                                ) {
                                    setColorSide(colors.frame.outer, colors.frame.inner, undefined, 'outer', 'frame', conf, true);
                                    setColorSide(colorsDefault.frame.outer, colorsDefault.frame.inner, undefined, 'outer', 'frame', conf, true);
                                    setColorSide(colors.sash.outer, colors.sash.inner, undefined, 'outer', 'sash', conf, true);
                                    setColorSide(colorsDefault.sash.outer, colorsDefault.sash.inner, undefined, 'outer', 'sash', conf, true);
                                } else {
                                    setColorSide(colors.frame.outer, getDefaultColorForSide('outer', 'frame', false, conf, filterColors), undefined, 'outer', 'frame', conf, true);
                                    setColorSide(colorsDefault.frame.outer, getDefaultColorForSide('outer', 'frame', false, conf, filterColors), undefined, 'outer', 'frame', conf, true);
                                    setColorSide(colors.sash.outer, getDefaultColorForSide('outer', 'sash', false, conf, filterColors), undefined, 'outer', 'sash', conf, true);
                                    setColorSide(colorsDefault.sash.outer, getDefaultColorForSide('outer', 'sash', false, conf, filterColors), undefined, 'outer', 'sash', conf, true);
                                }
                            } else if (colors.frame.outer.id != colors.frame.inner.id
                                || colors.frame.outer.RAL != colors.frame.inner.RAL
                            ) {
                                setColorSide(colors.frame.inner, colors.frame.outer, undefined, 'inner', 'frame', conf, true);
                                setColorSide(colorsDefault.frame.inner, colorsDefault.frame.outer, undefined, 'inner', 'frame', conf, true);
                                setColorSide(colors.sash.inner, colors.sash.outer, undefined, 'inner', 'sash', conf, true);
                                setColorSide(colorsDefault.sash.inner, colorsDefault.sash.outer, undefined, 'inner', 'sash', conf, true);
                            }
                        } else {
                            const sides = [];
                            if (type === 'Bicolor' && side !== 'core' && side !== 'alushell') {
                                sides.push('F|D' + side[0].toUpperCase());
                            } else {
                                sides.push('F|' + side[0].toUpperCase());
                            }
                            if (!angular.isString(colors.frame[side].type) || ['white', 'cream'].indexOf(colors.frame[side].type) > -1
                                || !angular.isString(colors.sash[side].type) || ['white', 'cream'].indexOf(colors.sash[side].type) > -1
                                || colors.frame[side].sides && sides.some(s => colors.frame[side].sides.indexOf(s) === -1)
                            ) {
                                setColorSide(colors.frame[side], getDefaultColorForSide(side, 'frame', false, conf, filterColors), undefined, side, 'frame', conf, true);
                                setColorSide(colorsDefault.frame[side], getDefaultColorForSide(side, 'frame', false, conf, filterColors), undefined, side, 'frame', conf, true);
                                setColorSide(colors.sash[side], getDefaultColorForSide(side, 'sash', false, conf, filterColors), undefined, side, 'sash', conf, true);
                                setColorSide(colorsDefault.sash[side], getDefaultColorForSide(side, 'sash', false, conf, filterColors), undefined, side, 'sash', conf, true);
                            }
                        }
                        break;
                }
            } finally {
                EventBusService.resume(['setConstructionColor'], pauseId);
            }
        }
        colors = null;
    }

    /**
     * Ustawia układ kolorów.
     * @memberof ColorsFactory
     * @param {string} type Układ kolorów
     */
    function setColorType(type, setRelated = true, conf = ConfigurationsService.conf.Current, confDefault = ConfigurationsService.conf.Default, filterColors) {
        confDefault.ColorType = type;
        setDefaultsOnChangeWoodType(setRelated, conf, confDefault, filterColors);
        setDefaultColorForType('outer', conf, confDefault, filterColors);
        setDefaultColorForType('inner', conf, confDefault, filterColors);
        setDefaultColorForType('core', conf, confDefault, filterColors);

        if (['White', 'Cream'].indexOf(type) > -1) {
            conf.ColorsSashExt = false;
            setColorsSashExt(true, conf, confDefault);
        }

        if (setRelated) {
            ConstructionLimitationFactory.findReinforcement(conf);
            PriceService.count();
            HandlesFactory.checkIsOneHandleAndAllHasHandle(conf);
            HandlesFactory.setDefaultColorForHinge();

            EventBusService.post({
                key: 'icc-redraw',
                value: 'frame'
            });
        }

        if (IccConfig.Configurators.dependencies) {
            EventBusService.post({key: 'processDependencies', value: null});
        }

        if (conf.Sashes) {
            conf.Sashes.forEach(sash => {
                if (sash.glazing.type == 'pvc_panels') {
                    sash.glazing.selectedColor = Core.copy(conf.Colors);
                    sash.intSashes.forEach(intSash => {
                        if (intSash.glazing.type == 'pvc_panels') {
                            intSash.glazing.selectedColor = Core.copy(conf.Colors);
                        }
                    });
                    EventBusService.post({
                        key  : 'changedSashes',
                        value: {}
                    });
                }
            });
        }
    }

    /**
     * Ustawia kolory przy przełączaniu innego koloru skrzydła.
     * @memberof ColorsFactory
     * @param {bool} refresh Czy odświeżać ceny, rysunek, itp.
     */
    function setColorsSashExt(refresh = false, conf = ConfigurationsService.conf.Current, confDefault = ConfigurationsService.conf.Default) {
        confDefault.ColorsSashExt = conf.ColorsSashExt;
        if (!conf.ColorsSashExt) {
            var object = conf.Colors;
            var objectd = confDefault.Colors;
            const pauseId = EventBusService.pause(['setConstructionColor']);
            try {
                setColorSide(object.sash.outer, object.frame.outer, object.frame.outer.group, 'outer', 'sash', conf, object.frame.outer.isDefault);
                setColorSide(objectd.sash.outer, objectd.frame.outer, objectd.frame.outer.group, 'outer', 'sash', conf, objectd.frame.outer.isDefault);
                setColorSide(object.sash.inner, object.frame.inner, object.frame.inner.group, 'inner', 'sash', conf, object.frame.inner.isDefault);
                setColorSide(objectd.sash.inner, objectd.frame.inner, objectd.frame.inner.group, 'inner', 'sash', conf, objectd.frame.inner.isDefault);
                setColorSide(object.sash.core, object.frame.core, object.frame.core.group, 'core', 'sash', conf, object.frame.core.isDefault);
                setColorSide(objectd.sash.core, objectd.frame.core, objectd.frame.core.group, 'core', 'sash', conf, objectd.frame.core.isDefault);
                setColorSide(object.sash.alushell, object.frame.alushell, object.frame.alushell.group, 'alushell', 'sash', conf, object.frame.alushell.isDefault);
                setColorSide(objectd.sash.alushell, objectd.frame.alushell, objectd.frame.alushell.group, 'alushell', 'sash', conf, objectd.frame.alushell.isDefault);
            } finally {
                EventBusService.resume(['setConstructionColor'], pauseId);
            }
        }

        if (!refresh) {
            HandlesFactory.checkIsOneHandleAndAllHasHandle(conf);
            HandlesFactory.setDefaultColorForHinge();
            PriceService.count();
        }
    }

    /**
     * Ustawia kolor w danym w miejscu konstrukcji.
     * @memberof ColorsFactory
     * @param {string}     colorType Strona konstrukcji (outer, inner, core, alushell)
     * @param {string}     place     Miejsce - skrzydło, rama
     * @param {object}     color     Kolor
     * @param {string|int} type      Grupa koloru
     * @param {bool}       refresh   Czy odświeżać ceny, rysunek, itp.
     */
    function setColor(colorType, place, color, type, refresh, conf = ConfigurationsService.conf.Current, confDefault = ConfigurationsService.conf.Default) {
        var object = conf.Colors;
        var objectd = confDefault.Colors;
        if (color == 'none') {
            color = {};
        }

        const pauseId = EventBusService.pause(['setConstructionColor']);
        try {
            if (!conf.ColorsSashExt) {
                setColorSide(object.frame[colorType], color, type, colorType, 'frame');
                setColorSide(objectd.frame[colorType], color, type, colorType, 'frame');
                setColorSide(object.sash[colorType], color, type, colorType, 'sash');
                setColorSide(objectd.sash[colorType], color, type, colorType, 'sash');
            } else {
                setColorSide(object[place][colorType], color, type, colorType, place);
                setColorSide(objectd[place][colorType], color, type, colorType, place);
            }
        } finally {
            EventBusService.resume(['setConstructionColor'], pauseId);
        }

        if (colorType === 'outer'
            && ['Bilateral', 'Transparent', 'Opaque', '3D'].indexOf(conf.ColorType) > -1
        ) {
            setColor('inner', place, color, type, refresh, conf, confDefault);
        }

        if (!refresh) {
            if (CurConfService.conf == 'window' || CurConfService.conf == 'hs') {
                RollerColorsFactory.setDefaultsColorsOnChangeColor('window');
            }
            if (CurConfService.conf == 'window' || CurConfService.conf == 'hs' || CurConfService.conf == 'door') {
                HandlesFactory.checkIsOneHandleAndAllHasHandle(conf);
                HandlesFactory.setDefaultColorForHinge();
                $rootScope.$emit('refreshPriceProp');
                ConstructionLimitationFactory.findReinforcement(conf);
                PriceService.count();
            }
        }

        EventBusService.post({
            key: 'icc-redraw',
            value: 'frame'
        });
    }

    /**
     * Ustawia rodzaj drewna
     * @memberof ColorsFactory
     * @param {object} wood Rodzaj drewna.
     */
    function setWood(wood, conf = ConfigurationsService.conf.Current, confDefault = ConfigurationsService.conf.Default, callback) {
        conf.Wood = Core.copy(wood);
        confDefault.Wood = Core.copy(wood);

        if (typeof callback === 'function'){
            callback();
        } else {
            setDefaults();
        }
    }

    /**
     * Otwiera popup z wyborem koloru.
     * @memberof ColorsFactory
     * @param  {string} type  Strona konstrukcji (outer, inner, core, alushell)
     * @param  {string} place Miejsce - skrzydło, rama
     */
    function openModalColor(type, place, conf = ConfigurationsService.conf.Current, filterColors, refreshConf = true, novalidate = false) {
        let disable = [];
        switch (type) {
            case 'outer':
                disable = ['White', 'Cream', 'Inner'];
                break;
            case 'inner':
                disable = ['White', 'Cream', 'Outer', 'Bilateral', 'Transparent', 'Opaque', '3D'];
                break;
            case 'core':
                disable = ['White', 'Cream'];
                break;
        }
        if (disable.indexOf(conf.ColorType) > -1) {
            return;
        }
        let side = [];
        if (type !== 'outer') {
            side = [place[0].toUpperCase() + '|' + type[0].toUpperCase()];
            if (['Bicolor'].indexOf(conf.ColorType) > -1 && type === 'inner') {
                side = [place[0].toUpperCase() + '|D' + type[0].toUpperCase()];
            }
        } else {
            if (['Bilateral', '3D', 'Transparent', 'Opaque'].indexOf(conf.ColorType) > -1) {
                side = [place[0].toUpperCase() + '|B'];
            } else {
                side = [place[0].toUpperCase() + '|' + type[0].toUpperCase()];
                if (['Bicolor'].indexOf(conf.ColorType) > -1) {
                    side = [place[0].toUpperCase() + '|D' + type[0].toUpperCase()];
                }
            }
        }
        const colorsWin = [];
        let groups = [];

        factory.windowColors.filter(color => {
            let match = false;
            if (angular.isArray(color.sides)) {
                match = side.every(s => color.sides.indexOf(s) > -1);
            }
            return match;
        }).forEach(color => {
            if (angular.isArray(color.groups)) {
                colorsWin.push(color);
                groups = groups.concat(color.groups.filter(group => groups.indexOf(group) === -1));
            }
        });

        if (conf.ColorType != 'Transparent' || type == 'alushell') {
            factory.windowColorRals.forEach(color => {
                if (angular.isArray(color.groups)) {
                    groups = groups.concat(color.groups.filter(group => groups.indexOf(group) === -1));
                }
            });
        }

        const modalInstance = $uibModal.open({
            templateUrl: 'modalColorPVC.html',
            controller : 'ModalColorPVCCtrl as mColorPVC',
            resolve    : {
                colors       : () => colorsWin,
                colorsRAL    : () => factory.windowColorRals,
                selectedColor: () => conf.Colors[place][type],
                colorGroups  : () =>
                    ConfiguratorsDataService.data.windowColorGroups.filter(el => {
                        let val = groups.indexOf(el.id) > -1
                                && (angular.isArray(el.systems)
                                && el.systems.indexOf(conf.System.id) > -1
                                && el.target.indexOf('show') > -1
                                && (
                                    conf.System.type != 'wood'
                                    || angular.isArray(el.woodTypes)
                                        && el.woodTypes.indexOf(conf.Wood.id) > -1
                                ) || filterColors && filterColors(el));

                        const groupSides = (el.sides || []).filter(Boolean)
                        if (groupSides.length) {
                            val = val && side.some(s => groupSides.includes(s))
                        }

                        return val;
                    }),
                transparentWood: () =>
                    conf.System.type == 'wood'
                    && conf.ColorType == 'Transparent',
                colorsSashExt: () => conf.ColorsSashExt,
                type         : () => type
            }
        });

        modalInstance.result.then(data => {
            if (angular.isObject(data)) {
                let defaultConf = ConfigurationsService.conf.Default;

                if (!angular.isObject(ConfigurationsService.conf.Default.Colors) && conf) {
                    defaultConf = {
                        Colors: {
                            frame: {
                                outer   : {},
                                inner   : {},
                                core    : {},
                                alushell: {}
                            },
                            sash: {
                                outer   : {},
                                inner   : {},
                                core    : {},
                                alushell: {}
                            }
                        }
                    };
                }
                setColor(type, place, data.color, data.group, !refreshConf, conf, defaultConf);
                if (!novalidate && conf.Sashes) {
                    conf.Sashes.forEach(sash => {
                        if (sash.glazing.type == 'pvc_panels') {
                            sash.glazing.selectedColor = Core.copy(conf.Colors);
                            sash.intSashes.forEach(intSash => {
                                if (intSash.glazing.type == 'pvc_panels') {
                                    intSash.glazing.selectedColor = Core.copy(conf.Colors);
                                }
                            });
                            EventBusService.post({
                                key  : 'changedSashes',
                                value: {}
                            });
                        }
                    });
                }
            }
            EventBusService.post({
                key: 'icc-redraw',
                value: 'frame'
            });
        });

        modalInstance.closed.then(() => {
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg'
                });
            }
        });
    }

    /**
     * Otwiera popup z wyborem rodzaju drewna.
     * @memberof ColorsFactory
     */
    function openModalWood(conf = ConfigurationsService.conf.Current, confDefault = ConfigurationsService.conf.Default, callback) {
        var modalInstance = $uibModal.open({
            templateUrl: 'modalWood.html',
            controller : 'ModalWoodCtrl as wood',
            resolve    : {
                woods  : () => factory.woods,
                selWood: () => conf.Wood
            }
        });

        modalInstance.result.then(selectedWood => {
            if (angular.isObject(selectedWood)) {
                setWood(selectedWood, conf, confDefault, callback);
            }
        });
    }

    /**
     * Sprawdza poprawność kolorów konstrukcji.
     * @return {bool} Poprawność kolorów konstrukcji.
     */
    function validate() {
        if (configurators.indexOf(CurConfService.conf) === -1) {
            IssuesService.unregister('no-window-colors', ConfigurationsService.conf.Current);
            IssuesService.unregister('no-window-alushell-color', ConfigurationsService.conf.Current);
            return true;
        }
        var colors = ConfigurationsService.conf.Current.Colors;
        if ((ConfigurationsService.conf.Current.System || {}).type == 'alu') {
            if (angular.isUndefined(colors.frame.outer.id) || angular.isUndefined(colors.sash.outer.id)
                || angular.isUndefined(colors.frame.inner.id) || angular.isUndefined(colors.sash.inner.id)) {
                IssuesService.simpleRegister(
                    'no-window-colors',
                    'Kolory nie zostały określone.',
                    $filter('translate')('COLOR|Kolory nie zostały określone.'),
                    ConfigurationsService.conf.Current,
                    {
                        logLevel: IssueLevel.NONE
                    }
                );
                return false;
            }
        } else {
            if ((ConfigurationsService.conf.Current.System || {}).type == 'pvc' && (angular.isUndefined(colors.frame.core.id) || angular.isUndefined(colors.sash.core.id))) {
                IssuesService.simpleRegister(
                    'no-window-colors',
                    'Kolor okna nie został określony.',
                    $filter('translate')('WINDOW|Kolor okna nie został określony.'),
                    ConfigurationsService.conf.Current,
                    {
                        logLevel: IssueLevel.NONE
                    }
                );
                return false;
            }
        }
        if (ConfigurationsService.conf.Current.HasAlushell
            && ((IccConfig.Configurators.alushellExt && ConfigurationsService.conf.Current.AlushellType != 'brushed')
                || !IccConfig.Configurators.alushellExt)
            && (angular.isUndefined(colors.frame.alushell.id) || angular.isUndefined(colors.sash.alushell.id))) {
                IssuesService.simpleRegister(
                    'no-window-alushell-color',
                    'Wybierz kolor nakładki.',
                    $filter('translate')('WINDOW|Wybierz kolor nakładki.'),
                    ConfigurationsService.conf.Current,
                    {
                        logLevel: IssueLevel.NONE
                    }
                );
            return false;
        }
        colors = null;
        IssuesService.unregister('no-window-colors', ConfigurationsService.conf.Current);
        IssuesService.unregister('no-window-alushell-color', ConfigurationsService.conf.Current);
        return true;
    }

    /**
     * Zwraca kolor wybrany w wizualizatorze.
     * @return {boolean|string} Nazwa koloru lub false kiedy nie wybrany.
     */
    function getColorFromVisualizer() {
        var visColors = Core.parseJson(localStorage.getItem('colors'));
        var windowColor;
        var visColor;
        var timeLimit = new Date(new Date().getTime() - (24 * 60 * 60 * 1000)); // doba wstecz

        if (angular.isUndefined(visColors.time) || new Date(visColors.time) >= timeLimit) {
            if (CurConfService.conf == 'window' || CurConfService.conf == 'hs') {
                visColor = visColors.okna;
            } else if (CurConfService.conf == 'door') {
                visColor = visColors.drzwi;
            }
        }

        if (angular.isString(visColor)) {
            if (visColor.indexOf('okleiny/') > -1) {
                windowColor = visColor.replace('okleiny/', '');
            } else {
                windowColor = visColor.substring(1);
            }
            return windowColor;
        } else {
            return false;
        }
    }

    function clearAlushellColor() {
        ConfigurationsService.conf.Current.Colors.frame.alushell = {};
        ConfigurationsService.conf.Current.Colors.sash.alushell = {};
    }

    function setAlushellColor(colorType = '') {
        if (ConfigurationsService.conf.Current.HasAlushell) {
            if (!ConfigurationsService.conf.Current.Colors.frame.alushell.id) {
                if (colorType === 'brushed' || IccConfig.Configurators.alushellExt) {
                    ConfigurationsService.conf.Current.AlushellType = 'brushed';
                    const windowColors = ConfiguratorsDataService.data.windowColorsAll;
                    const colorsGroups = ConfiguratorsDataService.data.windowColorGroups;

                    const brushedAluGroup = colorsGroups.find(group => group.brushed_alu_group === true);
                    if (!angular.isObject(brushedAluGroup)) {
                        clearAlushellColor();
                        InfoFactory.showInfo($filter('translate')('WINDOW|Brak zdefiniowanej grupy dla aluminium szczotkowanego'), null);
                        return;
                    }

                    const brushedAluColor = windowColors.find(color => angular.isArray(color.groups) && color.groups.indexOf(brushedAluGroup.id) > -1);

                    if (!angular.isObject(brushedAluColor)) {
                        clearAlushellColor();
                        InfoFactory.showInfo($filter('translate')('WINDOW|Brak przypisanego koloru do grupy dla nakładki aluminiowej szczotkowanej'), null);
                        return;
                    }

                    setColor('alushell', 'frame', brushedAluColor, brushedAluGroup, true);
                    if (ConfigurationsService.conf.Current.ColorsSashExt) {
                        setColor('alushell', 'sash', brushedAluColor, brushedAluGroup, true);
                    }
                }
            } else if (colorType === 'painted') {
                clearAlushellColor();
                EventBusService.post({
                    key  : 'alushellColorTypeChange',
                    value: {}
                });
                ConfigurationsService.conf.Current.AlushellType = colorType;
            }
        } else {
            clearAlushellColor();
            EventBusService.post({
                key  : 'alushellColorTypeChange',
                value: {}
            });
        }
    }

}
