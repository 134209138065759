import {Injectable, Inject} from '@angular/core';
import {Glass, InterPaneSpace, GlazingUnitElement, FillingsProducer} from 'configurations/parts/window';
import {EventBusService} from 'event-bus.service';
import {LoadedConfiguratorsDataValue, ConfiguratorsDataService} from 'configurators/configurators-data.service';
import {FillingsService} from './fillings.service';
import {core} from 'helpers';
import ConfigurationsService from 'configurations/configurations.service';
import WindowActiveConfiguration from "configurations/WindowActiveConfiguration";
import { fixCustomFillings } from 'fix-glazing-units';

@Injectable()
export default class GlazingUnitsService {

    private glasses: Glass[] = [];
    private interPaneSpaces: InterPaneSpace[] = [];
    private fillingsProducers: FillingsProducer[] = [];
    private systemIds: string[] = [];
    private allFillings = [];

    constructor(
        private ConfiguratorsDataService: ConfiguratorsDataService,
        private configurationsService: ConfigurationsService,
        private eventBusService: EventBusService,
        private fillingsService: FillingsService,
        @Inject('$uibModal') private $uibModal: ng.ui.bootstrap.IModalService,
    ) {
        this.eventBusService.subscribe<LoadedConfiguratorsDataValue>('loadedConfiguratorsData', data => {
            this.glasses = core.copy(data.value.glasses);
            this.interPaneSpaces = core.copy(data.value.interPaneSpaces);
            this.fillingsProducers = core.copy(data.value.fillingsProducers);
            this.systemIds = data.value.windowLines.map(el => el.id);
            this.allFillings = core.copy(data.value.fillings);
        });
    }

    newFillingFromGlazingUnit(glazingUnit: GlazingUnitElement[], producerId, filling = null) {
        let extendedGlazingUnit = core.copy(glazingUnit);
        let glasses = [];
        let interPaneSpaces = [];
        let producer = <FillingsProducer>core.fIdO(this.fillingsProducers, producerId);
        const ugFactor = this.setNewGlazingUnitUgFactor(glazingUnit) || null;
        extendedGlazingUnit = extendedGlazingUnit.map(el => {
            if (el.type == 'glass') {
                el.data = <Glass>core.fIdO(this.glasses, el.value);
                glasses.push(core.copy(el.data));
            } else if (el.type == 'interPaneSpace') {
                el.data = <InterPaneSpace>core.fIdO(this.interPaneSpaces, el.value);
                interPaneSpaces.push(core.copy(el.data));
            }
            return el;
        });
        let newFilling = {
            id: core.generateUUID(),
            fillings_producer_id: producerId,
            custom: true,
            name: extendedGlazingUnit.map(el => el.data.name).join(' / '),
            glazing_unit: core.copy(glazingUnit),
            conf: filling ? filling.conf : [
                'door',
                'window'
            ],
            frame_width: Math.min(...interPaneSpaces.map(el => el.thickness)),
            glazing_count: glasses.length,
            has_glazing_unit: true,
            minBillingArea: filling ? filling.minBillingArea : producer.min_area_price,
            ornament: filling ? filling.ornament : false,
            price_sq_m: this.glazingUnitPrice(extendedGlazingUnit, filling),
            thickness_mm: (glasses.reduce((prev, curr) => prev + curr.thickness, 0))
                        + (interPaneSpaces.reduce((prev, curr) => prev + curr.thickness, 0)),
            thinkness_glass: Math.min(...glasses.map(el => el.thicknessForCalculations)),
            type: 'glazing',
            u: ugFactor,
            weight: glasses.reduce((prev, curr) => prev + curr.weight, 0),
            system_types: filling ? filling.system_types : [
                'pvc',
                'alu',
                'wood'
            ],
            system_ids: filling ? filling.system_ids : this.systemIds,
        };

        return newFilling;
    }

    setNewGlazingUnitUgFactor(newGlazingUnit) {
        const ugFactorSchemaSets = JSON.parse(this.ConfiguratorsDataService.data.windowUgFactorSets);
        if (ugFactorSchemaSets === null) {
            return null;
        }

        let unitSchema: any = [];

        let simplifiedSchemaSets = ugFactorSchemaSets.map(set => Object.keys(set).map(key => set[key]));
        simplifiedSchemaSets.forEach((text, index, self) => {
          text.pop();
          // [1, 2, 1, 34] => [1:2:1:34]
          self[index] = text.join(':');
        });

        newGlazingUnit.forEach((unitElem, index) => {
            if (unitElem.type === 'glass') {

                const glassCoating = this.glasses.filter(glass => glass.id === unitElem.value)[0].glassCoating;
                if (glassCoating === null) {
                    unitSchema.push('0');
                } else {
                    unitSchema.push(String(glassCoating.id));
                }
            }
            if (unitElem.type === 'interPaneSpace') {
                unitSchema.push(String(unitElem.value));
            }
        });
        unitSchema = unitSchema.join(':');

        const foundedUgFactorSchemaIndex = simplifiedSchemaSets.findIndex(elem => elem === unitSchema);
        if (foundedUgFactorSchemaIndex > -1) {
            return ugFactorSchemaSets[foundedUgFactorSchemaIndex].ug;
        }
    }

    glazingUnitPrice(glazingUnit: GlazingUnitElement[], filling = null) {
        let price;

        if (filling == null) {
            price = glazingUnit.reduce((prev, curr) => prev + curr.data.price, 0);
        } else {
            price = filling.price_sq_m;
            for (let i = 0; i < glazingUnit.length; i++) {
                if (glazingUnit[i].value != filling.glazing_unit[i].value) {
                    if (glazingUnit[i].type == 'glass') {
                        let glass = <Glass>core.fIdO(this.glasses, filling.glazing_unit[i].value);
                        price = price - glass.price + (<Glass>glazingUnit[i].data).price;
                    } else if (glazingUnit[i].type == 'interPaneSpace') {
                        let interPaneSpace = <InterPaneSpace>core.fIdO(this.interPaneSpaces, filling.glazing_unit[i].value);
                        price = price - interPaneSpace.price + (<InterPaneSpace>glazingUnit[i].data).price;
                    }
                }
            }
        }

        return price;
    }

    async addGlazingUnit(filling = null) {
        let [newGlazingUnit, producerId] = await this.openGlazingUnitModal(filling ? <GlazingUnitElement[]>core.copy(filling.glazing_unit) : null, filling ? Number(filling.fillings_producer_id) : null);
        if (newGlazingUnit) {
            const newGlazingUnitJson = JSON.stringify(newGlazingUnit);
            if (filling && JSON.stringify(filling.glazing_unit) == newGlazingUnitJson) {
                return false;
            } else {
                const conf = this.configurationsService.conf.Current as WindowActiveConfiguration;
                if (conf.CustomFillings) {
                    conf.CustomFillings = fixCustomFillings(conf.CustomFillings, this.glasses, this.interPaneSpaces);
                    conf.CustomFillings.forEach(customFilling => {
                        if (!this.allFillings.some(el => el.id === customFilling.id)) {
                            this.allFillings.unshift(customFilling);
                        }
                    });
                }
                const matchedFillings = this.allFillings.filter(el => el.glazing_unit && JSON.stringify(el.glazing_unit) == newGlazingUnitJson);

                if (matchedFillings.length) {
                    return matchedFillings[0];
                } else {
                    let newFilling = this.newFillingFromGlazingUnit(newGlazingUnit, producerId, filling);
                    this.fillingsService.saveCustomFilling(newFilling);
                    this.allFillings.unshift(newFilling);
                    return newFilling;
                }
            }
        } else {
            return false;
        }
    }

    async openGlazingUnitModal(glazingUnit: GlazingUnitElement[] | null, producerId: number) {
        const modalInstance = this.$uibModal.open({
            component: 'glazingUnitModal',
            resolve: {
                glasses: () => this.glasses,
                interPaneSpaces: () => this.interPaneSpaces,
                fillingsProducers: () => this.fillingsProducers,
                glazingUnit: () => glazingUnit,
                producerId: () => producerId,
            }
        });

        return await modalInstance.result;
    }

}
