import angular from 'angular';
import { IssueLevel } from 'issues.service';

/**
 * Fabryka rozmiarów rolet
 * @param {object} $rootScope               Angular Root Scope
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} Core                     Core
 * @param {object} IccConfig                Konfiguracja systemu
 * @param {object} ConfiguratorsDataService Fabryka danych konfiguracji
 * @param {object} CurConfService           Bieżaca konfiguracja
 * @param {object} DrawDataService          Fabryka rysowania
 * @param {object} PriceService             Liczenie cen
 */
export default function DimensionsFactory($rootScope, $uibModal, $filter, ConfigurationsService, Core, IccConfig, ParametersService,
                                ConfiguratorsDataService, CurConfService, DrawDataService, PriceService, PriceRollerService,
                                StepFactory, IssuesService, EventBusService, StateService
                            ) {
    'ngInject';

    var allProfiles   = [];
    var allRoundReels = [];
    let matchedPrices = [];

    var factory = {
        railCutAngles       : [],
        profiles            : [],
        roundReels          : [],
        boxSizes            : [],
        prices              : [],
        pricesData          : [],
        montagesInfo        : {},
        defaultRealBoxHeight: 0,
        loadedData          : false,
        guideProfiles       : [],
        slatProfiles        : [],

        loadProfilesBySystem,
        setBoundaryDimensionsFromPrices,
        loadBoxHeights,
        updateDimensions,
        updateRailsBottomCut,
        refreshRatio,
        changeProfile,
        changeRoundReel,
        onBoxHeightChange,
        toggleMosquito,
        openModalMontagesInfo,
        setDimensions,

        openModalGuideSelection,
        setGuideProfiles,
        saveGuideProfile,
        getGuide,
        existsGuideProfile,
        resetGuides,

        openModalSlatSelection,
        setSlatProfiles,
        saveSlatProfile,
        getSlat,
        existsSlatProfile,
        resetSlats
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });
    $rootScope.$on('changedStep', () => {
        updateDimensions();
    });

    return factory;

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        if (CurConfService.conf != 'window' && CurConfService.conf != 'roller_shutter' && CurConfService.conf != 'hs') {
            return;
        }
        if (angular.isArray(ConfiguratorsDataService.data.profiles)) {
            allProfiles = ConfiguratorsDataService.data.rollerShutterProfiles;
        }
        if (angular.isArray(ConfiguratorsDataService.data.roundReels)) {
            allRoundReels = ConfiguratorsDataService.data.roundReels;
        }
        if (angular.isArray(ConfiguratorsDataService.data.rollerPrices)) {
            factory.prices = ConfiguratorsDataService.data.rollerPrices;
        }
        if (angular.isArray(ConfiguratorsDataService.data.rollerPricesData)) {
            factory.pricesData = ConfiguratorsDataService.data.rollerPricesData;
        }
        if (angular.isObject(ConfiguratorsDataService.data.rollerShutterMontages)) {
            factory.montagesInfo = ConfiguratorsDataService.data.rollerShutterMontages;
        }

        factory.railCutAngles = IccConfig.Settings.railCutAngles.split(',');

        loadProfilesBySystem(ConfigurationsService.conf.Current);
        setSlatProfiles();
        if (angular.isUndefined(ConfigurationsService.conf.Current.RollerShutter.profile)
            || angular.isUndefined(ConfigurationsService.conf.Current.RollerShutter.profile.id)
        ) {
            setDefaultData();
        } else {
            setBoundaryDimensionsFromPrices();
        }

        updateRailsBottomCut(false);

        PriceService.count();
        ParametersService.count(ConfigurationsService.conf.Current);
        factory.loadedData = true;
    }

    /**
     * Funkcja ustawiajaca domyślne dane
     */
    function setDefaultData() {
        loadBoxHeights();
        setBoundaryDimensionsFromPrices();
        ConfigurationsService.conf.Current.RollerShutter.boxType          = 'R';
        setDefaultProfile(ConfigurationsService.conf.Current);
        ConfigurationsService.conf.Current.RollerShutter.changedSize      = false;
        ConfigurationsService.conf.Current.RollerShutter.realRollerHeight = 1500;
        ConfigurationsService.conf.Current.RollerShutter.realBoxWidth     = 1500;
        updateDimensions();
    }

    /**
     * Funkcja pobierajace prowadnice oraz ustawiajaca domyślna prowadnice
     */
    function setGuideProfiles() {
        if (
            !IccConfig.Configurators.roller_shutter.guides
            || !(ConfiguratorsDataService.data && ConfiguratorsDataService.data.profilesProfiles)
            || !ConfigurationsService.conf.Current.RollerShutter.profile
        ) {
            return;
        }

        const matchedProfiles = ConfiguratorsDataService.data.profilesProfiles
            .filter(p => (p.profile_id == ConfigurationsService.conf.Current.RollerShutter.profile.id || p.connected_profile_id == ConfigurationsService.conf.Current.RollerShutter.profile.id) && p.type == 'assembling')
            .map(p => {
                if (p.profile_id == ConfigurationsService.conf.Current.RollerShutter.profile.id) {
                    return p.connected_profile_id;
                } else if (p.connected_profile_id == ConfigurationsService.conf.Current.RollerShutter.profile.id) {
                    return p.profile_id;
                }
            });

        factory.guideProfiles = ConfiguratorsDataService.data.profiles
            .filter(p =>
                matchedProfiles.includes('' + p.id)
                && p.type === 'roller_guide'
                && p.systems.indexOf(ConfigurationsService.conf.Current.RollerShutter.system.id) > -1
            );

        const guideProfiles = factory.guideProfiles.filter(p => !p.options.includes('double_guide'));
        const commonGuideProfiles = factory.guideProfiles.filter(p => p.options.includes('double_guide'));
        const shutters = ConfigurationsService.conf.Current.RollerShutter.shutters.slice();
        const pauseId = EventBusService.pause(['saveGuideProfile']);
        try {
            if (factory.guideProfiles) {
                if (shutters.length) {
                    let defaultGuideProfile = null;
                    if (guideProfiles.length) {
                        defaultGuideProfile = guideProfiles[0];
                    }
                    const leftGuide = getGuide(shutters[0].id, 'left');
                    if (!leftGuide || !factory.guideProfiles.some(g => g.id === leftGuide.id)) {
                        saveGuideProfile(shutters[0].id, defaultGuideProfile, 'left', true);
                    }
                    const rightGuide = getGuide(shutters[shutters.length - 1].id, 'right')
                    if (!rightGuide || !factory.guideProfiles.some(g => g.id === rightGuide.id)) {
                        saveGuideProfile(shutters[shutters.length - 1].id, defaultGuideProfile, 'right', true);
                    }
                    shutters.splice(0, 1);

                    if (shutters.length) {
                        for (let i = 0; i < shutters.length; i++) {
                            const shutter = shutters[i];
                            if (!('commonRail' in shutter) || !shutter.commonRail) {
                                const middleLeftGuide = getGuide(shutter.id, `middleLeft${shutter.id}`);
                                if (!middleLeftGuide || !factory.guideProfiles.some(g => g.id === middleLeftGuide.id)) {
                                    saveGuideProfile(shutter.id - 1, defaultGuideProfile, `middleLeft${shutter.id}`, true);
                                }
                                const middleRightGuide = getGuide(shutter.id, `middleRight${shutter.id}`);
                                if (!middleRightGuide || !factory.guideProfiles.some(g => g.id === middleRightGuide.id)) {
                                    saveGuideProfile(shutter.id, defaultGuideProfile, `middleRight${shutter.id}`, true);
                                }
                            } else {
                                if (commonGuideProfiles.length) {
                                    defaultGuideProfile = commonGuideProfiles[0];
                                } else {
                                    defaultGuideProfile = null;
                                }
                                const commonGuide = getGuide(shutter.id, `common${shutter.id}`);
                                if (!commonGuide || !factory.guideProfiles.some(g => g.id === commonGuide.id)) {
                                    saveGuideProfile(shutter.id, defaultGuideProfile, `common${shutter.id}`, true);
                                } else {
                                    saveGuideProfile(shutter.id, null, `common${shutter.id}`, true);
                                }
                            }
                        }
                    }
                }
            } else {
                resetGuides();
            }
        } finally {
            EventBusService.resume(['saveGuideProfile'], pauseId);
        }
    }

    /**
     * Funkcja zapisujaca wybrana prowadnice
     */
    function saveGuideProfile(rollerId, profile, side, selectedAsDefault = false) {
        const sideNumber = side.match(/[0-9]+/) || '';

        const common = side.includes('common' + sideNumber);
        const leftOf = side.includes('middleLeft' + sideNumber);
        const rightOf = side.includes('middleRight' + sideNumber);

        if (ConfigurationsService.conf.Current.RollerShutter.guideRails.length) {
            const existsGuideRailIndex = ConfigurationsService.conf.Current.RollerShutter.guideRails.findIndex(g => g.side === side && g.rollerId === rollerId);
            if (existsGuideRailIndex > -1) {
                ConfigurationsService.conf.Current.RollerShutter.guideRails.splice(existsGuideRailIndex, 1);
            }

            if (common) {
                while (ConfigurationsService.conf.Current.RollerShutter.guideRails.findIndex(g => g.side.includes('middleLeft' + sideNumber)) > -1) {
                    const middleLeftGuideRailIndex = ConfigurationsService.conf.Current.RollerShutter.guideRails.findIndex(g => g.side.includes('middleLeft' + sideNumber));
                    if (middleLeftGuideRailIndex > -1) {
                        ConfigurationsService.conf.Current.RollerShutter.guideRails.splice(middleLeftGuideRailIndex, 1);
                    }
                }

                while (ConfigurationsService.conf.Current.RollerShutter.guideRails.findIndex(g => g.side.includes('middleRight' + sideNumber)) > -1) {
                    const middleRightGuideRailIndex = ConfigurationsService.conf.Current.RollerShutter.guideRails.findIndex(g => g.side.includes('middleRight' + sideNumber));
                    if (middleRightGuideRailIndex > -1) {
                        ConfigurationsService.conf.Current.RollerShutter.guideRails.splice(middleRightGuideRailIndex, 1);
                    }
                }

            }

            if (leftOf || rightOf) {
                while (ConfigurationsService.conf.Current.RollerShutter.guideRails.findIndex(g => g.side.includes('common' + sideNumber)) > -1) {
                    const commonGuideRailIndex = ConfigurationsService.conf.Current.RollerShutter.guideRails.findIndex(g => g.side.includes('common' + sideNumber));
                    if (commonGuideRailIndex > -1) {
                        ConfigurationsService.conf.Current.RollerShutter.guideRails.splice(commonGuideRailIndex, 1);
                    }
                }
            }
        }

        const guideRail = {
            id: profile ? profile.id : null,
            name: profile ? profile.name : null,
            leftOf: null,
            rightOf: null,
            side,
            selectedAsDefault,
            rollerId
        };

        switch (side) {
            case 'left': guideRail.leftOf = rollerId; break;
            case 'right': guideRail.rightOf = rollerId; break;
        }

        if (side.includes('common')) {
            guideRail.rightOf = rollerId - 1;
            guideRail.leftOf = rollerId;
        }

        if (side.includes('middleLeft')) {
            guideRail.rightOf = rollerId;
        }

        if (side.includes('middleRight')) {
            guideRail.leftOf = rollerId;
        }

        ConfigurationsService.conf.Current.RollerShutter.guideRails.push(guideRail);
        EventBusService.post({key: 'saveGuideProfile', value: null});
        PriceService.count();
    }

    /**
     * Funkcja pobierajaca wybrana prowadnice
     */
    function getGuide(rollerId, side) {
        return ConfigurationsService.conf.Current.RollerShutter.guideRails.find(g => g[`${side}Of`] === rollerId);
    }

    /**
     * Funkcja sprawdzajaca czy istnieje prowadnica
     */
    function existsGuideProfile(id) {
        return factory.guideProfiles.find(p => p.id === id) || false;
    }

    /**
     * Funkcja resetujaca prowadnice
     */
    function resetGuides() {
        ConfigurationsService.conf.Current.RollerShutter.guideRails = [];
    }

    /**
     * Funkcja pobierajace prowadnice oraz ustawiajaca domyślna prowadnice
     */
    function setSlatProfiles() {
        if (
            !IccConfig.Configurators.roller_shutter.slats
            || !(ConfiguratorsDataService.data && ConfiguratorsDataService.data.profilesProfiles)
            || !ConfigurationsService.conf.Current.RollerShutter.profile
        ) {
            return;
        }
        const matchedProfiles = ConfiguratorsDataService.data.profilesProfiles
            .filter(p => (p.profile_id == ConfigurationsService.conf.Current.RollerShutter.profile.id || p.connected_profile_id == ConfigurationsService.conf.Current.RollerShutter.profile.id) && p.type == 'assembling')
            .map(p => {
                if (p.profile_id == ConfigurationsService.conf.Current.RollerShutter.profile.id) {
                    return p.connected_profile_id;
                } else if (p.connected_profile_id == ConfigurationsService.conf.Current.RollerShutter.profile.id) {
                    return p.profile_id;
                }
            });

        factory.slatProfiles = ConfiguratorsDataService.data.profiles
            .filter(p => matchedProfiles.includes('' + p.id) && p.type === 'roller_slat');
        const pauseId = EventBusService.pause(['saveSlatProfile']);
        try {
            if (factory.slatProfiles.length) {
                const defaultSlatProfile = factory.slatProfiles[0];
                ConfigurationsService.conf.Current.RollerShutter.shutters.forEach(shutter => {
                    const slat = getSlat(shutter.id);
                    if (!slat || !factory.slatProfiles.some(s => s.id === slat.id)) {
                        saveSlatProfile(shutter.id, defaultSlatProfile, true);
                    }
                });
            } else {
                resetSlats();
            }
        } finally {
            EventBusService.resume(['saveSlatProfile'], pauseId);
        }
    }

    /**
     * Funkcja zapisujaca wybrana prowadnice
     */
    function saveSlatProfile(rollerId, profile, selectedAsDefault = false) {
        if (!('id' in profile) || !('name' in profile)) {
            return;
        }
        if (ConfigurationsService.conf.Current.RollerShutter.slats.length) {
            const existsSlatIndex = ConfigurationsService.conf.Current.RollerShutter.slats.findIndex(g => g.rollerId === rollerId);
            if (existsSlatIndex > -1) {
                ConfigurationsService.conf.Current.RollerShutter.slats.splice(existsSlatIndex, 1);
            }
        }

        const slat = {
            id: profile.id,
            name: profile.name,
            selectedAsDefault,
            rollerId
        };

        ConfigurationsService.conf.Current.RollerShutter.slats.push(slat);
        EventBusService.post({key: 'saveSlatProfile', value: null});
        PriceService.count();
    }

    /**
     * Funkcja pobierajaca wybrana prowadnice
     */
    function getSlat(rollerId) {
        return ConfigurationsService.conf.Current.RollerShutter.slats.find(g => g.rollerId === rollerId);
    }

    /**
     * Funkcja sprawdzajaca czy istnieje listwa dolna
     */
    function existsSlatProfile(id) {
        return factory.slatProfiles.find(p => p.id === id) || false;
    }

    /**
     * Funkcja resetujaca listwy dolne
     */
    function resetSlats() {
        ConfigurationsService.conf.Current.RollerShutter.slats = [];
    }

    /**
     * Ustawia graniczne wymiary rolety
     */
    function setBoundaryDimensionsFromPrices() {
        matchedPrices = PriceRollerService.getPricesForRoller(ConfigurationsService.conf.Current.RollerShutter, ConfiguratorsDataService.data.rollerPricesData, ConfigurationsService.conf.Current.type, ConfigurationsService.conf.Current.Colors);
        if (angular.isDefined(matchedPrices) && angular.isDefined(matchedPrices[0])) {
            ConfigurationsService.conf.Current.RollerShutter.hasPrices = true;
        } else {
            ConfigurationsService.conf.Current.RollerShutter.hasPrices = false;
        }
        PriceService.count();
        ParametersService.count(ConfigurationsService.conf.Current);
    }

    /**
     * Zmiana kąta zacięcia prowadnicy
     */
    function updateRailsBottomCut(updateShutterHeight = false) {
        const angle = Number(ConfigurationsService.conf.Current.RollerShutter.railsBottomCut) || 0;

        if (updateShutterHeight) {
            ConfigurationsService.conf.Current.RollerShutter.shutters.map(shutter => {
                const guide = getGuide(shutter.id, 'left');
                const guideProfile = factory.guideProfiles.find(el => el.id === guide.id);
                const height = Number((guideProfile.depth * Math.tan(angle * (Math.PI / 180))).toFixed(1)) || 0;
                shutter.realHeight -= shutter.railCutHeight;
                shutter.realHeight += height;
                shutter.railCutHeight = height;
            });
        }

        updateDimensions();

    }

    /**
     * Funkcja odświeżajaca wysokość
     */
    function updateDimensions() {
        const conf = ConfigurationsService.conf.Current;
        const commonRail = (conf.type == 'roller_shutter'
            ? IccConfig.Configurators.price.commonRail.rollerShutter
            : IccConfig.Configurators.price.commonRail.window) || false;
        var i, shutter;
        var maxHeight = 0;
        var totalWidth = 0;
        const shutterWithCommonRail = [];
        let shutterWidth = 0;
        let shutterHeight = 0;
        let correctDimensions = true;
        if (conf.RollerShutter && conf.RollerShutter.shutters && conf.RollerShutter.shutters.length) {
            const railsHeightModify = conf.RollerShutter.railsHeightModify;
            for (i = conf.RollerShutter.shutters.length - 1; i >= 0; i--) {
                shutter = conf.RollerShutter.shutters[i];
                if (conf.type !== 'roller_shutter') {
                    if (railsHeightModify != null && IccConfig.Configurators.extendedRollerInWindow !== 'full') {
                        shutter.railsHeightModify = railsHeightModify;
                        shutter.realHeight = conf.Height + railsHeightModify;
                    } else {
                        shutter.railsHeightModify = shutter.realHeight - conf.Height;
                    }
                }
                if (shutter.commonRail) {
                    shutterWithCommonRail.push(shutter);
                } else {
                    shutterWithCommonRail.forEach(sh => {
                        sh.realHeight = shutter.realHeight;
                        if (conf.type !== 'roller_shutter') {
                            if (railsHeightModify != null && IccConfig.Configurators.extendedRollerInWindow !== 'full') {
                                sh.railsHeightModify = railsHeightModify;
                                sh.realHeight = conf.Height + railsHeightModify;
                            } else {
                                sh.railsHeightModify = sh.realHeight - conf.Height;
                            }
                        }
                    });
                    Core.clear(shutterWithCommonRail);
                }

                if (IccConfig.Configurators.roller_shutter.extraDimensionsOptions) {
                    if (shutter.realHeight > maxHeight) {
                        maxHeight = shutter.realHeight;
                    }
                    conf.RollerShutter.realRollerHeight = maxHeight;
                }

                totalWidth += ~~shutter.realWidth;

                if (commonRail) {
                    if (IccConfig.Configurators.price.shutterHeightWithBox) {
                        shutterHeight = shutter.realHeight + conf.RollerShutter.realBoxHeight;
                    } else {
                        shutterHeight = shutter.realHeight;
                    }
                    if (shutter.commonRail) {
                        shutterWidth += shutter.realWidth;
                    } else {
                        shutterWidth += shutter.realWidth;
                        correctDimensions = correctDimensions && validDimensions({width: shutterWidth, height: shutterHeight, hasMosquito: shutter.mosquito});
                        shutterHeight = 0;
                        shutterWidth = 0;
                    }
                }
            }
            conf.RollerShutter.realBoxWidth = totalWidth;

            if (!commonRail) {
                let height = 0;
                if (IccConfig.Configurators.price.shutterHeightWithBox) {
                    height = conf.RollerShutter.realRollerHeight + conf.RollerShutter.realBoxHeight;
                } else {
                    height = conf.RollerShutter.realRollerHeight;
                }

                correctDimensions = correctDimensions && validDimensions({width: conf.RollerShutter.realBoxWidth, height, hasMosquito: false});
            }
            if (CurConfService.conf == 'roller_shutter') {
                if (!correctDimensions) {
                    IssuesService.simpleRegister(
                        'incorrect-roller_shutter-dimensions',
                        'Podane wymiary są nieprawidłowe.',
                        $filter('translate')('CONFIGURATOR|Podane wymiary są nieprawidłowe.'),
                        ConfigurationsService.conf.Current,
                        {
                            logLevel: IssueLevel.NONE
                        }
                    );
                } else {
                    IssuesService.unregister('incorrect-roller_shutter-dimensions', ConfigurationsService.conf.Current);
                }
                ConfigurationsService.conf.Current.Height = ConfigurationsService.conf.Current.RollerShutter.realRollerHeight;
                ConfigurationsService.conf.Current.Width  = ConfigurationsService.conf.Current.RollerShutter.realBoxWidth;
            }

            refreshRatio();
            conf.RollerShutter.rollerHeight = conf.RollerShutter.realRollerHeight * conf.RollerShutter.ratio;
            conf.RollerShutter.boxWidth     = 0;

            for (i = 0; i < conf.RollerShutter.shutters.length; i++) {
                shutter        = conf.RollerShutter.shutters[i];
                shutter.width  = shutter.realWidth * conf.RollerShutter.ratio;
                shutter.height = shutter.realHeight * conf.RollerShutter.ratio;
                conf.RollerShutter.boxWidth += shutter.width;
                if (i > 0) {
                    shutter.x = conf.RollerShutter.shutters[i - 1].width + conf.RollerShutter.shutters[i - 1].x;
                }
            }

            checkShuttersConstructionLimitations();
            loadRoundReelsByProfile(ConfigurationsService.conf.Current);
            $rootScope.$emit('changedShutter');
            EventBusService.post({
                key: 'icc-redraw',
                value: null
            });
            PriceService.count();
            ParametersService.count(ConfigurationsService.conf.Current);
        }
    }

    /**
     * Funkcja odświezania wysokości skrzynki
     * @param  {object} height wysokość
     */
    function loadBoxHeights(height, alwaysChange = false) {
        var boxHeights      = [];
        var filteredBoxHeights = [];
        var boxHeightsList  = [];
        var rollerPriceData = [];
        var i               = 0;
        var comparedHeight = ConfigurationsService.conf.Current.Height + ConfigurationsService.conf.Current.RollerShutter.railsHeightModify;
        const comparedHeightWithBoxHeight = !height && IccConfig.Configurators.price.shutterHeightWithBox && ConfigurationsService.conf.Current.RollerShutter.realBoxHeight;
        if (height) {
            comparedHeight = height;
        }

        var rollerPricesFiltered = PriceRollerService.getPricesForRoller(
            ConfigurationsService.conf.Current.RollerShutter,
            ConfiguratorsDataService.data.rollerPricesData,
            ConfigurationsService.conf.Current.type,
            ConfigurationsService.conf.Current.Colors
        );
        var fieldBoxHeight = angular.isObject(ConfigurationsService.conf.Current.RollerShutter.drive)
            && ConfigurationsService.conf.Current.RollerShutter.drive.type != 'manual' ? 'height_box_electrical' : 'height_box';
        var fieldBoxValue = 0;

        if (
            ConfigurationsService.conf.Current.RollerShutter.system
            && ConfigurationsService.conf.Current.RollerShutter.system.box_heights
            && ConfigurationsService.conf.Current.RollerShutter.system.box_heights.length
        ) {
            filteredBoxHeights = ConfigurationsService.conf.Current.RollerShutter.system.box_heights.filter(el => {
                return el.height > 0 && el.options.some(opt => {
                    return opt.height >= ConfigurationsService.conf.Current.RollerShutter.realRollerHeight
                        && (!opt.profile || opt.profile == ConfigurationsService.conf.Current.RollerShutter.profile.id)
                        && (!opt.reel || opt.reel == ConfigurationsService.conf.Current.RollerShutter.roundReel.id)
                        && (!opt.hanger || (ConfigurationsService.conf.Current.RollerShutter.hanger && opt.hanger == ConfigurationsService.conf.Current.RollerShutter.hanger.id))
                        && opt.mosquito === ConfigurationsService.conf.Current.RollerShutter.shutters.some(e => e.mosquito);
                });
            }).map(el => el.height);
        }

        if (
            !filteredBoxHeights.length
            && angular.isDefined(rollerPricesFiltered)
            && angular.isDefined(rollerPricesFiltered[0])
        ) {
            rollerPriceData = rollerPricesFiltered[0].data.sort((a, b) => b.height_to - a.height_to);

            for (i = 0; i < rollerPriceData.length; i++) {
                fieldBoxValue = rollerPriceData[i][fieldBoxHeight] ? rollerPriceData[i][fieldBoxHeight] : rollerPriceData[i].height_box;
                if (boxHeightsList.indexOf(fieldBoxValue) === -1) {
                    boxHeights.push({
                        to    : rollerPriceData[i].height_to,
                        height: fieldBoxValue
                    });
                    boxHeightsList.push(fieldBoxValue);
                }
            }
            filteredBoxHeights = boxHeights
                .filter(el => {
                    let localHeight = comparedHeight;
                    if (comparedHeightWithBoxHeight) {
                        localHeight = ConfigurationsService.conf.Current.Height + Number(el.height);
                    }
                    return ~~el.to >= localHeight;
                })
                .map(el => el.height * 1)
                .sort((a, b) => a - b);

            if (filteredBoxHeights.length === 0) {
                let firstComparedHeight = comparedHeight;
                if (comparedHeightWithBoxHeight) {
                    firstComparedHeight = ConfigurationsService.conf.Current.Height + Number(boxHeights[0].height);
                }
                let lastComparedHeight = comparedHeight;
                if (comparedHeightWithBoxHeight) {
                    lastComparedHeight = ConfigurationsService.conf.Current.Height + Number(boxHeights[boxHeights.length - 1].height);
                }
                if (firstComparedHeight > Number(boxHeights[0].to)) {
                    filteredBoxHeights.push(Number(boxHeights[0].height));
                } else if (lastComparedHeight < Number(boxHeights[boxHeights.length - 1].to)) {
                    filteredBoxHeights.push(Number(boxHeights[boxHeights.length - 1].height));
                }
            }
        }

        factory.defaultRealBoxHeight = filteredBoxHeights[0];
        ConfigurationsService.conf.Current.RollerShutter.originalBoxHeight = filteredBoxHeights[0];

        Core.clear(factory.boxSizes);
        for (i = 0; i < filteredBoxHeights.length; i++) {
            factory.boxSizes.push(filteredBoxHeights[i]);
        }
        if (factory.boxSizes.length === 0 && ConfigurationsService.conf.Current.type != 'roller_shutter') {
            if (IccConfig.Configurators.extendedRollerInWindow !== 'full' || !IccConfig.Configurators.alwaysShowRollerStep) {
                ConfigurationsService.conf.Current.hasRoller = false;
            }
            if (!IccConfig.Configurators.alwaysShowRollerStep) {
                StepFactory.disable('rollershutter');
            }
        }
        if (factory.boxSizes.indexOf(ConfigurationsService.conf.Current.RollerShutter.realBoxHeight) == -1 || alwaysChange) {
            ConfigurationsService.conf.Current.RollerShutter.realBoxHeight = factory.defaultRealBoxHeight;
        }
        ConfigurationsService.conf.Current.RollerShutter.boxHeightLevel = factory.boxSizes.indexOf(ConfigurationsService.conf.Current.RollerShutter.realBoxHeight);

        PriceService.count();
        ParametersService.count(ConfigurationsService.conf.Current);
        return filteredBoxHeights;
    }

    /**
     * Funkcja odświeżajaca proporcje
     */
    function refreshRatio() {
        var destWidth, destHeight;
        if ((CurConfService.conf == 'window' || CurConfService.conf == 'hs' || ConfigurationsService.conf.Current.type == 'roller_shutter')) {
            if (CurConfService.conf == 'window' || CurConfService.conf == 'hs') {
                ConfigurationsService.conf.Current.RollerShutter.ratio = DrawDataService.ratio;
                onBoxHeightChange();
            } else {
                if (!IccConfig.Configurators.roller_shutter.extraDimensionsOptions) {
                    destWidth  = 320;
                    destHeight = 220;
                } else {
                    destWidth  = 300;
                    destHeight = 200;
                }
                var ratioX     = destWidth / ConfigurationsService.conf.Current.RollerShutter.realBoxWidth;
                var ratioY     = destHeight / ConfigurationsService.conf.Current.RollerShutter.realRollerHeight;
                ConfigurationsService.conf.Current.RollerShutter.ratio
                    = ((ratioX * ConfigurationsService.conf.Current.RollerShutter.realRollerHeight) < destHeight) ? ratioX : ratioY;
            }
            loadBoxHeights();
        }
    }

    /**
     * Funkcja
     */
    function toggleMosquito() {
        loadBoxHeights();
        setBoundaryDimensionsFromPrices();
        PriceService.count();
        ParametersService.count(ConfigurationsService.conf.Current);
    }

    /**
     * Aktualizuje wysokość skrzynki w px
     */
    function onBoxHeightChange() {
        ConfigurationsService.conf.Current.RollerShutter.boxHeight = ConfigurationsService.conf.Current.RollerShutter.realBoxHeight
                                                                     * ConfigurationsService.conf.Current.RollerShutter.ratio;
        if (ConfigurationsService.conf.Current.RollerShutter.realBoxHeight != factory.defaultRealBoxHeight) {
            ConfigurationsService.conf.Current.RollerShutter.changedSize =  true;
        } else {
            ConfigurationsService.conf.Current.RollerShutter.changedSize =  false;
        }
        ConfigurationsService.conf.Current.RollerShutter.boxHeightLevel = factory.boxSizes.indexOf(ConfigurationsService.conf.Current.RollerShutter.realBoxHeight);
        PriceService.count();
        ParametersService.count(ConfigurationsService.conf.Current);
        EventBusService.post({
            key: 'icc-redraw',
            value: 'frame'
        });
    }

    /**
     * Ustawia wymiary rolety poazujace do konfiguracji
     */
    function setDimensions() {
        var roller = ConfigurationsService.conf.Current.RollerShutter;

        if ((ConfigurationsService.conf.Current.type == 'window' || ConfigurationsService.conf.Current.type == 'hs')
            && angular.isDefined(roller.shutters)
        ) {
            var width = roller.realBoxWidth;
            var widthLeft = ConfigurationsService.conf.Current.Width;
            let maxHeight = 0;

            for (let i = roller.shutters.length - 1; i >= 0; i--) {
                width -= roller.shutters[i].realWidth;
                if (widthLeft - width < 200) {
                    roller.shutters[i].realWidth = 200;
                } else {
                    roller.shutters[i].realWidth = widthLeft - width;
                }
                roller.shutters[i].width = roller.shutters[i].realWidth * roller.ratio;
                widthLeft -= roller.shutters[i].realWidth;

                roller.shutters[i].realHeight = ConfigurationsService.conf.Current.Height + roller.shutters[i].railsHeightModify;
                roller.shutters[i].height = roller.shutters[i].realHeight * roller.ratio;
                if (roller.shutters[i].realHeight > maxHeight) {
                    maxHeight = roller.shutters[i].realHeight;
                }
            }
            roller.realRollerHeight = maxHeight;
            roller.realBoxWidth = ConfigurationsService.conf.Current.Width;
            PriceService.count();
            ParametersService.count(ConfigurationsService.conf.Current);
        }
    }

    function checkShuttersConstructionLimitations() {
        const conf = ConfigurationsService.conf.Current;
        if ((conf.type !== 'roller_shutter' && !conf.hasRoller)
            || angular.isUndefined(conf.RollerShutter.shutters)
            || !angular.isObject(conf.RollerShutter.profile)
            || conf.RollerShutter.shutters.length === 0
            || conf.type === 'window' && !conf.hasRoller
            || !angular.isObject(conf.RollerShutter.system)
            || angular.isUndefined(conf.RollerShutter.system.id)
        ) {
            return;
        }

        const sizeRange = ConfiguratorsDataService.data.sizeRanges.find(range =>
            Number(range.id) === Number(conf.RollerShutter.profile.sizeRangeId));

        if (!conf.RollerShutter.shutters.every(shutter => {
            let width = shutter.realWidth;
            let height = shutter.realHeight;
            if (IccConfig.Configurators.roller_shutter.realDimensions) {
                const leftGuideRail = getGuide(shutter.id, 'left');
                const rightGuideRail = getGuide(shutter.id, 'right');
                const slat = getSlat(shutter.id);
                if (leftGuideRail && rightGuideRail) {
                    IssuesService.unregister('no-guide-rails', conf);
                    const leftGuideRailProfile = factory.guideProfiles.find(el => el.id === leftGuideRail.id);
                    const rightGuideRailProfile = factory.guideProfiles.find(el => el.id === rightGuideRail.id);
                    if (leftGuideRailProfile && rightGuideRailProfile) {
                        width -= (leftGuideRailProfile.widthOut - leftGuideRailProfile.depth + rightGuideRailProfile.widthOut - rightGuideRailProfile.depth);
                    }
                } else {
                    IssuesService.simpleRegister(
                        'no-guide-rails',
                        'Brak pasujących prowadnic',
                        $filter('translate')('WINDOW|Brak pasujących prowadnic'),
                        conf,
                        {
                            logLevel: IssueLevel.NONE
                        }
                    );
                }
                if (slat) {
                    IssuesService.unregister('no-slat', conf);
                    const slatProfile = factory.slatProfiles.find(el => el.id === slat.id);
                    height = angular.isNumber(conf.RollerShutter.profile.width)
                        ? Math.ceil(
                                (height + conf.RollerShutter.realBoxHeight / 2 - slatProfile.widthOut)
                                / conf.RollerShutter.profile.width)
                            * conf.RollerShutter.profile.width + slatProfile.widthOut
                        : height;
                } else {
                    IssuesService.simpleRegister(
                        'no-slat',
                        'Brak pasujących listw końcowych',
                        $filter('translate')('WINDOW|Brak pasujących listw końcowych'),
                        conf,
                        {
                            logLevel: IssueLevel.NONE
                        }
                    );
                }
            }
            return (!parseFloat(conf.RollerShutter.profile.max_area)
                || (height * width / 1000000) <= parseFloat(conf.RollerShutter.profile.max_area))
            && (!sizeRange
                || Core.pointInPolygon(sizeRange.sizes, width, height));
        })) {
            IssuesService.simpleRegister(
                'no-matched-construction-limitations',
                'UWAGA! Konstrukcja nie spełnia ograniczeń wymiarowych dla wybranego pancerza',
                $filter('translate')('WINDOW|UWAGA! Konstrukcja nie spełnia ograniczeń wymiarowych dla wybranego pancerza'),
                conf,
                {
                    logLevel: IssueLevel.NONE
                }
            );
        } else {
            IssuesService.unregister('no-matched-construction-limitations', conf);
        }
    }

    function validDimensions({width, height, hasMosquito}) {
        const rollerPricesMatched = matchedPrices.filter(r =>
            ((parseInt(r.with_mosquito) == 1 && hasMosquito) || (parseInt(r.with_mosquito) == 0 && !hasMosquito) || parseInt(r.with_mosquito) == 2)
        );
        if (rollerPricesMatched.length > 0) {
            const rollerPriceData = rollerPricesMatched[0].data;
            return rollerPriceData.filter(boundary => (
                width <= Number(boundary.width_to)
                && width >= Number(boundary.width_from)
                && height <= Number(boundary.height_to)
                && height >= Number(boundary.height_from)
            )).length > 0;
        }
        return false;
    }

    /**
     * Funkcja znajudujaca profil systemu
     * @param  {object} conf conf
     */
    function loadProfilesBySystem(conf) {
        factory.profiles = [];
        if (angular.isObject(conf.RollerShutter.system)) {
            factory.profiles = allProfiles.filter(el =>
                angular.isArray(el.systems) && el.systems.indexOf(conf.RollerShutter.system.id) > -1
            );
        }
        var availProfile = factory.profiles.some(el =>
            angular.isObject(conf.RollerShutter.profile) && el.id == conf.RollerShutter.profile.id
        );
        if (!availProfile) {
            setDefaultProfile(conf);
        }
    }

    /**
     * Ustawia domyślny profil
     * @param {object} conf conf
     */
    function setDefaultProfile(conf) {
        if (angular.isDefined(factory.profiles[0])) {
            conf.RollerShutter.profile = Core.copy(factory.profiles[0]);
            loadRoundReelsByProfile(conf);
            setGuideProfiles();
            setSlatProfiles();
        }
    }

    /**
     * Funkcja zmieniajca profil
     */
    function changeProfile(profile) {
        ConfigurationsService.conf.Current.RollerShutter.profile = profile;
        loadRoundReelsByProfile(ConfigurationsService.conf.Current);
        loadBoxHeights();
        setBoundaryDimensionsFromPrices();
        setGuideProfiles();
        setSlatProfiles();
        PriceService.count();
        ParametersService.count(ConfigurationsService.conf.Current);
    }

    /**
     * Funkcja ładująca rury nawojowe pasujące do pancerza.
     * @param  {object} conf conf
     */
    function loadRoundReelsByProfile(conf) {
        Core.clear(factory.roundReels);
        if (angular.isObject(conf.RollerShutter.profile)) {
            factory.roundReels.push(...allRoundReels.filter(el =>
                angular.isArray(el.profiles) && el.profiles.indexOf('' + conf.RollerShutter.profile.id) > -1
                && (!el.shutter_max_width || parseFloat(el.shutter_max_width) >= conf.RollerShutter.realBoxWidth)
            ));
        }
        var availRoundReel = factory.roundReels.some(el =>
            angular.isObject(conf.RollerShutter.roundReel) && el.id == conf.RollerShutter.roundReel.id
        );
        if (!availRoundReel) {
            setDefaultRoundReel(conf);
        }
    }

    /**
     * Ustawia domyślną rurę nawojową.
     * @param {object} conf conf
     */
    function setDefaultRoundReel(conf) {
        if (angular.isDefined(factory.roundReels[0])) {
            conf.RollerShutter.roundReel = Core.copy(factory.roundReels[0]);
        }
    }

    /**
     * Funkcja zmieniajca rurę nawojową
     */
    function changeRoundReel(roundReel) {
        ConfigurationsService.conf.Current.RollerShutter.roundReel = roundReel;
        loadBoxHeights();
        setBoundaryDimensionsFromPrices();
        PriceService.count();
        ParametersService.count(ConfigurationsService.conf.Current);
    }

    /**
     * Funkcja otwierajaca okno modal
     */
    function openModalMontagesInfo(details) {
        $uibModal.open({
            templateUrl: 'modalMontagesInfo.html',
            controller : 'ModalMontagesInfoCtrl as minfo',
            resolve    : {details: () => details}
        });
    }

    /**
     * Funkcja otwierajaca okno modal do wyboru szprosów
     * @param  {object} rollerId    Typ koloru
     * @param  {object} conf      Konfiguracja
     */
    function openModalGuideSelection(rollerId, side, conf) {
        setGuideProfiles();

        const guideProfiles = side.includes('common')
            ? factory.guideProfiles.filter(p => p.options.includes('double_guide'))
            : factory.guideProfiles.filter(p => !p.options.includes('double_guide'));
        const selectedProfile = getGuide(rollerId, side);

        const modalInstance = $uibModal.open({
            component: 'modalGuidesSelection',
            resolve: {
                profiles: () => guideProfiles,
                profilesPrices: () => ConfiguratorsDataService.data.profilesPrices,
                conf: () => conf,
                type: () => 'roller_guide',
                selectedProfile: () => selectedProfile ? factory.guideProfiles.find(p => p.id === selectedProfile.id) : null,
                currency: () => StateService.state.offers[0].doc.currency,
                rollerId: () => rollerId,
                side: () => side
            }
        });

        modalInstance.result.then(data => {
            if (data && side) {
                saveGuideProfile(rollerId, data, side);
            }
        }, res => null);
    }

    /**
     * Funkcja otwierajaca okno modal do wyboru listw koncowych
     * @param  {object} rollerId    ID shuttera
     * @param  {object} conf      Konfiguracja
     */
    function openModalSlatSelection(rollerId, conf) {
        setSlatProfiles();

        const selectedProfile = getSlat(rollerId);
        const modalInstance = $uibModal.open({
            component: 'modalSlatsSelection',
            resolve: {
                profiles: () => factory.slatProfiles,
                profilesPrices: () => ConfiguratorsDataService.data.profilesPrices,
                conf: () => conf,
                type: () => 'roller_slat',
                selectedProfile: () => selectedProfile ? factory.slatProfiles.find(p => p.id === selectedProfile.id) : null,
                currency: () => StateService.state.offers[0].doc.currency,
                rollerId: () => rollerId
            }
        });

        modalInstance.result.then(data => {
            if (data) {
                saveSlatProfile(rollerId, data);
            }
        }, res => null);
    }
}
