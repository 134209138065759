import { Injectable, Inject } from '@angular/core';
import { EventBusService } from 'event-bus.service';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import { LoadedConfiguratorsDataValue } from 'configurators/configurators-data.service';
import { core, logger } from 'helpers';
import Common from 'Common';
import StepFactory, { ChangedStepValue } from 'configurator/steps/StepFactory';
import { IssuesService, IssueLevel } from 'issues.service';
import { ValidationService } from 'configurators/validation.service';
import { APP_CONFIG, AppConfig } from 'config';
import SealColorsDefaultsService from 'colors/seal-colors-defaults.service';

@Injectable()
export class SealColorsService {
    allColors = [];
    colors = [];

    private loadedData = false;

    constructor(
        private eventBusService: EventBusService,
        private stepFactory: StepFactory,
        @Inject('$uibModal') private $uibModal: ng.ui.bootstrap.IModalService,
        @Inject(APP_CONFIG) private config: AppConfig,
        private issuesService: IssuesService,
        private validationService: ValidationService,
        private sealColorsDefaultsService: SealColorsDefaultsService
    ) {
        eventBusService.subscribe<LoadedConfiguratorsDataValue>('setConstructionColor', data => {
            if (
                WindowActiveConfiguration.is(data.activeConfiguration)
                && WindowActiveConfiguration.is(data.defaultConfiguration)
            ) {
                this.setDefaultColor(
                    data.activeConfiguration,
                    data.defaultConfiguration,
                    data.user
                );
            }
        });

        eventBusService.subscribe<LoadedConfiguratorsDataValue>('loadedConfiguratorsData', data => {
            if (config.IccConfig.Configurators.sealColorSelect) {
                this.loadColors(data.value);
            }
        });

        eventBusService.subscribe<LoadedConfiguratorsDataValue>('setSystem', data => {
            try {
                this.loadMatchingColors(data.activeConfiguration as WindowActiveConfiguration);
                this.setDefaultColor(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration,
                    data.user
                );
            } catch (err) {
                logger.error(err);
            }
        });
    }

    loadColors(data) {
        this.allColors = data.windowSealColors || [];
    }

    loadMatchingColors(conf: WindowActiveConfiguration) {
        if (this.allColors.length > 0) {
            this.colors = this.allColors.filter(
                color =>
                    color.window_lines_ids
                    && color.window_lines_ids.map(Number).indexOf(Number(conf.System.id)) > -1
            );
            this.loadedData = true;
        }
    }

    setDefaultColor(conf: WindowActiveConfiguration, defaultConf, user) {
        if (!this.loadedData) {
            return;
        }
        if (this.colors.length === 0) {
            this.issuesService.registerDataProblem(
                'no-seal-colors',
                'Brak kolorów uszczelek!',
                conf,
                {
                    level: IssueLevel.FATAL,
                    extra: {
                        systemId: Number(conf.System.id)
                    }
                }
            );
            this.validationService.invalid(conf, 'sealColor');
            return;
        }
        this.issuesService.unregister('no-seal-colors', conf);
        const shouldBeChanged =
            !Common.isObject(conf.SealColor)
            || !conf.SealColor.id
            || conf.SealColor.isDefault
            || (conf.SealColor.window_lines_ids
                && conf.SealColor.window_lines_ids.map(Number).indexOf(Number(conf.System.id))
                    === -1);
        if (shouldBeChanged) {
            const defaultColor = this.sealColorsDefaultsService.getDefaultColor(
                this.colors,
                conf,
                user,
                defaultConf
            );
            this.setColor(defaultColor, true, conf, defaultConf);
        } else {
            this.validationService.valid(conf, 'sealColor');
            this.eventBusService.post({
                key: 'setSealColor',
                value: {
                    colorId: conf.SealColor.id,
                },
            });
        }
    }
    setColor(color, isDefault = false, conf: WindowActiveConfiguration, defaultConf) {
        color = core.copy(color);
        color.isDefault = isDefault;
        conf.SealColor = core.copy(color);
        defaultConf.SealColor = color;
        this.validationService.valid(conf, 'sealColor');
        this.eventBusService.post({
            key: 'setSealColor',
            value: {
                colorId: color.id,
            },
        });
    }
    openModal(conf: WindowActiveConfiguration, defaultConf) {
        this.$uibModal
            .open({
                templateUrl: 'modalSealColor.html',
                controller: 'ModalSealColorCtrl as msealcolor',
                resolve: {
                    colors: () => this.colors,
                    selColor: () => conf.SealColor,
                },
            })
            .result.then(selectedColor => {
                if (selectedColor) {
                    this.setColor(selectedColor, false, conf, defaultConf);
                }
            });
    }
}
