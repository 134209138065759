import { Injectable, Inject } from '@angular/core';
import { core } from 'helpers';
import { ConfiguratorsDataService } from 'configurators/configurators-data.service';
import { APP_CONFIG, AppConfig } from 'config';
import Core from 'Core';
import * as angular from 'angular';
import UserService from 'user.service';
import BrowserConfigurationsService from 'configurator/configurations.service';
import { Location } from '@angular/common';
import BrowserConfiguratorsDataService from 'configurator/configurators-data.service';
import CoupledWindowActiveConfiguration from 'configurations/CoupledWindowActiveConfiguration';
import WindowConfiguration from 'configurations/WindowConfiguration';
import { EventBusService } from 'event-bus.service';
import { DrawService } from 'configurators/draw.service';
import { Coupling, Profile, SideProfile, Frame } from 'configurations/parts/window';
import { CouplingsService } from 'configurator/layout/couplings.service';
import ProfilesService from 'profiles.service';
import { WindowInCoupling, RollerShutterInCoupling } from 'configurations/parts/coupled_window';
import PriceService from 'price/price.service';
import RollerShutterConfiguration from 'configurations/RollerShutterConfiguration';
import ParametersService from 'configurators/parameters.service';
import { ProfilesModalService } from 'configurator/steps/window/profiles/profiles-modal.service';
import Common from 'Common';
import { ExtensionsService } from 'configurator/layout/extensions.service';
import { TranslateService } from 'translate.service';
import ManyPositionsService from 'panel/many-positions.service';
import OfferSequenceService from 'offers/OfferSequenceService';
import { IccSill } from 'data-types';
import { WindowSillsService } from 'configurator/steps/complementary_goods/window-sills/window-sills.service';
import { iccSideColorsToSideColors } from 'configurations/converters/window/colors';

@Injectable()
export class CoupledWindowService {
    currentCoupledWindow: CoupledWindowActiveConfiguration = null;
    couplingId: string = null;
    editing = false;
    couplingOptions: {
        frameId: number | null;
        frameEdgeIndex: number | null;
        frameProfileId: number | null;
        couplingId: number | null;
        positionId: string;
        side: string;
        matchedWindowSystems: number[] | null;
    } = {
        frameId: null,
        frameEdgeIndex: null,
        frameProfileId: null,
        couplingId: null,
        positionId: null,
        side: 'bottom',
        matchedWindowSystems: null,
    };

    constructor(
        private configuratorsDataService: ConfiguratorsDataService,
        private userService: UserService,
        @Inject(APP_CONFIG) private config: AppConfig,
        private configurationsService: BrowserConfigurationsService,
        private location: Location,
        @Inject('$uibModal') private $uibModal: ng.ui.bootstrap.IModalService,
        @Inject('$rootScope') private $rootScope,
        @Inject('InfoFactory') private infoFactory,
        private drawService: DrawService,
        private profilesService: ProfilesService,
        private profilesModalService: ProfilesModalService,
        private priceService: PriceService,
        private parametersService: ParametersService,
        private extensionsService: ExtensionsService,
        private translateService: TranslateService,
        private manyPositionsService: ManyPositionsService,
        private windowSillsService: WindowSillsService
    ) {}

    addCoupledWindow(basePosition, offer) {
        const object = core.copy(basePosition);
        this.configuratorsDataService.load().then(() => {
            const user = this.userService.get();
            const confFactory = this.configurationsService.getOrInitConfiguratorConfigurations(
                'coupled_window',
                true
            );

            confFactory.Current.windows.push({
                positionId: object.tmp_id,
                details: object.details,
                x: 0,
                y: 0,
                index: 1,
                groupCode: object.groupCode,
                adjacentElements: [],
            });
            confFactory.Current.height = object.details.height;
            confFactory.Current.width = object.details.width;
            confFactory.Current.Price = object.details.price;
            confFactory.Current.PriceNoMargin = object.details.priceNoMargin;
            confFactory.Current.parameters.weight = object.details.parameters.weight;
            this.currentCoupledWindow = confFactory.Current;
            this.currentCoupledWindow.drawData = this.drawService.getData(
                this.currentCoupledWindow
            );
            this.$rootScope.completelyNewPosition = false;
            this.location.go(`/app/coupled_window`);
        });
    }

    addNewElement(
        positionId: string,
        configuration: WindowConfiguration | RollerShutterConfiguration,
        groupCode: string
    ) {
        switch (configuration.type) {
            case 'window':
            case 'hs':
            case 'door':
            case 'folding_door':
                this.addNewWindow(positionId, configuration, groupCode);
                break;
            case 'roller_shutter':
                this.addNewRollerShutter(positionId, configuration, groupCode);
                break;
        }
    }

    updateElement(
        positionId: string,
        configuration: WindowConfiguration | RollerShutterConfiguration,
        groupCode: string
    ) {
        switch (configuration.type) {
            case 'window':
            case 'hs':
            case 'door':
            case 'folding_door':
                this.updateWindow(positionId, configuration, groupCode);
                break;
            case 'roller_shutter':
                this.updateRollerShutter(positionId, configuration, groupCode);
                break;
        }
    }

    addNewWindow(positionId: string, windowConfiguration: WindowConfiguration, groupCode: string) {
        let { side, y, x, window, extension } = this.getCouplingSide();
        const newWindowDrawData = this.drawService.getData(windowConfiguration);
        switch (side.outerEdge.side) {
            case 'top':
                const newBottomFrame = windowConfiguration.frames.find(
                    f => f.y + f.height === windowConfiguration.height
                );
                const bottomEdge = this.getWindowEdge(newBottomFrame, 0, windowConfiguration, null, newWindowDrawData);
                y -= bottomEdge.y;
                break;
            case 'left':
                const newRightFrame = windowConfiguration.frames.find(
                    f => f.x + f.width === windowConfiguration.width
                );
                const rightEdge = this.getWindowEdge(newRightFrame, 1, windowConfiguration, null, newWindowDrawData);
                x -= rightEdge.x;
                break;
            case 'bottom':
                const newTopFrame = windowConfiguration.frames.find(
                    f => f.y === 0
                );
                const topEdge = this.getWindowEdge(newTopFrame, 2, windowConfiguration, null, newWindowDrawData);
                y -= topEdge.y;
                break;
            case 'right':
                const newLeftFrame = windowConfiguration.frames.find(
                    f => f.x === 0
                );
                const leftEdge = this.getWindowEdge(newLeftFrame, 3, windowConfiguration, null, newWindowDrawData);
                x -= leftEdge.x;
                break;
        }

        const newWindow: WindowInCoupling = {
            positionId,
            details: windowConfiguration,
            x,
            y,
            index: 0,
            groupCode,
            adjacentElements: [],
        };
        const newCoupling =
            this.couplingOptions.couplingId != null
                ? this.joinCouplingToWindows(newWindow, side.outerEdge.side)
                : this.addCouplingBetweenWindows(window, newWindow, extension, side.outerEdge.side);

        if (newCoupling) {
            const extensionId =
                newCoupling
                && (newCoupling.adjacentSideProfileId.length > 0
                    ? newCoupling.adjacentSideProfileId[0]
                    : newCoupling.adjacentOtherSideProfileId[0]);

            if (extensionId != null) {
                const extensionsWidth = this.extensionsService.getSideProfilesWidthById(
                    extensionId,
                    this.currentCoupledWindow
                );
                if (newCoupling.direction === 'vertical') {
                    newWindow.x +=
                        side.outerEdge.side === 'right' ? extensionsWidth : -extensionsWidth;
                } else {
                    newWindow.y +=
                        side.outerEdge.side === 'bottom' ? extensionsWidth : -extensionsWidth;
                }
            }
            if (newCoupling.direction === 'vertical') {
                newWindow.x +=
                    side.outerEdge.side === 'right' ? newCoupling.width : -newCoupling.width;
            } else {
                newWindow.y +=
                    side.outerEdge.side === 'bottom' ? newCoupling.width : -newCoupling.width;
            }
            window.adjacentElements.push({
                edgeIndex: this.couplingOptions.frameEdgeIndex,
                elementId: newCoupling.id,
                elementType: 'coupling',
                frameId: this.couplingOptions.frameId,
            });
            newWindow.adjacentElements.push({
                edgeIndex: 3,
                elementId: newCoupling.id,
                elementType: 'coupling',
                frameId: newWindow.details.frames[0].id,
            });
            this.currentCoupledWindow.windows.push(newWindow);
        }

        this.updateCoordinates();

        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        this.priceService.count(this.currentCoupledWindow);
        this.parametersService.count(this.currentCoupledWindow);
        this.refreshDraw();
        this.$rootScope.completelyNewPosition = false;
    }

    addNewRollerShutter(
        positionId: string,
        rollerShutterConfiguration: RollerShutterConfiguration,
        groupCode: string
    ) {
        let { side, y, x, window } = this.getCouplingSide();

        switch (side.outerEdge.side) {
            case 'top':
                y -= rollerShutterConfiguration.rollerShutter.boxHeight;
                break;
            case 'left':
                x -= rollerShutterConfiguration.rollerShutter.boxWidth;
                break;
        }

        const newRollerShutter: RollerShutterInCoupling = {
            positionId,
            details: rollerShutterConfiguration,
            x,
            y,
            index: 1,
            groupCode,
            adjacentElements: [],
        };

        window.adjacentElements.push({
            edgeIndex: this.couplingOptions.frameEdgeIndex,
            elementId: positionId,
            elementType: 'rollerShutter',
            frameId: this.couplingOptions.frameId,
        });
        newRollerShutter.adjacentElements.push({
            elementId: window.positionId,
            elementType: 'window',
        });
        this.currentCoupledWindow.rollerShutters.push(newRollerShutter);

        this.updateCoordinates();

        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        this.priceService.count(this.currentCoupledWindow);
        this.parametersService.count(this.currentCoupledWindow);
        this.refreshDraw();
        this.$rootScope.completelyNewPosition = false;
    }

    addExistingWindow(newWindow: WindowInCoupling) {
        let { side, y, x, window, extension } = this.getCouplingSide();

        const newWindowDrawData = this.drawService.getData(newWindow.details);
        switch (side.outerEdge.side) {
            case 'top':
                const newBottomFrame = newWindow.details.frames.find(
                    f => f.y + f.height === newWindow.details.height
                );
                const bottomEdge = this.getWindowEdge(newBottomFrame, 0, newWindow.details, null, newWindowDrawData);
                y -= bottomEdge.y;
                break;
            case 'left':
                const newRightFrame = newWindow.details.frames.find(
                    f => f.x + f.width === newWindow.details.width
                );
                const rightEdge = this.getWindowEdge(newRightFrame, 1, newWindow.details, null, newWindowDrawData);
                x -= rightEdge.x;
                break;
            case 'bottom':
                const newTopFrame = newWindow.details.frames.find(
                    f => f.y === 0
                );
                const topEdge = this.getWindowEdge(newTopFrame, 2, newWindow.details, null, newWindowDrawData);
                y -= topEdge.y;
                break;
            case 'right':
                const newLeftFrame = newWindow.details.frames.find(
                    f => f.x === 0
                );
                const leftEdge = this.getWindowEdge(newLeftFrame, 3, newWindow.details, null, newWindowDrawData);
                x -= leftEdge.x;
                break;
        }
        newWindow.x = x;
        newWindow.y = y;
        const newCoupling =
            this.couplingOptions.couplingId != null
                ? this.joinCouplingToWindows(newWindow, side.outerEdge.side)
                : this.addCouplingBetweenWindows(window, newWindow, extension, side.outerEdge.side);

        if (newCoupling) {
            const extensionId =
                newCoupling
                && (newCoupling.adjacentSideProfileId.length > 0
                    ? newCoupling.adjacentSideProfileId[0]
                    : newCoupling.adjacentOtherSideProfileId[0]);

            if (extensionId != null) {
                const extensionsWidth = this.extensionsService.getSideProfilesWidthById(
                    extensionId,
                    this.currentCoupledWindow
                );
                if (newCoupling.direction === 'vertical') {
                    newWindow.x +=
                        side.outerEdge.side === 'right' ? extensionsWidth : -extensionsWidth;
                } else {
                    newWindow.y +=
                        side.outerEdge.side === 'bottom' ? extensionsWidth : -extensionsWidth;
                }
            }
            if (newCoupling.direction === 'vertical') {
                newWindow.x +=
                    side.outerEdge.side === 'right' ? newCoupling.width : -newCoupling.width;
            } else {
                newWindow.y +=
                    side.outerEdge.side === 'bottom' ? newCoupling.width : -newCoupling.width;
            }
            window.adjacentElements.push({
                edgeIndex: this.couplingOptions.frameEdgeIndex,
                elementId: newCoupling.id,
                elementType: 'coupling',
                frameId: this.couplingOptions.frameId,
            });
            newWindow.adjacentElements.push({
                edgeIndex: 3,
                elementId: newCoupling.id,
                elementType: 'coupling',
                frameId: newWindow.details.frames[0].id,
            });
            this.currentCoupledWindow.windows.push(newWindow);
        }

        this.updateCoordinates();

        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        this.priceService.count(this.currentCoupledWindow);
        this.parametersService.count(this.currentCoupledWindow);
        this.refreshDraw();
        this.$rootScope.completelyNewPosition = false;
    }

    updateWindow(positionId: string, windowConfiguration: WindowConfiguration, groupCode: string) {
        const window = this.currentCoupledWindow.windows.find(w => w.positionId === positionId);
        const oldWidth = window.details.width;
        const oldHeight = window.details.height;
        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        const oldLeftFrame = window.details.frames.find(
            f => f.x === 0
        );
        const oldRightFrame = window.details.frames.find(
            f => f.x + f.width === window.details.width
        );
        const oldTopFrame = window.details.frames.find(
            f => f.y === 0
        );
        const oldBottomFrame = window.details.frames.find(
            f => f.y + f.height === window.details.height
        );
        const oldLeftEdge = this.getWindowEdge(oldLeftFrame, 3, window.details, positionId);
        const oldRightEdge = this.getWindowEdge(oldRightFrame, 1, window.details, positionId);
        const oldTopEdge = this.getWindowEdge(oldTopFrame, 2, window.details, positionId);
        const oldBottomEdge = this.getWindowEdge(oldBottomFrame, 0, window.details, positionId);
        window.details = windowConfiguration;
        window.groupCode = groupCode;
        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        const leftFrame = window.details.frames.find(f => f.x === 0);
        const rightFrame = window.details.frames.find(f => f.x + f.width === window.details.width);
        const topFrame = window.details.frames.find(
            f => f.y === 0
        );
        const bottomFrame = window.details.frames.find(
            f => f.y + f.height === window.details.height
        );
        const leftEdge = this.getWindowEdge(leftFrame, 3, window.details, positionId);
        const rightEdge = this.getWindowEdge(rightFrame, 1, window.details, positionId);
        const topEdge = this.getWindowEdge(topFrame, 2, window.details, positionId);
        const bottomEdge = this.getWindowEdge(bottomFrame, 0, window.details, positionId);
        if (rightEdge.x !== oldRightEdge.x || leftEdge.x !== oldLeftEdge.x) {
            if (leftEdge.x !== oldLeftEdge.x) {
                window.x -= leftEdge.x - oldLeftEdge.x;
            }
            let rightCoupling = this.currentCoupledWindow.couplings.find(c =>
                c.framesId.some(f => f.positionId === positionId && f.edges.some(e => e === 1))
            );
            if (!rightCoupling) {
                const lastExtension = this.extensionsService.getLastSideProfileByFrameIndex(
                    windowConfiguration.frames.map(f => ({
                        positionId,
                        frameEdgeIndex: 1,
                        frameId: f.id,
                    })),
                    this.currentCoupledWindow
                );
                rightCoupling = this.currentCoupledWindow.couplings.find(
                    c => lastExtension && c.adjacentSideProfileId.includes(lastExtension.id)
                );
            }

            if (rightCoupling) {
                this.moveAdjacentWindows(rightCoupling, oldLeftEdge.x - leftEdge.x + rightEdge.x - oldRightEdge.x, 'vertical');

                this.currentCoupledWindow.couplings
                    .filter(
                        c =>
                            c.framesId.some(f => f.positionId === positionId)
                            && c.direction === 'horizontal'
                    )
                    .forEach(c => (c.length += windowConfiguration.width - oldWidth));
            }
        }
        if (bottomEdge.y !== oldBottomEdge.y || topEdge.y !== oldTopEdge.y) {
            if (topEdge.y !== oldTopEdge.y) {
                window.y -= topEdge.y - oldTopEdge.y;
            }
            let bottomCoupling = this.currentCoupledWindow.couplings.find(c =>
                c.framesId.some(f => f.positionId === positionId && f.edges.some(e => e === 0))
            );
            if (!bottomCoupling) {
                const lastExtension = this.extensionsService.getLastSideProfileByFrameIndex(
                    windowConfiguration.frames.map(f => ({
                        positionId,
                        frameEdgeIndex: 0,
                        frameId: f.id,
                    })),
                    this.currentCoupledWindow
                );
                bottomCoupling = this.currentCoupledWindow.couplings.find(
                    c => lastExtension && c.adjacentSideProfileId.includes(lastExtension.id)
                );
            }
            if (bottomCoupling) {
                this.moveAdjacentWindows(
                    bottomCoupling,
                    oldTopEdge.y - topEdge.y + bottomEdge.y - oldBottomEdge.y,
                    'horizontal'
                );

                this.currentCoupledWindow.couplings
                    .filter(
                        c =>
                            c.framesId.some(f => f.positionId === positionId)
                            && c.direction === 'vertical'
                    )
                    .forEach(c => (c.length += windowConfiguration.height - oldHeight));
            }
        }

        this.updateCoordinates();

        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        this.priceService.count(this.currentCoupledWindow);
        this.parametersService.count(this.currentCoupledWindow);
        this.refreshDraw();
        this.$rootScope.completelyNewPosition = false;
    }

    private getWindowEdge(
        frame: Frame,
        frameEdgeIndex: number,
        window: WindowConfiguration,
        positionId: string,
        drawData = this.currentCoupledWindow.drawData
    ) {
        const windowExtension = this.extensionsService.getLastSideProfileByFrameIndex(
            [
                {
                    frameId: frame.id,
                    frameEdgeIndex: frameEdgeIndex,
                },
            ],
            window
        );
        const frameData = drawData.frame.find(
            f => f.frameId === frame.id && f.positionId === positionId
        );
        const extensionData =
            windowExtension
            && drawData.extension.find(
                f => f.extensionId === windowExtension.id && f.positionId === positionId
            );
        const side = frameData.sides[frameEdgeIndex];
        const edge = extensionData
            ? extensionData.poly[
                  (frameEdgeIndex + (side.outerEdge.angle <= 1 ? 0 : 1)) % extensionData.poly.length
              ]
            : frameData.outer.poly[
                  (frameEdgeIndex + (side.outerEdge.angle < 1 ? 0 : 1))
                      % frameData.outer.poly.length
              ];
        return edge;
    }

    updateRollerShutter(
        positionId: string,
        rollerShutterConfiguration: RollerShutterConfiguration,
        groupCode: string
    ) {
        const rollerShutter = this.currentCoupledWindow.rollerShutters.find(
            w => w.positionId === positionId
        );
        const oldHeight = rollerShutter.details.rollerShutter.boxHeight;
        rollerShutter.details = rollerShutterConfiguration;
        rollerShutter.groupCode = groupCode;

        if (rollerShutterConfiguration.rollerShutter.boxHeight !== oldHeight) {
            this.currentCoupledWindow.windows
                .filter(w =>
                    rollerShutter.adjacentElements.some(el => el.elementId === w.positionId)
                )
                .forEach(window => {
                    window.y += rollerShutterConfiguration.rollerShutter.boxHeight - oldHeight;
                    this.currentCoupledWindow.couplings
                        .filter(c => c.framesId.some(f => f.positionId === window.positionId))
                        .forEach(c => {
                            this.moveAdjacentWindows(
                                c,
                                rollerShutterConfiguration.rollerShutter.boxHeight - oldHeight,
                                'horizontal'
                            );
                        });
                });
        }

        this.updateCoordinates();

        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        this.priceService.count(this.currentCoupledWindow);
        this.parametersService.count(this.currentCoupledWindow);
        this.refreshDraw();
        this.$rootScope.completelyNewPosition = false;
    }

    openJoinNewToWindowModal(
        offer,
        edge: {
            frameId: number;
            frameEdgeIndex: number;
            positionId: string;
        } | null = null,
        coupling: Coupling = null
    ) {
        const couplingEdge = coupling && (coupling.framesId[0] || coupling.otherFramesId[0]);
        const extensionId =
            coupling
            && (coupling.adjacentSideProfileId[0] || coupling.adjacentOtherSideProfileId[0]);

        if (couplingEdge) {
            this.couplingOptions.frameId = couplingEdge.id;
            this.couplingOptions.frameEdgeIndex = couplingEdge.edges[0];
            this.couplingOptions.positionId = couplingEdge.positionId;
        } else if (extensionId) {
            const firstExtension = this.extensionsService.getFirstSideProfileById(
                extensionId,
                this.currentCoupledWindow
            );
            if (!firstExtension || firstExtension.framesId.length === 0) {
                return Promise.reject();
            }
            const frameId = firstExtension.framesId[0].id;
            const frameEdgeIndex = firstExtension.framesId[0].edges[0];
            const positionId = firstExtension.framesId[0].positionId;

            this.couplingOptions.frameId = frameId;
            this.couplingOptions.frameEdgeIndex = frameEdgeIndex;
            this.couplingOptions.positionId = positionId;
        } else if (edge) {
            this.couplingOptions.frameId = edge.frameId;
            this.couplingOptions.frameEdgeIndex = edge.frameEdgeIndex;
            this.couplingOptions.positionId = edge.positionId;
        }
        this.couplingOptions.couplingId = coupling != null ? coupling.id : null;

        const window = this.currentCoupledWindow.windows.find(
            w => w.positionId === this.couplingOptions.positionId
        );
        const frame = window.details.frames.find(f => f.id === this.couplingOptions.frameId);
        const frameProfile =
            frame.frame[
                this.couplingOptions.frameEdgeIndex < frame.frame.length
                    ? this.couplingOptions.frameEdgeIndex
                    : 0
            ];
        this.couplingOptions.frameProfileId = frameProfile.id;
        this.couplingOptions.side = frameProfile.side;

        this.setMatchingWindowSystems();
        this.$rootScope.coupledPosition = true;
        const modalInstance = this.$uibModal.open({
            templateUrl:
                '/application/configurator/coupled/add-position-modal/add-position.project.html',
            controller: 'ModalNewPositionCtrl as $ctrl',
            resolve: {
                offer: () => offer,
                fromCoupling: () => coupling && coupling.id != null,
                toCoupledWindow: () => true,
            },
            windowClass: 'newProduct',
        });

        modalInstance.closed.then(() => {});

        return modalInstance.result.then(configurator => {
            if (configurator) {
                if (configurator === 'coupling') {
                    return this.profilesModalService
                        .selectProfileAndPut(window.details, 'coupling')
                        .then(
                            selectedProfile => selectedProfile && this.addCoupling(selectedProfile)
                        );
                } else if (configurator === 'sideProfile') {
                    return this.profilesModalService
                        .selectProfileAndPut(window.details, 'extension')
                        .then(
                            selectedProfile => selectedProfile && this.addExtension(selectedProfile)
                        );
                } else if (configurator === 'fromOffer') {
                    return this.openJoinExistingInOfferToWindowsModal(offer, {
                        frameId: this.couplingOptions.frameId,
                        frameEdgeIndex: this.couplingOptions.frameEdgeIndex,
                        positionId: this.couplingOptions.positionId,
                    }).then(() => {});
                } else {
                    this.location.go(`/app/${configurator}`);
                }
            }
        });
    }

    async openJoinExistingInOfferToWindowsModal(
        offer,
        edge: {
            frameId: number;
            frameEdgeIndex: number;
            positionId: string;
        } | null = null,
        coupling: Coupling = null
    ) {
        const couplingEdge = coupling && (coupling.framesId[0] || coupling.otherFramesId[0]);
        const extensionId =
            coupling
            && (coupling.adjacentSideProfileId[0] || coupling.adjacentOtherSideProfileId[0]);

        if (couplingEdge) {
            this.couplingOptions.frameId = couplingEdge.id;
            this.couplingOptions.frameEdgeIndex = couplingEdge.edges[0];
            this.couplingOptions.positionId = couplingEdge.positionId;
        } else if (extensionId) {
            const firstExtension = this.extensionsService.getFirstSideProfileById(
                extensionId,
                this.currentCoupledWindow
            );
            if (!firstExtension || firstExtension.framesId.length === 0) {
                return Promise.reject();
            }
            const frameId = firstExtension.framesId[0].id;
            const frameEdgeIndex = firstExtension.framesId[0].edges[0];
            const positionId = firstExtension.framesId[0].positionId;

            this.couplingOptions.frameId = frameId;
            this.couplingOptions.frameEdgeIndex = frameEdgeIndex;
            this.couplingOptions.positionId = positionId;
        } else if (edge) {
            this.couplingOptions.frameId = edge.frameId;
            this.couplingOptions.frameEdgeIndex = edge.frameEdgeIndex;
            this.couplingOptions.positionId = edge.positionId;
        }
        this.couplingOptions.couplingId = coupling != null ? coupling.id : null;

        const { side } = this.getCouplingSide();

        const searched = OfferSequenceService.keysFromSequence(offer.sequence);

        const { pos: offerPositions } = await this.manyPositionsService.listById(
            searched,
            offer.tmpId
        );

        const windowsCouldBeAttached = offerPositions
            .filter(
                position =>
                    !position.doc.coupled_position_id
                    && ['window', 'hs', 'door', 'folding_door', 'roller_shutter'].includes(
                        position.doc.confType
                    )
                    && this.currentCoupledWindow.windows.every(
                        w => w.positionId !== position.doc.tmp_id
                    )
                    && this.currentCoupledWindow.rollerShutters.every(
                        w => w.positionId !== position.doc.tmp_id
                    )
                    && position.doc.details.shape.shape === 'rect'
            )
            .map<WindowInCoupling>(position => ({
                x: 0,
                y: 0,
                width: position.doc.details.width,
                height: position.doc.details.height,
                details: position.doc.details,
                adjacentElements: [],
                groupCode: position.doc.groupCode,
                index: 0,
                positionId: position.doc.tmp_id,
            }));

        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'joinPositionModal',
            resolve: {
                windows: () => windowsCouldBeAttached,
            },
        };
        const window: WindowInCoupling | null = await this.$uibModal.open(modalOptions).result;
        if (window) {
            this.addExistingWindow(window);
        }
        return window;
    }

    async openJoinExistingInCouplingToWindowsModal(
        offer,
        edge: {
            frameId: number;
            frameEdgeIndex: number;
            positionId: string;
        } | null = null,
        coupling: Coupling = null
    ) {
        const couplingEdge = coupling && (coupling.framesId[0] || coupling.otherFramesId[0]);
        const extensionId =
            coupling
            && (coupling.adjacentSideProfileId[0] || coupling.adjacentOtherSideProfileId[0]);

        if (couplingEdge) {
            this.couplingOptions.frameId = couplingEdge.id;
            this.couplingOptions.frameEdgeIndex = couplingEdge.edges[0];
            this.couplingOptions.positionId = couplingEdge.positionId;
        } else if (extensionId) {
            const firstExtension = this.extensionsService.getFirstSideProfileById(
                extensionId,
                this.currentCoupledWindow
            );
            if (!firstExtension || firstExtension.framesId.length === 0) {
                return Promise.reject();
            }
            const frameId = firstExtension.framesId[0].id;
            const frameEdgeIndex = firstExtension.framesId[0].edges[0];
            const positionId = firstExtension.framesId[0].positionId;

            this.couplingOptions.frameId = frameId;
            this.couplingOptions.frameEdgeIndex = frameEdgeIndex;
            this.couplingOptions.positionId = positionId;
        } else if (edge) {
            this.couplingOptions.frameId = edge.frameId;
            this.couplingOptions.frameEdgeIndex = edge.frameEdgeIndex;
            this.couplingOptions.positionId = edge.positionId;
        }
        this.couplingOptions.couplingId = coupling != null ? coupling.id : null;

        const { side } = this.getCouplingSide();

        const modalOptions: ng.ui.bootstrap.IModalSettings = {
            component: 'joinPositionModal',
            resolve: {
                windows: () => this.currentCoupledWindow.windows,
            },
        };
        const window: WindowInCoupling | null = await this.$uibModal.open(modalOptions).result;
        if (window) {
            this.joinCouplingToWindows(window, side.outerEdge.side);
            this.currentCoupledWindow.drawData = this.drawService.getData(
                this.currentCoupledWindow
            );
            this.priceService.count(this.currentCoupledWindow);
            this.parametersService.count(this.currentCoupledWindow);
            this.refreshDraw();
        }
        return window;
    }

    openJoinExtensionToExtensionModal(offer, extensionId: number) {
        const firstExtension = this.extensionsService.getFirstSideProfileById(
            extensionId,
            this.currentCoupledWindow
        );
        if (!firstExtension || firstExtension.framesId.length === 0) {
            return Promise.reject();
        }
        const frameId = firstExtension.framesId[0].id;
        this.couplingOptions.frameId = frameId;
        const frameEdgeIndex = firstExtension.framesId[0].edges[0];
        this.couplingOptions.frameEdgeIndex = frameEdgeIndex;
        const positionId = firstExtension.framesId[0].positionId;
        this.couplingOptions.positionId = positionId;

        const window = this.currentCoupledWindow.windows.find(w => w.positionId === positionId);
        const frame = window.details.frames.find(f => f.id === frameId);
        const frameProfile = frame.frame[frameEdgeIndex < frame.frame.length ? frameEdgeIndex : 0];
        this.couplingOptions.frameProfileId = frameProfile.id;
        this.couplingOptions.side = frameProfile.side;

        return this.profilesModalService
            .selectProfileAndPut(window.details, 'extension')
            .then(
                selectedProfile =>
                    selectedProfile && this.addExtension(selectedProfile, extensionId)
            );
    }

    async openMoveElementModal(
        startShift: number,
        length: number,
        neighbour: { index: string; type: 'window' | 'sideProfile' } | null,
        noLength = false
    ) {
        const modalInstance = this.$uibModal.open({
            component: 'moveElementModal',
            resolve: {
                startShift: () => startShift,
                length: () => length,
                noLength: () => noLength,
                neighbour: () => neighbour,
            },
        });

        return modalInstance.result;
    }

    async openSillsModal(frameId: number, frameEdgeIndex: number, positionId: string) {
        this.couplingOptions.frameId = frameId;
        this.couplingOptions.frameEdgeIndex = frameEdgeIndex;
        this.couplingOptions.positionId = positionId;

        const sillOuter = this.windowSillsService.sillToIccSill(
            this.currentCoupledWindow.windowSills.find(s =>
                s.framesId.some(
                    f =>
                        f.id === frameId
                        && f.positionId === positionId
                        && f.edges.includes(frameEdgeIndex)
                        && f.side === 'outer'
                )
            )
        );
        const sillInner = this.windowSillsService.sillToIccSill(
            this.currentCoupledWindow.windowSills.find(s =>
                s.framesId.some(
                    f =>
                        f.id === frameId
                        && f.positionId === positionId
                        && f.edges.includes(frameEdgeIndex)
                        && f.side === 'inner'
                )
            )
        );

        const modalInstance = this.$uibModal.open({
            component: 'sillsModal',
            resolve: {
                selectedOuterSill: () => sillOuter,
                selectedInnerSill: () => sillInner,
            },
        });

        const selectedSill = await modalInstance.result;
        if (selectedSill) {
            this.setSill(selectedSill);
        }
    }

    async setSill(selectedSills: { outer: IccSill; inner: IccSill }) {
        const newSillOuter = this.windowSillsService.iccSillToSill(selectedSills.outer);
        const newSillInner = this.windowSillsService.iccSillToSill(selectedSills.inner);
        this.currentCoupledWindow.windowSills = this.currentCoupledWindow.windowSills.filter(s =>
            s.framesId.some(
                f =>
                    f.id !== this.couplingOptions.frameId
                    || f.positionId !== this.couplingOptions.positionId
                    || !f.edges.includes(this.couplingOptions.frameEdgeIndex)
            )
        );
        if (newSillOuter) {
            newSillOuter.framesId = [
                {
                    id: this.couplingOptions.frameId,
                    positionId: this.couplingOptions.positionId,
                    side: 'outer',
                    edges: [this.couplingOptions.frameEdgeIndex],
                },
            ];
            this.currentCoupledWindow.windowSills.push(newSillOuter);
        }
        if (newSillInner) {
            newSillInner.framesId = [
                {
                    id: this.couplingOptions.frameId,
                    positionId: this.couplingOptions.positionId,
                    side: 'inner',
                    edges: [this.couplingOptions.frameEdgeIndex],
                },
            ];
            this.currentCoupledWindow.windowSills.push(newSillInner);
        }

        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        this.priceService.count(this.currentCoupledWindow);
        this.parametersService.count(this.currentCoupledWindow);
        this.refreshDraw();
    }

    addCoupling(couplingProfile: Profile) {
        const { side, y, x, window, extension } = this.getCouplingSide();

        const commonLine = this.getCouplingPosition(window);
        const oneSideFramesId = !extension
            ? [
                  {
                      id: this.couplingOptions.frameId,
                      edges: [this.couplingOptions.frameEdgeIndex],
                      positionId: window.positionId,
                  },
              ]
            : [];

        const adjacentSideProfileId = extension ? [extension.id] : [];

        const adjacentOtherSideProfileId = [];

        const otherSideFramesId = [];

        const newCoupling = new Coupling({
            id: CouplingsService.getIdForNew(this.currentCoupledWindow),
            direction: this.getCouplingDirection(),
            color: core.copy(window.details.colors.frame),
            length: commonLine.length,
            profileId: couplingProfile.id,
            width: couplingProfile.width,
            widthOut: couplingProfile.widthOut,
            wood: null,
            adjacentSideProfileId:
                side.outerEdge.side === 'bottom' || side.outerEdge.side === 'right'
                    ? adjacentSideProfileId
                    : adjacentOtherSideProfileId,
            adjacentOtherSideProfileId:
                side.outerEdge.side === 'bottom' || side.outerEdge.side === 'right'
                    ? adjacentOtherSideProfileId
                    : adjacentSideProfileId,
            framesId:
                side.outerEdge.side === 'bottom' || side.outerEdge.side === 'right'
                    ? oneSideFramesId
                    : otherSideFramesId,
            otherFramesId:
                side.outerEdge.side === 'bottom' || side.outerEdge.side === 'right'
                    ? otherSideFramesId
                    : oneSideFramesId,
            side: side.outerEdge.side,
            otherSide: core.opposite(side.outerEdge.side),
        });
        newCoupling.isDefault = false;
        newCoupling.color = couplingProfile.selectedColor
            ? core.copy(iccSideColorsToSideColors(couplingProfile.selectedColor.frame))
            : null;
        newCoupling.wood = couplingProfile.selectedWood
            ? core.copy(couplingProfile.selectedWood)
            : null;
        newCoupling.shift = commonLine.position;
        this.currentCoupledWindow.couplings.push(newCoupling);
        if (extension) {
            extension.adjacentOtherCouplingId.push(newCoupling.id);
        }

        this.setUsedProfiles(this.currentCoupledWindow, couplingProfile);

        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        this.priceService.count(this.currentCoupledWindow);
        this.parametersService.count(this.currentCoupledWindow);
        this.refreshDraw();
        return newCoupling;
    }

    joinCouplingToWindows(window2: WindowInCoupling, side: 'top' | 'bottom' | 'left' | 'right') {
        const coupling = this.currentCoupledWindow.couplings.find(
            c => c.id === this.couplingOptions.couplingId
        );
        if (coupling) {
            const framesId = [
                {
                    id: window2.details.frames[0].id,
                    edges: [this.sideToEdgeIndex(core.opposite(side))],
                    positionId: window2.positionId,
                },
            ];

            coupling.framesId =
                side === 'bottom' || side === 'right' ? coupling.framesId : framesId;
            coupling.otherFramesId =
                side === 'bottom' || side === 'right' ? framesId : coupling.otherFramesId;
            return coupling;
        }
    }

    async changeCouplingProfile(couplingid: number) {
        const coupling = this.currentCoupledWindow.couplings.find(c => c.id === couplingid);
        const window = this.currentCoupledWindow.windows.find(w =>
            coupling.framesId.length > 0
                ? coupling.framesId[0].positionId === w.positionId
                : coupling.otherFramesId[0].positionId === w.positionId
        );
        const selectedProfile = await this.profilesModalService.selectProfileAndPut(
            window.details,
            'coupling'
        );
        if (selectedProfile) {
            const oldWidth = coupling.width;
            coupling.width = selectedProfile.width;
            coupling.widthOut = selectedProfile.widthOut;
            coupling.profileId = selectedProfile.id;
            coupling.isDefault = false;
            coupling.color = selectedProfile.selectedColor
                ? core.copy(iccSideColorsToSideColors(selectedProfile.selectedColor.frame))
                : null;
            coupling.wood = selectedProfile.selectedWood
                ? core.copy(selectedProfile.selectedWood)
                : null;
            const diff = coupling.width - oldWidth;
            this.moveAdjacentWindows(coupling, diff, coupling.direction);
            this.setUsedProfiles(this.currentCoupledWindow, selectedProfile);

            this.updateCoordinates();

            this.currentCoupledWindow.drawData = this.drawService.getData(
                this.currentCoupledWindow
            );
            this.priceService.count(this.currentCoupledWindow);
            this.parametersService.count(this.currentCoupledWindow);
            this.refreshDraw();
        }
    }

    moveAdjacentWindows(
        coupling: Coupling,
        diff: number,
        direction: 'horizontal' | 'vertical',
        symmetrical = false,
        wasMoved = {
            windows: [],
            couplings: [],
        }
    ) {
        coupling = CouplingsService.getLastCoupling(
            [coupling],
            this.currentCoupledWindow
        );
        const sideProfileFrameIds = [
            ...coupling.adjacentOtherSideProfileId,
            ...(symmetrical ? coupling.adjacentSideProfileId : []),
        ].reduce<
            {
                id: number;
                edges: number[];
                positionId?: string;
            }[]
        >(
            (arr, sideProfileId) =>
                arr.concat(
                    ...this.extensionsService.getFirstSideProfileById(
                        sideProfileId,
                        this.currentCoupledWindow
                    ).framesId
                ),
            []
        );
        [
            ...sideProfileFrameIds,
            ...coupling.otherFramesId,
            ...(symmetrical ? coupling.framesId : []),
        ].forEach(fId => {
            if (wasMoved.windows.indexOf(fId.positionId) === -1) {
                const window = this.currentCoupledWindow.windows.find(
                    w => w.positionId === fId.positionId
                );
                if (direction === 'horizontal') {
                    window.y += diff;
                } else {
                    window.x += diff;
                }
                wasMoved.windows.push(window.positionId);
                this.currentCoupledWindow.couplings.forEach(c => {
                    if (
                        (c.framesId.some(f => f.positionId === window.positionId)
                            || c.otherFramesId.some(f => f.positionId === window.positionId))
                        && c.id !== coupling.id
                    ) {
                        this.moveAdjacentWindows(c, diff, direction, true, wasMoved);
                    }
                });
            }
        });
    }

    confirmRemoveWindow(positionId: string) {
        this.infoFactory.confirmModal(
            this.translateService.instant('WINDOW|Usuwanie'),
            this.translateService.instant(
                'WINDOW|Czy chcesz usunąć tą konstrukcję z oferty i konstrukcji złożonej?'
            ),
            [
                {
                    name: this.translateService.instant('WINDOW|Usuń z konstrukcji i z oferty'),
                    callback: () => {
                        this.removeWindow(positionId, true);
                    },
                },
                {
                    name: this.translateService.instant('WINDOW|Odłącz od konstrukcji'),
                    callback: () => {
                        this.removeWindow(positionId);
                    },
                },
                {
                    name: this.translateService.instant('INTERFACE|Anuluj'),
                    callback: () => {},
                    accent: true,
                },
            ]
        );
    }

    confirmRemoveRollerShutter(positionId: string) {
        this.infoFactory.confirmModal(
            this.translateService.instant('WINDOW|Usuwanie'),
            this.translateService.instant(
                'WINDOW|Czy chcesz usunąć tą konstrukcję z oferty i konstrukcji złożonej?'
            ),
            [
                {
                    name: this.translateService.instant('WINDOW|Usuń z konstrukcji i z oferty'),
                    callback: () => {
                        this.removeRollerShutter(positionId, true);
                    },
                },
                {
                    name: this.translateService.instant('WINDOW|Odłącz od konstrukcji'),
                    callback: () => {
                        this.removeRollerShutter(positionId);
                    },
                },
                {
                    name: this.translateService.instant('INTERFACE|Anuluj'),
                    callback: () => {},
                    accent: true,
                },
            ]
        );
    }

    removeWindow(positionId: string, permanent = false) {
        this.currentCoupledWindow.windows = this.currentCoupledWindow.windows.filter(
            w => w.positionId !== positionId
        );
        this.currentCoupledWindow.couplings.forEach(c => {
            c.framesId = c.framesId.filter(s => s.positionId !== positionId);
            c.otherFramesId = c.otherFramesId.filter(s => s.positionId !== positionId);
        });
        this.currentCoupledWindow.couplings = this.currentCoupledWindow.couplings.filter(
            c => c.framesId.length > 0 || c.otherFramesId.length > 0
        );
        this.currentCoupledWindow.sideProfiles.forEach(c => {
            c.framesId = c.framesId.filter(s => s.positionId !== positionId);
        });
        this.currentCoupledWindow.sideProfiles = this.currentCoupledWindow.sideProfiles.filter(
            c => c.framesId.length > 0
        );

        if (permanent) {
            this.currentCoupledWindow.removedPositions.push(positionId);
        } else {
            this.currentCoupledWindow.detachedPositions.push(positionId);
        }

        this.updateCoordinates();

        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        this.priceService.count(this.currentCoupledWindow);
        this.parametersService.count(this.currentCoupledWindow);
        this.refreshDraw();
    }

    removeRollerShutter(positionId: string, permanent = false) {
        this.currentCoupledWindow.rollerShutters = this.currentCoupledWindow.rollerShutters.filter(
            r => r.positionId !== positionId
        );

        if (permanent) {
            this.currentCoupledWindow.removedPositions.push(positionId);
        } else {
            this.currentCoupledWindow.detachedPositions.push(positionId);
        }

        this.updateCoordinates();

        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        this.priceService.count(this.currentCoupledWindow);
        this.parametersService.count(this.currentCoupledWindow);
        this.refreshDraw();
    }

    removeExtension(extensionId: number) {
        this.currentCoupledWindow.sideProfiles = this.currentCoupledWindow.sideProfiles.filter(
            r => r.id !== extensionId
        );
        this.currentCoupledWindow.sideProfiles.forEach(s => {
            s.adjacentOtherSideProfileId = s.adjacentOtherSideProfileId.filter(
                a => a !== extensionId
            );
            s.adjacentSideProfileId = s.adjacentSideProfileId.filter(a => a !== extensionId);
        });

        this.updateCoordinates();

        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        this.priceService.count(this.currentCoupledWindow);
        this.parametersService.count(this.currentCoupledWindow);
        this.refreshDraw();
    }

    removeCoupling(couplingId: number) {
        this.currentCoupledWindow.couplings = this.currentCoupledWindow.couplings.filter(
            r => r.id !== couplingId
        );
        this.currentCoupledWindow.couplings.forEach(s => {
            s.adjacentOtherSideProfileId = s.adjacentOtherSideProfileId.filter(
                a => a !== couplingId
            );
            s.adjacentSideProfileId = s.adjacentSideProfileId.filter(a => a !== couplingId);
        });

        this.updateCoordinates();

        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        this.priceService.count(this.currentCoupledWindow);
        this.parametersService.count(this.currentCoupledWindow);
        this.refreshDraw();
    }

    setCoupledWindow(conf: CoupledWindowActiveConfiguration, editing = false) {
        this.currentCoupledWindow = conf;
        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        this.$rootScope.completelyNewPosition = false;
        this.editing = editing;
    }

    addExtension(extensionProfile: Profile, extensionId: number = null) {
        const { side, window, extension, coupling } = this.getCouplingSide();

        let selectedExtension = this.currentCoupledWindow.sideProfiles.find(
            e => e.id === extensionId
        );
        if (!selectedExtension && extension) {
            selectedExtension = extension;
        }

        const commonLine = this.getCouplingPosition(window);
        const oneSideFramesId = !selectedExtension
            ? [
                  {
                      id: this.couplingOptions.frameId,
                      edges: [this.couplingOptions.frameEdgeIndex],
                      positionId: window.positionId,
                  },
              ]
            : [];

        const newExtension = new SideProfile({
            id: this.extensionsService.getIdForNew(this.currentCoupledWindow),
            length: commonLine.length,
            profileId: extensionProfile.id,
            width: extensionProfile.width,
            widthOut: extensionProfile.widthOut,
            adjacentSideProfileId: selectedExtension ? [selectedExtension.id] : [],
            adjacentCouplingId: coupling ? [coupling.id] : [],
            framesId: oneSideFramesId,
            color: extensionProfile.selectedColor
                ? core.copy(iccSideColorsToSideColors(extensionProfile.selectedColor.frame))
                : null,
            wood: extensionProfile.selectedWood ? core.copy(extensionProfile.selectedWood) : null,
            side: side.outerEdge.side,
        });
        this.currentCoupledWindow.sideProfiles.push(newExtension);
        if (selectedExtension) {
            selectedExtension.adjacentOtherSideProfileId.push(newExtension.id);
        } else if(coupling) {
            coupling.adjacentOtherSideProfileId.push(newExtension.id);
        }
        this.setUsedProfiles(this.currentCoupledWindow, extensionProfile);
        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        this.priceService.count(this.currentCoupledWindow);
        this.parametersService.count(this.currentCoupledWindow);
        this.refreshDraw();
        return newExtension;
    }

    async moveExtension(extensionId: number) {
        const extension = this.currentCoupledWindow.sideProfiles.find(e => e.id === extensionId);
        let neighbouringIndex = 0;
        let neighbouringType: 'window' | 'sideProfile' = 'sideProfile';
        if (extension.adjacentSideProfileId.length > 0) {
            neighbouringIndex = extension.adjacentSideProfileId[0];
        } else if (extension.framesId.length > 0) {
            const adjacentWindow = this.currentCoupledWindow.windows.find(w =>
                extension.framesId.some(f => f.positionId === w.positionId)
            );
            neighbouringIndex = adjacentWindow.index;
            neighbouringType = 'window';
        }
        const { startShift, length } = await this.openMoveElementModal(
            extension.shift,
            extension.length,
            {
                index: String(neighbouringIndex),
                type: neighbouringType,
            }
        );
        extension.shift = startShift;
        extension.length = length;
        this.setUsedProfiles(this.currentCoupledWindow);
        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        this.priceService.count(this.currentCoupledWindow);
        this.parametersService.count(this.currentCoupledWindow);
        this.refreshDraw();
    }

    async moveCoupling(couplingId: number) {
        const coupling = this.currentCoupledWindow.couplings.find(e => e.id === couplingId);
        let neighbouringIndex = '';
        let neighbouringType: 'window' | 'sideProfile' = 'sideProfile';
        if (coupling.adjacentSideProfileId.length > 0) {
            neighbouringIndex = String(coupling.adjacentSideProfileId[0]);
        } else if (coupling.framesId.length > 0) {
            const adjacentWindow = this.currentCoupledWindow.windows.find(w =>
                coupling.framesId.some(f => f.positionId === w.positionId)
            );
            neighbouringIndex = core.indexToLetter(adjacentWindow.index);
            neighbouringType = 'window';
        } else if (coupling.otherFramesId.length > 0) {
            const adjacentWindow = this.currentCoupledWindow.windows.find(w =>
                coupling.otherFramesId.some(f => f.positionId === w.positionId)
            );
            neighbouringIndex = core.indexToLetter(adjacentWindow.index);
            neighbouringType = 'window';
        }
        const { startShift, length } = await this.openMoveElementModal(
            coupling.shift,
            coupling.length,
            {
                index: neighbouringIndex,
                type: neighbouringType,
            }
        );
        coupling.shift = startShift;
        coupling.length = length;
        this.setUsedProfiles(this.currentCoupledWindow);
        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        this.priceService.count(this.currentCoupledWindow);
        this.parametersService.count(this.currentCoupledWindow);
        this.refreshDraw();
    }

    async moveWindow(positionId: string) {
        const window = this.currentCoupledWindow.windows.find(e => e.positionId === positionId);
        const adjacentCouplings = this.currentCoupledWindow.couplings.filter(
            c =>
                c.framesId.some(f => f.positionId === positionId)
                || c.otherFramesId.some(f => f.positionId === positionId)
        );
        const adjacentVerticalCouplings = adjacentCouplings.filter(c => c.direction === 'vertical');
        const adjacentHorizontalCouplings = adjacentCouplings.filter(
            c => c.direction === 'horizontal'
        );
        if (adjacentVerticalCouplings.length === 0 || adjacentHorizontalCouplings.length === 0) {
            const moveResult = await this.openMoveElementModal(
                adjacentVerticalCouplings.length > 0 ? window.y : window.x,
                null,
                null,
                true
            );

            if (moveResult) {
                const { startShift } = moveResult;
                if (adjacentVerticalCouplings.length > 0) {
                    window.y = startShift;
                } else {
                    window.x = startShift;
                }

                this.setUsedProfiles(this.currentCoupledWindow);
                this.currentCoupledWindow.drawData = this.drawService.getData(
                    this.currentCoupledWindow
                );
                this.priceService.count(this.currentCoupledWindow);
                this.parametersService.count(this.currentCoupledWindow);
                this.refreshDraw();
            }
        }
    }

    async moveRollerShutter(positionId: string) {
        const rollerShutter = this.currentCoupledWindow.rollerShutters.find(
            e => e.positionId === positionId
        );

        const { startShift, length } = await this.openMoveElementModal(
            rollerShutter.x,
            null,
            null,
            true
        );

        rollerShutter.x = startShift;

        this.setUsedProfiles(this.currentCoupledWindow);
        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        this.priceService.count(this.currentCoupledWindow);
        this.parametersService.count(this.currentCoupledWindow);
        this.refreshDraw();
    }

    canBeMoved(positionId: string) {
        const adjacentCouplings = this.currentCoupledWindow.couplings.filter(
            c =>
                c.framesId.some(f => f.positionId === positionId)
                || c.otherFramesId.some(f => f.positionId === positionId)
        );
        const adjacentVerticalCouplings = adjacentCouplings.filter(c => c.direction === 'vertical');
        const adjacentHorizontalCouplings = adjacentCouplings.filter(
            c => c.direction === 'horizontal'
        );
        return adjacentHorizontalCouplings.length === 0 || adjacentVerticalCouplings.length === 0;
    }

    setUsedProfiles(conf: CoupledWindowActiveConfiguration, profile?: Profile) {
        if (profile && Common.isUndefined(core.fId(conf.usedProfiles, profile.id))) {
            conf.usedProfiles.push(profile);
        }
        const { profilesIds } = this.getUsedProfilesIds(conf);
        const profiles = this.profilesService.getFilteredProfiles(
            null,
            ['coupling', 'extension'],
            {}
        );
        conf.usedProfiles = conf.usedProfiles.length
            ? conf.usedProfiles.filter(el => profilesIds.indexOf(el.id) > -1)
            : (conf.usedProfiles = profiles.filter(el => profilesIds.indexOf(el.id) > -1));
    }

    getUsedProfilesIds(conf: CoupledWindowActiveConfiguration) {
        const profilesIds = [];

        conf.sideProfiles.forEach(el => {
            if (profilesIds.indexOf(el.profileId) === -1) {
                profilesIds.push(el.profileId);
            }

            const extensionColor = {
                outer: null,
                inner: null,
                core: null,
            };

            Object.keys(extensionColor).forEach(s => {
                if (el.color && el.color[s] && el.color[s].id) {
                    extensionColor[s] = {
                        id: el.color[s].id,
                        RAL: el.color[s].RAL,
                        groups: el.color[s].groups,
                        name: el.color[s].name,
                        type: el.color[s].type,
                    };
                }
            });
        });

        conf.couplings.forEach(el => {
            if (profilesIds.indexOf(el.profileId) === -1) {
                profilesIds.push(el.profileId);
            }
        });

        return { profilesIds };
    }

    refreshDraw() {
        this.currentCoupledWindow.timestamp = Date.now();
    }

    canWindowBeRemoved(positionId: string) {
        const adjacentPositions = this.currentCoupledWindow.couplings.reduce((prev, c) => {
            if (c.framesId.some(f => f.positionId === positionId)) {
                c.otherFramesId.map(f => prev.push(f.positionId));
            }
            if (c.otherFramesId.some(f => f.positionId === positionId)) {
                c.framesId.map(f => prev.push(f.positionId));
            }
            return prev;
        }, []);
        const canBe =
            adjacentPositions.length > 0
            && adjacentPositions.every(
                windowPositionId =>
                    this.currentCoupledWindow.couplings.some(
                        c =>
                            (c.framesId.some(f => f.positionId === windowPositionId)
                                && c.otherFramesId.some(f => f.positionId !== positionId))
                            || (c.otherFramesId.some(f => f.positionId === windowPositionId)
                                && c.framesId.some(f => f.positionId !== positionId))
                    ) || this.currentCoupledWindow.windows.length === 2
            );
        return canBe;
    }

    setMatchingWindowSystemsForEditing(positionId: string) {
        const couplings = this.currentCoupledWindow.couplings.filter(
            c =>
                c.framesId.some(f => f.positionId === positionId)
                || c.otherFramesId.some(f => f.positionId === positionId)
        );
        if (couplings.length > 0) {
            const matchingSystems = couplings
                .map(c => this.profilesService.getProfile(c.profileId))
                .reduce((prev, profile) => prev.concat(...profile.systems), []);
            this.couplingOptions.matchedWindowSystems = matchingSystems;
        } else {
            this.couplingOptions.matchedWindowSystems = null;
        }
    }

    private addCouplingBetweenWindows(
        window: WindowInCoupling,
        window2: WindowInCoupling,
        extension: SideProfile,
        side: 'bottom' | 'top' | 'left' | 'right'
    ) {
        const window2Side = core.opposite(side);
        const couplingProfiles = this.profilesService
            .getFilteredProfiles(window.details, 'coupling', {})
            .filter(
                profile =>
                    profile.systems.includes(window.details.system.id)
                    && profile.systems.includes(window2.details.system.id)
            );
        if (couplingProfiles.length > 0) {
            const commonLine = this.getCommonLine(window, window2, side);

            const oneSideFramesId = !extension
                ? [
                      {
                          id: this.couplingOptions.frameId,
                          edges: [this.couplingOptions.frameEdgeIndex],
                          positionId: window.positionId,
                      },
                  ]
                : [];

            const otherSideFramesId = [
                {
                    id: window2.details.frames[0].id,
                    edges: [this.sideToEdgeIndex(window2Side)],
                    positionId: window2.positionId,
                },
            ];

            const adjacentSideProfileId = extension ? [extension.id] : [];

            const adjacentOtherSideProfileId = [];

            const newCoupling = new Coupling({
                id: CouplingsService.getIdForNew(this.currentCoupledWindow),
                direction: this.getCouplingDirection(),
                color: core.copy(window.details.colors.frame),
                length: commonLine.length,
                profileId: couplingProfiles[0].id,
                width: couplingProfiles[0].width,
                widthOut: couplingProfiles[0].widthOut,
                wood: null,
                adjacentSideProfileId:
                    side === 'bottom' || side === 'right'
                        ? adjacentSideProfileId
                        : adjacentOtherSideProfileId,
                adjacentOtherSideProfileId:
                    side === 'bottom' || side === 'right'
                        ? adjacentOtherSideProfileId
                        : adjacentSideProfileId,
                framesId:
                    side === 'bottom' || side === 'right' ? oneSideFramesId : otherSideFramesId,
                otherFramesId:
                    side === 'bottom' || side === 'right' ? otherSideFramesId : oneSideFramesId,
                side,
                otherSide: window2Side,
            });
            newCoupling.shift = commonLine.position;
            this.currentCoupledWindow.couplings.push(newCoupling);
            if (extension) {
                extension.adjacentOtherCouplingId.push(newCoupling.id);
            }
            this.setUsedProfiles(this.currentCoupledWindow, couplingProfiles[0]);
            return newCoupling;
        }
    }

    private getCommonLine(
        window: WindowInCoupling,
        window2: WindowInCoupling,
        side: 'left' | 'right' | 'top' | 'bottom'
    ) {
        const direction = this.getCouplingDirection();
        if (direction === 'vertical') {
            return {
                position: Math.min(window.y, window2.y) - window.y,
                length:
                    Math.min(window.y + window.details.height, window2.y + window2.details.height)
                    - Math.max(window.y, window2.y),
            };
        } else {
            return {
                position: Math.min(window.x, window2.x) - window.x,
                length:
                    Math.min(window.x + window.details.width, window2.x + window2.details.width)
                    - Math.max(window.x, window2.x),
            };
        }
    }

    private getCouplingPosition(window: WindowInCoupling) {
        const direction = this.getCouplingDirection();
        if (direction === 'vertical') {
            return {
                position: 0,
                length: window.details.height,
            };
        } else {
            return {
                position: 0,
                length: window.details.width,
            };
        }
    }

    private getCouplingDirection() {
        return this.couplingOptions.side === 'top' || this.couplingOptions.side === 'bottom'
            ? 'horizontal'
            : 'vertical';
    }

    private getCouplingSide() {
        let x = 0;
        let y = 0;
        const window = this.currentCoupledWindow.windows.find(
            w => w.positionId === this.couplingOptions.positionId
        );
        const frame = window.details.frames.find(f => f.id === this.couplingOptions.frameId);
        const windowExtension = this.extensionsService.getLastSideProfileByFrameIndex(
            [
                {
                    frameId: this.couplingOptions.frameId,
                    frameEdgeIndex: this.couplingOptions.frameEdgeIndex,
                },
            ],
            window.details
        );
        const frameData = this.currentCoupledWindow.drawData.frame.find(
            f => f.frameId === frame.id && f.positionId === this.couplingOptions.positionId
        );
        const extensionData =
            windowExtension
            && this.currentCoupledWindow.drawData.extension.find(
                f =>
                    f.extensionId === windowExtension.id
                    && f.positionId === this.couplingOptions.positionId
            );
        const side = frameData.sides[this.couplingOptions.frameEdgeIndex];
        const edge = extensionData
            ? extensionData.poly[
                  (this.couplingOptions.frameEdgeIndex + (side.outerEdge.angle <= 1 ? 0 : 1))
                      % extensionData.poly.length
              ]
            : frameData.outer.poly[
                  (this.couplingOptions.frameEdgeIndex + (side.outerEdge.angle < 1 ? 0 : 1))
                      % frameData.outer.poly.length
              ];
        const coupling = this.couplingOptions.couplingId
            ? this.currentCoupledWindow.couplings.find(
                w => w.id === this.couplingOptions.couplingId
            )
            : CouplingsService.getLastCouplingByFrameIndex(
                [
                    {
                        frameId: this.couplingOptions.frameId,
                        frameEdgeIndex: this.couplingOptions.frameEdgeIndex,
                        positionId: this.couplingOptions.positionId,
                    },
                ],
                this.currentCoupledWindow
            );
        const extension = this.extensionsService.getLastSideProfileByFrameIndex(
            [
                {
                    frameId: this.couplingOptions.frameId,
                    frameEdgeIndex: this.couplingOptions.frameEdgeIndex,
                    positionId: this.couplingOptions.positionId,
                },
            ],
            this.currentCoupledWindow
        );
        x = edge.x;
        y = edge.y;
        return { side, y, x, window, coupling, extension };
    }

    private setIndex(conf: CoupledWindowActiveConfiguration) {
        let index = 1;
        conf.windows
            .sort((a, b) => {
                if (a.y === b.y) {
                    return a.x - b.x;
                } else {
                    return a.y - b.y;
                }
            })
            .forEach(window => {
                window.index = index++;
            });
        conf.rollerShutters
            .sort((a, b) => {
                if (a.y === b.y) {
                    return a.x - b.x;
                } else {
                    return a.y - b.y;
                }
            })
            .forEach(rollerShutter => {
                rollerShutter.index = index++;
            });
    }

    private updateCoordinates() {
        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);
        const minX = Math.min(
            ...this.currentCoupledWindow.windows.map(w => w.x),
            ...this.currentCoupledWindow.rollerShutters.map(w => w.x)
        );
        const minY = Math.min(
            ...this.currentCoupledWindow.windows.map(w => w.y),
            ...this.currentCoupledWindow.rollerShutters.map(w => w.y)
        );
        this.currentCoupledWindow.windows.forEach(w => {
            w.x -= minX;
            w.y -= minY;
        });
        this.currentCoupledWindow.rollerShutters.forEach(w => {
            w.x -= minX;
            w.y -= minY;
        });
        this.currentCoupledWindow.drawData = this.drawService.getData(this.currentCoupledWindow);

        this.currentCoupledWindow.width = Math.max(
            ...this.currentCoupledWindow.windows.map(w => {
                const rightFrame = w.details.frames.find(f => f.x + f.width === w.details.width);
                const rightEdge = this.getWindowEdge(rightFrame, 1, w.details, w.positionId);
                return rightEdge.x;
            }),
            ...this.currentCoupledWindow.rollerShutters.map(
                w => w.x + w.details.rollerShutter.boxWidth
            )
        );
        this.currentCoupledWindow.height = Math.max(
            ...this.currentCoupledWindow.windows.map(w => {
                const bottomFrame = w.details.frames.find(f => f.y + f.height === w.details.height);
                const bottomEdge = this.getWindowEdge(bottomFrame, 0, w.details, w.positionId);
                return bottomEdge.y;
            }),
            ...this.currentCoupledWindow.rollerShutters.map(
                w => w.y + w.details.rollerShutter.boxHeight
            )
        );

        this.setIndex(this.currentCoupledWindow);
    }

    private sideToEdgeIndex(side: 'left' | 'right' | 'bottom' | 'top') {
        const sides = ['bottom', 'right', 'top', 'left'];
        return sides.indexOf(side);
    }

    private setMatchingWindowSystems() {
        const { window, coupling } = this.getCouplingSide();
        const matchingSystems = (coupling
            ? [this.profilesService.getProfile(coupling.profileId)]
            : this.profilesService.getFilteredProfiles(window.details, 'coupling', {})
        )
            .filter(profile => profile.systems.includes(window.details.system.id))
            .reduce((prev, profile) => prev.concat(...profile.systems), []);
        this.couplingOptions.matchedWindowSystems = matchingSystems;
    }
}
