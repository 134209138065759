import ProfilesService from 'profiles.service';
import { ProfilesPriceService } from 'profiles/profiles-price.service';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import { Colors, Wood, SideColors } from 'configurations/parts/common';
import { Profile, ProfileType } from 'configurations/parts/window';
import { core } from 'helpers';
import Common from 'Common';
import * as $ from 'jquery';
import CurrentConfiguratorService from 'configurator/current-configurator.service';
import BrowserProfilesService from '../profiles.service';
import { IccSideColors } from 'data-types';

export default class ProfilesModalController {
    resolve: {
        profiles: Profile[];
        profilesPrices: any[];
        selectedProfile: Profile;
        system: any | null;
        type: ProfileType;
        currency;
        color?: IccSideColors;
        wood?: Wood;
        hideCouplings?: boolean
    };
    close: ({ $value: Profile }) => any;
    dismiss: () => any;
    showTabs = false;
    showColorsWhenCoupling = this.CurConfService.conf === 'coupled_window';
    filteredProfiles: Profile[] = [];
    selectedTypeTab = 'fixed_mullion';

    constructor(
        private profilesService: BrowserProfilesService,
        private profilesPriceService: ProfilesPriceService,
        private $timeout,
        public IccConfig,
        private ScrollbarService,
        private CurConfService: CurrentConfiguratorService
    ) {}

    $onInit() {
        let hasCouplings = false;
        this.resolve.profiles.forEach(profile => {
            delete profile.selectedColor;
            delete profile.selectedWood;

            if (this.resolve.selectedProfile && this.resolve.selectedProfile.id === profile.id) {
                profile.selectedColor = {
                    frame: this.resolve.color,
                    sash: this.resolve.color,
                };
                profile.selectedWood = this.resolve.wood;
            } else {
                profile.selectedColor = {
                    frame: this.profilesService.getDefaultProfileColorSet(
                        profile,
                        this.resolve.color
                    ),
                    sash: this.profilesService.getDefaultProfileColorSet(
                        profile,
                        this.resolve.color
                    ),
                };
            }
            if (profile.type === 'coupling') {
                hasCouplings = true;
            }
        });
        this.filteredProfiles = this.resolve.profiles;
        if (
            Common.isArray(this.resolve.type)
            && this.resolve.type.includes('coupling')
            && hasCouplings
        ) {
            if (this.resolve.type.length > 1) {
                this.showTabs = true;
                this.selectedTypeTab = this.resolve.selectedProfile.type;
            } else {
                this.selectedTypeTab = 'coupling';
            }
            this.filterProfiles();
        }
    }

    $onChanges(changes) {
        this.ScrollbarService.update();
    }

    hasZeroPrice(price, type = null) {
        if (!price) {
            return false;
        }
        let requiredFields = [price.price_field, price.price_length, price.price_piece];
        if (
            (Common.isArray(this.resolve.type) && type === 'frame')
            || this.resolve.type === 'frame'
        ) {
            requiredFields.push(price.price_percent);
        }
        if (
            (Common.isArray(this.resolve.type) && type === 'threshold')
            || this.resolve.type === 'threshold'
        ) {
            requiredFields = [price.price_sash, price.price_length];
        } else if (
            !this.resolve.type
            || (Common.isArray(this.resolve.type)
                && ['coupling', 'extension', 'alignment', 'other'].includes(type))
            || ['coupling', 'extension', 'alignment', 'other'].includes(this.resolve.type)
        ) {
            requiredFields = [price.price_piece, price.price_length];
        } else if (
            (Common.isArray(this.resolve.type) && type === 'sandwich')
            || this.resolve.type === 'sandwich'
        ) {
            requiredFields = [price.price_area];
        }
        return requiredFields.every(p => p === 0);
    }

    hasNoPrice(price, type = null) {
        if (!price) {
            return true;
        }
        let requiredFields = [
            price.price_field,
            price.price_length,
            price.price_piece,
        ];
        if (
            (Common.isArray(this.resolve.type) && type === 'frame')
            || this.resolve.type === 'frame'
        ) {
            requiredFields.push(price.price_percent);
        }
        if (
            (Common.isArray(this.resolve.type) && type === 'threshold')
            || this.resolve.type === 'threshold'
        ) {
            requiredFields = [price.price_sash, price.price_length];
        } else if (
            !this.resolve.type
            || (Common.isArray(this.resolve.type)
                && ['coupling', 'extension', 'alignment', 'other'].includes(type))
            || ['coupling', 'extension', 'alignment', 'other'].includes(this.resolve.type)
        ) {
            requiredFields = [price.price_piece, price.price_length];
        } else if (
            (Common.isArray(this.resolve.type) && type === 'sandwich')
            || this.resolve.type === 'sandwich'
        ) {
            requiredFields = [price.price_area];
        }
        return requiredFields.some(p => isNaN(Number(p)) || p === null);
    }

    selectProfile(profile) {
        this.close({ $value: profile });
    }

    closeModal() {
        this.close({ $value: false });
    }

    openModalColors(profile) {
        this.profilesService
            .openProfileColorsModal(profile, this.resolve.selectedProfile)
            .then(selection => {
                profile.price = this.profilesPriceService.getProfilePrice(
                    profile.id,
                    Common.isArray(this.resolve.type) ? profile.type : this.resolve.type,
                    this.resolve.system,
                    selection.colors,
                    this.resolve.profilesPrices
                );

                profile.selectedColor = core.copy(selection.colors);
                profile.selectedWood = core.copy(selection.wood);
            });
    }

    refresh() {
        this.ScrollbarService.update();
    }

    filterProfiles() {
        this.filteredProfiles = this.resolve.profiles.filter(
            profile => profile.type === this.selectedTypeTab
        );
    }
}
ProfilesModalController.$inject = [
    'ProfilesService',
    'ProfilesPriceService',
    '$timeout',
    'IccConfig',
    'ScrollbarService',
    'CurConfService',
];
