import angular from 'angular';
import {logger} from 'helpers';

export default function SystemsCtrl(
    $scope, $uibModal, $timeout, filterFilter, IccConfig, SystemsFactory, ShapeService, CurConfService, systemsByTagFilter,
    ConfigurationsService, ProfileSetsService, EventBusService, StepFactory, ScrollbarService
) { // jshint ignore:line
    'ngInject';

    const vm = this;

    vm.conf        = ConfigurationsService.conf.Current;
    vm.subStep     = 1;
    vm.systems     = [];
    vm.systemTypes = [];
    vm.tags        = [];
    vm.allSystems  = true;
    vm.systemType  = {};
    vm.selectedSystems     = [];

    vm.selectSystem        = selectSystem;
    vm.selectDestination   = selectDestination;
    vm.selectType          = selectType;
    vm.goToSubstep         = goToSubstep;
    vm.openModalSystemInfo = openModalSystemInfo;
    vm.openProfileSetModal = openProfileSetModal;
    vm.selectedTag         = selectedTag;
    vm.isAvailable         = isAvailable;

    if (SystemsFactory.loadedData) {
        init();
    }

    const subscriptions = [];
    subscriptions.push(EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => init()));
    $scope.$on('$destroy', () => subscriptions.map(el => el.unsubscribe()));

    $scope.$on('Systems_goToNextSubstep', () => vm.goToSubstep(2));

    function init() {
        vm.systemTypes = SystemsFactory.systemTypes;
        vm.systems     = SystemsFactory.systems.filter(filterLines);
        vm.selectType(SystemsFactory.getSystemType());

        /** drzwi harmonijkowe - krok dostępny pod warunkiem utworzenia minimum 2 linii */
        if (vm.conf && vm.conf.type === 'folding_door' && SystemsFactory.systems && SystemsFactory.systems.length === 1) {
            if (vm.conf.System && vm.conf.System.id) {
                StepFactory.goToNextStep(true);
            } else {
                selectSystem(SystemsFactory.systems[0], true);
            }
        }
    }

    function filterLines(el) {
        return (angular.isDefined(vm.systemType)) ? el.type == vm.systemType.static : true;
    }

    function selectedTag(systems) {
        vm.selectedSystems = systems;
        refresh();
    }

    function selectDestination(destination) {
        SystemsFactory.selectDestination(destination);
    }

    function selectSystem(system, goToNextStep) {
        SystemsFactory.selectSystem(system, vm.systemType, (goToNextStep || IccConfig.Configurators.selectSystemAutoNextStep) === false ? false : true, true);
    }

    function selectType(type) {
        vm.systemType = type;
        vm.systems     = SystemsFactory.systems.filter(filterLines);
        vm.tags        = SystemsFactory.tags.filter(tag => systemsByTagFilter(vm.systems, [tag.id]).length > 0);
        vm.systemType = type;
    }

    function goToSubstep(subStep) {
        vm.subStep = subStep;
    }

    function openModalSystemInfo(system) {
        let modalInstance = $uibModal.open({
            templateUrl: 'modalSystemInfo.html',
            controller : 'ModalSystemInfoCtrl as msystem',
            resolve    : {system: () => system}
        });
        if (IccConfig.Configurators.tutorialAvailable) {
            EventBusService.post({
                key: 'tutorialSteps',
                value: 'empty'
             });
        }

        modalInstance.result.then(sys => {
            if (angular.isDefined(sys)) {
                vm.selectSys(sys);
            }
        });

        modalInstance.closed.then(() => {
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg'
                });
            }
        });
    }

    function refresh() {
        ScrollbarService.update();
    }

    function isAvailable(systemId) {
        return ShapeService.isAvailable(ConfigurationsService.conf.Current.Shape.shape, systemId);
    }

    function openProfileSetModal() {
        ProfileSetsService.openProfileSetsModal(ConfigurationsService.conf.Current, ConfigurationsService.conf.Default);
    }
}
