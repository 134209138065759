import { NgModule } from '@angular/core';
import ColorMappingService from './ColorsMappingService';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [ColorMappingService],
})
export class ColorsModule {}
