import angular from 'angular';

/**
 * Fabryka grup pozycji
 * @param {object} $rootScope                               Angular Root Scope
 * @param {object} $uibModal                                Dostarcza obsługę okien modalnych
 * @param {object} $location                                Lokalizacja
 * @param {object} $filter                                  Filtry
 * @param {object} ConfiguratorsDataService                 Fabryka danych konfiguracji
 * @param {object} OffersService                            Fabryka ofert
 * @param {object} GlazingFactory                           Fabryka szyb
 * @param {object} HandlesFactory                           Fabryka klamek
 * @param {object} Core                                     Core
 */

export default function OffersGroupFactory($rootScope, $uibModal, $location, $q, $filter, ConfiguratorsDataService, ConfigurationsService,
    OffersService, GlazingFactory, HandlesFactory, Core, IccConfig, WarmEdgesService, FillingsService, EventBusService, ProfilesService) {

    'ngInject';

    var allFillings         = [];
    var allWarmEdges        = [];
    var allFittings         = [];
    var allHandles          = [];
    var handleCategories    = [];
    var handleSubcategories = [];
    var handleColorsAll     = [];
    var modalGlassTypeData  = {
        glassTab        : 'glazing',
        selectedSecurity: null,
        selectedOrnament: null,
        selectedUg      : null,
        selectedRw      : null,
        selectedCategory: null
    };

    var factory = {
        loadedData             : false,
        glassTypes             : [],
        fillingsCategories     : [],
        glassTypesSubcategories: [],
        fillings               : [],
        handles                : [],
        warmEdges              : [],
        glazingRestricts       : {},
        glazingBeads           : [],
        modalGlassTypeData     : {
            glassTab        : 'glasses',
            selectedSecurity: undefined,
            selectedOrnament: undefined,
            selectedUg      : undefined,
            selectedRw      : undefined
        },

        findFittingsBySystem    : HandlesFactory.findFittingsBySystem,
        findHandlesBySystem     : HandlesFactory.findHandlesBySystem,
        openModalGlazing,
        openModalHandle,
        openModalWarmEdge,
        openModalFitting,
        addPositionToGroup,
        addPositionToGroupConf
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }
    EventBusService.subscribe('loadedConfiguratorsData', init);

    return factory;

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        allFillings                     = ConfiguratorsDataService.data.fillings;
        allWarmEdges                    = ConfiguratorsDataService.data.windowWarmEdges;
        handleCategories                = ConfiguratorsDataService.data.handleCategory;
        handleSubcategories             = ConfiguratorsDataService.data.handleSubcategory;
        allFittings                     = ConfiguratorsDataService.data.windowFittings;
        allHandles                      = angular.copy(ConfiguratorsDataService.data.windowHandlers);
        handleColorsAll                 = ConfiguratorsDataService.data.windowHandlesColors;

        factory.fillingsCategories      = FillingsService.fillingsCategories;
        // factory.fillingsCategories      = ConfiguratorsDataService.data.glassTypesCategory;
        factory.glassTypesSubcategories = GlazingFactory.glassTypesSubcategories;
        factory.glassColors             = FillingsService.glassColors;
        if (ConfigurationsService.conf && ConfigurationsService.conf.Current) {
            factory.fillings                = FillingsService.getMatchingFillings(ConfigurationsService.conf.Current) || [];
        }
        factory.glazingRestricts        = GlazingFactory.glazingRestricts;
        factory.beadColors              = GlazingFactory.beadColors || [];
        factory.fittings                = HandlesFactory.fittings;
        factory.handleColors            = HandlesFactory.handleColors;

        factory.loadedData = true;
    }

    /**
     * Otwiera modal do wyboru wypełnienia.
     * @param  {object} positionGroupsData Dane grupy
     * @param  {string} groupKey      Kod grupy
     * @param  {object} offer         Oferta
     */
    function openModalGlazing(positionGroupsData, groupKey, offer) {
        positionGroupsData.valid = {system: true, colors: true, loadedFillings: false};
        FillingsService.loadMatchingFillings(positionGroupsData, false);
        var modalFillings = FillingsService.fillings;
        var filling = FillingsService.fillings.find(elem => elem.id == positionGroupsData.Glazing.id || elem.id.split('.')[0] == positionGroupsData.Glazing.id);

        var modalInstance = $uibModal.open(
            {
                templateUrl: 'modalGlazing.html',
                controller : 'ModalGlazingCtrl as mglazing',
                resolve    : {
                    fillings          : () => modalFillings,
                    fillingsCategories: () => factory.fillingsCategories,
                    glassColors       : () => factory.glassColors,
                    modalData         : () => modalGlassTypeData,
                    selGlassType      : () => filling,
                    selectFor         : () => undefined,
                    noDivInSash       : () => false,
                    hasGlasses        : () => FillingsService.hasGlasses,
                    bondedGlazing     : () => false,
                    b2c               : () => false,
                }
            });

        modalInstance.result.then((selectedData) => {
            if (angular.isDefined(selectedData)) {
                var data = {
                    Glazing: {
                        id  : selectedData.glass.id,
                        name: selectedData.glass.name
                    }
                };
                editPositionsGroupsData(offer, groupKey, data);
            }
        });
    }

    /**
     * Funkcja otwierajaca okno modal ciepłej ramki
     * @param  {object} positionGroupsData Dane grupy
     * @param  {string} groupKey   Kod grupy
     * @param  {object} offer      Oferta
     */
    function openModalWarmEdge(positionGroupsData, groupKey, offer) {
        WarmEdgesService.loadMatchingWarmEdges(positionGroupsData, false);
        factory.warmEdges = WarmEdgesService.warmEdges;
        var modalInstance = $uibModal.open({
            templateUrl: 'modalWarmEdge.html',
            controller : 'ModalWarmEdgeCtrl as mwarmedge',
            resolve    : {
                windowWarmEdges: () => factory.warmEdges,
                selected       : () => factory.warmEdges.filter((elem) => elem.id == positionGroupsData.WarmEdge.id)[0]
            }
        });

        modalInstance.result.then((selectedEdge) => {
            if (angular.isDefined(selectedEdge) && angular.isDefined(selectedEdge.id)) {
                var data = {
                    WarmEdge: {
                        id  : selectedEdge.id,
                        name: selectedEdge.name
                    }
                };
                editPositionsGroupsData(offer, groupKey, data);
            }
        });
    }

    /**
     * Okno modalne z wyborem klamki
     * @param  {object} positionGroupsData Dane grupy
     * @param  {string} groupKey           Kod grupy
     * @param  {object} offer              Oferta
     */
    function openModalHandle(positionGroupsData, groupKey, offer) {
        factory.handles = [];
        factory.handles = factory.findHandlesBySystem(positionGroupsData.System, positionGroupsData.Fitting);
        var modalInstance = $uibModal.open({
            templateUrl: 'modalHandle.html',
            controller : 'ModalHandleCtrl as mhandle',
            resolve    : {
                handles            : () => factory.handles,
                selHandle          : () => factory.handles.filter((elem) => elem.id == positionGroupsData.Handle.id)[0],
                handleHeights      : () => [],
                handleY            : () => 0,
                sashHeight         : () => 0,
                showHeightSelect   : () => false,
                handleCategories   : () => handleCategories,
                handleSubcategories: () => handleSubcategories,
                handleData         : () => factory.handles,
                handleColors       : () => handleColorsAll,
                selHandleColor     : () => positionGroupsData.HandleColor,
                selHandleOuterColor: () => positionGroupsData.HandleColor,
                b2c                : () => false,
                sashType           : () => null,
                position           : () => null,
                availColorOuter    : () => false,
                place              : () => null,
                handleHeightType   : () => null,
                maxHandleHeight    : () => null,
                minHandleHeight    : () => null,
                systemHeight       : () => false,
            }
        });

        modalInstance.result.then((selectedData) => {
            if (angular.isObject(selectedData) && angular.isDefined(selectedData.handle)) {
                var handleColor = handleColorsAll.filter((elem) => elem.id === selectedData.color)[0];
                var data = {
                    Handle: {
                        id  : selectedData.handle.id,
                        name: selectedData.handle.name
                    },
                    HandleColor: {
                        id  : handleColor.id,
                        name: handleColor.name
                    }
                };
                editPositionsGroupsData(offer, groupKey, data);
            }
        });
    }

    /**
     * Funkcja otwierajaca okno modal ciepłej ramki
     * @param  {object} positionGroupsData Dane grupy
     * @param  {string} groupKey           Kod grupy
     * @param  {object} offer              Oferta
     */
    function openModalFitting(positionGroupsData, groupKey, offer) {
        factory.fittings = [];
        factory.fittings = factory.findFittingsBySystem(positionGroupsData.System);
        var modalInstance = $uibModal.open({
            templateUrl: 'modalFitting.html',
            controller : 'ModalFittingCtrl as mfitting',
            resolve    : {
                windowFittings: () => factory.fittings,
                selected      : () => factory.fittings.filter((elem) => elem.id == positionGroupsData.Fitting.id)[0]
            }
        });

        modalInstance.result.then((selectFitting) => {
            if (angular.isDefined(selectFitting) && angular.isDefined(selectFitting.id)) {
                var data = {
                    Fitting: {
                        id  : selectFitting.id,
                        name: selectFitting.name
                    }
                };
                editPositionsGroupsData(offer, groupKey, data);
            }
        });
    }

    /**
     * Uaktualnianie danych grup
     * @param  {object} offer    Oferta
     * @param  {string} groupKey Kod grupy
     * @param  {object} data     Dane do zapisania
     */
    function editPositionsGroupsData(offer, groupKey, data) {
        offer.synced = false;
        var positionsGroupsData = offer.positions_groups_data;
        positionsGroupsData[groupKey] = angular.extend(positionsGroupsData[groupKey], data);
        OffersService.update(offer._id, angular.extend(offer, {positions_groups_data: positionsGroupsData}));
    }

    /**
     * Resetowanie konfiguracji do edycji w konfiguratorze
     * @param {object} type                  Kod konfiguratora
     * @param {object} positionGroupsData    position
     * @param {string} url                   url
     */
    function addPositionToGroupConf(type, positionGroupsData, url) {

        const confFactory = ConfigurationsService.getOrInitConfiguratorConfigurations(type);
        var system      = null;
        var systemType  = null;
        var warmEdge    = null;
        var handle      = null;
        var fitting     = null;
        var handleColor = null;
        var glazing     = null;

        if (confFactory.conf == 'window') {
            system = ConfiguratorsDataService.data.windowLines.filter((elem) => elem.id === positionGroupsData.System.id)[0];
        } else if (confFactory.conf == 'hs') {
            system = ConfiguratorsDataService.data.hsLines.filter((elem) => elem.id === positionGroupsData.System.id)[0];
        } else if (confFactory.conf == 'door') {
            system = ConfiguratorsDataService.data.doorLines.filter((elem) => elem.id === positionGroupsData.System.id)[0];
        } else if (confFactory.conf == 'folding_door') {
            system = ConfiguratorsDataService.data.foldingDoorLines.filter((elem) => elem.id === positionGroupsData.System.id)[0];
        }
        systemType = ConfiguratorsDataService.data.windowLineTypes.filter((elem) => elem.id === positionGroupsData.SystemType.id)[0];

        warmEdge = allWarmEdges.filter((elem) => elem.id === positionGroupsData.WarmEdge.id)[0];

        handle = allHandles.filter((elem) => elem.id === positionGroupsData.Handle.id)[0];

        if (angular.isDefined(handle) && handle !== null) {
            handle.selectedColor = positionGroupsData.HandleColor.id;
        }

        fitting = allFittings.filter((elem) => elem.id === positionGroupsData.Fitting.id)[0];

        handleColor  = ConfiguratorsDataService.data.windowHandlesColors.filter((elem) => elem.id === positionGroupsData.HandleColor.id)[0];

        glazing = allFillings.filter((elem) => elem.id === positionGroupsData.Glazing.id)[0];

        // confFactory.GroupData             = {};
        confFactory.Default.Colors          = Core.copy(positionGroupsData.Colors);
        confFactory.Default.ColorType       = Core.copy(positionGroupsData.ColorType);
        confFactory.Default.ColorsSashExt   = Core.copy(positionGroupsData.ColorsSashExt);
        confFactory.Default.HasAlushell     = Core.copy(positionGroupsData.HasAlushell);
        confFactory.Default.Wood            = Core.copy(positionGroupsData.Wood);
        confFactory.Default.Fitting         = Core.copy(fitting);
        confFactory.Default.ProfileSet      = Core.copy(positionGroupsData.ProfileSet);
        confFactory.Default.Glazing         = Core.copy(glazing);
        confFactory.Default.Handle          = Core.copy(handle);
        confFactory.Default.HandleColor     = Core.copy(handleColor);
        confFactory.Default.System          = Core.copy(system);
        confFactory.Current.System          = Core.copy(system);
        confFactory.Default.SystemType      = Core.copy(systemType);
        confFactory.Default.WarmEdge        = Core.copy(warmEdge);
        confFactory.Default.DefaultWarmEdge = false;
        confFactory.addPositionToGroup      = true;

        if (url) {
            $location.url(`/app/${type}`);
        }
    }

    /**
     * Przejscie pod konfigurator do edycji pozycji
     *  @param {object} object              Obiekt
     */
    function addPositionToGroup(object) {
        $rootScope.completelyNewPosition = false;
        addPositionToGroupConf(object.confType, object, true);
    }

}
