import { NgModule } from '@angular/core';
import { ColorsPositionService } from './colors-position.service';
import { CuttingStockService } from './cutting-stock.service';
import OffersService from './offers.service';
import { OfferSummaryService } from './offer-summary.service';
import OfferGroupService from './offer-group.service';
import ManyPositionsService from './many-positions.service';
import { currentConfiguratorServiceProvider } from './ajs-upgraded-providers';
import { editPositionFactoryProvider } from 'offer/ajs-upgraded-providers';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [
        ColorsPositionService,
        ManyPositionsService,
        CuttingStockService,
        OffersService,
        OfferSummaryService,
        OfferGroupService,
        currentConfiguratorServiceProvider,
        editPositionFactoryProvider,
    ],
})
export class PanelModule {}
