import angular from 'angular';
import snakeCase from 'snake-case';
import {logger} from 'helpers';

/**
 * Fabryka kolorów rolet
 * @param {object} $rootScope               Angular Root Scope
 * @param {object} $uibModal                   Dostarcza obsługę okien modalnych
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} StepFactory              Fabryka kroków
 * @param {object} ConfiguratorsDataService Fabryka danych konfiguracji
 * @param {object} PriceService             Liczenie cen
 * @param {object} CurConfService           Bieżaca konfiguracja
 * @param {object} RollerDimensionsFactory  Fabryka rozmiarów rolet
 */
export default function ColorsFactory($rootScope, $uibModal, Core, IccConfig, ConfigurationsService, StepFactory, ConfiguratorsDataService,
                             PriceService, CurConfService, RollerDimensionsFactory, ColorsMappingService, EventBusService) {
    'ngInject';

    var colors = [];

    var factory = {
        loadedData: false,
        openModalRollerColor,
        setDefaultsColors,
        setColor,
        setDefaultsColorsOnChangeColor,
        loadDefaultProfileColor,
        loadColorsForSystem,
        boxGroups: [],
        guideRailGroups: [],
        revisionGroups: [],
        boxInnerGroups: [],
        boxGuideOuterGroups: [],
        profileGroups: [],
        endslatGroups: [],
        boxSideSurfaceGroups: [],
        init,
    };

    if (ConfiguratorsDataService.loaded) {
        init(true);
    }

    EventBusService.subscribeWithoutConfiguration(['initializedConfigurator'], () => {
        init(true);
    });
    EventBusService.subscribeWithoutConfiguration(['saveGuideProfile', 'saveSlatProfile'], () => {
        init();
    });

    return factory;

    /**
     * Funkcja inicjalizujaca
     */
    function init(first = false) {
        if (CurConfService.conf != 'window' && CurConfService.conf != 'roller_shutter' && CurConfService.conf != 'hs') {
            return;
        }
        if (angular.isArray(ConfiguratorsDataService.data.windowColorsAll)
            && angular.isArray(ConfiguratorsDataService.data.windowColorGroups)) {

            colors = ConfiguratorsDataService.data.windowColorsAll;

            const guideRailsIds = [];
            if (ConfigurationsService.conf.Current.RollerShutter.guideRails && ConfigurationsService.conf.Current.RollerShutter.guideRails.length) {
                for (let i = 0; i < ConfigurationsService.conf.Current.RollerShutter.guideRails.length; i++) {
                    if (!guideRailsIds.includes(ConfigurationsService.conf.Current.RollerShutter.guideRails[i].id)) {
                        guideRailsIds.push(ConfigurationsService.conf.Current.RollerShutter.guideRails[i].id);
                    }
                }
            }

            const slatsIds = [];
            if (ConfigurationsService.conf.Current.RollerShutter.slats && ConfigurationsService.conf.Current.RollerShutter.slats.length) {
                for (let i = 0; i < ConfigurationsService.conf.Current.RollerShutter.slats.length; i++) {
                    if (!slatsIds.includes(ConfigurationsService.conf.Current.RollerShutter.slats[i].id)) {
                        slatsIds.push(ConfigurationsService.conf.Current.RollerShutter.slats[i].id);
                    }
                }
            }

            factory.boxGroups =  ConfiguratorsDataService.data.windowColorGroups.filter((el) =>
                angular.isArray(el.roller_element) && el.roller_element.indexOf('box') > -1
                && el.roller_systems && el.roller_systems.indexOf(ConfigurationsService.conf.Current.RollerShutter.system.id) > -1
                && el.profiles && el.profiles.indexOf(ConfigurationsService.conf.Current.RollerShutter.profile.id) > -1
            );

            factory.guideRailGroups =  ConfiguratorsDataService.data.windowColorGroups.filter((el) =>
                angular.isArray(el.roller_element) && el.roller_element.indexOf('guide_rail') > -1
                && el.roller_systems && el.roller_systems.indexOf(ConfigurationsService.conf.Current.RollerShutter.system.id) > -1
                && el.profiles && el.profiles.indexOf(ConfigurationsService.conf.Current.RollerShutter.profile.id) > -1
            );
            if (IccConfig.Configurators.roller_shutter.guides) {
                factory.guideRailGroups = factory.guideRailGroups.filter(el => el.profiles && el.profiles.some(r => guideRailsIds.includes(Number(r))));
            }

            factory.revisionGroups =  ConfiguratorsDataService.data.windowColorGroups.filter((el) =>
                angular.isArray(el.roller_element) && el.roller_element.indexOf('revision') > -1
                && el.roller_systems && el.roller_systems.indexOf(ConfigurationsService.conf.Current.RollerShutter.system.id) > -1
                && el.profiles && el.profiles.indexOf(ConfigurationsService.conf.Current.RollerShutter.profile.id) > -1
            );
            factory.boxInnerGroups =  ConfiguratorsDataService.data.windowColorGroups.filter((el) =>
                angular.isArray(el.roller_element) && el.roller_element.indexOf('box_inner') > -1
                && el.roller_systems && el.roller_systems.indexOf(ConfigurationsService.conf.Current.RollerShutter.system.id) > -1
                && el.profiles && el.profiles.indexOf(ConfigurationsService.conf.Current.RollerShutter.profile.id) > -1
            );
            factory.boxGuideOuterGroups =  ConfiguratorsDataService.data.windowColorGroups.filter((el) =>
                angular.isArray(el.roller_element) && el.roller_element.indexOf('box_guide_outer') > -1
                && el.roller_systems && el.roller_systems.indexOf(ConfigurationsService.conf.Current.RollerShutter.system.id) > -1
                && el.profiles && el.profiles.indexOf(ConfigurationsService.conf.Current.RollerShutter.profile.id) > -1
            );
            factory.profileGroups =  ConfiguratorsDataService.data.windowColorGroups.filter((el) =>
                angular.isArray(el.roller_element) && el.roller_element.indexOf('profile') > -1
                && el.roller_systems && el.roller_systems.indexOf(ConfigurationsService.conf.Current.RollerShutter.system.id) > -1
                && el.profiles && el.profiles.indexOf(ConfigurationsService.conf.Current.RollerShutter.profile.id) > -1
            );

            factory.endslatGroups =  ConfiguratorsDataService.data.windowColorGroups.filter((el) =>
                angular.isArray(el.roller_element) && el.roller_element.indexOf('endslat') > -1
                && el.roller_systems && el.roller_systems.indexOf(ConfigurationsService.conf.Current.RollerShutter.system.id) > -1
                && el.profiles && el.profiles.indexOf(ConfigurationsService.conf.Current.RollerShutter.profile.id) > -1
            );
            if (IccConfig.Configurators.roller_shutter.slats) {
                factory.endslatGroups = factory.endslatGroups.filter(el => el.profiles && el.profiles.some(r => slatsIds.includes(Number(r))));
            }

            factory.boxSideSurfaceGroups =  ConfiguratorsDataService.data.windowColorGroups.filter((el) =>
                angular.isArray(el.roller_element) && el.roller_element.indexOf('box_side_surface') > -1
                && el.roller_systems && el.roller_systems.indexOf(ConfigurationsService.conf.Current.RollerShutter.system.id) > -1
                && el.profiles && el.profiles.indexOf(ConfigurationsService.conf.Current.RollerShutter.profile.id) > -1
            );
        }
        factory.windowColorRals = ConfiguratorsDataService.data.windowColorRals;
        ConfigurationsService.conf.Current.RollerShutter.guideType = 'pvc';
        ConfigurationsService.conf.Default.RollerShutter.guideType = 'pvc';
        if (!(ConfigurationsService.conf.Edit && first)) {
            setDefaultsColors();
        }
        RollerDimensionsFactory.loadBoxHeights();
        factory.loadedData = true;
    }

    /**
     * Funkcja ładujaca kolor dla systemów
     */
    function loadColorsForSystem() {
        init();
    }

    /**
     * Funkcja ustawiajaca domyślny kolor
     */
    function setDefaultsColors() {
        var conf = ConfigurationsService.conf.Current;

        if (conf.type == 'roller_shutter') {
            setColor(getDefaultColorForGroup('profile', conf), null, 'profile', true);
            setColor(getDefaultColorForGroup('box', conf), null, 'box', true);
            setColor(getDefaultColorForGroup('boxInner', conf), null, 'boxInner', true);
            setColor(getDefaultColorForGroup('boxGuideOuter', conf), null, 'boxGuideOuter', true);
        } else {
            setColor(getDefaultColorForGroup('box', conf), null, 'box', true);
            setColor(getDefaultColorForGroup('boxInner', conf), null, 'boxInner', true);
            setColor(getDefaultColorForGroup('boxGuideOuter', conf), null, 'boxGuideOuter', true);
            setColor(getDefaultColorForGroup('profile', conf), null, 'profile', true);
        }
        setColor(getDefaultColorForGroup('guideRail', conf), null, 'guideRail', true);
        setColor(getDefaultColorForGroup('endslat', conf), null, 'endslat', true);
        setColor(getDefaultColorForGroup('revision', conf), null, 'revision', true);
        setColor(getDefaultColorForGroup('boxSideSurface', conf), null, 'boxSideSurface', true);
    }

    /**
     * Ustawia domyślne kolory pasujące do danego koloru.
     *
     * @param {any}     group              Miejsce koloru
     * @param {boolean} [setDefault=false] Czy ustawić w tym miejscu domyślny.
     */
    function setDefaultsColorsOnChangeColor(group, setDefault = false) {
        const conf = ConfigurationsService.conf.Current;
        if (group != 'window') {
            if (setDefault && (!conf.RollerShutter.colors[group] || conf.RollerShutter.colors[group].isDefault)) {
                setColor(getDefaultColorForGroup(group, conf), null, group, true);
            }
        }
        if (group == 'window') {
            setDefaultsColorsOnChangeColor('boxInner', true);
            if (IccConfig.Configurators.roller_shutter.boxColorGuideColor) {
                setDefaultsColorsOnChangeColor('guideRail', true);
            } else {
                setDefaultsColorsOnChangeColor('boxGuideOuter', true);
            }
        } else if (group == 'boxGuideOuter') {
            if (!IccConfig.Configurators.roller_shutter.boxColorGuideColor
                && conf.RollerShutter.type.type == 'N'
            ) {
                if (conf.type != 'roller_shutter') {
                    setDefaultsColorsOnChangeColor('profile', true);
                } else {
                    setDefaultsColorsOnChangeColor('boxInner', true);
                    setDefaultsColorsOnChangeColor('box', true);
                }
                setDefaultsColorsOnChangeColor('endslat', true);
                setDefaultsColorsOnChangeColor('boxSideSurface', true);
            }
        } else if (group == 'profile') {
            if (conf.type == 'roller_shutter') {
                if (IccConfig.Configurators.roller_shutter.boxColorGuideColor
                    || conf.RollerShutter.type.type != 'N'
                ) {
                    setDefaultsColorsOnChangeColor('guideRail', true);
                } else {
                    setDefaultsColorsOnChangeColor('boxGuideOuter', true);
                }
            }
            setDefaultsColorsOnChangeColor('revision', true);
        } else if (group == 'guideRail') {
            if (conf.type != 'roller_shutter') {
                setDefaultsColorsOnChangeColor('profile', true);
            }
            setDefaultsColorsOnChangeColor('boxGuideOuter', true);
            setDefaultsColorsOnChangeColor('boxInner', true);
            setDefaultsColorsOnChangeColor('box', true);
            setDefaultsColorsOnChangeColor('endslat', true);
            setDefaultsColorsOnChangeColor('boxSideSurface', true);
        }
    }

    /**
     * Funkcja ładujaca domyślny profil koloru
     */
    function loadDefaultProfileColor() {
        setDefaultsColors();
    }

    /**
     * Funkcja ustawiajaca kolor
     * @param {object} color Kolor
     * @param {object} group Grupa
     * @param {object} prop  Prop
     */
    function setColor(color, group, prop, isDefault = false) {
        if (!angular.isObject(color)) {
            logger.warn('Kolor nie może być być null!');
            return;
        }
        color = Core.copy(color);
        color.isDefault = isDefault;
        ConfigurationsService.conf.Current.RollerShutter.colors[prop] = color;
        ConfigurationsService.conf.Default.RollerShutter.colors[prop] = color;

        var groupsForColor = findGroupForColor(color, ConfigurationsService.conf.Current, prop);
        var charge = getMaxColorGroupFactor(groupsForColor, 'rs_price_percent', ConfigurationsService.conf.Current);
        var chargeM2 = getMaxColorGroupFactor(groupsForColor, 'rs_price_m2', ConfigurationsService.conf.Current);
        var chargeH = getMaxColorGroupFactor(groupsForColor, 'rs_price_h', ConfigurationsService.conf.Current);
        var chargeW = getMaxColorGroupFactor(groupsForColor, 'rs_price_w', ConfigurationsService.conf.Current);
        if (!angular.isString(group)) {
            group = getColorGroup(color, prop);
        }

        ConfigurationsService.conf.Current.RollerShutter.colors[prop + 'Group'] = group;
        ConfigurationsService.conf.Default.RollerShutter.colors[prop + 'Group'] = group;

        ConfigurationsService.conf.Current.RollerShutter.colors[prop + 'Charge'] = charge;
        ConfigurationsService.conf.Default.RollerShutter.colors[prop + 'Charge'] = charge;

        ConfigurationsService.conf.Current.RollerShutter.colors[prop + 'ChargeM2'] = chargeM2;
        ConfigurationsService.conf.Default.RollerShutter.colors[prop + 'ChargeM2'] = chargeM2;

        ConfigurationsService.conf.Current.RollerShutter.colors[prop + 'ChargeH'] = chargeH;
        ConfigurationsService.conf.Default.RollerShutter.colors[prop + 'ChargeH'] = chargeH;

        ConfigurationsService.conf.Current.RollerShutter.colors[prop + 'ChargeW'] = chargeW;
        ConfigurationsService.conf.Default.RollerShutter.colors[prop + 'ChargeW'] = chargeW;

        setDefaultsColorsOnChangeColor(prop);

        RollerDimensionsFactory.loadBoxHeights();
        RollerDimensionsFactory.setBoundaryDimensionsFromPrices();
        PriceService.count();
    }

    /**
     * Funkcja pobierajaca max grupe kolorow
     * @param  {object} colorGroups Grupa kolorow
     * @param  {object} prop        Prop
     */
    function getMaxColorGroupFactor(colorGroups, prop) {
        let colorFactor = null;
        if (angular.isDefined(colorGroups)) {
            for (let i = 0; i < colorGroups.length; i++) {
                if (colorFactor === null || (colorGroups[i][prop] * 1) > colorFactor) {
                    colorFactor = colorGroups[i][prop] * 1;
                }
            }
        }

        if (colorFactor === null) {
            colorFactor = 0;
        }

        return colorFactor;
    }

    /**
     * Zwraca grupę koloru - bez dopłaty, za dopłata.
     *
     * @param      {object}  color   Kolor
     * @return     {string}  Grupa koloru
     */
    function getColorGroup(color, prop) {
        var groupsForColor = findGroupForColor(color, ConfigurationsService.conf.Current, prop);
        var charge = getMaxColorGroupFactor(groupsForColor, 'rs_price_percent', ConfigurationsService.conf.Current);
        var chargeM2 = getMaxColorGroupFactor(groupsForColor, 'rs_price_m2', ConfigurationsService.conf.Current);
        var chargeH = getMaxColorGroupFactor(groupsForColor, 'rs_price_h', ConfigurationsService.conf.Current);
        var chargeW = getMaxColorGroupFactor(groupsForColor, 'rs_price_w', ConfigurationsService.conf.Current);
        var group = 'color';
        if (charge > 0 || chargeM2 > 0 || chargeH > 0 || chargeW > 0) {
            group = 'charge';
        }
        return group;
    }

    /**
     * Funkcja znajdujaca grupe kolorow
     * @param  {object} color Kolor
     * @param  {object} conf  Konfiguracja
     */
    function findGroupForColor(color, conf, prop) {
        var gr = [];
        if (!angular.isObject(color)) {
            return gr;
        }
        if (angular.isObject(conf.RollerShutter.system) && angular.isObject(conf.RollerShutter.profile)) {
            gr = ConfiguratorsDataService.data.windowColorGroups.filter(el =>
                angular.isArray(el.roller_systems) && el.roller_systems.indexOf(conf.RollerShutter.system.id) > -1
                && angular.isArray(el.profiles) && el.profiles.indexOf(conf.RollerShutter.profile.id) > -1
                && (color.groups && color.groups.indexOf(el.id) > -1 || el.include_rals && color.RAL)
                && angular.isArray(el.roller_element) && el.roller_element.indexOf(snakeCase(prop)) > -1
            );
        }
        return gr;
    }

    /**
     * Funkcja służaca do sortowania kolorów w kolejnosci pierwsze bez dopłaty, potem te za dopłata.
     *
     * @param      {object}  a       Kolor 1
     * @param      {object}  b       Kolor 2
     * @return     {number}  Wynik porównania
     */
    function compareColors(group, a, b) {
        var groupA = getColorGroup(a, group);
        var groupB = getColorGroup(b, group);
        if (groupA === 'color' && groupB === 'charge') {
            return -1;
        } else if (groupA === groupB) {
            return a.order - b.order;
        } else if (groupA === 'charge' && groupB === 'color') {
            return 1;
        }
        return 0;
    }

    /**
     * Funkcja pobierajaca grupe kolorow
     * @param  {object}  group     Grupa
     * @param  {object}  hasCharge Załadowana
     * @param  {object}  conf      Konfiguracja
     */
    function getColorsForGroup(group, conf) {
        var colorGroups = ConfiguratorsDataService.data.windowColorGroups;
        switch (group) {
            case 'box':
                colorGroups = factory.boxGroups;
                break;
            case 'guideRail':
                colorGroups = factory.guideRailGroups;
                break;
            case 'revision':
                colorGroups = factory.revisionGroups;
                break;
            case 'boxInner':
                colorGroups = factory.boxInnerGroups;
                break;
            case 'boxGuideOuter':
                colorGroups = factory.boxGuideOuterGroups;
                break;
            case 'profile':
                colorGroups = factory.profileGroups;
                break;
            case 'endslat':
                colorGroups = factory.endslatGroups;
                break;
            case 'boxSideSurface':
                colorGroups = factory.boxSideSurfaceGroups;
                break;
        }
        var gr = [];
        if (angular.isObject(conf.RollerShutter.system) && angular.isObject(conf.RollerShutter.profile)) {
            gr = colorGroups.filter((el) =>
                angular.isArray(el.roller_systems) && el.roller_systems.indexOf(conf.RollerShutter.system.id) > -1
                        &&  angular.isArray(el.profiles) && el.profiles.indexOf(conf.RollerShutter.profile.id) > -1
            );
        }
        var groupsIds = [];
        var concatRals = gr.some(g => g.include_rals);

        for (var i = gr.length - 1; i >= 0; i--) {
            groupsIds.push(gr[i].id);
        }

        var availColors = ConfiguratorsDataService.data.windowColorsAll.filter(el => el.groups && el.groups.some(el2 => groupsIds.indexOf(el2) > -1)).sort(compareColors.bind(null, group));
        if (concatRals && gr.length > 0) {
            availColors = availColors.concat(factory.windowColorRals);
        }
        return availColors;
    }

    /**
     * Funckaj pobierajaca domyslny kolor dla grupy
     * @param  {object} group Grupa
     * @param  {object} conf  Konfiguracja
     */
    function getDefaultColorForGroup(group, conf) {
        let matchedColors = [];

        if (group == 'endslat') {
            // z prowadnic - guideRail lub boxGuideOuter
            let guideColorId = null;
            if (conf.RollerShutter.type.type == 'N' && !IccConfig.Configurators.roller_shutter.boxColorGuideColor && conf.RollerShutter.colors.boxGuideOuter) {
                guideColorId = conf.RollerShutter.colors.boxGuideOuter.id;
            } else if (conf.RollerShutter.colors.guideRail){
                guideColorId = conf.RollerShutter.colors.guideRail.id;
            }

            matchedColors = ColorsMappingService.getColors(guideColorId, 'window', 'window');
        } else {
            if (conf.type == 'roller_shutter') {
                if (group != 'guideRail' && group != 'boxGuideOuter') {
                    // z prowadnic - guideRail lub boxGuideOuter
                    let guideColorId = null;
                    if (conf.RollerShutter.type.type == 'N' && !IccConfig.Configurators.roller_shutter.boxColorGuideColor && conf.RollerShutter.colors.boxGuideOuter) {
                        guideColorId = conf.RollerShutter.colors.boxGuideOuter.id;
                    } else if (conf.RollerShutter.colors.guideRail){
                        guideColorId = conf.RollerShutter.colors.guideRail.id;
                    }
                    matchedColors = ColorsMappingService.getColors(guideColorId, 'window', 'window');
                } else if (group == 'guideRail') {
                    // z pancerza
                    if (conf.RollerShutter.colors.profile) {
                        const profileColorId = conf.RollerShutter.colors.profile.id;
                        matchedColors = ColorsMappingService.getColors(profileColorId, 'window', 'window');
                    }
                } else if (group == 'boxGuideOuter') {
                    if (conf.RollerShutter.type.type == 'N' && !IccConfig.Configurators.roller_shutter.boxColorGuideColor) {
                        // z pancerza
                        if (conf.RollerShutter.colors.profile) {
                            const profileColorId = conf.RollerShutter.colors.profile.id;
                            matchedColors = ColorsMappingService.getColors(profileColorId, 'window', 'window');
                        }
                    } else {
                        // z prowadnic
                        let guideColorId = null;
                        if (conf.RollerShutter.colors.guideRail) {
                            guideColorId = conf.RollerShutter.colors.guideRail.id;
                        }
                        matchedColors = ColorsMappingService.getColors(guideColorId, 'window', 'window');
                    }
                }
            } else {
                if (group == 'boxGuideOuter' && !IccConfig.Configurators.roller_shutter.boxColorGuideColor || group == 'guideRail') {
                    // z okna zewn.
                    const windowColorId = ColorsMappingService.getWindowColorId(conf, 'outer');
                    const windowColorRal = ColorsMappingService.getWindowColorId(conf, 'outer', 'RAL');
                    matchedColors = ColorsMappingService.getColors(windowColorId, windowColorRal ? 'ral' : 'window', 'window');
                } else if (group == 'boxInner') {
                    // z okna wewn.
                    const windowColorId = ColorsMappingService.getWindowColorId(conf, 'inner');
                    const windowColorRal = ColorsMappingService.getWindowColorId(conf, 'inner', 'RAL');
                    matchedColors = ColorsMappingService.getColors(windowColorId, windowColorRal ? 'ral' : 'window', 'window');
                } else {
                    // z prowadnic - guideRail lub boxGuideOuter
                    let guideColorId = null;
                    if (!IccConfig.Configurators.roller_shutter.boxColorGuideColor && conf.RollerShutter.colors.boxGuideOuter) {
                        guideColorId = conf.RollerShutter.colors.boxGuideOuter.id;
                    } else if (conf.RollerShutter.colors.guideRail) {
                        guideColorId = conf.RollerShutter.colors.guideRail.id;
                    }
                    matchedColors = ColorsMappingService.getColors(guideColorId, 'window', 'window');
                }
            }
        }

        var colorsForGroup = getColorsForGroup(group, conf);
        if (colorsForGroup.length > 0) {
            const matchedColorsToGroup = matchedColors.map(m => colorsForGroup.filter(c => c && c.id == m && !c.RAL)[0]).filter(m => m);
            const inInsulationColorsToGroup = colorsForGroup.filter(c => c && c.in_insulation);
            if ((group == 'boxInner' || group == 'boxGuideOuter')
                && IccConfig.Configurators.roller_shutter.inInsulation
                && conf.RollerShutter.system.inInsulation
                && conf.RollerShutter.inInsulation
                && inInsulationColorsToGroup.length
            ) {
                return inInsulationColorsToGroup[0];
            }
            if (matchedColorsToGroup.length && IccConfig.Configurators.colorsMapping.roller_shutter) {
                return matchedColorsToGroup[0];
            }
            return colorsForGroup[0];
        }
        logger.warn('Brak kolorów w tej grupie! Grupa ' + group.name);
        return null;
    }

    /**
     * Funkcja otwierajaca okno modalne
     * @param  {object} colorType Typ koloru
     * @param  {object} conf      Konfiguracja
     */
    function openModalRollerColor(colorType, conf) {
        const modalInstance = $uibModal.open({
            templateUrl: 'modalColorPVC.html',
            controller : 'ModalColorPVCCtrl as mColorPVC',
            resolve    : {
                colors       : () => getColorsForGroup(colorType, conf),
                colorsRAL    : () => factory.windowColorRals,
                selectedColor: () => conf.RollerShutter.colors[colorType],
                colorGroups  : () => factory[colorType + 'Groups'],
                transparentWood: () => false,
                colorsSashExt: () => false,
                type         : () => false
            }
        });
        if (IccConfig.Configurators.tutorialAvailable) {
            EventBusService.post({
                key: 'tutorialSteps',
                value: 'color'
            });
        }

        modalInstance.result.then((data) => {
            if (angular.isDefined(data)) {
                factory.setColor(data.color, data.group, colorType);
            }
        });

        modalInstance.closed.then(() => {
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg'
                });
            }
        });
    }
}
