import angular from 'angular';

/**
 * Fabryka zamków
 * @param  {object} $rootScope               Angular Root Scope
 * @param  {object} $filter                  Filtry
 * @param  {object} ConfiguratorsDataService Fabyrka danych konfiguracji
 * @param  {object} CurConfService           Bieżaca konfiguracja
 * @param  {object} ParametersService        Fabryka parametrów
 * @param  {object} ConfigurationsService    Fabryka konfiguracji
 * @param  {object} Core                     Core
 * @param  {object} PriceService             Liczenie cen
 * @param  {object} IccConfig                Konfiguracja ICC
 * @return {object} factory                  Fabryka
 */
export default function LocksFactory($rootScope, $filter, ConfiguratorsDataService, CurConfService, // eslint-disable-line max-params
                                     ParametersService, ConfigurationsService, Core, PriceService, IccConfig, EventBusService) {
    'ngInject';

    var configurators       = ['door'];
    var allLocks         = [];

    var factory = {
        locks     : [],
        loadedData: false,

        selectLock,
        findLocksBySystem
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    return factory;

    /**
     * Funkcja inicjalizacyjna
     */
    function init() {
        allLocks      = ConfiguratorsDataService.data.locks;
        factory.locks = ConfiguratorsDataService.data.locks;

        if (configurators.indexOf(CurConfService.conf) !== -1) {
            findLocksBySystem();
        }

        factory.loadedData = true;
    }

    /**
     * Funkcja ustawiajaca domyślne zamki
     */
    function setDefaultLock() {
        if (angular.isUndefined(ConfigurationsService.conf)) {
            return;
        }
        if (angular.isArray(factory.locks) && factory.locks.length > 0) {
            ConfigurationsService.conf.Current.Lock = factory.locks[0];
            ConfigurationsService.conf.Default.Lock = factory.locks[0];
        } else {
            ConfigurationsService.conf.Current.Lock = {};
            ConfigurationsService.conf.Default.Lock = {};
        }
        if (IccConfig.Configurators.dependencies) {
            EventBusService.post({ key: 'processDependencies', value: null });
        }
        PriceService.count();
        ParametersService.count(ConfigurationsService.conf.Current);
    }

    /**
     * Funkcja wybierajaca zamki
     * @param  {object} lock Zamki
     */
    function selectLock(lock) {
        ConfigurationsService.conf.Current.Lock = lock;
        ConfigurationsService.conf.Default.Lock = lock;
        if (IccConfig.Configurators.dependencies) {
            EventBusService.post({ key: 'processDependencies', value: null });
        }
        PriceService.count();
        ParametersService.count(ConfigurationsService.conf.Current);
    }

    /**
     * Funkcja znajdujaca zamki do systemu
     * @param  {object} system System
     */
    function findLocksBySystem(system) {
        if (angular.isUndefined(system) || angular.isUndefined(system.id)) {
            system = ConfigurationsService.conf.Current.System;
        }

        if (angular.isUndefined(system) || angular.isUndefined(system.id)) {
            return;
        }

        const sashTypesIds = ConfigurationsService.conf.Current.Sashes.map(sash => Number(sash.type.id));
        if (sashTypesIds.length === 0) {
            return;
        }
        factory.locks = [];
        angular.forEach(allLocks, function forEachLock(lock) {
            var systemIds = lock.window_lines_ids;
            if (angular.isObject(system) && systemIds.indexOf(system.id) > -1 && lock.sash_types_ids.some(sashTypeId => sashTypesIds.map(Number).indexOf(Number(sashTypeId)) > -1)) {
                if (!IccConfig.Configurators.assignLockToHandles
                    || (IccConfig.Configurators.assignLockToHandles && checkIfLockMatchHandles(lock))
                ) {
                    factory.locks.push(lock);
                }
            }
        });
        if (factory.locks.map(lock => lock.id).indexOf(ConfigurationsService.conf.Current.Lock.id) === -1) {
            setDefaultLock();
        }
    }

    /**
     * Funkcja sprawdzająca, czy zamek jest dostępny dla wybranej klamki
     * @param  {object} lock Zamek
     * @return {bool}      Informacja czy zamek pasuje do klamki
     */
    function checkIfLockMatchHandles(lock) {
        var handleType = ConfigurationsService.conf.Current.HandleType;
        var handle = ConfigurationsService.conf.Current.Handle;
        if (!handle || !handle.id) {
            const doorSash = ConfigurationsService.conf.Current.Sashes.find(sash => sash.type.type === 'DRA' || sash.type.type === 'DOA');
            if (doorSash) {
                handle = doorSash.handle;
            }
        }
        if (handleType == 'DoubleLever' && lock.lock_assign.indexOf(handle.handle_type) > -1) {
            return true;
        } else if (handleType == 'DoublePull' && handle.handle_type == 'pull' && lock.lock_assign.indexOf('doublePull') > -1) {
            return true;
        } else if (handleType == 'LeverPull' && handle.handle_type == 'doubleLever' && lock.lock_assign.indexOf('leverPull') > -1) {
            return true;
        } else if (handleType == 'LeverPull' && handle.handle_type == 'doubleKnob' && lock.lock_assign.indexOf('knobPull') > -1) {
            return true;
        }
        return false;
    }
}
