import DatabaseManager, { DatabaseManagerToken } from 'sync/Databases/DatabaseManager';
import { IccDatabase } from 'sync/Databases/IccDatabase';
import OfferSequenceService from 'offers/OfferSequenceService';
import { core } from 'helpers';
import Common from 'Common';
import OffersService from './offers.service';
import ManyPositionsService from './many-positions.service';
import PositionDetailedSummaryService from 'PositionDetailedSummaryService';
import OfferTransportCostService from 'offers/OfferTransportCostService';
import OfferDiscountsService from 'offers/OfferDiscountsService';
import TimeLimitService from 'time_limit/time_limit.service';
import { IccSimpleDatabase } from 'sync/Databases/IccSimpleDatabase';
import OfferGroupService from './offer-group.service';
import StateService from 'state.service';
import UserService from 'user.service';
import { Injectable, Inject } from '@angular/core';
import { APP_CONFIG, AppConfig } from 'config';
import { EventBusService } from 'event-bus.service';

@Injectable()
export class OfferSummaryService {
    constructor(
        private stateService: StateService,
        @Inject(DatabaseManagerToken) private databaseManager: DatabaseManager,
        @Inject(APP_CONFIG) private config: AppConfig,
        private offersService: OffersService,
        private manyPositionsService: ManyPositionsService,
        private offerGroupService: OfferGroupService,
        private userService: UserService,
        private eventBusService: EventBusService,
        @Inject('TimeLimitService') private timeLimitService: TimeLimitService
    ) {
        eventBusService.subscribe<any>('correctTotalOfferPrice', async data => {
            const offer = await this.offersService.get(data.value.offerId, true);
            const updatedOffer = await this.autoUpdateOffer(
                offer.tmp_id,
                null,
                null,
                data.value.sequence,
                true
            );
            if (core.isWorker()) {
                (self as any).postMessage({
                    subject: 'emittedEvent',
                    name: 'correctedTotalOfferPrice',
                    value: {
                        offer: updatedOffer,
                    },
                });
            } else {
                eventBusService.post({
                    key: 'correctedTotalOfferPrice',
                    value: {
                        offer: updatedOffer,
                    },
                });
            }
        });
    }

    /**
     * Automatyczne uaktualnienie oferty po zmianach w pozycjach
     * @param  {string} _id              Id oferty
     * @param  {object} posObj           Objekt pozycji
     * @param  {string} action           Akcja na pozycji
     * @param  {object} preparedSequence Gotowa struktura pozycji
     * @return {object}                  Promise
     */
    async autoUpdateOffer(
        offerId,
        posObj,
        action,
        preparedSequence,
        forceUpdateBasedOnDB = false,
        dealer = null,
        dataToUpdate = {}
    ) {
        const user: any = this.userService.get();
        if (!dealer) {
            dealer = user.dealer;
        }

        let offer = await this.offersService.get(offerId);
        if (Object.keys(dataToUpdate).length !== 0) {
            offer = Common.extend(offer, dataToUpdate);
        }
        const newOfferData = {
            circuit: 0,
            quantity: 0,
            weight: 0,
            weight_positions_quantity: 0,
            area: 0,
            glazing_area: 0,
            number_items: 0,
            valuation: 0,
            sequence: '[]',

            client_discount_position: 0,

            client_price_before_discount_position: 0,
            client_price_before_discount: 0,
            client_price: 0,

            dealer_client_price_before_discount_position: 0,
            dealer_client_price_before_discount: 0,
            dealer_client_price: 0,

            dealer_price_before_discount_position: 0,
            dealer_price_before_discount: 0,
            dealer_price: 0,

            transport_cost: 0,
            client_transport_cost: 0,
            client_discount_special: 0,
            time_limit: 0,

            summary: {
                client: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
                dealer: {
                    additionals: {
                        product: 0,
                        service: 0,
                    },
                    others: {
                        product: 0,
                        service: 0,
                    },
                    components: {
                        glass: 0,
                        fitting: 0,
                        addons: 0,
                        base: 0,
                        roller: 0,
                        mosquito: 0,
                        colorCost: 0,
                    },
                },
            },
        };

        const sequence = OfferSequenceService.updateOfferSequence(
            preparedSequence || offer.sequence,
            [posObj],
            action,
            this.config.IccConfig
        );
        const searched = OfferSequenceService.keysFromSequence(sequence);

        const positionsData = await this.manyPositionsService.listById(
            searched,
            offer.tmpId,
            true,
            forceUpdateBasedOnDB
        );
        let positions = positionsData.pos;

        positions = positions.map(el => {
            if (!Common.isObject(el.doc.details)) {
                el.doc.details = core.parseJson(el.doc.details);
            }
            return el;
        });
        const position = positions.filter(elem => {
            return Common.isObject(posObj) && elem.doc.tmp_id === posObj.id;
        });
        const positionsGroupsData = this.offerGroupService.updatePositionsGroupsData(
            offer.positions_groups_data,
            sequence,
            position[0]
        );
        let transportPosition = null;
        let i = 0;
        let offerFroupDiscounts = [];
        const transportCost = await this.calculateTransportCost(positions, user);
        while (i < positions.length) {
            if (!Common.isUndefined(positions[i].doc)) {
                newOfferData.weight += positions[i].doc.weight * positions[i].doc.quantity;
                if (newOfferData.weight) {
                    newOfferData.weight_positions_quantity++;
                }
                newOfferData.glazing_area += positions[i].doc.glazing_area * positions[i].doc.quantity;
                if (!positions[i].doc.coupled_position_id) {
                    newOfferData.circuit += positions[i].doc.circuit * positions[i].doc.quantity;
                    newOfferData.area += positions[i].doc.area * positions[i].doc.quantity;
                    newOfferData.number_items += 1;
                    newOfferData.quantity += ~~positions[i].doc.quantity || 0;
                }
                if (
                    !positions[i].doc.standard
                    && !positions[i].doc.valuated_price
                    && !positions[i].doc.coupled_position_id
                ) {
                    newOfferData.valuation = 1;
                }

                if (
                    this.config.IccConfig.Offer.calculatedDealerTransportCost
                    && positions[i].doc.confType === 'transport_cost'
                    && transportCost !== 0
                    && positions[i].doc.dealer_price !== transportCost
                ) {
                    positions[i].doc.dealer_price = transportCost;
                    positions[i].doc.dealer_price_before_discount = transportCost;
                    positions[i].doc.client_price = transportCost;
                    positions[i].doc.client_price_before_discount = transportCost;

                    transportPosition = core.copy(positions[i].doc);
                }

                if (!positions[i].doc.coupled_position_id) {
                    if (positions[i].doc.confType !== 'additional') {
                        newOfferData.dealer_price_before_discount_position += core.roundPrice(
                            positions[i].doc.dealer_price_before_discount
                                * positions[i].doc.quantity
                        ); // jscs:ignore
                        newOfferData.dealer_price_before_discount += core.roundPrice(
                            positions[i].doc.dealer_price * positions[i].doc.quantity
                        ); // jscs:ignore
                        newOfferData.dealer_price +=
                            core.roundPrice(
                                positions[i].doc.dealer_price * positions[i].doc.quantity
                            )
                            - core.roundPrice(
                                (1 / 100)
                                    * positions[i].doc.dealer_price
                                    * positions[i].doc.quantity
                                    * offer.dealer_discount_producer_special
                            ); // jscs:ignore

                        newOfferData.dealer_client_price_before_discount_position += core.roundPrice(
                            positions[i].doc.client_price_before_discount
                                * positions[i].doc.quantity
                        ); // jscs:ignore
                        newOfferData.dealer_client_price_before_discount += core.roundPrice(
                            positions[i].doc.client_price * positions[i].doc.quantity
                        ); // jscs:ignore
                        newOfferData.dealer_client_price +=
                            core.roundPrice(
                                positions[i].doc.client_price * positions[i].doc.quantity
                            )
                            - core.roundPrice(
                                (1 / 100)
                                    * positions[i].doc.client_price
                                    * positions[i].doc.quantity
                                    * offer.client_discount_special
                            ); // jscs:ignore
                    }

                    newOfferData.client_price_before_discount_position += core.roundPrice(
                        positions[i].doc.client_price_before_discount * positions[i].doc.quantity
                    ); // jscs:ignore
                    newOfferData.client_price_before_discount += core.roundPrice(
                        positions[i].doc.client_price * positions[i].doc.quantity
                    ); // jscs:ignore
                    newOfferData.client_price +=
                        core.roundPrice(positions[i].doc.client_price * positions[i].doc.quantity)
                        - core.roundPrice(
                            (1 / 100)
                                * positions[i].doc.client_price
                                * positions[i].doc.quantity
                                * offer.client_discount_special
                        ); // jscs:ignore
                }

                if (this.config.IccConfig.Offer.detailedSummary) {
                    newOfferData.summary = PositionDetailedSummaryService.detailedSummary(
                        positions[i].doc,
                        this.config.IccConfig,
                        newOfferData.summary
                    );
                }

                i++;
            }
        }

        if (i === positions.length) {
            if (
                ['logistic-minimum', 'm2-cost', 'weight'].includes(
                    this.config.IccConfig.Offer.transportCostType
                )
                && offer.transport_from_producent
                && !offer.split_transport_cost
            ) {
                newOfferData.transport_cost = offer.transport_cost;
                if (
                    this.config.IccConfig.Offer.transportCostType === 'm2-cost'
                    && !Number(offer.order)
                ) {
                    newOfferData.transport_cost = OfferTransportCostService.transportM2Cost(
                        user.dealer,
                        newOfferData
                    );
                    offer.transport_cost = newOfferData.transport_cost;
                }
                if (IccConfig.Offer.transportCostType === 'weight' && !Number(offer.order)) {
                    newOfferData.transport_cost = OfferTransportCostService.transportWeightCost(
                        dealer,
                        newOfferData
                    );
                    offer.transport_cost = newOfferData.transport_cost;
                }

                if (newOfferData.transport_cost) {
                    OfferTransportCostService.addTransportService(newOfferData);
                }
            }
            if (
                ['logistic-minimum', 'm2-cost', 'weight'].includes(
                    this.config.IccConfig.Offer.transportCostType
                )
                && offer.client_transport_cost
                && (['1', '3'].includes(offer.status) || !offer.client_split_transport_cost)
            ) {
                newOfferData.client_transport_cost = offer.client_transport_cost;
                newOfferData.client_discount_position = offer.client_discount_position;
                newOfferData.client_discount_special = offer.client_discount_special;
                OfferTransportCostService.addClientTransportService(newOfferData);
            }
            if (this.config.IccConfig.Offer.offerDiscountsMulti) {
                offerFroupDiscounts = OfferDiscountsService.groupDiscounts(
                    offer.group_discounts,
                    newOfferData.dealer_price_before_discount,
                    user,
                    offer
                );
                newOfferData.dealer_price = offerFroupDiscounts.length
                    ? offerFroupDiscounts[offerFroupDiscounts.length - 1].price
                    : newOfferData.dealer_price;
            }
            if (this.config.IccConfig.Configurators.timeLimits && !~~offer.order) {
                newOfferData.time_limit = this.timeLimitService.getOfferTimeLimit(positions);
            }
            offer = Common.extend(offer, {
                quantity: newOfferData.quantity,
                circuit: newOfferData.circuit,
                number_items: newOfferData.number_items,
                valuation: newOfferData.valuation,
                weight: newOfferData.weight,
                weight_positions_quantity: newOfferData.weight_positions_quantity,
                area: newOfferData.area,
                glazing_area: newOfferData.glazing_area,
                sequence,
                dealer_price_before_discount_position:
                    newOfferData.dealer_price_before_discount_position,
                dealer_price_before_discount: newOfferData.dealer_price_before_discount,
                dealer_price: newOfferData.dealer_price,
                dealer_client_price_before_discount_position:
                    newOfferData.dealer_client_price_before_discount_position,
                dealer_client_price_before_discount:
                    newOfferData.dealer_client_price_before_discount,
                dealer_client_price: newOfferData.dealer_client_price,
                client_price_before_discount_position:
                    newOfferData.client_price_before_discount_position,
                client_price_before_discount: newOfferData.client_price_before_discount,
                client_price: newOfferData.client_price,
                summary: newOfferData.summary,
                group_discounts: offerFroupDiscounts,
                positions_groups_data: positionsGroupsData,
                changed_positions: this.saveOfferChanges(offer.changed_positions, posObj, action),
                time_limit: newOfferData.time_limit,
            });

            await this.offersService.update(offer.tmp_id, offer);
            const currentOfferId = this.stateService.getKey('offer_id');
            if (currentOfferId === offer.tmp_id) {
                this.stateService.setKey('offer', offer);
            }
            if (transportPosition) {
                await (this.databaseManager.get('Position') as IccDatabase).update(
                    transportPosition,
                    { internalId: transportPosition.tmp_id }
                );
                this.offersService.emitModifiedOffer();
                return offer;
            } else {
                this.offersService.emitModifiedOffer();
                return offer;
            }
        }
    }

    /**
     * Obliczanie kosztów transportu oferty
     * @param  {array} positions Pozycje oferty
     * @return {object}          Promise
     */
    async calculateTransportCost(positions, user) {
        if (
            this.config.IccConfig.Offer.calculatedDealerTransportCost
            && Common.isObject(user.dealer)
            && user.dealer.add_transport_cost
        ) {
            const positionsQuantity = positions.reduce((prev, curr) => {
                if (curr.doc.confType === 'transport_cost') {
                    return prev;
                } else {
                    return prev + (curr.doc.quantity || 0);
                }
            }, 0);

            const prices = await (this.databaseManager.get('Prices') as IccSimpleDatabase).get();
            prices.data = core.parseJson(prices.data);
            const market = prices.data.markets[user.market];
            const transportCost =
                Math.ceil(positionsQuantity / market.average_number_of_positions_on_stand)
                * (Common.isObject(user.dealer) ? user.dealer.distance : 0)
                * market.price_for_one_stand_per_km
                * market.factor_for_transport_cost
                * 1;
            return transportCost;
        } else {
            return 0;
        }
    }

    /**
     * Zapisuje zmiany pozycji w zamowieniu/ofercie
     * @param  {object} oldChanges Lista zmian wg typu zmiany
     * @param  {string} posObj     Id pozycji
     * @param  {string} action     Nazwa akcji
     * @return {object}            Nowa lista zmian
     */
    saveOfferChanges(oldChanges, posObj, action) {
        const changes = core.parseJson(oldChanges) || {};

        if (posObj && posObj.id) {
            if (action === 'remove') {
                // nie zapamietujemy dodanych i zaraz usunietych pozycji
                if (!Common.isArray(changes.add) || changes.add.indexOf(posObj.id) === -1) {
                    if (!Common.isArray(changes[action])) {
                        changes[action] = [posObj.id];
                    } else {
                        changes[action].push(posObj.id);
                    }
                }

                // jak cos jest usuniete, nie powinno byc w dodanych i zmienionych
                if (Common.isArray(changes.add) && changes.add.indexOf(posObj.id) >= 0) {
                    changes.add.splice(changes.add.indexOf(posObj.id), 1);
                }
                if (Common.isArray(changes.update) && changes.update.indexOf(posObj.id) >= 0) {
                    changes.update.splice(changes.update.indexOf(posObj.id), 1);
                }
            } else if (action === 'add') {
                // kazda nowe pozycja
                if (!Common.isArray(changes[action])) {
                    changes[action] = [posObj.id];
                } else {
                    changes[action].push(posObj.id);
                }
            } else {
                // zmiany istniejacych pozycji, dodane sa w grupie z nowymi
                if (
                    (!Common.isArray(changes.update) || changes.update.indexOf(posObj.id) === -1)
                    && (!Common.isArray(changes.add) || changes.add.indexOf(posObj.id) === -1)
                ) {
                    if (!Common.isArray(changes.update)) {
                        changes.update = [posObj.id];
                    } else {
                        changes.update.push(posObj.id);
                    }
                }
            }
        }

        return changes;
    }
}
