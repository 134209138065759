import angular from 'angular';

/**
 * Fabryka schematów rolet
 * @param {object} $rootScope               Angular Root Scope
 * @param {object} $uibModal                   Dostarcza obsługę okien modalnych
 * @param {object} filterFilter             Filtry
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} StepFactory              Fabryka kroków
 * @param {object} ConfiguratorsDataService Fabryka danych konfiguracji
 * @param {object} CurConfService           Bieżaca konfiguracja
 * @param {object} DrawDataService          Fabryka rysowania
 * @param {object} PriceService             Liczenie cen
 * @param {object} RollerDimensionsFactory  Fabryka rozmiarów rolet
 * @param {object} DrivesFactory            Fabryka napędow
 * @param {object} RollerColorsFactory      Fabryak kolorów rolet
 */
export default function SchemasFactory($rootScope, $uibModal, filterFilter, ConfigurationsService, StepFactory, ConfiguratorsDataService,
                        CurConfService, DrawDataService, PriceService, RollerDimensionsFactory, DrivesFactory, RollerColorsFactory, IccConfig,
                        InfoFactory, $filter, EventBusService) {
    'ngInject';

    var factory = {
        systems: [],

        setShuttersBySchema,
        setDefaultSystem,
        openModalSystems,
        init
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    $rootScope.$on('changedStep', (event, newVal, oldVal) => {
        if (newVal.code != 'rollerschema' && newVal.code != 'rollertype'
            && (oldVal.code == 'rollerschema' || oldVal.code == 'rollertype')
            && newVal.code !== oldVal.code
        ) {
            if (angular.isUndefined(ConfigurationsService.conf.Current.RollerShutter.shutters)
                || (
                    angular.isArray(ConfigurationsService.conf.Current.RollerShutter.shutters)
                    && ConfigurationsService.conf.Current.RollerShutter.shutters.length === 0
                )
            ) {
                InfoFactory.openWarning($filter('translate')('ROLLERSHUTTER|Nie wybrano schematu'));
                StepFactory.selectStep(oldVal.i);
            }
        }
    });

    EventBusService.subscribeWithoutConfiguration('initializedConfigurator', () => {
        init();
    });

    return factory;

    /**
     * Funkcja inicjalizujaca
     */
    function init() {
        if (CurConfService.conf != 'window' && CurConfService.conf != 'roller_shutter' && CurConfService.conf != 'hs') {
            return;
        }

        factory.systems = ConfiguratorsDataService.data.rollerShutterSystems || [];
        if (ConfigurationsService.conf.Current.type !== 'roller_shutter'
            && angular.isObject(ConfigurationsService.conf.Current.System.roller_shutter_systems)
        ) {
            factory.systems = factory.systems.filter(system => ConfigurationsService.conf.Current.System.roller_shutter_systems.includes(system.id));
        }

        if (!ConfigurationsService.conf.Current.RollerShutter.system
            || angular.isUndefined(ConfigurationsService.conf.Current.RollerShutter.system.id)
            || !factory.systems.some(system => ConfigurationsService.conf.Current.RollerShutter.system.id === system.id)
        ) {
            ConfigurationsService.conf.Current.RollerShutter.system = {};
            setDefaultSystem();
            if (angular.isUndefined(ConfigurationsService.conf.Current.RollerShutter.system.id)) {
                ConfigurationsService.conf.Current.hasRoller = false;
            }
        }
        if (((angular.isUndefined(ConfigurationsService.conf.Current.RollerShutter.schemaId)
            || ConfigurationsService.conf.Current.RollerShutter.schemaId === null))
            && (CurConfService.conf != 'roller_shutter' || (!$rootScope.user || !$rootScope.user.access || $rootScope.user.access === 'klient'))
        ) {
            ConfigurationsService.conf.Current.RollerShutter.schemaId = '1';
        }
        if ((angular.isUndefined(ConfigurationsService.conf.Current.RollerShutter.shutters)
            || (
                angular.isArray(ConfigurationsService.conf.Current.RollerShutter.shutters)
                && ConfigurationsService.conf.Current.RollerShutter.shutters.length === 0
            ))
            && (CurConfService.conf != 'roller_shutter' || (!$rootScope.user || !$rootScope.user.access || $rootScope.user.access === 'klient'))
        ) {
            setShuttersBySchema({nextStep: false});
        }
        PriceService.count();
    }

    /**
     * Funkcja ustawiajca rolete wg schematu
     * @param {number} width  Szerokość konfiguracji
     * @param {number} height Wysokość konfiguracji
     */
    function setShuttersBySchema({
        width = ConfigurationsService.conf.Current.Width,
        height = ConfigurationsService.conf.Current.Height,
        nextStep = true
    } = {}) {
        const conf = ConfigurationsService.conf.Current;
        switch (conf.RollerShutter.schemaId) {
            case '1':
                conf.RollerShutter.shutters = [
                    {
                        id        : 0,
                        x         : 1,
                        realWidth : width,
                        width     : 0,
                        realHeight: height,
                        height    : 0,
                        handling  : 'l'
                    }
                ];
                break;
            case '2a':
                conf.RollerShutter.shutters = [
                    {
                        id        : 0,
                        x         : 1,
                        realWidth : Math.floor(width / 2),
                        width     : 0,
                        realHeight: height,
                        height    : 0,
                        handling  : 'l'
                    },
                    {
                        id        : 1,
                        x         : 0 + 1,
                        realWidth : Math.ceil(width / 2),
                        width     : 0,
                        realHeight: height,
                        height    : 0,
                        handling  : 'l'
                    }
                ];
                break;
            case '2b':
                conf.RollerShutter.shutters = [
                    {
                        id        : 0,
                        x         : 1,
                        realWidth : Math.floor(width / 2),
                        width     : 0,
                        realHeight: height,
                        height    : 0,
                        handling  : 'l'
                    },
                    {
                        id        : 1,
                        x         : 0,
                        realWidth : Math.ceil(width / 2),
                        width     : 0,
                        realHeight: height,
                        height    : 0,
                        handling  : 'l'
                    }
                ];
                break;
            case '3':
                conf.RollerShutter.shutters = [
                    {
                        id        : 0,
                        x         : 1,
                        realWidth : Math.round(width / 3),
                        width     : 0,
                        realHeight: height,
                        height    : 0,
                        handling  : 'l'
                    },
                    {
                        id        : 1,
                        x         : 0 + 1,
                        realWidth : Math.round(width / 3),
                        width     : 0,
                        realHeight: height,
                        height    : 0,
                        handling  : 'l'
                    },
                    {
                        id        : 2,
                        x         : 0 + 1,
                        realWidth : width - (Math.round(width / 3) + Math.round(width / 3)),
                        width     : 0,
                        realHeight: height,
                        height    : 0,
                        handling  : 'l'
                    }
                ];
                break;
        }
        for (let i = 1; i < conf.RollerShutter.shutters.length; i++) {
            if (conf.RollerShutter.schemaId == '2b' || conf.RollerShutter.schemaId == '3') {
                conf.RollerShutter.shutters[i].commonRail = false;
            } else {
                conf.RollerShutter.shutters[i].commonRail = true;
            }
        }
        if (conf.type != 'roller_shutter') {
            for (let i = 0; i < conf.RollerShutter.shutters.length; i++) {
                conf.RollerShutter.shutters[i].realHeight = conf.Height;
            }
        }

        RollerDimensionsFactory.resetGuides();
        RollerDimensionsFactory.resetSlats();
        RollerDimensionsFactory.setGuideProfiles();
        RollerDimensionsFactory.setSlatProfiles();

        PriceService.count();
        if (nextStep) {
            StepFactory.goToNextStep(true);
        }
    }

    /**
     * Funkcja ustawiajaca domyślny system
     */
    function setDefaultSystem() {
        const systems = factory.systems.filter(system => system.n_type === ConfigurationsService.conf.Current.RollerShutter.type.type);

        if (systems && systems.length) {
            setSystem(systems[0]);
        }
    }

    /**
     * Funkcja ustawiajaca nazwe konfiguracji
     */
    function setConfigName() {
        if (CurConfService.conf == 'roller_shutter') {
            ConfigurationsService.conf.Current.Name
                = ConfigurationsService.conf.Current.RollerShutter.type.name + ' > ' + ConfigurationsService.conf.Current.RollerShutter.system.name;
        }
    }

    function setSystem(system) {
        const conf = ConfigurationsService.conf.Current;
        conf.RollerShutter.system = system;
        RollerDimensionsFactory.loadProfilesBySystem(conf);
        RollerDimensionsFactory.setBoundaryDimensionsFromPrices();
        RollerColorsFactory.init();
        RollerDimensionsFactory.loadBoxHeights();
        DrivesFactory.loadRetractor();
        DrivesFactory.loadDriveManuals();
        setConfigName();
        PriceService.count();
        conf.RollerShutter.concealedStrip
            = conf.RollerShutter.concealedStrip
                && IccConfig.Configurators.roller_shutter.concealedStrip
                && system.concealedStrip;
        conf.RollerShutter.inInsulation
            = conf.RollerShutter.inInsulation
                && IccConfig.Configurators.roller_shutter.inInsulation
                && system.inInsulation;
    }

    /**
     * Funkcja otwierajaca okno modal
     */
    function openModalSystems() {
        var modalInstance = $uibModal.open({
            templateUrl: 'modalSystems.html',
            controller : 'ModalSystemsCtrl as msystems',
            resolve    : {
                drives  : () => factory.systems.filter(system => system.n_type === ConfigurationsService.conf.Current.RollerShutter.type.type),
                selDrive: () => ConfigurationsService.conf.Current.RollerShutter.system,
                type    : () => ConfigurationsService.conf.Current.RollerShutter.type.type
            }
        });
        if (IccConfig.Configurators.tutorialAvailable) {
            EventBusService.post({
                key: 'tutorialSteps',
                value: 'systems'
             });
        }

        modalInstance.result.then(selectedSystem => {
            if (angular.isDefined(selectedSystem)) {
                setSystem(selectedSystem);
            }
        });

        modalInstance.closed.then(() => {
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg'
                });
            }
        });

        return modalInstance.result;
    }
}
