import angular from 'angular';
import moment from 'moment';
import { logger } from 'helpers';
import Common from 'Common';
import OfferSequenceService from '../../../common/offers/OfferSequenceService';
import OfferGroupCodeService from '../../../common/offers/OfferGroupCodeService';
import OfferDiscountsService from '../../../common/offers/OfferDiscountsService';
import PositionService from '../../../common/offers/PositionService';
import PositionDetailedSummaryService from 'PositionDetailedSummaryService';

/**
 * @ngdoc service
 * @name ICC.Panel.PositionsFactory
 * @param {object} $filter                        Filter angularowy
 * @param {object} $q                             Promise angularowy
 * @param {object} Core                           Biblioteka z domyślnymi funkcjami
 * @param {object} $rootScope                     Rootscope
 * @param {object} OffersFactory                  Obsługa oferty
 * @param {object} DiscountsAndMultipliersService Rabaty dla poszczegolnych linii
 * @param {object} UserService                    Informacje o uzytkowniku
 * @param {Object} IccConfig                      Ustawienia systemu
 * @param {Object} PriceService                   Serwis liczenia cen
 *
 * @description
 *
 * Fabryka pozycji oferty
 */
export default function PositionsFactory(
    $filter,
    $q,
    Core,
    $rootScope,
    $location,
    OffersService,
    machine,
    StateService,
    DiscountsAndMultipliersService,
    UserService,
    IccConfig,
    PriceService,
    DatabaseManager,
    ConfigurationsService,
    ColorsPositionService,
    EventBusService,
    ManyPositionsService,
    OfferSummaryService,
    ConfiguratorsDataService
) {
    'ngInject';
    var factory = {
        add,
        update,
        updateEdit,
        updateMany,
        remove,
        removeMany,
        list,
        get,
        multiCopy,
        copyPositionsFromScope,
        updatePrices,
        multiUpdatePrices,
        whenAllSynced,
        fixSequence,
        removeAllPositions,
        recalculatePrices,
        stringPositionValues,
        valuate,
        parsePositions,
        addTransportCost,
        checkOfferPositions,
        addColorCostPosition,
        parsePosition,
        positionsFromGroups,
    };

    EventBusService.subscribeWithoutConfiguration(['changedOfferObject'], data => {
        const sequenceKeys = OfferSequenceService.keysFromSequence(data.value.offer.sequence);
        ManyPositionsService.listById(sequenceKeys, data.value.offer_id).then(d => {
            StateService.setKey('positions', d.pos);
        });
    });

    return factory;

    /**
     * konstruktor pozycji oferty
     * @memberof PositionsFactory
     * @param {object} params Elementy potrzebne do utworzenia pozycji
     */
    function Position(params) {
        var tmpId = 'tmp_DealerOfferPosition_' + Core.generateUUID();
        const buyDiscounts = DiscountsAndMultipliersService.getBuyDiscounts();
        const saleDiscounts = DiscountsAndMultipliersService.getSaleDiscounts();
        var dealer = UserService.getDealer();

        const dealerMargin = params.offer ? params.offer.dealer_margin * 1 : 0;
        var discounts = OfferDiscountsService.generateGroupDiscounts(
            params,
            IccConfig,
            dealer,
            buyDiscounts,
            saleDiscounts,
            dealerMargin
        );

        this.id = params.id || tmpId;
        this.tmp_id = params.tmp_id || tmpId;
        this.dealer_offer_id = params.dealer_offer_id || params.offer.tmp_id;

        this.configuration = params.configuration;
        this.confType = params.confType || params.configuration.type;

        this.group_discounts = discounts.group_discounts;
        this.transport_cost = params.transport_cost || 0;
        this.client_transport_cost = params.client_transport_cost || 0;
        this.dealer_price_without_transport = params.dealer_price_without_transport || 0;
        this.client_price_without_transport = params.client_price_without_transport || 0;

        if (this.confType === 'other' && !params.recalculateDiscount) {
            this.dealer_price = Common.isNumber(params.dealer_price)
                ? params.dealer_price
                : params.price_no_margin || params.price;
            this.dealer_price_before_discount = Common.isNumber(params.dealer_price_before_discount)
                ? params.dealer_price_before_discount
                : this.dealer_price + discounts.dealer_discount;
        } else {
            this.dealer_price_before_discount = Common.isNumber(params.dealer_price_before_discount)
                ? params.dealer_price_before_discount
                : params.price_no_margin || params.price;
            this.dealer_price = Common.isNumber(params.dealer_price)
                ? params.dealer_price
                : this.dealer_price_before_discount - discounts.dealer_discount;
        }

        if (this.confType === 'additional' && !params.recalculateClientDiscount) {
            this.client_price = Common.isNumber(params.client_price)
                ? params.client_price
                : params.price;
            this.client_price_before_discount = Common.isNumber(params.client_price_before_discount)
                ? params.client_price_before_discount
                : this.client_price + discounts.client_discount;
        } else if (this.confType === 'other') {
            this.client_price_before_discount = Common.isNumber(params.client_price_before_discount)
                ? params.client_price_before_discount
                : this.dealer_price_before_discount * (1 + dealerMargin / 100);
            this.client_price = Common.isNumber(params.client_price)
                ? params.client_price
                : this.client_price_before_discount - discounts.client_discount;
        } else {
            this.client_price_before_discount = Common.isNumber(params.client_price_before_discount)
                ? params.client_price_before_discount
                : params.price;
            this.client_price = Common.isNumber(params.client_price)
                ? params.client_price
                : this.client_price_before_discount - discounts.client_discount;
        }

        this.description = params.description || '';
        this.translated_description = params.translated_description || '';
        this.technique_description = params.technique_description || '';
        this.weight = params.weight || params.configuration.Weight || 0;
        this.file = params.file || null;
        this.image = params.image || null;
        this.created = params.created || moment().format('YYYY-MM-DD HH:mm:ss');
        this.modified_tmp = moment().format('YYYY-MM-DD HH:mm:ss');
        this.standard = angular.isDefined(params.standard) ? params.standard : true;

        this.name = params.name || params.configuration.Name;
        this.quantity = params.quantity || params.configuration.Quantity || 0;
        this.groupCode = params.groupCode;
        this.custom_title = params.custom_title || '';
        this.synced = false;
        this.valuated_price = params.valuated_price || null;
        this.materials_cost = params.materials_cost || null;
        this.points = params.points || null;
        this.varnished_points = params.varnished_points || null;
        this.details = params.details;
        this.coupled_position_id = params.coupled_position_id;

        const { area, glazingArea, circuit, size } = PositionService.getDimensions(this, IccConfig);

        this.area = area;
        this.glazing_area = glazingArea;
        this.circuit = circuit;
        this.size = size;
    }

    /**
     * Funkcja sprawdzająca czy można modifikować pozycje
     * @param  {string} offerId ID oferty
     * @return {object}         Promise
     */
    function checkIfCanModifyPositions(offer) {
        const order = !!~~offer.order;
        if (
            ($rootScope.user.access == 'producent'
                && ((!order && [0, 1, 3, 5, 7].indexOf(Number(offer.status)) > -1)
                    || (order && [2, 5, 10].indexOf(Number(offer.status)) > -1)))
            || ($rootScope.user.access == 'technolog'
                && !order
                && [6].indexOf(Number(offer.status)) > -1)
            || (($rootScope.user.access == 'dealer' || $rootScope.user.access == 'dealerh')
                && ((!order
                    && [0, 1, 3].indexOf(Number(offer.status)) > -1
                    && !offer.client_offer
                    && (IccConfig.Offer.B2C.active
                        || (!IccConfig.Offer.B2C.active
                            && [0, 1].indexOf(Number(offer.dealer_status)) > -1)))
                    || (order && [10].indexOf(Number(offer.status)) > -1)))
            || ($rootScope.user.access == 'klient'
                || (!$rootScope.user.access && (!order && [99].indexOf(Number(offer.status)) > -1)))
        ) {
            return true;
        } else {
            $rootScope.showInfo(
                $filter('translate')('OFFER|Wybranej oferty nie można w tym momencie edytować.')
                    + ' '
                    + $filter('translate')('OFFER|Wybierz inną ofertę lub utwórz nową.')
            );
            $rootScope.loader = false;
            return false;
        }
    }

    /**
     * Dodawanie nowej pozycji
     * @memberof PositionsFactory
     * @param {object} [params] Objekt ozycji
     * @returns {object}        Promise z dodan pozycj
     */
    function add(params) {
        // czy doliczać koszty transportu - konfiguracja
        // IccConfig.Offer.transportCostType == 'old-style-factory-base-divided'

        return new Promise((resolve, reject) => {
            var EditPosition = DatabaseManager.get('Position');

            var parsedSequence = Core.parseJson(params.offer.sequence);
            var emptyOffer = Object.keys(parsedSequence).length === 0 ? true : false;

            if (checkIfCanModifyPositions(params.offer)) {
                const groupCode = OfferGroupCodeService.generateGroupCode(params, IccConfig);
                var newPosition = new Position(angular.extend(params, { groupCode }));
                EditPosition.create(newPosition).then(function afterAddPosition(res) {
                    var posObj = {
                        id: res.tmp_id || res.id,
                        groupCode,
                    };

                    OfferSummaryService.autoUpdateOffer(
                        params.dealer_offer_id || params.offer._id || params.offer.tmp_id,
                        posObj,
                        'add',
                        undefined,
                        ~~params.offer.order
                    ).then(function afterAutoUpdateOffer(offer) {
                        addColorCostPosition(
                            params.dealer_offer_id || params.offer._id || params.offer.tmp_id
                        ).then(() => {
                            // dodawanie kosztów transportu dealera jako pozycja niestandardowa
                            if (
                                IccConfig.Offer.transportCostType
                                    == 'old-style-factory-base-divided'
                                && emptyOffer
                                && angular.isObject($rootScope.user.dealer)
                                && ($rootScope.user.dealer.transport_cost_base_dealer > 0
                                    || $rootScope.user.dealer.transport_cost_factory_dealer > 0)
                            ) {
                                factory
                                    .add({
                                        configuration: {
                                            type: 'transport_cost',
                                            Quantity: 1,
                                            Name: $filter('translate')('OFFER|Koszt transportu'),
                                        },
                                        details: {
                                            type: 'transport_cost',
                                            quantity: 1,
                                            name: $filter('translate')('OFFER|Koszt transportu'),
                                        },
                                        offer,
                                        standard: true,
                                        price:
                                            $rootScope.user.dealer.transport_cost_base_dealer * 1
                                            + $rootScope.user.dealer.transport_cost_factory_dealer
                                                * 1,
                                    })
                                    .then(() => resolve(angular.extend(res, { groupCode })));
                            } else if (
                                IccConfig.Offer.calculatedDealerTransportCost
                                && emptyOffer
                                && angular.isObject($rootScope.user.dealer)
                                && angular.isDefined($rootScope.user.dealer.add_transport_cost)
                                && $rootScope.user.dealer.add_transport_cost
                            ) {
                                ConfiguratorsDataService.dataAsync().then(function afterPricesGet(
                                    prices
                                ) {
                                    var market = prices.markets[$rootScope.user.market];
                                    var transportCost =
                                        (angular.isObject($rootScope.user.dealer)
                                            ? $rootScope.user.dealer.distance
                                            : 0)
                                        * market.price_for_one_stand_per_km
                                        * market.factor_for_transport_cost
                                        * 1;
                                    if (transportCost) {
                                        factory
                                            .add({
                                                configuration: {
                                                    type: 'transport_cost',
                                                    Quantity: 1,
                                                    Name: $filter('translate')(
                                                        'OFFER|Koszt transportu'
                                                    ),
                                                },
                                                details: {
                                                    type: 'transport_cost',
                                                    quantity: 1,
                                                    name: $filter('translate')(
                                                        'OFFER|Koszt transportu'
                                                    ),
                                                },
                                                offer,
                                                standard: true,
                                                price: transportCost,
                                            })
                                            .then(() =>
                                                resolve(angular.extend(res, { groupCode }))
                                            );
                                    } else {
                                        resolve(angular.extend(res, { groupCode }));
                                    }
                                });
                            } else {
                                resolve(angular.extend(res, { groupCode }));
                            }
                        });
                    });
                });
            } else {
                reject();
            }
        });
    }

    function addTransportCost(offer, dealer) {
        var deferred = $q.defer();
        // dodawanie kosztów transportu dealera jako pozycja niestandardowa
        if (
            IccConfig.Offer.transportCostType == 'old-style-factory-base-divided'
            && angular.isObject(dealer)
            && (dealer.transport_cost_base_dealer > 0 || dealer.transport_cost_factory_dealer > 0)
        ) {
            factory
                .add({
                    configuration: {
                        type: 'transport_cost',
                        Quantity: 1,
                        Name: $filter('translate')('OFFER|Koszt transportu'),
                    },
                    details: {
                        type: 'transport_cost',
                        quantity: 1,
                        name: $filter('translate')('OFFER|Koszt transportu'),
                    },
                    offer,
                    standard: true,
                    price:
                        dealer.transport_cost_base_dealer * 1
                        + dealer.transport_cost_factory_dealer * 1,
                })
                .then(() => deferred.resolve(offer));
        } else if (
            IccConfig.Offer.calculatedDealerTransportCost
            && angular.isObject(dealer)
            && angular.isDefined(dealer.add_transport_cost)
            && dealer.add_transport_cost
        ) {
            ConfiguratorsDataService.dataAsync().then(function afterPricesGet(prices) {
                var market = prices.markets[$rootScope.user.market];
                var transportCost =
                    (angular.isObject(dealer) ? dealer.distance : 0)
                    * market.price_for_one_stand_per_km
                    * market.factor_for_transport_cost
                    * 1;
                if (transportCost) {
                    factory
                        .add({
                            configuration: {
                                type: 'transport_cost',
                                Quantity: 1,
                                Name: $filter('translate')('OFFER|Koszt transportu'),
                            },
                            details: {
                                type: 'transport_cost',
                                quantity: 1,
                                name: $filter('translate')('OFFER|Koszt transportu'),
                            },
                            offer,
                            standard: true,
                            price: transportCost,
                        })
                        .then(() => deferred.resolve(offer));
                } else {
                    deferred.resolve(offer);
                }
            });
        } else {
            deferred.resolve(offer);
        }
        return deferred.promise;
    }

    /**
     * Edycja pozycji
     * @memberof PositionsFactory
     * @param  {string}   _id      Id pozycje edytowanej
     * @param  {object}   object   Nowe dane edytowanej pozycja
     * @param  {object}   offer    Oferta
     * @param  {Function} callback Fukncja wywołana po edycji
     * @return {object}            Promise z now pozycj
     */
    function update(_id, object, offer, callback) {
        return new Promise((resolve, reject) => {
            if (checkIfCanModifyPositions(offer)) {
                const groupCode = OfferGroupCodeService.generateGroupCode(object, IccConfig);

                DatabaseManager.get('Position')
                    .update(stringPositionValues(object, groupCode), {
                        internalId: _id || object.tmp_id,
                        machine,
                    })
                    .then(function afterUpdatePosition(res) {
                        var posObj = {
                            id: res.tmp_id || res.id,
                            groupCode,
                        };
                        var newPosition = new Position(
                            angular.extend(object, {
                                groupCode,
                                offer,
                            })
                        );
                        ManyPositionsService.updatePosition(newPosition);
                        OfferSummaryService.autoUpdateOffer(
                            object.dealer_offer_id || object.offer._id,
                            posObj,
                            'update'
                        ).then(() => {
                            addColorCostPosition(object.dealer_offer_id || object.offer._id).then(
                                () => resolve(res, callback)
                            );
                        });
                    });
            } else {
                reject();
            }
        });
    }

    /**
     * Edycja pozycji po edycji w konfiguratorze
     * @memberof PositionsFactory
     * @param  {string} _id    Id pozycji
     * @param  {object} object edytowana pozycja
     * @return {object}        Promise z edytowana pozycji
     */
    function updateEdit(_id, object) {
        return new Promise((resolve, reject) => {
            if (checkIfCanModifyPositions(object.offer)) {
                const groupCode = OfferGroupCodeService.generateGroupCode(object, IccConfig);
                DatabaseManager.get('Position')
                    .get(_id)
                    .then(function afterGetPosition(otherDoc) {
                        object._rev = otherDoc._rev;
                        delete otherDoc.dealer_price_before_discount;
                        delete otherDoc.dealer_price;
                        delete otherDoc.dealer_discount;
                        delete otherDoc.client_price_before_discount;
                        delete otherDoc.client_price;
                        delete otherDoc.client_discount;
                        delete otherDoc.weight;
                        delete otherDoc.weight_positions_quantity;
                        delete otherDoc.configuration;
                        delete otherDoc.confType;
                        delete otherDoc.size;
                        delete otherDoc.name;
                        delete otherDoc.quantity;
                        delete otherDoc.circuit;
                        delete otherDoc.area;
                        angular.extend(otherDoc, object);
                        object = otherDoc;

                        var newPosition = new Position(
                            angular.extend(object, {
                                groupCode,
                                configuration: object.configuration,
                            })
                        );

                        DatabaseManager.get('Position')
                            .update(newPosition, { internalId: _id, machine })
                            .then(function afterPositionUpdate(res) {
                                var posObj = {
                                    id: res.tmp_id,
                                    groupCode,
                                };
                                ManyPositionsService.updatePosition(newPosition);
                                OfferSummaryService.autoUpdateOffer(
                                    object.dealer_offer_id || object.offer._id,
                                    posObj,
                                    'update'
                                ).then(() => {
                                    addColorCostPosition(
                                        object.dealer_offer_id || object.offer._id
                                    ).then(() => resolve(res));
                                });
                            });
                    });
            } else {
                reject();
            }
        });
    }

    /**
     * Aktualizuje pozycje o przezanych ids danymi.
     * @param {string[]} positionsIds Id pozycji
     * @param {any} changes Zmiany
     * @param {any} offer Oferta
     */
    async function updateMany(positionsIds = [], changes = {}, offer) {
        const positions = await ManyPositionsService.getMany(positionsIds);
        const updatedPositions = positions.map(position => {
            const newPosition = Object.assign(position.doc, changes);
            return newPosition;
        });
        await DatabaseManager.get('Position').createMany(updatedPositions);
        await OfferSummaryService.autoUpdateOffer(offer.tmp_id, null, null, offer.sequence);
        await addColorCostPosition(offer.tmp_id);
    }

    /**
     * Usunięcie pozycji
     * @param  {string} _id    Id usuwanej pozycji
     * @param  {object} object Usuwane pozycja
     * @param  {object} offer Oferta
     * @return {object}        Promise z usunieta pozycja
     */
    function remove(_id, object, offer) {
        return new Promise((resolve, reject) => {
            if (checkIfCanModifyPositions(offer)) {
                DatabaseManager.get('Position')
                    .remove(object)
                    .then(function removeSucces(res) {
                        var posObj = { id: object.tmp_id };
                        OfferSummaryService.autoUpdateOffer(
                            object.dealer_offer_id,
                            posObj,
                            'remove'
                        ).then(() => {
                            addColorCostPosition(object.dealer_offer_id).then(() => resolve(res));
                        });
                    });
            } else {
                reject();
            }
        });
    }

    /**
     * Usuwa pozycje o przezanych ids.
     * @param {string[]} positionsIds Id pozycji
     * @param {any} changes Zmiany
     * @param {any} offer Oferta
     */
    async function removeMany(positionsIds = [], offer) {
        const positions = await ManyPositionsService.getMany(positionsIds);
        await DatabaseManager.get('Position').removeMany(positions.map(p => p.doc));
        const sequence = OfferSequenceService.updateOfferSequence(offer.sequence, positions.map(p => ({id: p.doc.tmp_id})), 'remove', IccConfig)
        await OfferSummaryService.autoUpdateOffer(offer.tmp_id, null, null, sequence);
        await addColorCostPosition(offer.tmp_id);
    }

    /**
     * Lista wszytskich pozycji
     * @return {object} Promise ze wszystkimi pozycjami
     */
    function list() {
        var deferred = $q.defer();
        DatabaseManager.get('Position')
            .getAll()
            .then(positions => {
                deferred.resolve(positions);
            });
        return deferred.promise;
    }

    /**
     * Pobranie jednej pozycji
     * @param  {string} _id Id konkretnrj pozycji
     * @return {object}     Promise z jedna pozycja
     */
    function get(_id) {
        var deferred = $q.defer();
        DatabaseManager.get('Position')
            .get(_id)
            .then(
                function getSuccess(position) {
                    deferred.resolve(PositionService.parsePositionValues(position));
                },
                function getError(err) {
                    deferred.reject(err);
                }
            );
        return deferred.promise;
    }

    /**
     * Parsuje wartości pozycji przed zwroceniem
     * @param  {object} offer Obiekt pozycji
     * @return {object}       Obiekt sparsowany
     */
    function stringPositionValues(position, groupCode) {
        return angular.extend(position, {
            groupCode,
            configuration: position.configuration,
            group_discounts: position.group_discounts,
            valuated_price:
                !position.standard && !position.valuated_price ? null : position.valuated_price,
            dealer_price:
                !position.standard && !position.valuated_price ? null : position.dealer_price,
            dealer_price_before_discount:
                !position.standard && !position.valuated_price
                    ? null
                    : position.dealer_price_before_discount,
            client_price:
                !position.standard && !position.valuated_price ? null : position.client_price,
            client_price_before_discount:
                !position.standard && !position.valuated_price
                    ? null
                    : position.client_price_before_discount,
        });
    }

    /**
     * Usuwanie wszystkich pozycji
     * @param  {object} offer Oferta
     * @return {object}       Promise
     */
    function removeAllPositions(offer) {
        return new Promise((resolve, reject) => {
            var searched = OfferSequenceService.keysFromSequence(offer.sequence);

            if (checkIfCanModifyPositions(offer)) {
                DatabaseManager.get('Position')
                    .getMany(searched)
                    .then(function afterPositionsGet(positions) {
                        DatabaseManager.get('Position')
                            .removeMany(positions.map(e => e.doc))
                            .then(() =>
                                OfferSummaryService.autoUpdateOffer(offer._id, null, null, []).then(
                                    () => resolve()
                                )
                            );
                    });
            } else {
                reject();
            }
        });
    }

    /**
     * Kopiowanie wielu pozycji
     * @param  {object} source       Oferta źrdłowa
     * @param  {object} destination  Oferta docelowa
     * @param  {object} sequence     Objekt z pogrupowanymi pozycjami
     * @param  {array}  skip         Pozycje do pominięcia przy kopiowaniu
     * @param  {bool}   recalcPrices Przeliczanie cen przy kopiowaniu
     * @return {object}              Promise
     */
    function multiCopy(source, destination, sequence, skip, recalcPrices) {
        var searched = OfferSequenceService.keysFromSequence(sequence);
        var deferred = $q.defer();
        var relations = {};
        skip = angular.isArray(skip) ? skip : [];

        var EditPosition = DatabaseManager.get('Position');
        const dataRequiredToUpdate = {
            profiles: ConfiguratorsDataService.data.profiles,
            windowAccessories: ConfiguratorsDataService.data.windowAccessories,
            windowAccessoriesCategories:
                ConfiguratorsDataService.data.windowAccessoriesCategories,
            profilesIdMap: ConfiguratorsDataService.data.profilesIdMap,
            garageGuides: ConfiguratorsDataService.data.garageGuides,
            rollerShutterProfilesMap:
                ConfiguratorsDataService.data.rollerShutterProfilesMap,
            rollerShutterColorGroupsMap:
                ConfiguratorsDataService.data.rollerShutterColorGroupsMap,
            rollerShutterColorsMap:
                ConfiguratorsDataService.data.rollerShutterColorsMap,
        };

        EditPosition.getMany(searched).then(positions => {
            positions = positions.filter(
                position =>
                    Common.isUndefined(position.doc.coupled_position_id)
                    || position.doc.coupled_position_id === null
                    || searched.includes(position.doc.coupled_position_id)
            );
            positions = positions.sort(
                (a, b) => searched.indexOf(a.doc.tmp_id) - searched.indexOf(b.doc.tmp_id)
            );
            var promises = positions
                .filter(e => e.doc && skip.indexOf(e.doc.confType) == -1)
                .map(pos => {
                    var deferCreatePosition = $q.defer();
                    var originalId = pos.doc.tmp_id || pos.doc._id;
                    var params = angular.extend(pos.doc, {
                        dealer_offer_id: destination,
                        id: null,
                        tmp_id: null,
                        configuration: pos.doc.configuration,
                        details: ['additional', 'other'].indexOf(pos.doc.confType) > -1
                            ? Core.parseJson(pos.doc.details)
                            : ConfigurationsService.createSimpleConfiguration(
                                Core.parseJson(pos.doc.details),
                                dataRequiredToUpdate
                            ),
                    });

                    const groupCode = OfferGroupCodeService.generateGroupCode(params, IccConfig);
                    if (
                        (recalcPrices || !params.details)
                        && [
                            'winter_garden',
                            'transport_cost',
                            'additional',
                            'other',
                            'glazing',
                            'custom',
                            'colors_cost',
                            'colors_waste_cost',
                        ].indexOf(params.confType) < 0
                    ) {
                        PriceService.count(params.configuration);
                        params.details = ConfigurationsService.createSimpleConfiguration(
                            Core.parseJson(params.configuration)
                        );
                        params.client_price_before_discount = null;
                        params.client_price = null;
                        params.dealer_price_before_discount = null;
                        params.dealer_price = null;
                        params.group_discounts = null;
                        params.price = params.configuration.Price;
                        params.price_no_margin = params.configuration.PriceNoMargin;
                        params.standard =
                            !isNaN(params.configuration.Price)
                            && params.configuration.Price !== null
                            && Core.isEmpty(params.configuration.NoPriceElems)
                                ? true
                                : false;
                    }
                    var newPosition = new Position(
                        angular.extend(params, {
                            groupCode,
                            offer: source,
                        })
                    );
                    relations[originalId] = newPosition.id;

                    deferCreatePosition.resolve(newPosition);

                    return deferCreatePosition.promise;
                });

            return $q
                .all(promises)
                .then(results => {
                    results
                        .filter(position => position.confType === 'coupled_window')
                        .forEach(position => {
                            position.details.windows = position.details.windows.map(window => {
                                window.positionId = relations[window.positionId];
                                return window;
                            });
                            position.details.rollerShutters = position.details.rollerShutters.map(
                                rollerShutter => {
                                    rollerShutter.positionId = relations[rollerShutter.positionId];
                                    return rollerShutter;
                                }
                            );
                            position.details.couplings = position.details.couplings.map(
                                coupling => {
                                    coupling.framesId.forEach(rel => {
                                        rel.positionId = relations[rel.positionId];
                                    });
                                    coupling.otherFramesId.forEach(rel => {
                                        rel.positionId = relations[rel.positionId];
                                    });
                                    return coupling;
                                }
                            );
                            position.details.sideProfiles = position.details.sideProfiles.map(
                                sideprofile => {
                                    sideprofile.framesId.forEach(rel => {
                                        rel.positionId = relations[rel.positionId];
                                    });
                                    return sideprofile;
                                }
                            );
                            position.details.windowSills = position.details.windowSills.map(
                                sill => {
                                    sill.framesId.forEach(rel => {
                                        rel.positionId = relations[rel.positionId];
                                    });
                                    return sill;
                                }
                            );

                            position.configuration.windows = position.configuration.windows.map(window => {
                                window.positionId = relations[window.positionId];
                                return window;
                            });
                            position.configuration.rollerShutters = position.configuration.rollerShutters.map(
                                rollerShutter => {
                                    rollerShutter.positionId = relations[rollerShutter.positionId];
                                    return rollerShutter;
                                }
                            );
                            position.configuration.couplings = position.configuration.couplings.map(
                                coupling => {
                                    coupling.framesId.forEach(rel => {
                                        rel.positionId = relations[rel.positionId];
                                    });
                                    coupling.otherFramesId.forEach(rel => {
                                        rel.positionId = relations[rel.positionId];
                                    });
                                    return coupling;
                                }
                            );
                            position.configuration.sideProfiles = position.configuration.sideProfiles.map(
                                sideprofile => {
                                    sideprofile.framesId.forEach(rel => {
                                        rel.positionId = relations[rel.positionId];
                                    });
                                    return sideprofile;
                                }
                            );
                            position.configuration.windowSills = position.configuration.windowSills.map(
                                sill => {
                                    sill.framesId.forEach(rel => {
                                        rel.positionId = relations[rel.positionId];
                                    });
                                    return sill;
                                }
                            );
                        });

                    results
                        .filter(position => position.coupled_position_id)
                        .forEach(position => {
                            position.coupled_position_id = relations[position.coupled_position_id];
                        });
                    return Promise.resolve(results);
                })
                .then(results =>
                    $q.all([
                        results.reduce(
                            (promise, pos) =>
                                promise.then(prevSequence => {
                                    var def = $q.defer();

                                    const newSequence = OfferSequenceService.updateOfferSequence(
                                        prevSequence,
                                        [{
                                            id: pos.tmp_id,
                                            groupCode: pos.groupCode,
                                        }],
                                        'add',
                                        IccConfig
                                    );
                                    def.resolve(newSequence);

                                    return def.promise;
                                }),
                            $q.resolve([])
                        ),

                        $q.resolve(results),
                    ])
                )
                .then(results => {
                    DatabaseManager.get('Position')
                        .createMany(results[1])
                        .then(() =>
                            OfferSummaryService.autoUpdateOffer(destination, null, null, results[0])
                        )
                        .then(() => {
                            deferred.resolve(relations);
                        });
                });
        });

        return deferred.promise;
    }

    /**
     * Kopiowanie wielu pozycji przy edycji grupowej
     * @param  {object} source      Pozycja źrdłowa
     * @param  {object} destination Pozycja docelowa
     * @param  {array}  positions   Lista pozycji
     * @return {object}             Promise
     */
    function copyPositionsFromScope(source, destination, searched) {
        var EditPosition = DatabaseManager.get('Position');

        let sourcePositions;
        const newPositions = [];
        return EditPosition.getMany(searched).then(positions => {
            sourcePositions = positions;
            return positions.reduce((promise, position) => promise.then((newPosition) => {
                if (newPosition) {
                    newPositions.push(newPosition);
                }
                return add(angular.extend(position.doc, {
                    dealer_offer_id: destination,
                    id: null,
                    tmp_id: null,
                    configuration: position.doc.configuration,
                    details: position.doc.details,
                    offer: source,
                    coupled_position_id: null
                }));
            }), Promise.resolve())
        }).then(newPosition => {
            if (newPosition) {
                newPositions.push(newPosition);
            }
            return sourcePositions.reduce((map, position, i) => {
                map[position.id] = newPositions[i].tmp_id;
                return map;
            }, {});
        });
    }

    /**
     * Przeliczanie cen w po zmianie rabatu
     * @param  {object} pos   Pozycja oferty
     * @param  {object} offer Oferta
     * @return {object}       Promise
     */
    function updatePrices(pos, offer) {
        var deferred = $q.defer();

        pos = recalculatePrices(pos);

        update(pos._id, pos, offer).then(function afterUpdatePrices() {
            deferred.resolve();
        });

        return deferred.promise;
    }

    /**
     * Przeliczanie cen pozycji
     * @param  {object} pos Pozycja
     * @return {object}     Zaktualizowana pozycja
     */
    function recalculatePrices(pos) {
        let clientDiscount = 0;
        let dealerDiscount = 0;
        const discount = [];

        for (let i = 0; i < pos.group_discounts.length; i++) {
            const tbClientDealer = {};

            if (!angular.isUndefined(pos.group_discounts[i].client)) {
                let clientPrice = pos.group_discounts[i].client.price;
                let clientValue = Core.roundPrice(
                    (1 / 100)
                        * pos.group_discounts[i].client.discount
                        * pos.group_discounts[i].client.price
                );
                if (pos.confType === 'additional') {
                    const price =
                        pos.group_discounts[i].client.price - pos.group_discounts[i].client.value;
                    clientPrice = Core.roundPrice(
                        price / ((100 - pos.group_discounts[i].client.discount) / 100)
                    );
                    clientValue = clientPrice - price;
                }
                tbClientDealer.client = {
                    price: clientPrice,
                    type: pos.group_discounts[i].client.type,
                    value: clientValue,
                    discount: pos.group_discounts[i].client.discount * 1,
                    name: pos.group_discounts[i].client.name,
                };
                clientDiscount += tbClientDealer.client.value;
            }

            if (!angular.isUndefined(pos.group_discounts[i].dealer)) {
                let dealerPrice = pos.group_discounts[i].dealer.price;
                let dealerValue = Core.round(
                    (1 / 100)
                        * pos.group_discounts[i].dealer.discount
                        * pos.group_discounts[i].dealer.price,
                    10000
                );
                if (pos.confType === 'other') {
                    const price =
                        pos.group_discounts[i].dealer.price - pos.group_discounts[i].dealer.value;
                    dealerPrice = Core.round(
                        price / ((100 - pos.group_discounts[i].dealer.discount) / 100)
                    );
                    dealerValue = dealerPrice - price;
                }
                tbClientDealer.dealer = {
                    price: dealerPrice,
                    type: pos.group_discounts[i].dealer.type,
                    value: dealerValue,
                    discount: pos.group_discounts[i].dealer.discount * 1,
                    name: pos.group_discounts[i].dealer.name,
                };
                dealerDiscount += tbClientDealer.dealer.value;
            }
            discount.push(tbClientDealer);
        }
        if (pos.confType === 'additional') {
            pos = angular.extend(pos, {
                client_price_before_discount: pos.client_price + clientDiscount,
                dealer_price_before_discount: pos.dealer_price + dealerDiscount,
                group_discounts: discount,
            });
        } else if (pos.confType === 'other') {
            pos = angular.extend(pos, {
                client_price: pos.client_price_before_discount - clientDiscount,
                dealer_price_before_discount: pos.dealer_price + dealerDiscount,
                group_discounts: discount,
            });
        } else {
            pos = angular.extend(pos, {
                client_price: pos.client_price_before_discount - clientDiscount,
                dealer_price: pos.dealer_price_before_discount - dealerDiscount,
                group_discounts: discount,
            });
        }

        return pos;
    }

    /**
     * Wycena pozycji
     * @param  {object} pos   Pozycja
     * @param  {object} price Cena
     * @return {object}       Zaktualizowana pozycja
     */
    function valuate(
        pos,
        currency,
        marketCode = null,
        { price = null, discount = 0, materialsCost = 0, points = 0, varnishedPoints = 0 },
        offer
    ) {
        var deferred = $q.defer();
        ConfiguratorsDataService.dataAsync().then(function afterGetPrices(prices) {
            const market = prices.markets[marketCode];
            if (price === null) {
                materialsCost = Core.roundPrice(
                    parseFloat(materialsCost) * parseFloat(currency.value)
                );
                price = Core.roundPrice(
                    (Core.num(market.point_value) * points
                        + Core.num(market.varnished_point_value) * varnishedPoints
                        + materialsCost)
                        / Core.num(market.factor_for_material_valuation)
                );
                pos.points = points;
                pos.materials_cost = materialsCost;
                pos.varnished_points = varnishedPoints;
            } else {
                price = Core.roundPrice(parseFloat(price) * parseFloat(currency.value));
            }
            if (discount > 0) {
                pos.dealer_price_before_discount = Core.roundPrice(price / (1 - discount / 100));
                if (!offer.dealer_id) {
                    pos.client_price_before_discount = pos.dealer_price_before_discount;
                }
            } else {
                pos.dealer_price_before_discount = price;
                if (!offer.dealer_id) {
                    pos.client_price_before_discount = price;
                }
            }
            const dealerMargin = offer ? offer.dealer_margin * 1 : 0;
            if (offer.dealer_id) {
                pos.client_price_before_discount =
                    pos.dealer_price_before_discount * (1 + dealerMargin / 100);
            }
            var groupDiscount = Core.copy(
                pos.group_discounts.find(g => (g.client || g.dealer).type.includes('system'))
            ) || {
                dealer: {},
                client: {},
            };
            groupDiscount.dealer.price = pos.dealer_price_before_discount;
            groupDiscount.dealer.discount = discount;
            if (!offer.dealer_id) {
                groupDiscount.client.price = pos.client_price_before_discount;
                groupDiscount.client.discount = discount;
            } else {
                groupDiscount.client.price =
                    pos.dealer_price_before_discount * (1 + dealerMargin / 100);
            }
            pos.group_discounts = [];
            pos.group_discounts.push(groupDiscount);
            if (pos.details) {
                pos.details.discountGroups = null;
                pos.details.discountGroupsNoMargin = null;
            }
            pos.valuated_price = price;
            pos = recalculatePrices(pos);
            deferred.resolve(pos);
        });
        return deferred.promise;
    }

    /**
     * Aktualizacja cen wielu pozycji
     * @param  {tring} _id  Id pozycji
     * @param  {object} doc Oferta
     * @return {object}     Promise
     */
    function multiUpdatePrices(_id, resetDiscounts = true) {
        const deferred = $q.defer();
        let EditPosition;
        let offer;

        OffersService.get(_id)
            .then(res => {
                offer = res;
                EditPosition = DatabaseManager.get('Position');
                const searched = OfferSequenceService.keysFromSequence(offer.sequence);

                return ManyPositionsService.listById(searched, offer.tmp_id);
            })
            .then(res => {
                const def = $q.defer();
                const positions = [];

                for (let i = 0; i < res.pos.length; i++) {
                    if (
                        !res.pos[i].doc.valuated_price
                        && [
                            'winter_garden',
                            'transport_cost',
                            'additional',
                            'other',
                            'glazing',
                            'custom',
                            'colors_cost',
                            'colors_waste_cost',
                        ].indexOf(res.pos[i].doc.confType) < 0
                    ) {
                        if (!angular.isObject(res.pos[i].doc.configuration)) {
                            res.pos[i].doc.configuration = Core.parseJson(
                                res.pos[i].doc.configuration
                            );
                        }
                        PriceService.count(res.pos[i].doc.configuration, offer);
                        res.pos[i].doc.details = ConfigurationsService.createSimpleConfiguration(
                            res.pos[i].doc.configuration
                        );
                        res.pos[i].doc.client_price_before_discount = null;
                        res.pos[i].doc.client_price = null;
                        res.pos[i].doc.dealer_price_before_discount = null;
                        res.pos[i].doc.dealer_price = null;
                        if (resetDiscounts) {
                            res.pos[i].doc.group_discounts = null;
                        }
                        res.pos[i].doc.price = res.pos[i].doc.configuration.Price;
                        res.pos[i].doc.price_no_margin = res.pos[i].doc.configuration.PriceNoMargin;
                        res.pos[i].doc.standard =
                            !isNaN(res.pos[i].doc.configuration.Price)
                            && res.pos[i].doc.configuration.Price !== null
                            && Core.isEmpty(res.pos[i].doc.configuration.NoPriceElems)
                                ? true
                                : false;
                    }
                    if (res.pos[i].doc.valuated_price) {
                        var groupDiscount = Core.copy(res.pos[i].doc.group_discounts.find(g => (g.client || g.dealer).type.includes('system'))) || {
                            dealer: {},
                            client: {}
                        };
                        const discount = groupDiscount.dealer.discount || 0;
                        if (discount > 0) {
                            res.pos[i].doc.dealer_price_before_discount = Core.roundPrice(res.pos[i].doc.valuated_price / (1 - (discount / 100)));
                            if (!offer.dealer_id) {
                                res.pos[i].doc.client_price_before_discount = res.pos[i].doc.dealer_price_before_discount;
                            }
                        } else {
                            res.pos[i].doc.dealer_price_before_discount = res.pos[i].doc.valuated_price;
                            if (!offer.dealer_id) {
                                res.pos[i].doc.client_price_before_discount = res.pos[i].doc.valuated_price;
                            }
                        }
                        const dealerMargin = offer ? offer.dealer_margin * 1 : 0;
                        if (offer.dealer_id) {
                            res.pos[i].doc.client_price_before_discount = res.pos[i].doc.dealer_price_before_discount * (1 + dealerMargin / 100);
                        }

                        groupDiscount.dealer.price = res.pos[i].doc.dealer_price_before_discount;
                        groupDiscount.dealer.discount = discount;
                        if (!offer.dealer_id) {
                            groupDiscount.client.price = res.pos[i].doc.client_price_before_discount;
                            groupDiscount.client.discount = discount;
                        } else {
                            groupDiscount.client.price = res.pos[i].doc.dealer_price_before_discount * (1 + dealerMargin / 100);
                        }
                        res.pos[i].doc.group_discounts = [];
                        res.pos[i].doc.group_discounts.push(groupDiscount);
                        if (res.pos[i].doc.details) {
                            res.pos[i].doc.details.discountGroups = null;
                            res.pos[i].doc.details.discountGroupsNoMargin = null;
                        }
                        res.pos[i].doc = recalculatePrices(res.pos[i].doc);
                    }
                    if (res.pos[i].doc.confType === 'other') {
                        if (resetDiscounts) {
                            res.pos[i].doc.group_discounts = null;
                        }
                        res.pos[i].doc.dealer_price_before_discount = null;
                        res.pos[i].doc.client_price_before_discount = null;
                        res.pos[i].doc.client_price = null;
                    }
                    var newPosition = new Position(angular.extend(res.pos[i].doc, { offer }));
                    newPosition._id = res.pos[i].doc._id;
                    newPosition._rev = res.pos[i].doc._rev;
                    angular.extend(res.pos[i].doc, newPosition);

                    positions.push(res.pos[i].doc);
                }
                def.resolve(positions);

                return def.promise;
            })
            .then(positions => EditPosition.createMany(positions))
            .then(() =>
                OfferSummaryService.autoUpdateOffer(offer.tmp_id, null, null, offer.sequence)
            )
            .then(() => addColorCostPosition(offer.tmp_id))
            .then(() => deferred.resolve());

        return deferred.promise;
    }

    /**
     * Funkcja sprawdza czy oferta wraz z pozycjami jest już zsynchronizowana
     * @param  {string} id Id oferty
     * @return {object}    Promise
     */
    function whenAllSynced(id, timer = 10) {
        var deferred = $q.defer();

        var syncTimeoutOrder = null;
        var syncTimeoutPosition = null;

        var EditOffer = DatabaseManager.get('Offer');

        syncTimeoutOrder = setInterval(() => {
            EditOffer.get(id)
                .then(offer => {
                    logger.log('Zamowienie zsynchronizowane: ', offer.synced, offer.tmp_id);

                    if (offer.synced === true) {
                        clearInterval(syncTimeoutOrder);
                        syncTimeoutOrder = null;
                        var searched = OfferSequenceService.keysFromSequence(offer.sequence);

                        if (searched.length) {
                            syncTimeoutPosition = setInterval(() => {
                                ManyPositionsService.listById(searched, offer.tmp_id).then(
                                    positions => {
                                        if (
                                            positions.pos.every(e => e.doc.synced)
                                            && positions.not_found.length === 0
                                        ) {
                                            logger.log(
                                                'Pozycje zsynchronizowane: ',
                                                positions.pos.map(e => [e.doc.synced, e.doc.tmp_id])
                                            );
                                            clearInterval(syncTimeoutPosition);
                                            syncTimeoutPosition = null;
                                            deferred.resolve();
                                        }
                                    }
                                );
                            }, 1000);
                        } else {
                            clearInterval(syncTimeoutOrder);
                            clearInterval(syncTimeoutPosition);
                            syncTimeoutOrder = null;
                            syncTimeoutPosition = null;
                            whenAllSynced(id, timer - 1).then(() => deferred.resolve());
                        }
                    }
                })
                .catch(err => {
                    clearInterval(syncTimeoutOrder);
                    syncTimeoutOrder = null;
                    whenAllSynced(id, timer - 1).then(() => deferred.resolve());
                });
        }, 1000);

        return deferred.promise;
    }

    /**
     * Przebudowanie struktury pozycji, kiedy w PouchDB nie istnieje pozycja a w ofercie
     */
    function fixSequence() {
        if (
            confirm(
                $filter('translate')('OFFER|Nie pobrano')
                    + ' '
                    + $rootScope.notFoundPositions.length
                    + ' '
                    + $filter('translate')('OFFER|pozycji.')
                    + ' '
                    + $filter('translate')('OFFER|Czy chcesz anulować ich pobieranie?')
                    + ' '
                    + $filter('translate')('OFFER|Ta operacja jest nieodwracalna!')
            )
        ) {
            var EditOffer = DatabaseManager.get('Offer');

            EditOffer.get($rootScope.offer_id).then(function afterGetOffer(offer) {
                offer.sequence = Core.parseJson(offer.sequence);
                for (var k = 0; k < offer.sequence.length; k++) {
                    for (var gCode in offer.sequence[k]) {
                        var len = offer.sequence[k][gCode].length;
                        for (var i = 0; i < len; i++) {
                            if (
                                $rootScope.notFoundPositions.indexOf(offer.sequence[k][gCode][i])
                                >= 0
                            ) {
                                offer.sequence[k][gCode].splice(i, 1);
                                if (offer.sequence[k][gCode].length === 0) {
                                    offer.sequence.splice(k, 1);
                                }
                            }
                        }
                    }
                }
                var sequence = offer.sequence;
                OffersService.update(
                    offer.tmp_id,
                    angular.extend(offer, {
                        currency: Core.stringJson(offer.currency),
                        sequence: Core.stringJson(offer.sequence),
                        key: Core.stringJson(offer.key),
                    })
                ).then(function afterUpdateOffer(res) {
                    OfferSummaryService.autoUpdateOffer(res.id, null, null, sequence).then(
                        function afterAutoUpdateOffer(newRes) {
                            $rootScope.showInfo(
                                $filter('translate')('OFFER|Oferta została zaktualizowana')
                            );
                            $location.url('/app/offers_view/' + newRes._id);
                            $rootScope.notFoundPositions = undefined;
                            $rootScope.offer_id = undefined;
                            $rootScope.order = undefined;
                        }
                    );
                });
            });
        }
    }

    /**
     * Funkcja przetwarza pozycje
     * @param  {array} positions Lista pozycji
     * @return {array}           Przetworzona lista
     */
    function parsePositions(positions, offer) {
        let ids = [];
        const showMistakeProductInfo = true;
        const isNonRectangularPosition = false;
        let newPositions = [];
        let indexPositions = 0;
        const synced = true;
        let transportCost = null;
        let transportCostPosition = null;
        let discounts = null;
        const hasNotStandardPosition = false;

        for (var i = 0; i < offer.sequence.length; i++) {
            for (var j in offer.sequence[i]) {
                ids = ids.concat(offer.sequence[i][j]);
            }
        }
        positions = positions.sort((a, b) => ids.indexOf(a.doc.tmp_id) - ids.indexOf(b.doc.tmp_id));
        newPositions = positions
            .filter(function parsePositionsfilter(e) {
                return e.doc.deleted != true;
            })
            .map(function parsePositionsMap(e) {
                ({
                    indexPositions,
                    transportCost,
                    transportCostPosition,
                    discounts,
                } = parsePosition(e, indexPositions, discounts));
                return e;
            });

        return {
            newPositions,
            showMistakeProductInfo,
            isNonRectangularPosition,
            transportCost,
            transportCostPosition,
            discounts,
            hasNotStandardPosition,
        };
    }

    function parsePosition(e, indexPositions, discounts) {
        let transportCost = 0;
        let transportCostPosition = null;
        if (!angular.isObject(e.doc.configuration)) {
            e.doc.configuration = Core.parseJson(e.doc.configuration);
        }
        if (!angular.isObject(e.doc.details)) {
            e.doc.details = Core.parseJson(e.doc.details);
        }
        // dodanie numeru kolejnego na liście
        if (
            ['transport_cost', 'colors_cost', 'colors_waste_cost', 'other', 'additional'].indexOf(
                e.doc.confType
            ) === -1
            && !e.doc.coupled_position_id
        ) {
            e.doc.listNumber = ++indexPositions;
        }
        // Koszt transportu
        if (e.doc.confType == 'transport_cost') {
            transportCost = e.doc.dealer_price;
            transportCostPosition = Core.copy(e.doc);
        }

        // Rabaty
        if (discounts === null) {
            discounts = {
                dealerDiscount: e.doc.group_discounts.length
                    ? e.doc.group_discounts[0].dealer.discount
                    : 0,
                clientDiscount: e.doc.group_discounts.length
                    ? e.doc.group_discounts[0].client.discount
                    : 0,
            };
        }

        e.doc.summary = PositionDetailedSummaryService.detailedSummary(e.doc, IccConfig);
        return { indexPositions, transportCost, transportCostPosition, discounts };
    }

    async function addColorCostPosition(offerId) {
        const EditPosition = DatabaseManager.get('Position');
        const offer = await OffersService.get(offerId);
        const positionsIds = OfferSequenceService.keysFromSequence(offer.sequence);
        const positionsData = await ManyPositionsService.listById(positionsIds, offerId);
        const positions = positionsData.pos;
        const colorPriceGroups = ColorsPositionService.groupPositionsByColors(positions);
        const colorWastePriceGroups = ColorsPositionService.groupPositionsByProfilesInColors(
            positions
        );
        const colorsCostPositions = positions.filter(
            position => position.doc && position.doc.confType === 'colors_cost'
        );
        const colorsWasteCostPositions = positions.filter(
            position => position.doc && position.doc.confType === 'colors_waste_cost'
        );

        let newSequence = offer.sequence;
        if (colorPriceGroups.length > 0) {
            if (colorsCostPositions.length > 0) {
                const colorsCostPosition = colorsCostPositions[0].doc;
                const overralPrice = colorPriceGroups.reduce(
                    (prev, group) => group.price + prev,
                    0
                );
                const overralDealerPrice = colorPriceGroups.reduce(
                    (prev, group) => group.dealerPrice + prev,
                    0
                );
                colorsCostPosition.dealer_price_before_discount = overralDealerPrice;
                colorsCostPosition.dealer_discount = offer.dealer_discount_producer;
                colorsCostPosition.dealer_price =
                    overralDealerPrice * (1 - offer.dealer_discount_producer / 100);
                colorsCostPosition.client_price_before_discount = overralPrice;
                colorsCostPosition.client_discount = offer.client_discount_position;
                colorsCostPosition.client_price =
                    overralPrice * (1 - offer.client_discount_position / 100);
                colorsCostPosition.configuration = {
                    type: 'colors_cost',
                    Quantity: 1,
                    Name: $filter('translate')('OFFER|Kolory niestandardowe'),
                    Colors: colorPriceGroups,
                };
                colorsCostPosition.details = {
                    type: 'colors_cost',
                    quantity: 1,
                    name: $filter('translate')('OFFER|Kolory niestandardowe'),
                    colors: colorPriceGroups,
                };

                await EditPosition.update(colorsCostPosition, {
                    internalId: colorsCostPosition.tmp_id,
                });
            } else {
                const params = {
                    configuration: {
                        type: 'colors_cost',
                        Quantity: 1,
                        Name: $filter('translate')('OFFER|Kolory niestandardowe'),
                        Colors: colorPriceGroups,
                    },
                    details: {
                        type: 'colors_cost',
                        quantity: 1,
                        name: $filter('translate')('OFFER|Kolory niestandardowe'),
                        colors: colorPriceGroups,
                    },
                    offer,
                    standard: true,
                    price:
                        colorPriceGroups.reduce((prev, group) => group.price + prev, 0),
                    dealer_price_before_discount:
                        colorPriceGroups.reduce((prev, group) => group.dealerPrice + prev, 0)
                };
                const groupCode = OfferGroupCodeService.generateGroupCode(params, IccConfig);
                const newPosition = new Position(angular.extend(params, { groupCode }));
                newPosition.group_discounts = '[]';
                newPosition.dealer_price = newPosition.dealer_price_before_discount;
                newSequence = OfferSequenceService.updateOfferSequence(
                    newSequence,
                    [{
                        id: newPosition.tmp_id,
                        groupCode: newPosition.groupCode,
                    }],
                    'add',
                    IccConfig
                );
                await DatabaseManager.get('Position').create(newPosition);
            }
        }

        if (colorWastePriceGroups.length > 0) {
            if (colorsWasteCostPositions.length > 0) {
                const colorsCostPosition = colorsWasteCostPositions[0].doc;
                const overralPrice = colorWastePriceGroups.reduce(
                    (prev, group) => group.price + prev,
                    0
                );
                colorsCostPosition.dealer_price = overralPrice;
                colorsCostPosition.dealer_price_before_discount = overralPrice;
                colorsCostPosition.client_price = overralPrice;
                colorsCostPosition.client_price_before_discount = overralPrice;
                colorsCostPosition.configuration = {
                    type: 'colors_waste_cost',
                    Quantity: 1,
                    Name: $filter('translate')('OFFER|Kolory niestandardowe'),
                    Colors: colorWastePriceGroups,
                };
                colorsCostPosition.details = {
                    type: 'colors_waste_cost',
                    quantity: 1,
                    name: $filter('translate')('OFFER|Kolory niestandardowe'),
                    colors: colorWastePriceGroups,
                };

                await EditPosition.update(colorsCostPosition, {
                    internalId: colorsCostPosition.tmp_id,
                });
            } else {
                const params = {
                    configuration: {
                        type: 'colors_waste_cost',
                        Quantity: 1,
                        Name: $filter('translate')('OFFER|Kolory niestandardowe'),
                        Colors: colorWastePriceGroups,
                    },
                    details: {
                        type: 'colors_waste_cost',
                        quantity: 1,
                        name: $filter('translate')('OFFER|Kolory niestandardowe'),
                        colors: colorWastePriceGroups,
                    },
                    offer,
                    standard: true,
                    price: colorWastePriceGroups.reduce((prev, group) => group.price + prev, 0),
                };
                const groupCode = OfferGroupCodeService.generateGroupCode(params, IccConfig);
                const newPosition = new Position(angular.extend(params, { groupCode }));
                newPosition.group_discounts = '[]';
                newPosition.dealer_price = newPosition.dealer_price_before_discount;
                newSequence = OfferSequenceService.updateOfferSequence(
                    newSequence,
                    [{
                        id: newPosition.tmp_id,
                        groupCode: newPosition.groupCode,
                    }],
                    'add',
                    IccConfig
                );
                await DatabaseManager.get('Position').create(newPosition);
            }
        }

        if (colorPriceGroups.length === 0 && colorsCostPositions.length > 0) {
            newSequence = OfferSequenceService.updateOfferSequence(
                newSequence,
                [{
                    id: colorsCostPositions[0].doc.tmp_id,
                }],
                'remove',
                IccConfig
            );
            await DatabaseManager.get('Position').remove(colorsCostPositions[0].doc);
        }
        if (colorWastePriceGroups.length === 0 && colorsWasteCostPositions.length > 0) {
            newSequence = OfferSequenceService.updateOfferSequence(
                newSequence,
                [{
                    id: colorsWasteCostPositions[0].doc.tmp_id,
                }],
                'remove',
                IccConfig
            );
            await DatabaseManager.get('Position').remove(colorsWasteCostPositions[0].doc);
        }
        await OfferSummaryService.autoUpdateOffer(offerId, null, null, newSequence);
    }

    /**
     * Funkcja sprawdzająca czy w oferta jest w pełni pobrana
     * @param  {string}  offerId  Numer oferty
     * @return {object}           Promise
     */
    function checkOfferPositions(offerId) {
        return new Promise((resolve, reject) => {
            OffersService.get(offerId, false).then(offer => {
                const sequenceKeys = OfferSequenceService.keysFromSequence(offer.sequence);
                ManyPositionsService.listById(sequenceKeys, offerId).then(positions => {
                    if (positions.seq.length != positions.pos.length) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                });
            });
        });
    }

    function positionsFromGroups(groups) {
        return groups.reduce((prev, group) => {
            prev.push(...group.rows);
            return prev;
        }, []);
    }
}
