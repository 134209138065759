import { Injectable, Inject } from '@angular/core';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import { EventBusService } from 'event-bus.service';
import { BrowserFramesService } from 'configurator/layout/frames.service';
import { APP_CONFIG, AppConfig } from 'config';
import BrowserProfilesService from './profiles.service';
import StateService from 'state.service';
import Common from 'Common';
import { AlignmentsService } from 'configurator/layout/alignments.service';
import { ExtensionsService } from 'configurator/layout/extensions.service';
import { ProfileType, Profile, SideProfile } from 'configurations/parts/window';
import { ActiveSash } from 'layout/active-sash';
import CoupledWindowActiveConfiguration from 'configurations/CoupledWindowActiveConfiguration';
import WindowConfiguration from 'configurations/WindowConfiguration';
import { iccSideColorsToSideColors } from 'configurations/converters/window/colors';
import { IccSideColors } from 'data-types';
import { ProfilesPriceService } from 'profiles/profiles-price.service';

@Injectable()
export class ProfilesModalService {
    constructor(
        private eventBusService: EventBusService,
        private framesService: BrowserFramesService,
        private profilesService: BrowserProfilesService,
        private profilesPriceService: ProfilesPriceService,
        private stateService: StateService,
        private alignmentsService: AlignmentsService,
        private extensionsService: ExtensionsService,
        @Inject(APP_CONFIG) private config: AppConfig,
        @Inject('$uibModal') private $uibModal: ng.ui.bootstrap.IModalService
    ) {}

    async openProfilesModal(
        conf: WindowActiveConfiguration | WindowConfiguration,
        type: ProfileType | ProfileType[],
        options: { and?: string[]; not?: string[] } | { and?: string[]; not?: string[] }[],
        selectedProfile: Profile,
        color?: Partial<IccSideColors>,
        wood?: SideProfile['wood']
    ): Promise<Profile> {
        const systemId = conf
            ? WindowConfiguration.is(conf)
                ? conf.system.id
                : Number(conf.System.id)
            : null;
        const profiles = this.profilesService.getFilteredProfiles(conf, type, options, false).map(profile => {
            this.profilesService.setProfileDefaultColors(profile, selectedProfile, color, wood);
            let profileOptions: {
                and?: string[];
                not?: string[];
            } = {
                and: [],
                not: [],
            };
            if (Common.isArray(options) && Common.isArray(type)) {
                profileOptions = options[type.indexOf(profile.type)];
            } else if (!Common.isArray(options) && !Common.isArray(type)) {
                profileOptions = options;
            }
            const priceType = this.profilesService.getProfilePriceType(profile.type, profileOptions);
            profile.price = systemId
                        ? this.profilesPriceService.getProfilePrice(
                            profile.id,
                            priceType,
                            WindowConfiguration.is(conf) ? conf.system : conf.System,
                            profile.selectedColor && profile.selectedColor.frame ? profile.selectedColor : (WindowConfiguration.is(conf) ? conf.colors : conf.Colors),
                            this.profilesService.profilesPrices
                        )
                        : null;
            return profile;
        });
        const modalInstance = this.$uibModal.open({
            component: 'profilesModal',
            resolve: {
                profiles: () => profiles,
                profilesPrices: () => this.profilesService.profilesPrices,
                system: () => (WindowConfiguration.is(conf) ? conf.system : conf.System),
                type: () => type,
                selectedProfile: () => selectedProfile,
                currency: () => this.stateService.state.offers[0].doc.currency,
                color: () => color,
                wood: () => wood,
                hideCouplings: () => WindowConfiguration.is(conf) ? conf.sashesType !== "Fix" : conf.SashesType !== "Fix"
            },   
        });
        return modalInstance.result;
    }

    selectAlignmentAndPut(
        field: ActiveSash,
        conf: WindowActiveConfiguration,
        side: 'top' | 'bottom' | 'left' | 'right'
    ) {
        let alignmentType = 'alignment_frame';
        if (Common.isNumber(field.parentId)) {
            alignmentType = 'alignment_sash';
        } else if (
            side === 'bottom'
            && field.nearAlignments.bottom === -1
            && field.nearAlignments.left === -1
            && field.nearAlignments.right === -1
            && field.nearMullions.bottom === -1
            && BrowserFramesService.getFrameById(field.frameId, conf).lowThreshold
        ) {
            alignmentType = 'alignment_threshold';
        }
        const options = {
            and: [alignmentType],
            not: [],
        };

        this.openProfilesModal(conf, null, options, null, conf.Colors.frame).then(
            selectedProfile => {
                if (selectedProfile) {
                    this.alignmentsService.putAlignmentInField(selectedProfile, field, conf, side);
                    if (this.config.IccConfig.Configurators.dependencies) {
                        this.eventBusService.post({ key: 'processDependencies', value: null });
                    }
                }
            }
        );
    }

    selectExtensionAndPut(
        conf: WindowActiveConfiguration,
        side: 'top' | 'bottom' | 'left' | 'right'
    ) {
        const options = [
            {
                and: [],
                not: [],
            },
            {
                and: [],
                not: [],
            },
        ];

        this.openProfilesModal(
            conf,
            ['extension', 'sandwich'],
            options,
            null,
            conf.Colors.frame
        ).then(profile => {
            if (profile) {
                this.extensionsService.putExtensionOnSide(conf, profile, side);
                if (this.config.IccConfig.Configurators.dependencies) {
                    this.eventBusService.post({ key: 'processDependencies', value: null });
                }
            }
        });
    }

    async selectProfileAndPut(
        conf: WindowActiveConfiguration | WindowConfiguration,
        type: ProfileType
    ) {
        const options = [
            {
                and: [],
                not: [],
            },
            {
                and: [],
                not: [],
            },
        ];

        const selectedProfile = await this.openProfilesModal(
            conf,
            [type],
            options,
            null,
            WindowConfiguration.is(conf)
                ? conf.colors.frame
                : iccSideColorsToSideColors(conf.Colors.frame)
        );
        return selectedProfile;
    }

    changeProfile(
        conf: WindowActiveConfiguration,
        type,
        {
            position = null,
            frame = null,
            sash = null,
            mullion = null,
            extension = null,
            coupling = null,
        }
    ) {
        const changeAllProfiles =
            position === 'all' && (type !== 'frame' || (frame && !frame.lowThreshold));
        const changeProfilesWithoutThreshold =
            position === 'all' && type === 'frame' && frame && frame.lowThreshold;
        let color;
        let wood;
        let profileId;
        let options;
        if (type === 'mullion') {
            mullion = this.getMullion(conf, mullion.id);
            profileId = mullion.profileId;
        } else if (type === 'sashFrame') {
            sash = this.getSash(conf, sash.id);
            const sashFrameData = conf.drawData.sashFrame.find(s => s.sashId === sash.id);
            const side =
                (sashFrameData
                    && sashFrameData.sides[position]
                    && sashFrameData.sides[position].outerEdge.side)
                || 'bottom';
            position = changeAllProfiles ? 'bottom' : side;
            profileId = sash.frame[position].profileId;
            color = conf.Colors.sash;
        } else if (type === 'frame') {
            position = changeProfilesWithoutThreshold ? 1 : changeAllProfiles ? 0 : position;
            profileId = frame.frame[position].profileId;
        } else if (type === 'extension') {
            extension = conf.SideProfiles.find(o => o.id === extension.id) || {};
            profileId = extension.profileId;
            color = extension.color;
            wood = extension.wood;
        } else if (type === 'coupling') {
            coupling = conf.couplings.find(c => c.id === coupling.id);
            profileId = coupling.profileId;
        }
        const profile = this.profilesService.getProfile(profileId);
        if (!color) {
            color = conf.Colors.frame;
        }
        if (type === 'extension' && profile && profile.type === 'sandwich') {
            profile.width = extension.width;
        }
        [type, options] = this.getOptions(conf, type, {
            position,
            frame,
            sash,
            mullion,
            extension,
            coupling,
        });
        if (type !== 'no_mullion') {
            this.openProfilesModal(conf, type, options, profile, color, wood).then(
                selectedProfile => {
                    if (selectedProfile) {
                        const pauseId = this.eventBusService.pause([
                            'setBondedGlazingInSash',
                            'setMullionProfile',
                            'setSashProfile',
                        ]);
                        try {
                            if (changeAllProfiles && type !== 'mullion') {
                                Object.keys(type === 'frame' ? frame.frame : sash.frame).map(
                                    pos => {
                                        position = pos;
                                        this.setProfile(conf, type, selectedProfile, {
                                            position,
                                            frame,
                                            sash,
                                            mullion,
                                            extension,
                                        });
                                    }
                                );
                            } else if (changeProfilesWithoutThreshold && type === 'frame') {
                                Object.keys(frame.frame).map(pos => {
                                    position = pos;
                                    const frameProfile = this.profilesService.getProfile(
                                        frame.frame[pos].profileId
                                    );
                                    if (frameProfile.type !== 'threshold') {
                                        this.setProfile(conf, type, selectedProfile, {
                                            position,
                                            frame,
                                            sash,
                                            mullion,
                                            extension,
                                        });
                                    }
                                });
                            } else if (selectedProfile.type === 'coupling' && mullion) {
                                this.framesService.splitFrame(
                                    selectedProfile,
                                    mullion,
                                    conf
                                );
                            } else if (selectedProfile.type === 'fixed_mullion' && coupling) {
                                this.framesService.joinFrame(
                                    selectedProfile,
                                    coupling,
                                    conf
                                );
                            } else {
                                this.setProfile(conf, selectedProfile.type, selectedProfile, {
                                    position,
                                    frame,
                                    sash,
                                    mullion,
                                    extension,
                                    coupling,
                                });
                            }
                        } finally {
                            this.eventBusService.resume(
                                ['setBondedGlazingInSash', 'setMullionProfile', 'setSashProfile'],
                                pauseId
                            );
                        }
                        if (this.config.IccConfig.Configurators.dependencies) {
                            this.eventBusService.post({ key: 'processDependencies', value: null });
                        }
                    }
                }
            );
        }
    }

    private setProfile(
        conf: WindowActiveConfiguration,
        type: ProfileType,
        profile: Profile,
        {
            position = null,
            frame = null,
            sash = null,
            mullion = null,
            extension = null,
            coupling = null,
        }
    ) {
        if (type === 'sash' || type === 'false_mullion_sash') {
            this.profilesService.setSashProfile(conf, sash, profile, position, {});
        } else if (type.indexOf('mullion') > -1) {
            this.profilesService.setMullionProfile(conf, mullion, profile, {});
        } else if (type === 'frame') {
            const frameData = conf.drawData && conf.drawData.frame && conf.drawData.frame[0];
            const side =
                (frameData
                    && frameData.sides[position]
                    && frameData.sides[position].outerEdge.side)
                || 'bottom';
            this.profilesService.setFrameProfile(conf, profile, frame, position, { side });
        } else if (type === 'extension' || type === 'sandwich') {
            this.profilesService.setExtensionProfile(extension, profile);
        } else if (type === 'coupling') {
            this.framesService.changeCouplingProfile(conf, coupling, profile, {});
        }
    }

    private getMullion(conf, mullionId) {
        for (const mullion of conf.Mullions) {
            if (mullion.id === mullionId) {
                return mullion;
            }
        }
        for (const sash of conf.Sashes) {
            for (const mullion of sash.intMullions) {
                if (mullion.id === mullionId) {
                    return mullion;
                }
            }
        }
    }

    private getSash(conf, sashId) {
        for (const sash of conf.Sashes) {
            if (sash.id === sashId) {
                return sash;
            }
        }
    }

    private getOptions(
        conf: WindowActiveConfiguration,
        type,
        {
            position = null,
            frame = null,
            sash = null,
            mullion = null,
            extension = null,
            coupling = null,
        }
    ) {
        let options:
            | {
                  and: string[];
                  not: string[];
              }
            | {
                  and: string[];
                  not: string[];
              }[] = {
            and: [],
            not: [],
        };

        let positionMap: {
            bottom: string;
            right: string;
            top: string;
            left: string;
            0?;
            1?;
            2?;
            3?;
        } = {
            0: 'bottom',
            1: 'side',
            2: 'top',
            3: 'side',
            bottom: 'bottom',
            right: 'side',
            top: 'top',
            left: 'side',
        };

        if (type !== 'extension' && position !== null) {
            if (type === 'sashFrame') {
                const sashFrameData =
                    conf.drawData
                    && conf.drawData.sashFrame
                    && conf.drawData.sashFrame.find(s => s.sashId === sash.id);

                if (sashFrameData) {
                    sashFrameData.sides.forEach((side, index) => {
                        positionMap[index] = side.outerEdge.side || 'bottom';
                    });
                }
                if (sash.nearMullions.bottom > -1) {
                    const bottomSashes = conf.Mullions.find(
                        el => el.id === sash.nearMullions.bottom
                    ).multiAlignBottom;

                    if (sash.type.type === 'SD' || bottomSashes.some(el => el.type.type === 'SU')) {
                        positionMap = {
                            top: 'top_in_top_sash',
                            bottom: 'bottom_in_top_sash',
                            left: 'side_in_top_sash',
                            right: 'side_in_top_sash',
                        };
                    }
                } else if (sash.nearMullions.top > -1) {
                    const topSashes = conf.Mullions.find(el => el.id === sash.nearMullions.top)
                        .multiAlignTop;

                    if (sash.type.type === 'SU' || topSashes.some(el => el.type.type === 'SD')) {
                        positionMap = {
                            top: 'top_in_bottom_sash',
                            bottom: 'bottom_in_bottom_sash',
                            left: 'side_in_bottom_sash',
                            right: 'side_in_bottom_sash',
                        };
                    }
                }
            }
            options.and.push(positionMap[position]);
        }

        if (type === 'sashFrame') {
            type = 'sash';
            options[sash.type.out_open ? 'and' : 'not'].push('outward_opening');
            if (
                sash.type.passive
                && ((sash.type.handle_position === 'R' && position === 'right')
                    || (sash.type.handle_position === 'L' && position === 'left'))
            ) {
                type = ['sash', 'false_mullion_sash'];
                options = [
                    options,
                    {
                        and: [],
                        not: [],
                    },
                ];
                options[1][sash.type.out_open ? 'and' : 'not'].push('outward_opening');
            }
        } else if (type === 'mullion') {
            type = mullion.type;
            if (type === 'fixed_mullion') {
                options.and.push(
                    mullion.direction + (mullion.parentSash != null ? '_sash' : '_frame')
                );
                if (
                    !mullion.parentSash
                    && ((mullion.direction === 'vertical' && mullion.rHeight === conf.Height)
                        || (mullion.direction === 'horizontal' && mullion.rWidth === conf.Width))
                    && this.config.IccConfig.Configurators.coupledFrames
                ) {
                    type = [type, 'coupling'];
                    options = [
                        options,
                        {
                            and: [],
                            not: [],
                        },
                    ];
                }
            }
        } else if (type === 'extension') {
            type = ['extension', 'sandwich'];
            options = [
                options,
                {
                    and: [],
                    not: [],
                },
            ];
        } else if (type === 'coupling') {
            type = ['fixed_mullion', 'coupling'];
            options = [
                {
                    and: [coupling.direction + '_frame'],
                    not: [],
                },
                {
                    and: [],
                    not: [],
                },
            ];
        }

        return [type, options];
    }
}
