import angular from 'angular';
import { IssueLevel } from 'issues.service';

/**
 * Fabryka klamek
 * @param {object} $rootScope               Angular Root Scope
 * @param {object} $uibModal                Dostarcza obsługę okien modalnych
 * @param {object} $filter                  Filtry
 * @param {object} ConfiguratorsDataService Fabyrka danych konfiguracji
 * @param {object} CurConfService           Bieżaca konfiguracja
 * @param {object} ParametersService        Fabryka parametrów
 * @param {object} ConfigurationsService    Fabryka konfiguracji
 * @param {object} Core                     Core
 * @param {object} PriceService             Liczenie cen
 * @param {object} IssuesService            Fabryka Issues
 * @param {object} LocksFactory             Fabryka Zamków
 * @param {object} IccConfig                Konfiguracja ICC
 *
 * @return {object} Factory
 */
export default function HandlesFactory(
    $rootScope,
    $uibModal,
    $filter,
    ConfiguratorsDataService,
    CurConfService, // eslint-disable-line max-params
    ParametersService,
    ConfigurationsService,
    Core,
    PriceService,
    IssuesService,
    LocksFactory,
    IccConfig,
    ColorsMappingService,
    DrawService,
    EventBusService,
    HandleHeightService,
    TimeLimitService,
    ConstructionLimitationFactory
) {
    'ngInject';

    const configurators = ['window', 'hs', 'door', 'folding_door'];
    const noHandleTypes = ['F', 'FF', 'OFF', 'DS', 'DSC', 'DRP', 'ODS', 'DOP'];
    let allFittings = [];
    let allHandles = [];
    let allHinges = [];
    let handleCategories = [];
    let handleSubcategories = [];
    let handleColorsAll = [];
    let sashTypes = [];
    const modalHandleData = {
        selectedCategory: undefined,
        selectedSubcategory: undefined,
    };

    const factory = {
        fittings: [],
        handles: [],
        handlesForSelect: [],
        hinges: [],
        handleColors: [],
        tags: [],
        loadedData: false,
        isMatch: { ref: false },
        selectFitting,
        findFittingsBySystem,
        setHandle,
        setHinge,
        setHandleColor,
        setDefaultHandleForSystem,
        findHandlesBySystem,
        findHingesBySystem,
        setDefaultHingeForSystem,
        checkIsOneHandleAndAllHasHandle,
        refreshTypes,
        openModalHandle,
        openModalHinge,
        setHandleType,
        setDefaultColorForHinge,
        systemHeight: HandleHeightService.systemHeight,
    };

    if (ConfiguratorsDataService.loaded) {
        init();
    }

    EventBusService.subscribeWithoutConfiguration(
        ['initializedConfigurator', 'loadedConfiguratorsData'],
        () => {
            init();
        }
    );

    return factory;

    /**
     * Funkcja inicjalizacyjna
     */
    function init() {
        // if (configurators.indexOf(CurConfService.conf) === -1) {
        //     return;
        // }

        handleCategories = ConfiguratorsDataService.data.handleCategory;
        handleSubcategories = ConfiguratorsDataService.data.handleSubcategory;
        allFittings = ConfiguratorsDataService.data.windowFittings;
        allHandles = angular.copy(ConfiguratorsDataService.data.windowHandlers);
        allHinges = angular.copy(ConfiguratorsDataService.data.hinges);
        handleColorsAll = ConfiguratorsDataService.data.windowHandlesColors;
        factory.fittings = ConfiguratorsDataService.data.windowFittings;
        factory.handleColors = ConfiguratorsDataService.data.windowHandlesColors;
        factory.tags = [];
        if (angular.isArray(ConfiguratorsDataService.data.systemsTags)) {
            factory.tags = ConfiguratorsDataService.data.systemsTags.filter(
                tag => tag.type === 'window_fitting'
            );
        }

        if (configurators.indexOf(CurConfService.conf) !== -1) {
            findFittingsBySystem();
            findHandlesBySystem();
            findHingesBySystem();
            setDefaultHingeForSystem();

            if (
                angular.isUndefined(ConfigurationsService.conf.addPositionToGroup)
                || !ConfigurationsService.conf.addPositionToGroup
            ) {
                checkIsOneHandleAndAllHasHandle(ConfigurationsService.conf.Current);
            }

            refreshTypes(ConfigurationsService.conf.Current);
        }

        factory.loadedData = true;
    }

    /**
     * Funkcja ustawiajaca domyślne okucie bezpieczne
     */
    function setDefaultFitting() {
        if (angular.isUndefined(ConfigurationsService.conf)) {
            return;
        }
        if (angular.isArray(factory.fittings) && factory.fittings.length > 0) {
            if (
                angular.isObject(ConfigurationsService.conf.Default.Fitting)
                && angular.isDefined(ConfigurationsService.conf.Default.Fitting.id)
                && factory.fittings.filter(
                    f => f.id == ConfigurationsService.conf.Default.Fitting.id
                ).length > 0
            ) {
                ConfigurationsService.conf.Current.Fitting =
                    ConfigurationsService.conf.Default.Fitting;
            } else {
                ConfigurationsService.conf.Current.Fitting = factory.fittings[0];
                ConfigurationsService.conf.Default.Fitting = factory.fittings[0];
            }
        }
        findHingesBySystem();
        setDefaultHingeForSystem();
        EventBusService.post({
            key: 'changedFitting',
            value: null,
        });
        PriceService.count();
        ParametersService.count(ConfigurationsService.conf.Current);
    }

    /**
     * Funkcja wybierajaca okucie
     * @param  {object} fitting Okucie
     */
    function selectFitting(fitting) {
        ConfigurationsService.conf.Current.Fitting = fitting;
        ConfigurationsService.conf.Default.Fitting = fitting;

        if (fitting.steel_closed && ConfigurationsService.conf.Current.Steel != 'Closed') {
            ConfigurationsService.conf.Current.Steel = 'Closed';
        }

        findHingesBySystem();
        setDefaultHingeForSystem();
        findHandlesBySystem();
        if (
            IccConfig.Configurators.filteringHandlesForRC
            || IccConfig.Configurators.selectHandleOnChangeFitting
        ) {
            const filter = sash =>
                !sash.handle
                || !sash.handle.id
                || sash.handle.window_fittings_ids.indexOf(
                    ConfigurationsService.conf.Current.Fitting.id
                ) === -1;
            setHandleType(ConfigurationsService.conf.Current.HandleType, filter);
        }
        refreshTypes(ConfigurationsService.conf.Current);
        valid(ConfigurationsService.conf.Current);
        EventBusService.post({
            key: 'changedFitting',
            value: null,
        });
        ParametersService.count(ConfigurationsService.conf.Current);
        if (IccConfig.Configurators.dependencies) {
            EventBusService.post({ key: 'processDependencies', value: null });
        }
        PriceService.count();
    }

    /**
     * Funkcja znajdujaca okucie wh systemu
     * @param  {object} system System
     * @return {object}        Okucia
     */
    function findFittingsBySystem(system) {
        if (angular.isUndefined(ConfigurationsService.conf) && angular.isUndefined(system)) {
            return [];
        }

        let systemPassed = true;
        if (angular.isUndefined(system)) {
            system = ConfigurationsService.conf.Current.System;
            systemPassed = false;
        }

        factory.fittings = [];
        angular.forEach(allFittings, fitting => {
            const systemTypes = fitting.system_types;
            const systemIds = fitting.window_lines_ids;
            if (angular.isArray(systemTypes)) {
                if (angular.isObject(system)) {
                    if (
                        systemTypes.indexOf(system.type) > -1
                        && systemIds.indexOf(system.id) > -1
                    ) {
                        factory.fittings.push(fitting);
                    }
                } else {
                    if (systemTypes.indexOf('pvc') > -1) {
                        factory.fittings.push(fitting);
                    }
                }
            }
        });

        if (!systemPassed) {
            setDefaultFitting();
            $rootScope.$broadcast('refreshHandlesColors');
            return factory.fittings;
        } else {
            return factory.fittings;
        }
    }

    /**
     * Funkcja ustawiajaca klamke
     * @param {object} sash      Skrzydło
     * @param {string} place     Miejsce
     * @param {object} newHandle Nowa klamka
     * @param {object} check     Sprawdzanie
     * @param {object} noRedraw  noRedraw
     */
    function setHandle(sash, place, newHandle, check, noRedraw, validate = true) {
        if (
            !sash
            || !ConfigurationsService.conf.Current.System
            || !ConfigurationsService.conf.Current.System.id
        ) {
            return;
        }
        if (noHandleTypes.indexOf(sash.type.type) == -1) {
            if (
                !angular.isObject(newHandle)
                || (newHandle.sash_types_ids || []).indexOf(sash.type.id) === -1
            ) {
                newHandle = getDefaultHandle(place);
            }
            if (angular.isUndefined(newHandle)) {
                IssuesService.registerDataProblem(
                    'no-handle-available',
                    'Brak podpiętej klamki do systemu',
                    ConfigurationsService.conf.Current,
                    {
                        extra: {
                            systemId: ConfigurationsService.conf.Current.System.id,
                            systemName: ConfigurationsService.conf.Current.System.name,
                        },
                    }
                );
                return;
            } else {
                IssuesService.unregister('no-handle-available', ConfigurationsService.conf.Current);
            }
            if (newHandle.sash_types_ids.indexOf(sash.type.id) === -1) {
                const matchedHandles = getHandleForSashType(sash.type.id);
                if (angular.isArray(matchedHandles) && matchedHandles.length > 0) {
                    newHandle = matchedHandles[0];
                    IssuesService.unregister(
                        'no-handle-available',
                        ConfigurationsService.conf.Current
                    );
                } else {
                    IssuesService.registerDataProblem(
                        'no-handle-available',
                        'Żadna klamka nie pasuje do wybranego systemu i skrzydła',
                        ConfigurationsService.conf.Current,
                        {
                            extra: {
                                systemId: ConfigurationsService.conf.Current.System.id,
                                systemName: ConfigurationsService.conf.Current.System.name,
                                sashType: sash.type.name,
                                sashTypeType: sash.type.type,
                            },
                        }
                    );
                    return;
                }
            }
            if (place == 'inner' || place == 'double' || !place) {
                sash.handle = angular.copy(newHandle);
            }
            if (place == 'outer' || place == 'double') {
                sash.handleOuter = angular.copy(newHandle);
            }
            if (validate) {
                valid(ConfigurationsService.conf.Current);
            }
        }
        if (
            !check
            && (angular.isUndefined(ConfigurationsService.conf.addPositionToGroup)
                || !ConfigurationsService.conf.addPositionToGroup)
        ) {
            checkIsOneHandleAndAllHasHandle(ConfigurationsService.conf.Current, noRedraw);
        }
        if (!noRedraw) {
            EventBusService.post({
                key: 'icc-redraw',
                value: 'sash',
            });
        }
        findColorsForHandle();
        LocksFactory.findLocksBySystem();
        PriceService.count();
        ParametersService.count(ConfigurationsService.conf.Current);
    }

    /**
     * Funkcja pobierajaca klamke dla typu skrzydła
     * @param  {object} typeId Type ID
     */
    function getHandleForSashType(typeId) {
        return factory.handles.filter(el => el.sash_types_ids.indexOf(typeId) > -1);
    }

    /**
     * Funkcja ustawiajaca kolor klamki wg ID
     * @param {object} handleColorId Id koloru klamki
     */
    function setHandleColorById(
        handleColorId,
        sash,
        place,
        filter = () => true,
        isDefault = false
    ) {
        const matchedColors = handleColorsAll.filter(el => el.id === handleColorId);
        if (matchedColors.length > 0) {
            const matchedColor = Core.copy(matchedColors[0]);
            matchedColor.isDefault = isDefault;
            setHandleColor(matchedColor, sash, place, filter);
        }
    }

    /**
     * Funkcja ustawiajaca kolor klamki
     * @param {object} handleColor Kolor klamki
     * @param {object} sash        Skrzydło
     */
    function setHandleColor(handleColor, sash, place, filter = () => true, check, noRedraw) {
        if (handleColor && !handleColor.isDefault) {
            handleColor.isDefault = false;
        }

        if (!place || place == 'inner' || place == 'global') {
            if (angular.isObject(sash)) {
                if (angular.isDefined(sash.handle) && angular.isArray(sash.handle.colors_ids)) {
                    if (
                        !angular.isObject(handleColor)
                        || sash.handle.colors_ids.indexOf(handleColor.id) == -1
                    ) {
                        if (
                            angular.isDefined(ConfigurationsService.conf.Default.HandleColor)
                            && ConfigurationsService.conf.Default.HandleColor !== null
                            && angular.isDefined(ConfigurationsService.conf.Default.HandleColor.id)
                            && sash.handle.colors_ids.indexOf(
                                ConfigurationsService.conf.Default.HandleColor.id
                            ) > -1
                            && !ConfigurationsService.conf.Default.HandleColor.isDefault
                        ) {
                            handleColor = Core.copy(ConfigurationsService.conf.Default.HandleColor);
                        } else {
                            handleColor = getDefaultColorForHandle(sash.handle, 'inner');
                            handleColor.isDefault = true;
                        }
                    }
                    sash.handleColor = Core.copy(handleColor);
                    PriceService.count();
                }
            } else {
                if (
                    angular.isDefined(ConfigurationsService.conf.Current.Handle)
                    && angular.isArray(ConfigurationsService.conf.Current.Handle.colors_ids)
                ) {
                    if (
                        !angular.isObject(handleColor)
                        || !ConfigurationsService.conf.Current.Handle.colors_ids.indexOf(
                            handleColor.id
                        ) == -1
                    ) {
                        handleColor = getDefaultColorForHandle();
                        handleColor.isDefault = true;
                    }
                    ConfigurationsService.conf.Current.HandleColor = Core.copy(handleColor);
                    ConfigurationsService.conf.Default.HandleColor = Core.copy(handleColor);
                    setHandleColorForAllSashes(handleColor, place, filter);
                    PriceService.count();
                }
            }
        }
        if (place == 'outer' || place == 'global') {
            if (angular.isObject(sash)) {
                if (
                    angular.isDefined(sash.handleOuter)
                    && angular.isArray(sash.handleOuter.colors_ids)
                ) {
                    if (
                        !angular.isObject(handleColor)
                        || sash.handleOuter.colors_ids.indexOf(handleColor.id) == -1
                    ) {
                        if (
                            angular.isDefined(ConfigurationsService.conf.Default.HandleOuterColor)
                            && ConfigurationsService.conf.Default.HandleOuterColor !== null
                            && angular.isDefined(
                                ConfigurationsService.conf.Default.HandleOuterColor.id
                            )
                            && sash.handleOuter.colors_ids.indexOf(
                                ConfigurationsService.conf.Default.HandleOuterColor.id
                            ) > -1
                            && !ConfigurationsService.conf.Default.HandleOuterColor.isDefault
                        ) {
                            handleColor = Core.copy(
                                ConfigurationsService.conf.Default.HandleOuterColor
                            );
                        } else {
                            handleColor = getDefaultColorForHandle(sash.handleOuter, 'outer');
                            handleColor.isDefault = true;
                        }
                    }
                    sash.handleOuterColor = Core.copy(handleColor);
                    PriceService.count();
                }
            } else {
                if (
                    angular.isDefined(ConfigurationsService.conf.Current.Handle)
                    && angular.isArray(ConfigurationsService.conf.Current.Handle.colors_ids)
                ) {
                    if (
                        !angular.isObject(handleColor)
                        || !ConfigurationsService.conf.Current.Handle.colors_ids.indexOf(
                            handleColor.id
                        ) == -1
                    ) {
                        handleColor = getDefaultColorForHandle();
                        handleColor.isDefault = true;
                    }
                    ConfigurationsService.conf.Current.HandleOuterColor = Core.copy(handleColor);
                    ConfigurationsService.conf.Default.HandleOuterColor = Core.copy(handleColor);
                    setHandleColorForAllSashes(handleColor, place, filter);
                    PriceService.count();
                }
            }
        }
        if (
            !check
            && (angular.isUndefined(ConfigurationsService.conf.addPositionToGroup)
                || !ConfigurationsService.conf.addPositionToGroup)
        ) {
            checkIsOneHandleAndAllHasHandle(ConfigurationsService.conf.Current, noRedraw);
        }
    }

    /**
     * Funkcja ustawiajaca kolor klamki dla wszyskich skrzydeł
     * @param {object} handleColor Kolor klamki
     */
    function setHandleColorForAllSashes(handleColor, place, filter = () => true) {
        ConfigurationsService.conf.Current.Sashes.filter(filter).forEach(sash => {
            setHandleColor(handleColor, sash, place);
        });
    }

    /**
     * Funkcja pobierajaca domyslny kolor dla klamki
     * @param  {object} sash Skrzydło
     */
    function getDefaultColorForHandle(handle, side = 'inner') {
        let color;
        if (!angular.isObject(handle)) {
            if (handle == 'outer') {
                handle = ConfigurationsService.conf.Current.HandleOuter;
            } else {
                handle = ConfigurationsService.conf.Current.Handle;
            }
        }
        if (
            angular.isObject(handle)
            && angular.isArray(handle.colors_ids)
            && angular.isArray(handleColorsAll)
        ) {
            const windowColorId = ColorsMappingService.getWindowColorId(
                ConfigurationsService.conf.Current,
                side
            );
            const windowColorRal = ColorsMappingService.getWindowColorId(
                ConfigurationsService.conf.Current,
                side,
                'RAL'
            );
            const matchedColors = ColorsMappingService.getColors(
                windowColorId,
                windowColorRal ? 'ral' : 'window',
                'accessory'
            );
            const colors = handleColorsAll.filter(el => handle.colors_ids.indexOf(el.id) > -1);
            const windowColors = matchedColors
                .map(m => colors.filter(c => c.id == m)[0])
                .filter(m => m);
            const whiteHandle = colors.filter(el => el.color.toString().toUpperCase() === 'FFFFFF');
            if (angular.isArray(windowColors) && angular.isDefined(windowColors[0])) {
                color = Core.copy(windowColors[0]);
            } else if (angular.isArray(whiteHandle) && angular.isDefined(whiteHandle[0])) {
                color = Core.copy(whiteHandle[0]);
            } else {
                color = Core.copy(colors[0]);
            }
            color.isDefault = true;
        }
        return color;
    }

    function setHandleType(type, filter = () => true) {
        if (type == 'LeverPull') {
            setDefaultHandleForSystem('inner', filter);
            setDefaultHandleForSystem('outer', filter);
        } else {
            setDefaultHandleForSystem('double', filter);
        }
    }

    /**
     * Funkcja pobierajaca domyślna klamke
     */
    function getDefaultHandle(place) {
        let defaultHandle = null;
        let handleId;
        const handlePlace = place == 'outer' ? 'HandleOuter' : 'Handle';

        if (
            angular.isDefined(ConfigurationsService.conf.Default[handlePlace])
            && angular.isDefined(ConfigurationsService.conf.Default[handlePlace].id)
        ) {
            handleId = ConfigurationsService.conf.Default[handlePlace].id;
        } else {
            handleId = ConfigurationsService.conf.Current.System.default_window_handler_id;
        }
        defaultHandle = Core.fIdO(
            factory.handlesForSelect.filter(filterHandlesByPlaceAndType, { place }),
            handleId
        );
        if (angular.isUndefined(defaultHandle)) {
            defaultHandle = Core.fIdO(
                factory.handlesForSelect.filter(filterHandlesByPlaceAndType, { place }),
                ConfigurationsService.conf.Current.System.default_window_handler_id
            );
            if (angular.isUndefined(defaultHandle)) {
                defaultHandle = factory.handlesForSelect.filter(filterHandlesByPlaceAndType, {
                    place,
                })[0];
            }
        }

        return defaultHandle;
    }

    /**
     * Funkcja ustawiajaca domyslna klamke dla systemu
     */
    function setDefaultHandleForSystem(place, filter = () => true) {
        const defaultHandle = getDefaultHandle(place);
        if (angular.isDefined(defaultHandle)) {
            setHandleInAllSashes(defaultHandle, place, filter);
        }
    }

    /**
     * Funkcja ustawiajaca domyslna zawias dla systemu
     */
    function setDefaultHingeForSystem() {
        const hinges = findHingesBySystem(ConfigurationsService.conf.Current.System);

        if (
            angular.isArray(hinges)
            && angular.isObject(hinges[0])
            && (!angular.isObject(ConfigurationsService.conf.Current.Hinge)
                || (angular.isObject(ConfigurationsService.conf.Current.Hinge)
                    && hinges.map(h => h.id).indexOf(ConfigurationsService.conf.Current.Hinge.id)
                        == -1))
        ) {
            setHinge(hinges[0]);
        }
    }

    /**
     * Funkcja ustawiająca Zawiasy
     * @param {object} hinge Zawiasy
     */
    function setHinge(hinge) {
        ConfigurationsService.conf.Current.Hinge = hinge;
        setDefaultColorForHinge();
        PriceService.count();
    }

    /**
     * Funkcja ustawiajca kolor zawiasów
     * @param {object} color Kolor
     */
    function setHingeColor(color) {
        ConfigurationsService.conf.Current.HingeColor = color;
    }

    /**
     * Funkcja ustawiająca domyslny kolor dla zawiasów
     */
    function setDefaultColorForHinge() {
        const hinge = ConfigurationsService.conf.Current.Hinge;

        if (
            angular.isObject(hinge)
            && angular.isArray(hinge.colors_ids)
            && angular.isArray(handleColorsAll)
        ) {
            const windowColorId = ColorsMappingService.getWindowColorId(
                ConfigurationsService.conf.Current,
                'inner'
            );
            const windowColorRal = ColorsMappingService.getWindowColorId(
                ConfigurationsService.conf.Current,
                'inner',
                'RAL'
            );
            const matchedColors = ColorsMappingService.getColors(
                windowColorId,
                windowColorRal ? 'ral' : 'window',
                'accessory'
            );
            const colors = handleColorsAll.filter(function filterHingesColors(el) {
                return hinge.colors_ids.indexOf(el.id) > -1;
            });
            if (angular.isArray(colors) && angular.isDefined(colors[0])) {
                const windowColors = matchedColors
                    .map(m => colors.filter(c => c.id == m)[0])
                    .filter(m => m);
                let color;
                if (angular.isArray(windowColors) && angular.isDefined(windowColors[0])) {
                    color = Core.copy(windowColors[0]);
                } else {
                    color = Core.copy(colors[0]);
                }
                color.isDefault = true;
                setHingeColor(color);
            }
        }
    }

    /**
     * Funkcja ustawiająca kolor dla zawiasów
     * @param  {number} hingeColorId  Id koloru
     */
    function setHingeColorById(hingeColorId, isDefault = false) {
        const matchedColors = handleColorsAll.filter(function filterHingesColorsById(el) {
            return el.id === hingeColorId;
        });
        if (matchedColors.length > 0) {
            const color = Core.copy(matchedColors[0]);
            color.isDefault = isDefault;
            setHingeColor(color);
        }
    }

    /**
     * Funkcja ustawiajaca klamkę dla wszystkich skrzydel
     * @param {object} newHandle Nowa klamka
     */
    function setHandleInAllSashes(newHandle, place, filter = () => true) {
        ConfigurationsService.conf.Current.Sashes.filter(filter).forEach(sash => {
            setHandle(sash, place, newHandle, false, false, false);
        });
        valid(ConfigurationsService.conf.Current);
    }

    function getHandleHeightsForSash(sash, conf = ConfigurationsService.conf.Current) {
        if (
            typeof sash !== 'undefined'
            && sash != 'default'
            && sash != 'window'
            && sash != 'door'
        ) {
            const frame = conf.Frames.find(f => sash.frameId === f.id);
            const bottomProfileId =
                sash.nearMullions.bottom !== -1
                    ? conf.Mullions.find(mullion => mullion.id === sash.nearMullions.bottom)
                          .profileId
                    : frame.frame[0].profileId;
            const bottomProfile = conf.UsedProfiles.find(el => el.id === bottomProfileId);

            const sashFrameProfile = conf.UsedProfiles.find(
                el => el.id === sash.frame.bottom.profileId
            );
            const sashFrameRebate =
                !IccConfig.Configurators.handlesWithRebateHeight && sashFrameProfile
                    ? Number(sashFrameProfile.rebateWidth) * 2 || 0
                    : 0;

            const sashFrame = conf.drawData.sashFrame.find(o => o.sashId === sash.id);
            const sashFittingsHeight = sashFrame
                ? sashFrame.outer.rect.height - sashFrameRebate
                : 0;
            const fittingsGroove =
                IccConfig.Configurators.handlesAddFittingsGroove && bottomProfile
                    ? bottomProfile.spaceSash
                    : 0;

            const handlersHeights = [];
            let handleHeightData = [];
            if (angular.isArray(HandleHeightService.handlerHeights)) {
                handleHeightData = HandleHeightService.handlerHeights.filter(
                    el =>
                        parseFloat(el.window_height_from) <= sashFittingsHeight
                        && parseFloat(el.window_height_to) >= sashFittingsHeight
                        && el.window_lines
                        && el.window_lines.indexOf(ConfigurationsService.conf.Current.System.id)
                            > -1
                );
            }
            if (handleHeightData.length) {
                HandleHeightService.systemHeight = true;
            }
            if (!handleHeightData.length) {
                handleHeightData = HandleHeightService.handlerHeights.filter(
                    el =>
                        parseFloat(el.window_height_from) <= sashFittingsHeight
                        && parseFloat(el.window_height_to) >= sashFittingsHeight
                        && el.system_types
                        && el.system_types.indexOf(conf.System.type) > -1
                );
            }

            const handleHeightBalcony = handleHeightData.filter(
                el => el.balcony_window == conf.Balcony
            );
            let heightsRepository = [];
            if (angular.isDefined(handleHeightBalcony[0]) && sash.nearMullions.bottom == -1) {
                heightsRepository = handleHeightBalcony;
            } else if (angular.isDefined(handleHeightData[0])) {
                heightsRepository = handleHeightData;
            }

            if (angular.isDefined(heightsRepository[0])) {
                if (parseInt(heightsRepository[0].height.default)) {
                    handlersHeights.push({
                        name: $filter('unitmm')(
                            parseInt(heightsRepository[0].height.default) + fittingsGroove,
                            1
                        ),
                        height: parseInt(heightsRepository[0].height.default) + fittingsGroove,
                    });
                }
                handlersHeights.push(
                    ...(heightsRepository[0].height.option || [])
                        .map(el => parseInt(el))
                        .filter(
                            el =>
                                el < sashFittingsHeight
                                && el > IccConfig.Configurators.minHandleHeight
                                && (!handlersHeights[0]
                                    || handlersHeights[0].height !== el + fittingsGroove)
                        )
                        .map(el => ({
                            name: $filter('unitmm')(el + fittingsGroove, 1),
                            height: el + fittingsGroove,
                        }))
                );
                if (~~heightsRepository[0].height.middle) {
                    handlersHeights.push({
                        name: $filter('translate')('WINDOW|½ wysokości skrzydła'),
                        height: 'middle',
                    });
                }
                if (IccConfig.Configurators.nonStandardHandleHeight) {
                    handlersHeights.push({
                        name: $filter('translate')('WINDOW|niestandardowa'),
                        height: 'nonstandard',
                    });
                }
            }

            return handlersHeights;
        } else {
            return [];
        }
    }

    /**
     * Funkcja ustawiania wysokości klamki
     * @param {object} sash   Skrzydło
     * @param {object} height Wysokośc
     */
    function setHandleHeight(sash, height, customHeight) {
        if (
            angular.isDefined(sash.handle)
            && angular.isDefined(sash.handle.id)
            && noHandleTypes.indexOf(sash.type.type) == -1
        ) {
            if (height == 'middle') {
                sash.rHandleY = sash.rHeight / 2;
                sash.defaultHandleHeight = isNaN(
                    HandleHeightService.defaultHeight(sash, ConfigurationsService.conf.Current)
                );
                sash.handleHeightType = 'middle';
            } else if (height == 'nonstandard') {
                sash.rHandleY = customHeight;
                sash.defaultHandleHeight = false;
                sash.handleHeightType = 'nonstandard';
            } else {
                sash.rHandleY = height;
                sash.defaultHandleHeight =
                    HandleHeightService.defaultHeight(sash, ConfigurationsService.conf.Current)
                    == height;
                sash.handleHeightType = 'standard';
            }
            checkIsOneHandleAndAllHasHandle(ConfigurationsService.conf.Current);
            EventBusService.post({
                key: 'icc-redraw',
                value: 'sash',
            });
        }
        PriceService.count();
    }

    /**
     * Funkcja znajdujaca klamki wg systemu
     * @param  {object} system  System
     * @param  {object} fitting Okucie
     * @return {object}         Klamki
     */
    function findHandlesBySystem(system, fitting) {
        if (angular.isUndefined(ConfigurationsService.conf) && angular.isUndefined(system)) {
            return;
        }

        let systemPassed = true;
        if (angular.isUndefined(system)) {
            system = ConfigurationsService.conf.Current.System;
            fitting = ConfigurationsService.conf.Current.Fitting;
            systemPassed = false;
        }

        factory.handles = [];
        factory.handlesForSelect = [];
        angular.forEach(allHandles, handle => {
            const availableSystemsIds = handle.window_lines_ids;
            const availableFittingsIds = handle.window_fittings_ids;
            if (!angular.isUndefined(fitting)) {
                if (availableSystemsIds.indexOf(system.id) > -1) {
                    if (
                        availableFittingsIds.indexOf(fitting.id) > -1
                        || !IccConfig.Configurators.filteringHandlesForRC
                    ) {
                        if (angular.isArray(handle.colors_ids)) {
                            handle.colors_ids = handle.colors_ids.sort((a, b) =>
                                ~~a > ~~b ? 1 : -1
                            );
                        }

                        factory.handles.push(handle);
                    }

                    if (
                        availableFittingsIds.indexOf(fitting.id) > -1
                        || !IccConfig.Configurators.selectHandleOnChangeFitting
                    ) {
                        if (angular.isArray(handle.colors_ids)) {
                            handle.colors_ids = handle.colors_ids.sort((a, b) =>
                                ~~a > ~~b ? 1 : -1
                            );
                        }

                        factory.handlesForSelect.push(handle);
                    }
                }
            }
        });
        if (!systemPassed) {
            $rootScope.$broadcast('refreshHandlesColors');
        } else {
            return factory.handles;
        }
    }

    /**
     * Funkcja znajdujaca kolor dla klamki
     */
    function findColorsForHandle() {
        if (angular.isUndefined(ConfigurationsService.conf)) {
            return;
        }
        factory.handleColors = [];
        if (angular.isArray(handleColorsAll)) {
            if (angular.isObject(ConfigurationsService.conf.Current.Handle)) {
                const handlesColors = ConfigurationsService.conf.Current.Handle.colors_ids;
                if (angular.isArray(ConfigurationsService.conf.Current.Handle.colors_ids)) {
                    factory.handleColors = handleColorsAll.filter(
                        el => handlesColors.indexOf(el.id) > -1
                    );
                    $rootScope.$broadcast('refreshHandlesColors');
                    if (
                        angular.isUndefined(ConfigurationsService.conf.Current.HandleColor)
                        || angular.isUndefined(ConfigurationsService.conf.Current.HandleColor.id)
                    ) {
                        ConfigurationsService.conf.Current.HandleColor = Core.copy(
                            getDefaultColorForHandle()
                        );
                    } else {
                        const matchedColors = factory.handleColors.filter(
                            el => el.id === ConfigurationsService.conf.Current.HandleColor.id
                        );
                        if (matchedColors.length === 0) {
                            ConfigurationsService.conf.Current.HandleColor = Core.copy(
                                getDefaultColorForHandle()
                            );
                        }
                    }
                } else {
                    factory.handleColors = [];
                    $rootScope.$broadcast('refreshHandlesColors');
                }
            }
        }
    }

    function reviseHandles(sash, noRedraw) {
        if (
            !sash.handle
            || !sash.handle.id
            || angular.isUndefined(Core.fId(factory.handles, sash.handle.id))
        ) {
            setHandle(sash, 'inner', true, true, noRedraw);
        }
        if (
            !sash.handleOuter
            || !sash.handleOuter.id
            || angular.isUndefined(Core.fId(factory.handles, sash.handleOuter.id))
        ) {
            setHandle(sash, 'outer', true, true, noRedraw);
        }
        if (
            !sash.handleColor
            || !sash.handleColor.id
            || angular.isUndefined(
                Core.fId(
                    handleColorsAll.filter(
                        hc => sash.handle.colors_ids && sash.handle.colors_ids.indexOf(hc.id) > -1
                    ),
                    sash.handleColor.id
                )
            )
            || sash.handleColor.isDefault
        ) {
            setHandleColor(null, sash, 'inner', undefined, true, noRedraw);
        }
        if (
            !sash.handleOuterColor
            || !sash.handleOuterColor.id
            || angular.isUndefined(
                Core.fId(
                    handleColorsAll.filter(
                        hc =>
                            sash.handleOuter.colors_ids
                            && sash.handleOuter.colors_ids.indexOf(hc.id) > -1
                    ),
                    sash.handleOuterColor.id
                )
            )
            || sash.handleOuterColor.isDefault
        ) {
            setHandleColor(null, sash, 'outer', undefined, true, noRedraw);
        }
    }

    function setOneHandle(place, firstHandle, type) {
        if (place[type]) {
            place[type] = firstHandle[type];
        }
        if (place[type + 'Color']) {
            place[type + 'Color'] = firstHandle[type + 'Color'];
        }
    }

    function checkIsOneHandleSashType(conf, firstHandle, firstHandleOuter, sash, type) {
        if (
            (!firstHandle[type]
                || !firstHandle[type].id
                || !firstHandle[type + 'Color']
                || !firstHandle[type + 'Color'].id)
            && sash.handle
            && sash.handle.id
            && sash.handleColor
            && sash.handleColor.id
        ) {
            firstHandle[type] = Core.copy(sash.handle);
            firstHandle[type + 'Color'] = Core.copy(sash.handleColor);
        }
        if (
            (!firstHandleOuter[type]
                || !firstHandleOuter[type].id
                || !firstHandleOuter[type + 'Color']
                || !firstHandleOuter[type + 'Color'].id)
            && sash.handleOuter
            && sash.handleOuter.id
            && sash.handleOuterColor
            && sash.handleOuterColor.id
        ) {
            firstHandleOuter[type] = Core.copy(sash.handleOuter);
            firstHandleOuter[type + 'Color'] = Core.copy(sash.handleOuterColor);
        }
        if (
            (firstHandle[type] && firstHandle[type].id && sash.handle.id != firstHandle[type].id)
            || (conf.HandleType == 'InnerLever'
                && firstHandleOuter[type]
                && firstHandleOuter[type].id
                && sash.handleOuter.id != firstHandleOuter[type].id)
        ) {
            conf.OneHandleSash[type] = false;
            conf.OneHandleOuterSash[type] = false;
        }
        if (
            (firstHandle[type + 'Color']
                && firstHandle[type + 'Color'].id
                && sash.handleColor.id != firstHandle[type + 'Color'].id)
            || (conf.HandleType == 'InnerLever'
                && firstHandleOuter[type + 'Color']
                && firstHandleOuter[type + 'Color'].id
                && sash.handleOuterColor.id != firstHandleOuter[type + 'Color'].id)
        ) {
            conf.OneHandleSash[type + 'Color'] = false;
            conf.OneHandleOuterSash[type + 'Color'] = false;
        }
    }

    /**
     * Funkcja sprawdzajaca checkIsOneHandleAndAllHasHandle
     * @param  {object} conf     Konfiguracja
     * @param  {object} noRedraw noRedraw
     */
    function checkIsOneHandleAndAllHasHandle(conf, noRedraw) {
        if (angular.isUndefined(conf) || conf.Sashes.length === 0) {
            return;
        }
        conf.OneHandle = true;
        conf.OneHandleSash = {
            window: true,
            windowColor: {},
            door: true,
            doorColor: {},
        };
        conf.OneHandleOuterSash = {
            window: true,
            windowColor: {},
            door: true,
            doorColor: {},
        };
        conf.OneHandlesHeight = true;
        conf.OneHandlesDefaultHeight = true;
        conf.HasHandle = false;

        let handleHeight;
        const firstHandle = {
            window: {},
            windowColor: {},
            door: {},
            doorColor: {},
        };
        const firstHandleOuter = {
            window: {},
            windowColor: {},
            door: {},
            doorColor: {},
        };
        conf.Sashes.forEach(sash => {
            if (noHandleTypes.indexOf(sash.type.type) > -1) {
                sash.handle = {};
                sash.handleOuter = {};
                sash.rHandleY = null;
            } else {
                reviseHandles(sash, noRedraw);
                if (sash.handle && sash.handle.id) {
                    conf.HasHandle = true;
                }
                if (sash.type.type !== 'DRA' && sash.type.type !== 'DOA') {
                    checkIsOneHandleSashType(conf, firstHandle, firstHandleOuter, sash, 'window');
                }

                if (sash.type.type === 'DRA' || sash.type.type === 'DOA') {
                    checkIsOneHandleSashType(conf, firstHandle, firstHandleOuter, sash, 'door');
                }

                if (!handleHeight && sash.rHandleY) {
                    handleHeight = sash.rHandleY;
                    conf.HandleHeight = handleHeight;
                }
                if (handleHeight && sash.rHandleY != handleHeight) {
                    conf.OneHandlesHeight = false;
                }
                if (!sash.defaultHandleHeight) {
                    conf.OneHandlesDefaultHeight = false;
                }
            }
        });
        setOneHandle(conf.OneHandleSash, firstHandle, 'window');
        setOneHandle(conf.OneHandleSash, firstHandle, 'door');
        setOneHandle(conf.OneHandleOuterSash, firstHandleOuter, 'window');
        setOneHandle(conf.OneHandleOuterSash, firstHandleOuter, 'door');
        if (
            !conf.OneHandleSash.window
            || !conf.OneHandleSash.door
            || !conf.OneHandleSash.windowColor
            || !conf.OneHandleSash.doorColor
            || (conf.OwnedSashesTypes.doorActive
                && (conf.OwnedSashesTypes.window
                    && conf.Sashes.some(
                        el => !['F', 'FF', 'OFF', 'DRA', 'DRP', 'DOA', 'DOP'].includes(el.type.type)
                    ))
                && (conf.OneHandleSash.window.id != conf.OneHandleSash.door.id
                    || conf.OneHandleSash.windowColor.id != conf.OneHandleSash.doorColor.id))
        ) {
            conf.OneHandle = false;
        }
        if (conf.OneHandleSash.door && conf.OneHandleSash.door.id) {
            conf.Handle = Core.copy(conf.OneHandleSash.door);
            conf.HandleColor = Core.copy(conf.OneHandleSash.doorColor);
            ConfigurationsService.conf.Default.Handle = Core.copy(conf.OneHandleSash.door);
            ConfigurationsService.conf.Default.HandleColor = Core.copy(
                conf.OneHandleSash.doorColor
            );
        } else if (conf.OneHandleSash.window && conf.OneHandleSash.window.id) {
            conf.Handle = Core.copy(conf.OneHandleSash.window);
            conf.HandleColor = Core.copy(conf.OneHandleSash.windowColor);
            ConfigurationsService.conf.Default.Handle = Core.copy(conf.OneHandleSash.window);
            ConfigurationsService.conf.Default.HandleColor = Core.copy(
                conf.OneHandleSash.windowColor
            );
        }
        if (conf.OneHandleOuterSash.door && conf.OneHandleOuterSash.door.id) {
            conf.HandleOuter = Core.copy(conf.OneHandleOuterSash.door);
            conf.HandleOuterColor = Core.copy(conf.OneHandleOuterSash.doorColor);
            ConfigurationsService.conf.Default.HandleOuter = Core.copy(
                conf.OneHandleOuterSash.door
            );
            ConfigurationsService.conf.Default.HandleOuterColor = Core.copy(
                conf.OneHandleOuterSash.doorColor
            );
        } else if (conf.OneHandleOuterSash.window && conf.OneHandleOuterSash.window.id) {
            conf.HandleOuter = Core.copy(conf.OneHandleOuterSash.window);
            conf.HandleOuterColor = Core.copy(conf.OneHandleOuterSash.windowColor);
            ConfigurationsService.conf.Default.HandleOuter = Core.copy(
                conf.OneHandleOuterSash.window
            );
            ConfigurationsService.conf.Default.HandleOuterColor = Core.copy(
                conf.OneHandleOuterSash.windowColor
            );
        }
        findColorsForHandle();
        LocksFactory.findLocksBySystem();
        PriceService.count();
    }

    /**
     * Funkcja odświezajaca typ
     * @param  {object} conf Konfiguracja
     */
    function refreshTypes(conf) {
        if (angular.isUndefined(conf)) {
            return false;
        }
        sashTypes = conf.Sashes.map(function mapSashes(el) {
            if (noHandleTypes.indexOf(el.type.type) == -1) {
                return el.type.id;
            }
        }).filter(function filterSashes(el, pos, self) {
            return self.indexOf(el) == pos;
        });

        if (conf.Sashes.every(sash => ['F', 'FF', 'OFF'].indexOf(sash.type.type) > -1)) {
            factory.isMatch.ref = false;
            ConfigurationsService.conf.Current.Hinge = null;
            ConfigurationsService.conf.Current.HingeColor = null;
        } else {
            factory.isMatch.ref = true;
            findHingesBySystem();
            setDefaultHingeForSystem();
        }
    }

    /**
     * Funkcja filtra klamek ze względu na położenie i typ
     * @param  {object} handle Klamka
     * @return {bool}          Czy klamka pasuje do położenia i typu
     */
    function filterHandlesByPlaceAndType(handle) {
        return checkIfHandleAvailableInPlaceAndType(handle, this.place, this.type);
    }

    /**
     * Funkcja sprawdzająca czy klamka jest dostępna w danym położeniu i typie
     * @param  {object} handle Klamka
     * @param  {string} place  Położenie
     * @param  {string} type   Typ
     * @return {bool}          Czy klamka pasuje do położenia i typu
     */
    function checkIfHandleAvailableInPlaceAndType(handle, place, type) {
        if (!angular.isObject(handle)) {
            return false;
        }

        if (IccConfig.Configurators.door.version == 1) {
            return true;
        } else {
            if (angular.isUndefined(type)) {
                type = ConfigurationsService.conf.Current.HandleType;
            }

            if (angular.isUndefined(place)) {
                place = 'double';
            }

            if (
                ConfigurationsService.conf.Current.type != 'door'
                && handle.handle_type == 'innerLever'
            ) {
                return true;
            } else if (
                (place == 'innerLever' || place == 'inner')
                && type == 'InnerLever'
                && handle.handle_type == 'innerLever'
            ) {
                return true;
            } else if (
                (place == 'innerLever'
                    || place == 'inner'
                    || place == 'outer'
                    || place == 'double')
                && type == 'DoubleLever'
                && (handle.handle_type == 'doubleLever'
                    || handle.handle_type == 'leverKnob'
                    || handle.handle_type == 'doubleKnob'
                    || handle.handle_type == 'leverPull')
            ) {
                return true;
            } else if (
                (place == 'innerLever' || place == 'inner')
                && type == 'LeverPull'
                && (handle.handle_type == 'doubleLever' || handle.handle_type == 'doubleKnob')
            ) {
                return true;
            } else if (
                (place == 'innerPull' || place == 'inner')
                && type == 'DoublePull'
                && handle.handle_type == 'pull'
            ) {
                return true;
            } else if (
                (place == 'outerPull' || place == 'outer' || place == 'double')
                && (type == 'LeverPull' || type == 'DoublePull')
                && handle.handle_type == 'pull'
            ) {
                return true;
            }

            return false;
        }
    }

    /**
     * Okno modalne z wyborem klamki
     * @param  {object} sash      Skrzydło
     * @param  {string} place     Położenie
     * @param  {string} type      Typ
     */
    function openModalHandle(sash, place, type) {
        const conf = ConfigurationsService.conf.Current;
        const doorTypes = ['DRA', 'DOA'];
        let filter = () => true;
        if (sash == 'window') {
            filter = s => doorTypes.indexOf(s.type.type) == -1;
        } else if (sash == 'door') {
            filter = s => doorTypes.indexOf(s.type.type) > -1;
        }
        let maxHandleHeight = null;
        const minHandleHeight = IccConfig.Configurators.minHandleHeight;
        if (
            typeof sash !== 'undefined'
            && sash != 'default'
            && sash != 'window'
            && sash != 'door'
        ) {
            const sashFrameProfile =
                conf.UsedProfiles.find(el => el.id === sash.frame.bottom.profileId) || {};
            const sashFrameRebate = !IccConfig.Configurators.handlesWithRebateHeight
                ? Number(sashFrameProfile.rebateWidth) * 2 || 0
                : 0;

            const sashFrame = conf.drawData.sashFrame.find(o => o.sashId === sash.id);
            const sashFittingsHeight = sashFrame
                ? sashFrame.outer.rect.height - sashFrameRebate
                : 0;
            maxHandleHeight = sashFittingsHeight;
        }
        const modalInstance = $uibModal.open({
            templateUrl: 'modalHandle.html',
            controller: 'ModalHandleCtrl as mhandle',
            resolve: {
                handles: () => {
                    let handles = [];

                    if (
                        typeof sash === 'undefined'
                        || sash == 'default'
                        || sash == 'window'
                        || sash == 'door'
                    ) {
                        sashTypes = conf.Sashes.filter(
                            s => noHandleTypes.indexOf(s.type.type) == -1
                        )
                            .filter(filter)
                            .map(s => s.type.id)
                            .filter((el, pos, self) => self.indexOf(el) == pos);
                        handles = factory.handles.filter(function handlesFilter(el) {
                            const availableSashTypesIds = el.sash_types_ids;
                            for (let j = 0; j < sashTypes.length; j++) {
                                if (angular.isUndefined(sashTypes[j])) {
                                    continue;
                                }
                                if (availableSashTypesIds.indexOf(sashTypes[j]) === -1) {
                                    return false;
                                }
                            }
                            return true;
                        });
                    } else {
                        handles = factory.handles.filter(function filterHandlesForSash(el) {
                            const availableSashTypesIds = el.sash_types_ids;
                            if (
                                !angular.isUndefined(sash.type)
                                && !angular.isUndefined(sash.type.id)
                            ) {
                                if (availableSashTypesIds.indexOf(sash.type.id) > -1) {
                                    return true;
                                }
                            }
                            return false;
                        });
                    }

                    handles = handles.filter(filterHandlesByPlaceAndType, { place, type });

                    return handles;
                },
                selHandle: () => {
                    if (typeof sash === 'undefined' || sash == 'default') {
                        if (place == 'outerPull') {
                            return ConfigurationsService.conf.Current.HandleOuter;
                        } else {
                            return ConfigurationsService.conf.Current.Handle;
                        }
                    } else if (sash == 'window') {
                        if (place == 'outerPull') {
                            return ConfigurationsService.conf.Current.OneHandleOuterSash.window;
                        } else {
                            return ConfigurationsService.conf.Current.OneHandleSash.window;
                        }
                    } else if (sash == 'door') {
                        if (place == 'outerPull') {
                            return ConfigurationsService.conf.Current.OneHandleOuterSash.door;
                        } else {
                            return ConfigurationsService.conf.Current.OneHandleSash.door;
                        }
                    } else {
                        if (place == 'outerPull') {
                            return sash.handleOuter;
                        } else {
                            return sash.handle;
                        }
                    }
                },
                handleHeights: () => getHandleHeightsForSash(sash),
                handleY: () => sash.rHandleY,
                sashHeight: () => sash.rHeight,
                showHeightSelect: () => {
                    if (
                        typeof sash === 'undefined'
                        || sash == 'default'
                        || sash == 'window'
                        || sash == 'door'
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                },
                handleCategories: () => handleCategories,
                handleSubcategories: () => handleSubcategories,
                handleData: () => modalHandleData,
                handleColors: () => handleColorsAll,
                selHandleColor: () => {
                    if (typeof sash === 'undefined' || sash == 'default') {
                        if (place == 'outerPull') {
                            return ConfigurationsService.conf.Current.HandleOuterColor;
                        } else {
                            return ConfigurationsService.conf.Current.HandleColor;
                        }
                    } else if (sash == 'window') {
                        if (place == 'outerPull') {
                            return ConfigurationsService.conf.Current.OneHandleOuterSash
                                .windowColor;
                        } else {
                            return ConfigurationsService.conf.Current.OneHandleSash.windowColor;
                        }
                    } else if (sash == 'door') {
                        if (place == 'outerPull') {
                            return ConfigurationsService.conf.Current.OneHandleOuterSash.doorColor;
                        } else {
                            return ConfigurationsService.conf.Current.OneHandleSash.doorColor;
                        }
                    } else {
                        if (place == 'outerPull') {
                            return sash.handleOuterColor;
                        } else {
                            return sash.handleColor;
                        }
                    }
                },
                selHandleOuterColor: () => {
                    if (typeof sash === 'undefined' || sash == 'default') {
                        return ConfigurationsService.conf.Current.HandleOuterColor;
                    } else if (sash == 'window') {
                        return ConfigurationsService.conf.Current.OneHandleOuterSash.windowColor;
                    } else if (sash == 'door') {
                        return ConfigurationsService.conf.Current.OneHandleOuterSash.doorColor;
                    } else {
                        return sash.handleOuterColor;
                    }
                },
                b2c: () =>
                    !$rootScope.user
                    || !$rootScope.user.access
                    || $rootScope.user.access == 'klient',
                sashType: () => {
                    if (
                        typeof sash === 'undefined'
                        || sash == 'default'
                        || sash == 'window'
                        || sash == 'door'
                    ) {
                        return null;
                    } else {
                        return sash.type.type;
                    }
                },
                position: () => {
                    if (
                        typeof sash === 'undefined'
                        || sash == 'default'
                        || sash == 'window'
                        || sash == 'door'
                    ) {
                        return null;
                    } else {
                        return sash.type.handle_position;
                    }
                },
                availColorOuter: () =>
                    place == 'innerLever'
                    && ConfigurationsService.conf.Current.HandleType == 'DoubleLever'
                    && ((IccConfig.Configurators.door.availHandleColorOuter
                        && ConfigurationsService.conf.Current.type == 'door')
                        || ConfigurationsService.conf.Current.type != 'door'),
                place: () => place,
                handleHeightType: () => sash.handleHeightType,
                maxHandleHeight: () => maxHandleHeight,
                minHandleHeight: () => minHandleHeight,
                systemHeight: () => HandleHeightService.systemHeight,
            },
        });

        modalInstance.result.then(selectedData => {
            if (angular.isObject(selectedData) && angular.isDefined(selectedData.handle)) {
                if (
                    typeof sash === 'undefined'
                    || sash == 'default'
                    || sash == 'window'
                    || sash == 'door'
                ) {
                    if (place == 'innerLever' || place == 'innerPull' || !place) {
                        setHandleInAllSashes(selectedData.handle, 'inner', filter);
                        setHandleColorById(
                            selectedData.color,
                            undefined,
                            'inner',
                            filter,
                            selectedData.defaultColor
                        );
                    }
                    if (
                        (place == 'innerLever'
                            && ConfigurationsService.conf.Current.HandleType == 'DoubleLever')
                        || place == 'outerPull'
                    ) {
                        setHandleInAllSashes(selectedData.handle, 'outer', filter);
                        if (place == 'innerLever') {
                            setHandleColorById(
                                selectedData.colorOuter,
                                undefined,
                                'outer',
                                filter,
                                selectedData.defaultColorOuter
                            );
                        } else {
                            setHandleColorById(
                                selectedData.color,
                                undefined,
                                'outer',
                                filter,
                                selectedData.defaultColor
                            );
                        }
                    }
                } else {
                    if (place == 'innerLever' || place == 'innerPull' || !place) {
                        setHandle(sash, 'inner', selectedData.handle);
                        setHandleColorById(
                            selectedData.color,
                            sash,
                            'inner',
                            undefined,
                            selectedData.defaultColor
                        );
                    }
                    if (
                        (place == 'innerLever'
                            && ConfigurationsService.conf.Current.HandleType == 'DoubleLever')
                        || place == 'outerPull'
                    ) {
                        setHandle(sash, 'outer', selectedData.handle);
                        if (place == 'innerLever') {
                            setHandleColorById(
                                selectedData.colorOuter,
                                sash,
                                'outer',
                                undefined,
                                selectedData.defaultColorOuter
                            );
                        } else {
                            setHandleColorById(
                                selectedData.color,
                                sash,
                                'outer',
                                undefined,
                                selectedData.defaultColor
                            );
                        }
                    }
                    if (angular.isDefined(selectedData.height)) {
                        setHandleHeight(sash, selectedData.height, selectedData.customHeight);
                    }
                    // ustawianie polozenia klamki, jezeli jest to okno uchylne
                    if (selectedData.position && sash.type.type == 'K') {
                        sash.type.handle_position = selectedData.position;
                        ConstructionLimitationFactory.findReinforcement(
                            ConfigurationsService.conf.Current
                        );
                        EventBusService.post({
                            key: 'icc-redraw',
                            value: 'sash',
                        });
                    }
                }
                modalHandleData.selectedCategory = selectedData.category;
                modalHandleData.selectedSubcategory = selectedData.subcategory;

                EventBusService.post({ key: 'processDependencies', value: null });
            }
            EventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
        });
        modalInstance.result.finally(() => {
            EventBusService.post({
                key: 'icc-redraw',
                value: 'frame',
            });
            PriceService.count();
            TimeLimitService.count();
        });
        modalInstance.closed.then(() => {
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }
    /**
     * Okno modalne z wyborem zawiasów
     */
    function openModalHinge() {
        const modalInstance = $uibModal.open({
            templateUrl: 'modalHinge.html',
            controller: 'ModalHingeCtrl as mhinge',
            resolve: {
                hinges: () => findHingesBySystem(ConfigurationsService.conf.Current.System),
                selHinge: () => ConfigurationsService.conf.Current.Hinge,
                hingeColors: () => handleColorsAll,
                selHingeColor: () => ConfigurationsService.conf.Current.HingeColor,
                b2c: () =>
                    !$rootScope.user
                    || !$rootScope.user.access
                    || $rootScope.user.access == 'klient',
            },
        });
        if (IccConfig.Configurators.tutorialAvailable) {
            EventBusService.post({
                key: 'tutorialSteps',
                value: 'modalHinge',
            });
        }

        modalInstance.result.then(function selectedHinge(selectedData) {
            if (angular.isObject(selectedData) && angular.isDefined(selectedData.hinge)) {
                setHinge(selectedData.hinge);
                setHingeColorById(selectedData.color, selectedData.defaultColor);
                EventBusService.post({ key: 'processDependencies', value: null });
                PriceService.count();
            }
        });

        modalInstance.closed.then(() => {
            if (IccConfig.Configurators.tutorialAvailable) {
                EventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });
    }

    /**
     * Znajdowanie zawiasów dostępnych w systemie
     * @param   {any}   system System
     * @returns {array} Tablica z zawiasami pasujcymi do systemu
     */
    function findHingesBySystem(system) {
        let systemPassed = true;
        if (angular.isUndefined(ConfigurationsService.conf) && angular.isUndefined(system)) {
            factory.hinges = [];
        } else {
            const sashes = ConfigurationsService.conf.Current.Sashes || [];
            const fittingId = ConfigurationsService.conf.Current.Fitting.id;

            if (angular.isUndefined(system)) {
                system = ConfigurationsService.conf.Current.System;
                systemPassed = false;
            }

            if (
                sashes.some(sash => ['F', 'FF', 'OFF'].indexOf(sash.type.type) === -1)
                && angular.isArray(allHinges)
            ) {
                factory.hinges = allHinges
                    .filter(
                        hinge =>
                            angular.isArray(hinge.window_lines_ids)
                            && angular.isArray(hinge.window_fittings_ids)
                            && hinge.window_lines_ids.indexOf(system.id) > -1
                            && hinge.window_fittings_ids.indexOf(fittingId) > -1
                            && hinge.type == 'hinge_' + system.type
                    )
                    .map(hinge => {
                        if (angular.isArray(hinge.colors_ids)) {
                            hinge.colors_ids = hinge.colors_ids.sort((a, b) => a - b);
                        }
                        return hinge;
                    });
            } else {
                factory.hinges = [];
            }
        }

        if (factory.hinges.length) {
            factory.isMatch.ref = true;
        } else {
            factory.isMatch.ref = false;
            ConfigurationsService.conf.Current.Hinge = null;
            ConfigurationsService.conf.Current.HingeColor = null;
        }

        if (!systemPassed) {
            $rootScope.$broadcast('refreshHingesColors');
        }
        return factory.hinges;
    }

    /**
     * Funkcja walidujaca
     * @param  {object} conf Konfiguracja
     */
    function valid(conf) {
        if (configurators.indexOf(CurConfService.conf) === -1) {
            IssuesService.unregister('incorrect-handle', conf);
            return true;
        }
        const reasons = [];
        let blockAddToOffer = false;
        for (let i = 0; i < conf.Sashes.length; i++) {
            const sash = conf.Sashes[i];
            if (
                CurConfService.conf != 'door'
                && angular.isDefined(sash.handle.window_fittings_ids)
                && angular.isObject(conf.Fitting)
                && sash.handle.window_fittings_ids.indexOf(conf.Fitting.id) == -1
                && noHandleTypes.indexOf(sash.type.type) == -1
            ) {
                reasons.push({
                    sashId: i + 1,
                    sashIndex: sash.index,
                    reason: $filter('translate')(
                        'WINDOW|Wybrana klamka nie pasuje do okucia bezpiecznego.'
                    ),
                    handle: sash.handle.name,
                    fitting: conf.Fitting.name,
                });
                blockAddToOffer =
                    blockAddToOffer || !IccConfig.Configurators.selectHandleOnChangeFitting;
            }

            if (angular.isUndefined(sash.handle) && noHandleTypes.indexOf(sash.type.type) == -1) {
                reasons.push({
                    sashId: i + 1,
                    sashIndex: sash.index,
                    reason: $filter('translate')(
                        'WINDOW|Żadna klamka nie pasuje do wybranego systemu i typu skrzydła.'
                    ),
                    handle: 'INTERFACE|Brak',
                    fitting: conf.Fitting.name,
                });
                blockAddToOffer = true;
            }
        }

        if (reasons.length) {
            const reason =
                reasons.reduce(
                    (prev, e) =>
                        prev
                        + `<li>${e.reason}<b>
                ${$filter('translate')('WINDOW|Okucie')}: ${e.fitting},
                ${$filter('translate')('WINDOW|Klamka')}: ${e.handle},
                ${$filter('translate')('WINDOW|Kwatera')}: ${e.sashIndex}
            </b></li>`,
                    '<ul>'
                ) + '</ul>';
            IssuesService.simpleRegister('incorrect-handle', 'Nie pasujące klamki', reason, conf, {
                blockAddToOffer,
                logLevel: IssueLevel.NONE,
                extra: {
                    reason
                }
            });
            return false;
        } else {
            IssuesService.unregister('incorrect-handle', conf);
            return true;
        }
    }
}
