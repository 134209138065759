import angular from 'angular';

/**
 * Rysowanie
 * @param {Object} $rootScope            rootScope
 * @param {Object} $scope                scope
 * @param {Object} ConfigurationsService ConfigurationsService
 * @param {Object} DrawDataService       DrawDataService
 * @param {Object} CurConfService        CurConfService
 * @param {Object} SashesFactory         SashesFactory
 * @param {Object} Core                  Core
 * @param {Object} LayoutFactory         LayoutFactory
 */
export default function DrawingCtrl(
    $scope,
    ConfigurationsService,
    IccConfig,
    CurConfService,
    DrawService,
    SashesFactory,
    Core,
    EventBusService,
    AlignmentsService,
    ExtensionsService,
    $filter,
    FramesService,
    RemoveSashService,
    SplitSashService,
    ResizeSashService,
    ProfilesModalService,
    EqualDivisionService
) {
    'ngInject';

    var vm = this;

    vm.config = ConfigurationsService.conf.Current;

    vm.drawService = DrawService;
    vm.drawOptions = null;
    vm.drawButtons = {
        alignments: true,
        dimensions: false,
        extensions: true,
        fullscreen: false,
        mullions: true,
        profiles: false,
        sashes: true,

        toggle: type => {
            if (type && typeof vm.drawButtons[type] !== 'undefined') {
                vm.drawButtons[type] = !vm.drawButtons[type];
            }

            vm.drawButtons.update();
        },

        update: () => {
            vm.drawOptions = angular.merge({}, DrawService.options, {
                dimensions: {
                    multiplier: 1,
                    type: vm.drawButtons.fullscreen
                        ? 'extended'
                        : DrawService.options.dimensions.type,
                },

                frame: {
                    actions:
                        ['window', 'door'].includes(vm.config.type)
                        || (vm.config.type === 'hs' && IccConfig.Configurators.hs.layoutEditing)
                            ? {
                                  onClick: response =>
                                      ProfilesModalService.changeProfile(vm.config, 'frame', {
                                          position: response.index,
                                          frame: response.frame,
                                      }),
                                  onHover: vm.drawButtons.profiles ? 'single-hover' : 'group-hover',
                              }
                            : {},
                },
                sashFrame: {
                    actions:
                        ['window', 'door'].includes(vm.config.type)
                        || (vm.config.type === 'hs' && IccConfig.Configurators.hs.layoutEditing)
                            ? {
                                  onClick: response =>
                                      ProfilesModalService.changeProfile(vm.config, 'sashFrame', {
                                          position: response.index,
                                          sash: response.sash,
                                      }),
                                  onHover: vm.drawButtons.profiles ? 'single-hover' : 'group-hover',
                              }
                            : {},
                },
                extensions: {
                    actions: {
                        onClick: response =>
                            ProfilesModalService.changeProfile(vm.config, 'extension', {
                                extension: response.extensions[response.index].extension,
                                position: response.extensions[response.index].side,
                            }),
                        onHover: 'single-hover',
                    },
                    buttons: [
                        {
                            class: 'ico-sides',
                            positions: ['top', 'bottom', 'left', 'right'],
                            title: $filter('translate')('INTERFACE|Edytuj poszerzenia'),
                            buttons: [
                                {
                                    class: 'ico-plus',
                                    condition: response =>
                                        (response.index === 'bottom'
                                            && vm.config.Shape.shape !== 'circle')
                                        || (response.index !== 'bottom'
                                            && vm.config.Shape.shape === 'rect'
                                            && !vm.config.hasRoller),
                                    onClick: response =>
                                        ProfilesModalService.selectExtensionAndPut(
                                            vm.config,
                                            response.index
                                        ),
                                    positions: ['bottom', 'left', 'right', 'top'],
                                    title: $filter('translate')('INTERFACE|Dodaj poszerzenie'),
                                },
                                {
                                    class: 'ico-remove',
                                    condition: response => {
                                        const frameEdges = FramesService.getFrameProfilesIndexesOnTheEdge(
                                            response.index,
                                            vm.config
                                        );
                                        return (
                                            ((response.index === 'bottom'
                                                && vm.config.Shape.shape !== 'circle')
                                                || (response.index !== 'bottom'
                                                    && vm.config.Shape.shape === 'rect'
                                                    && !vm.config.hasRoller))
                                            && vm.config.SideProfiles.filter(sp =>
                                                sp.framesId.some(
                                                    f =>
                                                        frameEdges
                                                        && frameEdges.some(
                                                            fE =>
                                                                f.id === fE.frameId
                                                                && f.edges.includes(
                                                                    fE.frameEdgeIndex
                                                                )
                                                        )
                                                )
                                            ).length
                                        );
                                    },
                                    onClick: response =>
                                        ExtensionsService.removeExtensionFromSide(
                                            vm.config,
                                            response.index
                                        ),
                                    positions: ['bottom', 'left', 'right', 'top'],
                                    title: $filter('translate')('INTERFACE|Usuń poszerzenie'),
                                },
                            ],
                        },
                    ],
                },
                mullion: {
                    actions:
                        ['window', 'door'].includes(vm.config.type)
                        || (vm.config.type === 'hs' && IccConfig.Configurators.hs.layoutEditing)
                            ? {
                                  onClick: response =>
                                      ProfilesModalService.changeProfile(vm.config, 'mullion', {
                                          mullion: response.mullion,
                                      }),
                                  condition: response => response.mullion.profileId,
                                  onHover: 'group-hover',
                                  title: $filter('translate')('INTERFACE|Edytuj słupek'),
                              }
                            : {},
                    buttons:
                        ['window', 'door'].includes(vm.config.type)
                        || (vm.config.type === 'hs' && IccConfig.Configurators.hs.layoutEditing)
                            ? [
                                  {
                                      class: 'ico-move',
                                      title: $filter('translate')('INTERFACE|Edytuj słupek'),
                                      buttons: [
                                          {
                                              class: 'ico-equal-glass',
                                              title: $filter('translate')(
                                                  'INTERFACE|Równy podział po szkle'
                                              ),
                                              onClick: response => {
                                                  const mullion = response.sash
                                                      ? Core.fIdO(
                                                            Core.fIdO(
                                                                vm.config.Sashes,
                                                                response.sash.id
                                                            ).intMullions,
                                                            response.mullion.id
                                                        )
                                                      : Core.fIdO(
                                                            vm.config.Mullions,
                                                            response.mullion.id
                                                        );
                                                  EqualDivisionService.equalDivision(
                                                      mullion,
                                                      vm.config,
                                                      'glazing'
                                                  );
                                              },
                                          },
                                          {
                                              class: 'ico-equal-axis',
                                              title: $filter('translate')(
                                                  'INTERFACE|Równy podział do osi'
                                              ),
                                              onClick: response => {
                                                  const mullion = response.sash
                                                      ? Core.fIdO(
                                                            Core.fIdO(
                                                                vm.config.Sashes,
                                                                response.sash.id
                                                            ).intMullions,
                                                            response.mullion.id
                                                        )
                                                      : Core.fIdO(
                                                            vm.config.Mullions,
                                                            response.mullion.id
                                                        );
                                                  EqualDivisionService.equalDivision(
                                                      mullion,
                                                      vm.config,
                                                      'axis'
                                                  );
                                              },
                                          },
                                          {
                                              class: 'ico-move-submit',
                                              title: $filter('translate')(
                                                  'INTERFACE|Edytuj słupek'
                                              ),
                                              condition: response => response.mullion.profileId,
                                              onClick: response =>
                                                  ProfilesModalService.changeProfile(
                                                      vm.config,
                                                      'mullion',
                                                      { mullion: response.mullion }
                                                  ),
                                          },
                                          {
                                              class: 'ico-move',
                                              title: $filter('translate')(
                                                  'INTERFACE|Przesuń słupek'
                                              ),
                                              onMove: response => {
                                                  const mullion = response.sash
                                                      ? Core.fIdO(
                                                            Core.fIdO(
                                                                vm.config.Sashes,
                                                                response.sash.id
                                                            ).intMullions,
                                                            response.mullion.id
                                                        )
                                                      : Core.fIdO(
                                                            vm.config.Mullions,
                                                            response.mullion.id
                                                        );
                                                  const diff =
                                                      mullion[
                                                          mullion.direction === 'vertical'
                                                              ? 'rx'
                                                              : 'ry'
                                                      ] - response.position;
                                                  ResizeSashService.changeSashWidth(
                                                      mullion,
                                                      diff,
                                                      response.direction,
                                                      vm.config,
                                                      response.positionMin,
                                                      response.positionMax,
                                                    );
                                              },
                                          },
                                      ],
                                  },
                              ]
                            : [],
                    minPosition: IccConfig.Configurators.minWidthGlass || 0,
                    maxPosition: IccConfig.Configurators.maxWidth || null,
                },
                glazing: {
                    buttons: [
                        {
                            class: 'ico-gear',
                            title: $filter('translate')('INTERFACE|Edytuj kwaterę'),
                            buttons: [
                                {
                                    class: 'ico-split-h',
                                    title: $filter('translate')('INTERFACE|Podziel w poziomie'),
                                    condition: response => {
                                        if (angular.isDefined(response.sash.parentId)) {
                                            const sash = Core.fIdO(
                                                vm.config.Sashes,
                                                response.sash.parentId
                                            );
                                            return canDivIntHorizontal(sash);
                                        } else {
                                            const sash = Core.fIdO(
                                                vm.config.Sashes,
                                                response.sash.id
                                            );
                                            return canDivHorizontal(sash);
                                        }
                                    },
                                    onClick: response => {
                                        if (angular.isDefined(response.sash.parentId)) {
                                            const sash = Core.fIdO(
                                                vm.config.Sashes,
                                                response.sash.parentId
                                            );
                                            const intSash = Core.fIdO(
                                                sash.intSashes,
                                                response.sash.id
                                            );
                                            SplitSashService.splitInternalSash(
                                                sash,
                                                intSash,
                                                'horizontal',
                                                vm.config
                                            );
                                        } else {
                                            const sash = Core.fIdO(
                                                vm.config.Sashes,
                                                response.sash.id
                                            );
                                            SplitSashService.splitSash(
                                                sash,
                                                'horizontal',
                                                vm.config
                                            );
                                        }
                                    },
                                },
                                {
                                    class: 'ico-split-v',
                                    title: $filter('translate')('INTERFACE|Podziel w pionie'),
                                    condition: response => {
                                        if (angular.isDefined(response.sash.parentId)) {
                                            const sash = Core.fIdO(
                                                vm.config.Sashes,
                                                response.sash.parentId
                                            );
                                            return canDivIntVertical(sash);
                                        } else {
                                            const sash = Core.fIdO(
                                                vm.config.Sashes,
                                                response.sash.id
                                            );
                                            return canDivVertical(sash);
                                        }
                                    },
                                    onClick: response => {
                                        if (angular.isDefined(response.sash.parentId)) {
                                            const sash = Core.fIdO(
                                                vm.config.Sashes,
                                                response.sash.parentId
                                            );
                                            const intSash = Core.fIdO(
                                                sash.intSashes,
                                                response.sash.id
                                            );
                                            SplitSashService.splitInternalSash(
                                                sash,
                                                intSash,
                                                'vertical',
                                                vm.config
                                            );
                                        } else {
                                            const sash = Core.fIdO(
                                                vm.config.Sashes,
                                                response.sash.id
                                            );
                                            SplitSashService.splitSash(sash, 'vertical', vm.config);
                                        }
                                    },
                                },
                                {
                                    class: 'ico-remove',
                                    title: $filter('translate')('INTERFACE|Usuń kwaterę'),
                                    condition: response => {
                                        if (angular.isDefined(response.sash.parentId)) {
                                            const sash = Core.fIdO(
                                                vm.config.Sashes,
                                                response.sash.parentId
                                            );
                                            return (
                                                ['window', 'door'].includes(vm.config.type)
                                                || (vm.config.type === 'hs'
                                                    && IccConfig.Configurators.hs.layoutEditing)
                                                || sash.intSashes.length > 1
                                            );
                                        } else {
                                            return vm.config.Sashes.length > 1;
                                        }
                                    },
                                    onClick: response => {
                                        if (angular.isDefined(response.sash.parentId)) {
                                            const sash = Core.fIdO(
                                                vm.config.Sashes,
                                                response.sash.parentId
                                            );
                                            const intSash = Core.fIdO(
                                                sash.intSashes,
                                                response.sash.id
                                            );
                                            RemoveSashService.removeSash(intSash, vm.config);
                                        } else {
                                            const sash = Core.fIdO(
                                                vm.config.Sashes,
                                                response.sash.id
                                            );
                                            RemoveSashService.removeSash(sash, vm.config);
                                        }
                                    },
                                },
                                {
                                    class: 'ico-sash',
                                    title: $filter('translate')('INTERFACE|Funkcja kwatery'),
                                    condition: response => {
                                        const isAvailable = SashesFactory.canBeSashTypeChanged(vm.config, response.sash);
                                        if (!isAvailable) {
                                            return false;
                                        }
                                        if (angular.isDefined(response.sash.parentId)) {
                                            return (
                                                vm.config.type !== 'hs'
                                                || IccConfig.Configurators.hs.layoutEditing
                                            );
                                        } else {
                                            const sash = Core.fIdO(
                                                vm.config.Sashes,
                                                response.sash.id
                                            );
                                            return (
                                                (vm.config.type !== 'hs'
                                                    || IccConfig.Configurators.hs.layoutEditing)
                                                && (vm.config.type !== 'door'
                                                    || sash.type.type !== 'F'
                                                    || sash.nearMullions.top !== -1
                                                    || sash.nearMullions.bottom === -1)
                                            );
                                        }
                                    },
                                    onClick: response => {
                                        // TODO: SashesService.openSashTypeModal(response.sash);
                                        const sashId = angular.isDefined(response.sash.parentId)
                                            ? response.sash.parentId
                                            : response.sash.id;
                                        const sash = Core.fIdO(vm.config.Sashes, sashId);
                                        SashesFactory.openSashTypeModal(sash);
                                    },
                                },
                                {
                                    class: 'ico-sides',
                                    title: $filter('translate')('INTERFACE|Edytuj wyrównania'),
                                    condition: response => {
                                        const field = angular.isDefined(response.sash.parentId)
                                            ? Core.fIdO(
                                                  Core.fIdO(
                                                      vm.config.Sashes,
                                                      response.sash.parentId
                                                  ).intSashes,
                                                  response.sash.id
                                              )
                                            : Core.fIdO(vm.config.Sashes, response.sash.id);
                                        return AlignmentsService.isAvailableAlignments(
                                            field,
                                            vm.config
                                        );
                                    },
                                    buttons: [
                                        {
                                            class: 'ico-plus',
                                            title: $filter('translate')(
                                                'INTERFACE|Dodaj wyrównanie'
                                            ),
                                            condition: response =>
                                                (response.index === 'bottom'
                                                    && vm.config.Shape.shape !== 'circle')
                                                || (response.index !== 'bottom'
                                                    && vm.config.Shape.shape === 'rect'
                                                    && !vm.config.hasRoller),
                                            onClick: response => {
                                                const field = angular.isDefined(
                                                    response.sash.parentId
                                                )
                                                    ? Core.fIdO(
                                                          Core.fIdO(
                                                              vm.config.Sashes,
                                                              response.sash.parentId
                                                          ).intSashes,
                                                          response.sash.id
                                                      )
                                                    : Core.fIdO(vm.config.Sashes, response.sash.id);
                                                ProfilesModalService.selectAlignmentAndPut(
                                                    field,
                                                    vm.config,
                                                    response.index
                                                );
                                            },
                                            positions: ['bottom', 'left', 'right', 'top'],
                                        },
                                        {
                                            class: 'ico-remove',
                                            title: $filter('translate')(
                                                'INTERFACE|Usuń wyrównanie'
                                            ),
                                            condition: response =>
                                                ((response.index === 'bottom'
                                                    && vm.config.Shape.shape !== 'circle')
                                                    || (response.index !== 'bottom'
                                                        && vm.config.Shape.shape === 'rect'
                                                        && !vm.config.hasRoller))
                                                && response.sash.nearAlignments[response.index]
                                                    !== -1,
                                            onClick: response => {
                                                const field = angular.isDefined(
                                                    response.sash.parentId
                                                )
                                                    ? Core.fIdO(
                                                          Core.fIdO(
                                                              vm.config.Sashes,
                                                              response.sash.parentId
                                                          ).intSashes,
                                                          response.sash.id
                                                      )
                                                    : Core.fIdO(vm.config.Sashes, response.sash.id);
                                                AlignmentsService.removeAlignmentInField(
                                                    field,
                                                    vm.config,
                                                    response.index
                                                );
                                            },
                                            positions: ['bottom', 'left', 'right', 'top'],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
                coupling: {
                    actions:
                        ['window', 'door'].includes(vm.config.type)
                        || (vm.config.type === 'hs' && IccConfig.Configurators.hs.layoutEditing)
                            ? {
                                  onClick: response =>
                                      ProfilesModalService.changeProfile(vm.config, 'coupling', {
                                          coupling: response.coupling,
                                      }),
                                  condition: response => response.coupling.profileId,
                                  onHover: 'group-hover',
                                  title: $filter('translate')('INTERFACE|Edytuj łącznik'),
                              }
                            : {},
                    buttons:
                        ['window', 'door'].includes(vm.config.type)
                        || (vm.config.type === 'hs' && IccConfig.Configurators.hs.layoutEditing)
                            ? [
                                  {
                                      class: 'ico-move',
                                      title: $filter('translate')('INTERFACE|Edytuj łącznik'),
                                      buttons: [
                                          {
                                              class: 'ico-equal-glass',
                                              title: $filter('translate')(
                                                  'INTERFACE|Równy podział po szkle'
                                              ),
                                              onClick: response => {
                                                  const coupling = Core.fIdO(
                                                      vm.config.couplings,
                                                      response.coupling.id
                                                  );
                                                  EqualDivisionService.equalDivision(
                                                      coupling,
                                                      vm.config,
                                                      'glazing'
                                                  );
                                              },
                                          },
                                          {
                                              class: 'ico-equal-axis',
                                              title: $filter('translate')(
                                                  'INTERFACE|Równy podział do osi'
                                              ),
                                              onClick: response => {
                                                  const coupling = Core.fIdO(
                                                      vm.config.couplings,
                                                      response.coupling.id
                                                  );
                                                  EqualDivisionService.equalDivision(
                                                      coupling,
                                                      vm.config,
                                                      'axis'
                                                  );
                                              },
                                          },
                                          {
                                              class: 'ico-move-submit',
                                              title: $filter('translate')(
                                                  'INTERFACE|Edytuj łącznik'
                                              ),
                                              condition: response => response.coupling.profileId,
                                              onClick: response =>
                                                  ProfilesModalService.changeProfile(
                                                      vm.config,
                                                      'coupling',
                                                      { coupling: response.coupling }
                                                  ),
                                          },
                                          {
                                              class: 'ico-move',
                                              title: $filter('translate')(
                                                  'INTERFACE|Przesuń łącznik'
                                              ),
                                              onMove: response => {
                                                  const coupling = vm.config.couplings.find(
                                                      c => c.id === response.coupling.id
                                                  );
                                                  FramesService.moveCoupling(
                                                      coupling,
                                                      response.position,
                                                      vm.config,
                                                      response.positionMin,
                                                      response.positionMax,
                                                  );
                                              },
                                          },
                                      ],
                                  },
                              ]
                            : [],
                    minPosition: IccConfig.Configurators.minWidthGlass || 0,
                    maxPosition: IccConfig.Configurators.maxWidth || null,
                },
                onInterfaceClick: () => {
                    $scope.$apply();
                },
            });

            EventBusService.post({
                key: 'icc-redraw',
                value: null,
            });
        },
    };
    vm.drawButtons.update();

    const subscription = EventBusService.subscribeWithoutConfiguration(
        'changedConfigurator',
        () => {
            vm.conf = CurConfService.conf;
        }
    );

    $scope.$on('$destroy', () => subscription.unsubscribe());

    function canDivVertical(sash) {
        var shape = ConfigurationsService.conf.Current.Shape;

        if (
            vm.config.type === 'folding_door'
            || (vm.config.type === 'hs' && !IccConfig.Configurators.hs.layoutEditing)
        ) {
            return false;
        }

        // koło - mogą być max 2 skrzydła
        if (
            (shape.shape == 'circle' && ConfigurationsService.conf.Current.Sashes.length == 2)
            || (CurConfService.conf == 'door'
                && ((IccConfig.Configurators.door.version == 1
                    && !ConfigurationsService.conf.Current.Model.div_vertical)
                    || (IccConfig.Configurators.door.version == 2
                        && sash.glazing.type == 'deco_panels'))
                && sash.type.type != 'F'
                && sash.type.type != 'K')
        ) {
            return false;
        }

        return true;
    }

    function canDivHorizontal(sash) {
        var shape = ConfigurationsService.conf.Current.Shape;

        if (
            vm.config.type === 'folding_door'
            || (vm.config.type === 'hs' && !IccConfig.Configurators.hs.layoutEditing)
        ) {
            return false;
        }

        // koło - mogą być max 2 skrzydła
        if (
            (shape.shape == 'circle' && ConfigurationsService.conf.Current.Sashes.length == 2)
            || (CurConfService.conf == 'door'
                && ((IccConfig.Configurators.door.version == 1
                    && !ConfigurationsService.conf.Current.Model.div_horizontal)
                    || (IccConfig.Configurators.door.version == 2
                        && sash.glazing.type == 'deco_panels'))
                && sash.type.type != 'F'
                && sash.type.type != 'K')
        ) {
            return false;
        }

        return true;
    }

    function canDivIntVertical(sash) {
        var shape = ConfigurationsService.conf.Current.Shape;

        if (
            vm.config.type === 'folding_door'
            || (vm.config.type === 'hs' && !IccConfig.Configurators.hs.layoutEditing)
        ) {
            return false;
        }

        if (
            shape.shape == 'circle'
            || (CurConfService.conf == 'door'
                && ((IccConfig.Configurators.door.version == 1
                    && !ConfigurationsService.conf.Current.Model.div_vertical)
                    || (IccConfig.Configurators.door.version == 2
                        && sash.glazing.type == 'deco_panels'))
                && sash.type.type != 'F'
                && sash.type.type != 'K')
        ) {
            return false;
        }

        return true;
    }

    function canDivIntHorizontal(sash) {
        var shape = ConfigurationsService.conf.Current.Shape;

        if (
            vm.config.type === 'folding_door'
            || (vm.config.type === 'hs' && !IccConfig.Configurators.hs.layoutEditing)
        ) {
            return false;
        }

        if (
            shape.shape == 'circle'
            || (CurConfService.conf == 'door'
                && ((IccConfig.Configurators.door.version == 1
                    && !ConfigurationsService.conf.Current.Model.div_horizontal)
                    || (IccConfig.Configurators.door.version == 2
                        && sash.glazing.type == 'deco_panels'))
                && sash.type.type != 'F'
                && sash.type.type != 'K')
        ) {
            return false;
        }

        return true;
    }
}
