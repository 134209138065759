import angular from 'angular';
import moment from 'moment';
import { core, logger } from 'helpers';
import Common from 'Common';

import OfferKeyService from '../../../common/offers/OfferKeyService';
import PositionsDetailsService from '../../../common/PositionsDetailsService';
import OfferSequenceService from '../../../common/offers/OfferSequenceService';
import OfferTransportCostService from '../../../common/offers/OfferTransportCostService';
import PositionsService from '../../../common/offers/PositionService';
import OfferDiscountsService from 'offers/OfferDiscountsService';
import TranslateService from '../../../common/translations/TranslateService';

/**
 * Konstruktor factory widoku oferty
 * @param {object} $rootScope                              rootScope
 * @param {object} $filter                                 filter
 * @param {object} $http                                   http
 * @param {object} $location                               location
 * @param {object} OffersFactory                           OffersFactory
 * @param {object} UserService                             UserService
 * @param {object} PositionsFactory                        PositionsFactory
 * @param {object} OnlineStatusService                     OnlineStatusService
 * @param {object} Core                                    Core
 * @param {object} WindowConfigurationFactory              WindowConfigurationFactory
 * @param {object} HSConfigurationFactory                  HSConfigurationFactory
 * @param {object} DoorConfigurationFactory                DoorConfigurationFactory
 * @param {object} RollerShutterConfigurationFactory       RollerShutterConfigurationFactory
 * @param {object} ComplementaryGoodsConfigurationFactory  ComplementaryGoodsConfigurationFactory
 * @param {object} AccessoriesConfigurationFactory         AccessoriesConfigurationFactory
 * @param {object} AwningConfigurationFactory              AwningConfigurationFactory
 * @param {object} GarageConfigurationFactory              GarageConfigurationFactory
 * @param {object} WinterGardenConfigurationFactory        WinterGardenConfigurationFactory
 * @param {object} MosquitoConfigurationFactory            MosquitoConfigurationFactory
 * @param {object} DatabaseManager                         DatabaseManager
 * @param {object} ClientsFactory                          ClientsFactory
 */
export default function OfferViewFactory(
    $q,
    $rootScope,
    $window,
    $filter,
    $http,
    $location,
    OffersFactory,
    OffersService,
    UserService,
    PositionsFactory,
    OnlineStatusService,
    Core,
    DatabaseManager,
    ClientsFactory,
    OfferAttachmentsFactory,
    PositionAttachmentsFactory,
    StateService,
    OfferPriceService,
    EnvConfig,
    DrawService,
    ConfiguratorsDataService,
    IccConfig,
    $uibModal,
    DiscountsAndMultipliersService,
    ConfigurationsService,
    offerOptions,
    InfoFactory,
    EventBusService,
    GtmService,
    TokenService,
    LangService,
    SynchronizeService,
    isNative,
    ManyPositionsService,
    OfferSummaryService,
) {
    'ngInject';

    var factory = {
        activeClient,
        addAdditionalPosition,
        addCopy,
        addCustomPosition,
        addNewOfferDiscount,
        addSubOffer,
        copyDemoOffer,
        createDemoOffer,
        addSubOrder,
        anotherClientOffer,
        cancelOffer,
        clearOffer,
        createOfferForClient,
        createOrder,
        confirmOrder,
        createValuation,
        deleteOfferDiscount,
        downloadFile,
        getComments,
        getDealersList,
        getFirstDealersList,
        openServicePage,
        quickSendInquiry,
        quickSendOrder,
        recalcDiscountRate,
        recalcDiscountValue,
        refreshDiscounts,
        removePosition,
        sendClientInquiry,
        addComment,
        sentToClient: confirmSendToClient,
        setClientToOffer,
        setDealerAndRefferToOffer,
        updateOfferClientSpecialDiscount,
        updateOfferDescription,
        updateOfferName,
        updateOfferDeliveryAddress,
        updateOfferDealerAdvance,
        updatePositionCustomTitle,
        updatePositionDescription,
        updatePostitionQuantity,
        updateOfferHeader,
        updateOfferPositionsDiscount,
        valuate,
        countPricesFromClient,
        countPricesFromDealer,
        confirmOrderEdit,
        setOrderStatus,
        setOfferStatus,
        updateOfferDealerTaxRate,
        getDealerSellersList,
        setDealerSellerToOffer,
        openModalHistory,
        getAttachments,
        getDealers,
        getClientsTypes,
        getSellers,
        getOffer,
        redirectToStoreCart,
        consultOffer,
        selectTransportCost,
        updateClientTransportCost,
        toggleClientSplitTransportCost,
        removeClientTransportCost,
        saveCustomKeyValue,
        getTaxRates,
        checkIsLoadedAllPositions,
        updateOfferMargin,
        filters: null,
        loadFilters: false,
        getTranslatedData,
        mergeTranslatedData,
        updateOfferCurrency
    };

    var user = UserService.get();
    let syncTimeout;

    $rootScope.$on('$routeChangeStart', (e, next, current) => {
        if (
            current.$$route.originalPath === '/app/offers_view/:id?'
            && ['/app/offers/:dealer?', '/app/orders/:id?'].includes(next.$$route.originalPath)
        ) {
            factory.loadFilters = true;
        }
    });

    return factory;

    /**
     * Zamawianie oferty
     * @param  {object} offer Oferta
     */
    function confirmOrder(offer) {
        if (IccConfig.Offer.confirmOrder) {
            InfoFactory.confirmModal(
                $filter('translate')('OFFER|Potwierdzenie wysłania zamówienia'),
                $filter('translate')('OFFER|Czy na pewno wysłać zamówienie do producenta?'),
                [
                    {
                        name: $filter('translate')('INTERFACE|Tak'),
                        callback: () => createOrder(offer),
                    },
                    {
                        name: $filter('translate')('INTERFACE|Nie'),
                        callback: () => { },
                        accent: true,
                    },
                ]
            );
        } else {
            createOrder(offer);
        }
    }

    /**
     * Tworzenie oferty demo.
     * @param  {Object} offer  Oferta
     */
    function createDemoOffer(offer) {
        const target = $rootScope.b2cShop ? 'shop' : 'store';
        $rootScope.loader = true;
        if (!offer.demo_target) {
            OffersService.update(
                offer._id,
                angular.extend(offer, {
                    demo_target: target,
                    demo_offer_expiration: moment()
                        .add(90, 'd')
                        .format('YYYY-MM-DD HH:mm:ss'),
                })
            )
                .then(() => {
                    $rootScope.showInfo(
                        $filter('translate')('OFFER|Oferta demonstracyjna wygenerowana'),
                        null
                    );
                    $rootScope.loader = false;
                    $location.path('/app/demo/' + offer.tmp_id);
                })
                .catch(() => {
                    $rootScope.showInfo(
                        $filter('translate')(
                            'OFFER|Błąd podczas tworzenia oferty demonstracyjna',
                            null
                        )
                    );
                    $rootScope.loader = false;
                });
        } else {
            $rootScope.loader = false;
            $rootScope.showInfo($filter('translate')('OFFER|Oferta jest już demonstracyjna', null));
            $location.path('/app/demo/' + offer.tmp_id);
        }
    }
    /**
     * Tworzy zamówienie.
     * @param  {Object} offer  Oferta
     */
    function createOrder(source) {
        $rootScope.loader = true;
        const timeout = setTimeout(
            () =>
                logger.error(
                    new Error(
                        `Zamawianie oferty trwało dłużej niż ${IccConfig.Raven.orderTimeout}s.`
                    )
                ),
            IccConfig.Raven.orderTimeout * 1000
        );
        const address = `${EnvConfig.remoteHost || window.location.origin}/api/order/${
            source.tmp_id
            }?iccAppVersion=${IccConfig.Version}`;
        if (OnlineStatusService.getStatus()) {
            $http
                .get(address)
                .then(response => {
                    $rootScope.showInfo(
                        $filter('translate')('OFFER|Oferta została zamówiona, trwa wysyłanie'),
                        null,
                        () => {
                            PositionsFactory.whenAllSynced(response.data.orderId).then(() => {
                                $location.url('/app/offers_view/' + response.data.orderId);
                                clearTimeout(timeout);
                            });
                        }
                    );
                })
                .catch(error => {
                    $rootScope.showInfo(
                        $filter('translate')('OFFER|Oferta nie została przekazana do zamówienia'),
                        null
                    );
                    clearTimeout(timeout);
                    logger.error(error);
                    $rootScope.loader = false;
                });
        }
    }

    /**
     * DO POPRAWY:
     * - połączyć settings i header
     * Wczytuje do pamięci dane klienta, dilera i nagłówka
     * @param {object} [offerDATA] dane oferty
     * @param {function} [callback] funkcja po zapisie
     */
    function activeClient(offerDATA, emptyOffer, callback) {
        let newState;
        if (StateService.state.offer && offerDATA.tmp_id === StateService.state.offer.tmp_id) {
            newState = StateService.state;
        } else {
            newState = {
                offer_id: offerDATA.tmp_id,
                client: {},
                offers: [{ doc: offerDATA.doc ? offerDATA.doc : offerDATA }],
                key: offerDATA.key,
                offer: offerDATA.doc,
            };
        }

        if (typeof offerDATA.client_id === 'string' && offerDATA.client_id.length) {
            ClientsFactory.get(offerDATA.client_id).then(c => {
                callback(c, null);
                newState.client = c;
            });
        } else {
            ClientsFactory.list().then(cs => {
                callback(null, cs);
            });
        }
        if (emptyOffer) {
            OffersService.get(offerDATA.tmp_id).then((parent) => {
                setSuboffersAndState(newState, offerDATA, [{
                    doc: offerDATA
                }], parent);
            }, () => {
                OffersService.get(offerDATA.tmp_id).then((parent) => {
                    setSuboffersAndState(newState, offerDATA, [{
                        doc: offerDATA
                    }], parent);
                });
            });
        } else {
            OffersFactory.getSubOffers(offerDATA.parent_id || offerDATA.tmp_id).then((o) => {
                OffersService.get(offerDATA.parent_id || offerDATA.tmp_id).then((parent) => {
                    setSuboffersAndState(newState, offerDATA, o, parent);
                }, () => {
                    OffersService.get(offerDATA.tmp_id).then((parent) => {
                        setSuboffersAndState(newState, offerDATA, o, parent);
                    });
                });
            });
        }
    }

    /**
     * Funkcja ustawiająca dane dla nagłówka
     * @param {object} state        Nowy state dla headera
     * @param {object} offer        Oferta
     * @param {array}  subOffers    Tablica podofert
     * @param {object} parentOffer  Główna oferta
     */
    function setSuboffersAndState(state, offer, subOffers, parentOffer) {
        if ($rootScope.editedPositionId) {
            const documentElement = document.getElementById($rootScope.editedPositionId);
            if (documentElement) {
                documentElement.scrollIntoView();
                delete $rootScope.editedPositionId;
            }
        }
        subOffers = subOffers.sort((a, b) => {
            var keyA = parseInt(a.doc.version);
            var keyB = parseInt(b.doc.version);
            return keyA < keyB ? -1 : keyA > keyB ? 1 : 0;
        });
        subOffers = subOffers.filter(e => e.doc.tmp_id != offer.tmp_id);
        if (parentOffer.tmp_id != offer.tmp_id) {
            subOffers.unshift({ doc: parentOffer });
        }
        subOffers.unshift({ doc: offer });
        state.offers = subOffers;
        state.offer = subOffers[0].doc;
        const searched = OfferSequenceService.keysFromSequence(offer.sequence);
        ManyPositionsService.listById(searched, offer.tmp_id, true, false, true).then(d => {
            state.positions = d.pos;
            StateService.setState(state);
            $rootScope.offerAdded = true;
            $rootScope.$apply();
        });
    }

    /**
     * Przekazywanie oferty do wyceny
     * @param  {object} offer Oferta do wyceny
     */
    function createValuation(offer) {
        $rootScope.loader = true;
        const updatedData = {
            order: '0',
            status: '2',
            offer: '1',
        };
        if (IccConfig.Offer.valuationEmail) {
            const dealerName = $rootScope.user.dealerfvname;
            const key = core.parseJson(offer.key).join('');
            updatedData.mail_message = {
                to: ['dealer', 'dealerh', 'producent'],
                type: 'valuation',
                title: $filter('translate')(
                    'OFFER|Klient {dealerName} przekazał ofertę o numerze {key} do wyceny w systemie IC COMPLEX',
                    { dealerName, key }
                ),
            };
        }
        OffersService.update(offer._id, angular.extend(offer, updatedData))
            .then(() => {
                if ($rootScope.user.access === 'dealer' || $rootScope.user.access === 'dealerh') {
                    $rootScope.showInfo(
                        $filter('translate')('OFFER|Oferta została przekazana do wyceny'),
                        null,
                        'app/offers'
                    );
                    $rootScope.loader = false;
                } else if ($rootScope.user.access === 'producent') {
                    $rootScope.showInfo(
                        $filter('translate')('OFFER|Oferta została przekazana do wyceny'),
                        null
                    );
                    $rootScope.loader = false;
                } else {
                    $rootScope.showInfo(
                        $filter('translate')('OFFER|Oferta została przekazana do wyceny'),
                        null,
                        () => {
                            PositionsFactory.whenAllSynced(offer._id).then(() => {
                                window.location.href = '/dealer/sellers/offers';
                            });
                        }
                    );
                }
            })
            .catch(() => {
                $rootScope.showInfo(
                    $filter('translate')('OFFER|Oferta nie została przekazana do wyceny', null)
                );
                $rootScope.loader = false;
            });
    }

    /**
     * Przekazywanie oferty do wyceny
     * @param  {object} offer Oferta do wyceny
     */
    function consultOffer(offer) {
        if ($rootScope.user.access === 'dealer' || $rootScope.user.access === 'dealerh') {
            OffersService.update(
                offer._id,
                angular.extend(offer, {
                    order: '0',
                    consulted: true,
                    offer: '1',
                })
            )
                .then(() => {
                    $rootScope.showInfo(
                        $filter('translate')('OFFER|Oferta została przekazana do konsultacji'),
                        null
                    );
                    $rootScope.loader = false;
                })
                .catch(() => {
                    $rootScope.showInfo(
                        $filter('translate')(
                            'OFFER|Oferta nie została przekazana do konsultacji',
                            null
                        )
                    );
                    $rootScope.loader = false;
                });
        }
    }

    /**
     * Dodanie zwykłago opisu do oferty
     * @param  {object} offerData       Dane oferty
     * @param  {object} description     Opis oferty
     * @param  {object} synced          Synced
     */
    function updateOfferDescription(offerData, description, synced) {
        offerData.description = description;
        $rootScope.loader = true;
        OffersService.update(offerData._id, offerData).then(() => {
            $rootScope.showInfo(
                $filter('translate')('OFFER|Opis został zaktualizowany'),
                null,
                null
            );
            synced.val = false;
            offerData.synced = false;
            $rootScope.loader = false;
        });
    }

    /**
     * Dodanie nazwy do oferty
     * @param  {object} offerData       Dane oferty
     * @param  {object} offerName     Nazwa oferty
     * @param  {object} synced          Synced
     */
    function updateOfferName(offerData, offerName, synced) {
        offerData.offer_name = offerName;
        $rootScope.loader = true;
        OffersService.update(offerData._id, offerData).then(() => {
            $rootScope.showInfo(
                $filter('translate')('OFFER|Nazwa została zaktualizowana'),
                null,
                null
            );
            synced.val = false;
            offerData.synced = false;
            $rootScope.loader = false;
        });
    }

    /**
     * Dodanie adresu dostawy do oferty
     * @param  {object} offerData           Dane oferty
     * @param  {object} delivery_address     Adres dostawy
     * @param  {object} synced              Synced
     */
    function updateOfferDeliveryAddress(offerData, delivery_address, synced) {
        offerData.delivery_address = delivery_address;
        $rootScope.loader = true;
        OffersService.update(offerData._id, offerData).then(() => {
            $rootScope.showInfo(
                $filter('translate')('OFFER|Adres został zaktualizowany'),
                null,
                null
            );
            synced.val = false;
            offerData.synced = false;
            $rootScope.loader = false;
        });
    }

    /**
     * Dodanie zaliczki przez dealera do oferty
     * @param  {object} offerData           Dane oferty
     * @param  {object} delivery_address     Adres dostawy
     * @param  {object} synced              Synced
     */
    function updateOfferDealerAdvance(offerData, dealer_advance, synced) {
        offerData.dealer_advance = dealer_advance;
        $rootScope.loader = true;
        OffersService.update(offerData._id, offerData).then(() => {
            $rootScope.showInfo(
                $filter('translate')('OFFER|Zaliczka została zaktualizowana'),
                null,
                null
            );
            synced.val = false;
            offerData.synced = false;
            $rootScope.loader = false;
        });
    }

    /**
     * Dodanie zwykłago opisu do pozycji
     * @param  {object} positionDATA    positionDATA
     * @param  {object} description     description
     * @param  {object} synced          synced
     */
    function updatePositionDescription(positionDATA, description, synced, offer) {
        $rootScope.loader = true;

        positionDATA.description = description;
        positionDATA.synced = false;

        PositionsFactory.update(positionDATA._id, positionDATA, offer).then(() => {
            $rootScope.showInfo(
                $filter('translate')('OFFER|Opis został zaktualizowany'),
                null,
                null
            );
            synced.val = false;
            $rootScope.loader = false;
        });
    }

    /**
     * Dodanie tytułu włsnego do pozycji
     * @param {type} [positionDATA]
     * @param {type} [customTitle]
     */
    /**
     * [updatePositionCustomTitle description]
     * @param  {object} positionDATA    positionDATA
     * @param  {object} customTitle     customTitle
     * @param  {object} synced          synced
     */
    function updatePositionCustomTitle(positionDATA, customTitle, synced, offer) {
        positionDATA.custom_title = customTitle;
        positionDATA.synced = false;

        PositionsFactory.update(positionDATA._id, positionDATA, offer).then(() => {
            $rootScope.showInfo(
                $filter('translate')('OFFER|Nazwa własna została zaktualizowana'),
                null,
                null
            );
            synced.val = false;
        });
    }

    /**
     * [addCopy description]
     * @param { object}   org       org
     * @param { object}   offer     oferta
     * @param { function} callback  callback
     */
    function addCopy(org, offer, callback) {
        if (confirm($filter('translate')('OFFER|Czy na pewno dodać kopię tej pozycji?'))) {
            $rootScope.loader = true;
            var object = Core.copy(org); // ok
            PositionsFactory.add(
                angular.extend(object.doc, {
                    id: null,
                    tmp_id: null,
                    offer,
                    price: object.doc.dealer_price,
                })
            ).then(() => {
                $rootScope.showInfo(
                    $filter('translate')('OFFER|Utworzono kopię pozycji.'),
                    null,
                    null
                );
                callback();
            });
        }
    }

    /**
     * Usuwanie pozycji z oferty
     * @param {object} object               pod.doc z pozycji dokumentu
     * @param {function} callback           callback
     */
    function removePosition(object, offer, callback) {
        if (confirm($filter('translate')('OFFER|Czy na pewno usunć tą pozycję?'))) {
            $rootScope.loader = true;
            if (object.confType === 'coupled_window') {
                const ids = [
                    object.tmp_id,
                    ...object.configuration.windows.map(
                        el => el.positionId
                    ),
                    ...object.configuration.rollerShutters.map(
                        el => el.positionId
                    ),
                ];
                PositionsFactory.removeMany(ids, offer).then(() => {
                    $rootScope.showInfo(
                        $filter('translate')('OFFER|Usunięto produkt z oferty.'),
                        null,
                        null
                    );
                    callback();
                });
            } else {
                PositionsFactory.remove(object._id, object, offer).then(() => {
                    $rootScope.showInfo(
                        $filter('translate')('OFFER|Usunięto produkt z oferty.'),
                        null,
                        null
                    );
                    callback();
                });
            }
        }
    }

    /**
     * Pobieranie załcznika
     * @param  {file}   attachment  zalacznik
     * @param  {object} fileFactory rodzja zalacznika
     * @param  {object} offer       ofera
     * @param  {object} attachments dostepne zalaczniki
     */
    function downloadFile(attachment, fileFactory, offer, attachments) {
        var available = (attachments ? attachments.rows || [] : []).filter(
            function filterAttachments(e) {
                return e.doc.tmp_id == attachment.tmp_id;
            }
        );

        if (available.length && angular.isDefined(available[0].doc.data)) {
            makeDownloadElement(available[0].doc.data, available[0].doc.name);
        } else {
            fileFactory.get(attachment.tmp_id, offer).then(function afterGetFile(res) {
                makeDownloadElement(res._attachments[res.name].data, res.name);
            });
        }
    }

    /**
     * Tworzy klikalny element w DOM
     * @param  {blob}   data Dane pliku
     * @param  {name}   name Nazwa pliku
     */
    function makeDownloadElement(data, name) {
        setTimeout(() => {
            var downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(Core.b64toBlob(data));
            downloadLink.download = name;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }, 0);
    }

    /**
     * Dodawanie pozycji dodatkowej
     * @param { object}   position  pozycja
     * @param { object}   offer     oferta
     * @param { function} callback    callback
     */
    function addAdditionalPosition(position, offer, callback) {
        var price = position.price
            ? parseFloat((position.price + '').replace(/\s/g, '').replace(',', '.'))
            : 0;
        var type = $rootScope.user.access == 'producent' ? 'other' : 'additional';

        if (angular.isUndefined(position.name)) {
            $rootScope.showInfo(
                $filter('translate')('OFFER|Podaj nazwę pozycji'),
                null,
                null,
                2000
            );
        } else if (isNaN(price)) {
            $rootScope.showInfo(
                $filter('translate')('OFFER|Cena musi być liczbą'),
                null,
                null,
                2000
            );
        } else if ((price.toString().split('.')[1] || []).length > 2) {
            $rootScope.showInfo(
                $filter('translate')('OFFER|Cena może mieć do 2 miejsc po przecinku'),
                null,
                null,
                2000
            );
        } else if (!~~position.quantity) {
            $rootScope.showInfo($filter('translate')('OFFER|Podaj ilość'), null, null, 2000);
        } else {
            $rootScope.loader = true;
            var priceBeforeDiscount = 0;
            var dealerPrice = 0;
            var dealerPriceBeforeDiscount = 0;
            var clientPrice = 0;
            const dealerMargin = offer ? offer.dealer_margin * 1 : 0;

            if (type === 'other') {
                priceBeforeDiscount = Core.roundPrice(
                    (price * offer.currency.value) / (1 - offer.dealer_discount_producer / 100)
                );
                dealerPrice = price * offer.currency.value;
                dealerPriceBeforeDiscount = priceBeforeDiscount;
                priceBeforeDiscount *= 1 + dealerMargin / 100;
                clientPrice = Core.round(
                    priceBeforeDiscount
                    - (1 / 100) * offer.client_discount_position * priceBeforeDiscount
                );
            } else {
                priceBeforeDiscount = Core.roundPrice(
                    (price * offer.currency.value) / (1 - offer.client_discount_position / 100)
                );
                dealerPrice = 0;
                dealerPriceBeforeDiscount = 0;
                clientPrice = price * offer.currency.value;
            }

            PositionsFactory.add({
                configuration: {
                    System: { name: $filter('translate')('OFFER|Pozycje dodatkowe') },
                    Quantity: position.quantity,
                    type,
                    GoodType: position.type,
                    Name: position.name,
                },
                details: {
                    system: { name: $filter('translate')('OFFER|Pozycje dodatkowe') },
                    quantity: position.quantity,
                    type,
                    goodType: position.type,
                    name: position.name,
                },
                offer,
                price: clientPrice,
                client_price: clientPrice,
                client_price_before_discount: priceBeforeDiscount,
                dealer_price_before_discount: dealerPriceBeforeDiscount,
                dealer_price: dealerPrice,
                group_discounts: [
                    {
                        client: {
                            price: priceBeforeDiscount,
                            type: 'system',
                            value:
                                (1 / 100)
                                * (offer.client_discount_position || 0)
                                * (priceBeforeDiscount || 0),
                            discount: offer.client_discount_position,
                        },
                        dealer: {
                            price: dealerPriceBeforeDiscount,
                            type: 'system',
                            value:
                                (1 / 100)
                                * (offer.dealer_discount_producer || 0)
                                * (dealerPriceBeforeDiscount || 0),
                            discount: offer.dealer_discount_producer,
                        },
                    },
                ],
            }).then(function afterAddAdditional() {
                if (type === 'other') {
                    $rootScope.showInfo(
                        $filter('translate')('OFFER|Dodano inną pozycję'),
                        null,
                        null
                    );
                } else {
                    $rootScope.showInfo(
                        $filter('translate')('OFFER|Dodano pozycję dodatkową'),
                        null,
                        null
                    );
                }
                callback();
            });
        }
    }

    /**
     * Dodanie pozycji niestandardowej
     * [addCustomPosition description]
     * @param {Object}   offer    oferta
     * @param {Function} callback callback
     */
    function addCustomPosition(offer, callback) {
        if (~~offer.order) {
            $rootScope.showInfo(
                $filter('translate')(
                    'OFFER|Pozycje bez wyceny można dodać tylko do oferty, nie podczas edycji zamówienia.'
                )
            );
        } else {
            PositionsFactory.add({
                configuration: {
                    System: { name: $filter('translate')('OFFER|Pozycje niestandardowe') },
                    type: 'custom',
                    Quantity: 1,
                    Name: $filter('translate')('OFFER|Pozycja niestandardowa'),
                },
                details: {
                    system: { name: $filter('translate')('OFFER|Pozycje niestandardowe') },
                    type: 'custom',
                    quantity: 1,
                    name: $filter('translate')('OFFER|Pozycja niestandardowa'),
                },
                offer,
                standard: false,
                price: NaN,
                group_discounts: [
                    {
                        client: {
                            price: 0,
                            type: 'system',
                            value: 0,
                            discount: offer.client_discount_position,
                        },
                        dealer: {
                            price: 0,
                            type: 'system',
                            value: 0,
                            discount: offer.dealer_discount_producer,
                        },
                    },
                ],
            }).then(function afterAddCustomPosition(pos) {
                $rootScope.showInfo(
                    $filter('translate')('OFFER|Pozycja niestandardowa zostala utworzona.')
                    + ' '
                    + (!offer.valuation
                        ? $filter('translate')(
                            'OFFER|Cała oferta będzie teraz skierowana do wyceny.'
                        )
                        : ''),
                    null,
                    null
                );
                callback();
                setTimeout(function timeoutFunction() {
                    $rootScope.goTo(pos.id);
                }, 500);
            });
        }
    }

    /**
     * Ustawienie klienta do oferty
     * @param { object} client  klient
     * @param { object} offer   oferta
     * @param { object} synced  synced
     */
    function setClientToOffer(client, offer, synced) {
        const tmpOffer = offer;
        var key = OffersFactory.changeKey($rootScope.user.offer_scheme, offer.key, {
            K_CLIENT: client.doc.reference,
        });
        StateService.setKey('key', key);

        OffersService.update(
            tmpOffer._id,
            angular.extend(tmpOffer, {
                client_id: client.doc.tmp_id,
                forComapny: parseInt(client.company) === 1 ? true : false,
                companyname: client.doc.company_name || '',
                clientname: client.doc.name || '',
                clientsurname: client.doc.surname || '',
                key,
            })
        ).then(() => {
            synced.val = false;
            offer.synced = false;
            $rootScope.showInfo($filter('translate')('OFFER|Przypisano klienta do oferty'), null);
        });
    }

    /**
     * Wybór rodzaju transportu
     * @param { boolean}  transport_from_producent Czy transport od producenta
     */
    function selectTransportCost(transport_from_producent, offer, callback) {
        const tmpOffer = offer;
        OffersService.update(
            tmpOffer.tmp_id,
            angular.extend(tmpOffer, { transport_from_producent })
        )
            .then(() => DatabaseManager.get('Users').get())
            .then(users => {
                users = users && users.data ? core.parseJson(users.data).users || {} : {};
                const dealer = users.dealers
                    ? users.dealers.find(el => el.id == offer.dealer_id)
                    : null;
                return OfferSummaryService.autoUpdateOffer(
                    offer.tmp_id,
                    null,
                    null,
                    offer.sequence,
                    false,
                    dealer
                );
            })
            .then(() => callback());
    }

    /**
     * Aktualizuje koszt transportu do klienta
     */
    function updateClientTransportCost(offer, client_transport_cost, callback) {
        const tmpOffer = offer;
        OffersService.update(tmpOffer.tmp_id, angular.extend(tmpOffer, { client_transport_cost }))
            .then(() =>
                OfferSummaryService.autoUpdateOffer(offer.tmp_id, null, null, offer.sequence)
            )
            .then(() => callback());
    }

    /**
     * Przełącza rozbijanie kosztu transportu do klienta na pozycje
     */
    function toggleClientSplitTransportCost(offer, client_split_transport_cost, callback) {
        const tmpOffer = offer;
        OffersService.update(
            tmpOffer.tmp_id,
            angular.extend(tmpOffer, { client_split_transport_cost })
        ).then(() => callback());
    }

    /**
     * Usuwa koszt transportu do klienta
     */
    function removeClientTransportCost(offer, callback) {
        const tmpOffer = offer;
        OffersService.update(
            tmpOffer.tmp_id,
            angular.extend(tmpOffer, {
                client_split_transport_cost: false,
                client_transport_cost: null,
            })
        )
            .then(() =>
                OfferSummaryService.autoUpdateOffer(offer.tmp_id, null, null, offer.sequence)
            )
            .then(() => callback());
    }

    /**
     * Przypisywanie handlowca dealera do oferty
     * @param { object}  dealerSellerId  Handlowiec dealera
     * @param { object}  offer           Oferta
     * @param {Function} callback        callback
     */
    function setDealerSellerToOffer(dealerSellerId, offer, callback) {
        if (parseInt(dealerSellerId)) {
            if (parseInt(dealerSellerId) < 0) {
                dealerSellerId = null;
            }
            const tmpOffer = offer;
            OffersService.update(
                tmpOffer.tmp_id,
                angular.extend(tmpOffer, { dealer_seller_id: dealerSellerId || null })
            ).then(() => callback());
        }
    }

    /**
     * Funkcja ustawiająca dealera i odwołująca do oferty
     * @param { object}   dealer        dealer
     * @param { object}   clientRefer   clientRefer
     * @param { object}   offer         offer
     * @param { object}   synced        synced
     * @param {Function} callback       callback
     */
    function setDealerAndRefferToOffer(dealer, client, clientRefer, orgOffer, synced, callback) {
        var key;
        var offer = orgOffer;

        if (angular.isDefined(clientRefer)) {
            clientRefer = clientRefer.toUpperCase();
        }

        if (
            !(
                angular.isDefined(dealer)
                || angular.isDefined(clientRefer)
                || angular.isDefined(client)
            )
        ) {
            $rootScope.showInfo(
                $filter('translate')('OFFER|Wybierz dealera, klienta lub podaj referencję klienta'),
                null
            );
            return;
        } else if (angular.isDefined(client)) {
            setClientToOffer(client, offer, synced);
        } else if (angular.isDefined(clientRefer) && angular.isUndefined(dealer)) {
            key = OffersFactory.changeKey($rootScope.user.offer_scheme, offer.key, {
                K_CLIENT: clientRefer,
            });
            OffersService.update(
                offer._id,
                angular.extend(offer, {
                    key,
                    client_id: -1,
                    reference: clientRefer,
                })
            ).then(function afterOfferUpdate() {
                synced.val = false;
                offer.synced = false;
                $rootScope.showInfo(
                    $filter('translate')('OFFER|Dodano referencję do oferty'),
                    null
                );
            });
        } else if (angular.isDefined(dealer)) {
            DiscountsAndMultipliersService.getOfferDiscountAsync().then(offerDiscountsAll => {
                var offerDATA = offer;
                var q = dealer;
                var offerDiscounts;

                var today = new Date();
                var toCheckMinDate = new Date(q.discount_special_from);
                var toCheckMaxDate = new Date(q.discount_special_to);
                if (today >= toCheckMinDate && today <= toCheckMaxDate) {
                    offerDATA.dealer_discount_producer_special = q.discount_special || 0;
                    offerDATA.dealer_discount_producer_special_date_from = q.discount_special_from;
                    offerDATA.dealer_discount_producer_special_date_to = q.discount_special_to;
                } else {
                    offerDATA.dealer_discount_producer_special = 0;
                }

                offerDATA.client_discount_position = parseFloat(q.discount_default) || 0;
                offerDATA.dealer_discount_producer = parseFloat(q.discount) || 0;
                offerDATA.client_discount_special = 0;
                offerDATA.dealer_margin = q.margin || 0;
                offerDATA.dealer_tax_rate = q.vat || (q.vat === 0 ? 0 : null);

                offerDATA.dealer_id = q.id;
                offerDATA.dealercity = q.fv_city;
                offerDATA.dealername = q.fv_name;
                offerDATA.dealersurname = q.fv_surname;
                // offerDATA.seller_id  = q.seller_id;
                offerDATA.client_id = clientRefer ? -1 : null;
                offerDATA.reference = clientRefer ? clientRefer : null;

                var customDiscounts = (angular.copy(offer.group_discounts) || []).filter(
                    function filterDiscounts(e) {
                        return e.custom;
                    }
                );
                var dealerDiscounts = (offerDiscountsAll || []).filter(function filterDiscounts(e) {
                    return e.dealer_id == dealer.id || !e.dealer_id;
                });
                offerDiscounts = OfferDiscountsService.groupDiscounts(
                    dealerDiscounts.concat(customDiscounts),
                    offer.dealer_price_before_discount,
                    offerDATA,
                    $rootScope.user
                );
                offerDATA.group_discounts = offerDiscounts || [];
                let multipliers = DiscountsAndMultipliersService.getMultipliers();
                multipliers = multipliers[offerDATA.dealer_id] || multipliers
                offerDATA.multipliers = multipliers;
                offerDATA.key = OffersFactory.changeKey($rootScope.user.offer_scheme, offer.key, {
                    K_DEALER: q.dealercode,
                    K_CLIENT: clientRefer,
                    N_DEALER: 'OFFER',
                });
                if (IccConfig.Offer.zeroKeyNumber) {
                    offerDATA.number = 0;
                }

                OffersService.update(offer._id, offerDATA)
                    .then(() => PositionsFactory.addTransportCost(offer, dealer))
                    .then(() => PositionsFactory.multiUpdatePrices(offer.tmp_id))
                    .then(() => {
                        $rootScope.showInfo(
                            $filter('translate')('OFFER|Przypisano dealera do oferty'),
                            null
                        );
                        callback();
                    });
            });
        }
    }

    /**
     * Pobiera listę komentarzy
     * @param   {string}   id        id
     * @param  {function} callback    Callback
     */
    function getComments(id, callback) {
        $http
            .get(
                (EnvConfig.remoteHost || window.location.origin)
                + '/dealer/offers/get_comments/'
                + id
                + '.json'
            )
            .then(function successGetComments(data) {
                callback(data.data.comments);
            })
            .catch(function errorGetComments() {
                $rootScope.showInfo(
                    $filter('translate')('OFFER|Połacz się z internetem aby pobrać komentarze.'),
                    null,
                    null,
                    10000
                );
            });
    }

    /**
     * Wysyła komentarz do oferty
     * @param  {string}   comment     Komentarz
     * @param  {string}   userId      Użytkownik
     * @param  {string}   flag        Komentarz wewnętrzny/zewnętrzny
     * @param  {string}   id          Id
     * @param  {function} callback    Callback
     */
    function addComment(comment, userId, flag, offer, callback) {
        $http
            .post(
                `${EnvConfig.remoteHost || window.location.origin}/dealer/offers/send_comment/${
                offer.tmp_id
                }.json`,
                {
                    comment,
                    flag,
                    id: offer.tmp_id,
                    user: userId,
                }
            )
            .then(function successAddComment() {
                if (IccConfig.Offer.commentEmail) {
                    const dealerName = $rootScope.user.dealerfvname;
                    const key = core.parseJson(offer.key).join('');
                    return OffersService.update(
                        offer.tmp_id,
                        angular.extend(offer, {
                            mail_sent: null,
                            mail_message: {
                                type: 'comment',
                                title: $filter('translate')(
                                    'OFFER|Klient {dealerName} dodał nowy komentarz do oferty o numerze {key} w systemie IC COMPLEX',
                                    { dealerName, key }
                                ),
                                content: comment,
                            },
                        })
                    );
                } else {
                    return Promise.resolve();
                }
            })
            .then(() => {
                callback();
            })
            .catch(function errorAddComment() {
                $rootScope.showInfo(
                    $filter('translate')('OFFER|Nie udało się wysłać komentarza.'),
                    null,
                    null,
                    10000
                );
            });
    }

    /**
     * Aktualizowanie rabatu dodatkowego calej oferty
     * @param  {    object}   discount  Zniżka
     * @param  {    object}   offer     Oferta
     * @param  {Function} callback      Callback
     */
    function updateOfferClientSpecialDiscount(discount, offer) {
        discount = Core.roundPrice(discount);
        if (
            $rootScope.user.access === 'dealerh'
            && Number($rootScope.user.dealer_seller.discount_special) < discount
        ) {
            discount = Number($rootScope.user.dealer_seller.discount_special);
            $rootScope.showInfo(
                $filter('translate')('OFFER|Przekroczono maksymalny rabat.'),
                null,
                null,
                10000
            );
        }
        // discount = ~~(discount);

        if (discount < 0) {
            discount = 0;
        } else if (discount > 100) {
            discount = 100;
        }

        offer.dealer_client_price =
            offer.dealer_client_price_before_discount
            - (1 / 100) * offer.dealer_client_price_before_discount * discount;
        offer.client_price =
            offer.client_price_before_discount
            - (1 / 100) * offer.client_price_before_discount * discount;
        Object.assign(offer, {
            client_discount_special: discount,
            dealer_client_price:
                offer.dealer_client_price_before_discount
                - (1 / 100) * offer.dealer_client_price_before_discount * discount,
            client_price:
                offer.client_price_before_discount
                - (1 / 100) * offer.client_price_before_discount * discount,
        });
        return new Promise((resolve, reject) => {
            OffersService.update(offer._id, offer).then(() => {
                resolve(offer);
            });
        });
    }

    /**
     * Aktualizowanie vatu oferty
     * @param  {number}   dealerTaxRate  Vat
     * @param  {object}   offer          Oferta
     * @param  {Function} callback       Callback
     */
    function updateOfferDealerTaxRate(dealerTaxRate, offer, callback) {
        var tmpOffer = offer;
        OffersService.update(
            tmpOffer._id,
            angular.extend(tmpOffer, { dealer_tax_rate: dealerTaxRate })
        ).then(() => callback());
    }

    /*
     * Aktualizowanie danych pozycji oferty po zmianie ilości
     * @param {object} [positions.doc] Dane pozycji
     * @param {int} [positions.doc.quantity] Nowa wartość rabatu
     * @param  {Function} callback  Callback
     */
    function updatePostitionQuantity(positionDATA, newquantity, callback, offer) {
        if (positionDATA.confType === 'coupled_window') {
            PositionsFactory.updateMany(
                [
                    positionDATA._id,
                    ...positionDATA.details.windows.map(window => window.positionId),
                    ...positionDATA.details.rollerShutters.map(
                        rollerShutter => rollerShutter.positionId
                    ),
                ],
                {
                    quantity: newquantity,
                },
                offer
            ).then(() => {
                callback();
            });
        } else {
            PositionsFactory.update(
                positionDATA._id,
                angular.extend(positionDATA, { quantity: newquantity }),
                offer
            ).then(() => {
                callback();
            });
        }
    }

    /**
     * Funkcja wylicza wartość rabatu oraz uaktualnia ceny
     * @param  {object}   pos           Pozycja
     * @param  {object}   offer         Oferta
     * @param  {Function} callback      Callback
     */
    function recalcDiscountValue(pos, i, offer, callback) {
        var posData = pos;
        if (
            $rootScope.user.access === 'dealerh'
            && Number($rootScope.user.dealer_seller.discount_max)
            < posData.group_discounts[i].client.discount
        ) {
            posData.group_discounts[i].client.discount = Number(
                $rootScope.user.dealer_seller.discount_max
            );
            $rootScope.showInfo(
                $filter('translate')('OFFER|Przekroczono maksymalny rabat.'),
                null,
                null,
                10000
            );
        }
        PositionsFactory.updatePrices(posData, offer).then(() => {
            callback();
        });
    }

    /**
     * Funkcja wylicza stawkę rabatu oraz uaktualnia ceny
     * @param  {Object}   pos           Pozycja
     * @param  {Number}   i                licznik
     * @param  {Object}   offer         Oferta
     * @param  {Function} callback      Callback
     */
    function recalcDiscountRate(pos, i, offer, callback) {
        var posData = pos;

        if (
            (posData.group_discounts[i].client.discount = Core.roundPrice(
                (posData.group_discounts[i].client.value / posData.group_discounts[i].client.price)
                * 100
            ))
            || ~~posData.group_discounts[i].client.value === 0
        ) {
            if (
                $rootScope.user.access === 'dealerh'
                && Number($rootScope.user.dealer_seller.discount_max)
                < posData.group_discounts[i].client.discount
            ) {
                posData.group_discounts[i].client.discount = Number(
                    $rootScope.user.dealer_seller.discount_max
                );
                $rootScope.showInfo(
                    $filter('translate')('OFFER|Przekroczono maksymalny rabat.'),
                    null,
                    null,
                    10000
                );
            }
            PositionsFactory.updatePrices(posData, offer).then(() => {
                callback();
            });
        }
    }

    /**
     * Nowa oferta dla klienta indywidualnego
     * @param  {Object}   offer         Oferta
     * @param  {Object}   client        Klient
     */
    function anotherClientOffer(offer, client) {
        $rootScope.loader = true;
        OffersFactory.add(
            {
                status: '99', // w trakcie tworzenia
                client_offer: 1,
                dealer_status: '2', // U klienta
                dealer_id: offer.dealer_id,
                seller_id: offer.seller_id,
                name: offer.name,
                surname: offer.surname,
                b2c_offer_source: 1,
            },
            angular.copy(client)
        ).then(id => {
            StateService.setState({
                offer_id: id,
                offers: null,
                client: null,
                key: null,
                offer: { id },
            });
            $rootScope.showInfo(
                $filter('translate')('OFFER|Nowa oferta została utworzona'),
                null,
                () => {
                    $location.path('/app/offers_view/' + id);
                }
            );
        });

        return;
    }

    /**
     * Oferta dla klienta indywidualnego
     * @param  {Object}   offer         Oferta
     * @param  {Object}   client        Klient
     */
    function createOfferForClient(offer, client) {
        $rootScope.loader = true;
        var offerDATA = angular.copy(offer);
        var sequence = angular.copy(offerDATA.sequence);

        OffersFactory.add(
            angular.extend(offerDATA, {
                copied_offer: offerDATA.tmp_id,
                status: '1', // Nowa
                dealer_status: '0', // U dealera
                client_offer: 0,
                sequence: null,
                key: null,
                number: null,
                b2c_offer_source: 1,
            }),
            client || {}
        ).then(res => {
            var newOfferId = res;
            OfferAttachmentsFactory.multiCopy(offerDATA.tmp_id, newOfferId).then(() => {
                PositionsFactory.multiCopy(offerDATA, newOfferId, sequence, ['other'], true).then(
                    relations => {
                        PositionAttachmentsFactory.multiCopy(
                            offerDATA.tmp_id,
                            newOfferId,
                            relations
                        ).then(() => {
                            OffersService.update(
                                offer._id,
                                angular.extend(offer, { no_reaction_alert_sent: 1, synced: false })
                            ).then(() => {
                                $rootScope.showInfo(
                                    $filter('translate')(
                                        'OFFER|Oferta została utworzona, poczekaj na załadowanie...'
                                    ),
                                    null,
                                    () => {
                                        $location.path('/app/offers_view/' + newOfferId);
                                    }
                                );
                            });
                        });
                    }
                );
            });
        });
    }

    function confirmSendToClient(offer, callback) {
        if (IccConfig.Offer.confirmSendToClient) {
            InfoFactory.confirmModal(
                $filter('translate')('OFFER|Potwierdzenie wysłania oferty'),
                $filter('translate')('OFFER|Czy na pewno wysłać ofertę do klienta?'),
                [
                    {
                        name: $filter('translate')('INTERFACE|Tak'),
                        callback: () => sentToClient(offer, callback),
                    },
                    {
                        name: $filter('translate')('INTERFACE|Nie'),
                        callback: () => { },
                        accent: true,
                    },
                ]
            );
        } else {
            sentToClient(offer, callback);
        }
    }

    /**
     * Wysłanie oferty od dealera do klienta
     * @param  {Object}   offer              Oferta
     * @param  {function}   callback        Callback
     */
    function sentToClient(offer, callback) {
        if (IccConfig.Offer.producentValuationEmail) {
            const key = core.parseJson(offer.key).join('');
            offer.mail_sent = null;
            offer.mail_message = {
                to: ['klient'],
                type: 'producent_valuation',
                title: $filter('translate')(
                    'OFFER|Oferta o numerze {key} została wyceniona w systemie IC COMPLEX',
                    { key }
                ),
            };
        }
        OffersService.update(offer.tmp_id, angular.extend(offer, { dealer_status: '2' }))
            .then(res => {
                if (
                    ['logistic-minimum', 'm2-cost', 'weight'].includes(
                        IccConfig.Offer.transportCostType
                    )
                    && res.client_split_transport_cost
                ) {
                    const EditPosition = DatabaseManager.get('Position');
                    const searched = OfferSequenceService.keysFromSequence(res.sequence);
                    return ManyPositionsService.listById(searched, offer.tmp_id).then(positions => {
                        const allDiscounts = {
                            buy: DiscountsAndMultipliersService.getBuyDiscounts(),
                            sale: DiscountsAndMultipliersService.getSaleDiscounts(),
                        };
                        const positionsData = positions.pos.map(p => p.doc);
                        const result = OfferTransportCostService.splitClientTransportCost(
                            positionsData,
                            res,
                            IccConfig,
                            UserService.getDealer(),
                            allDiscounts
                        );
                        return Promise.all([
                            OffersService.update(result.offer._id, result.offer),
                            EditPosition.createMany(result.positions),
                        ]);
                    });
                }
            })
            .then(() => {
                $rootScope.showInfo(
                    $filter('translate')('OFFER|Oferta została zatwierdzona'),
                    null
                );
                callback();
            });
    }

    /**
     * Wysłanie oferty od dealera do klienta
     * @param  {offer}   offer     Oferta
     * @param  {Function} callback Funkcja po zakonczeniu
     */
    function cancelOffer(offer, callback) {
        if (confirm($filter('translate')('OFFER|Czy na pewno anulować ofertę?'))) {
            OffersService.update(
                offer.tmp_id,
                angular.extend(offer, {
                    dealer_status: '4', // Anulowana
                    status: '4', // Anulowana
                })
            ).then(() => {
                $rootScope.showInfo($filter('translate')('OFFER|Oferta została anulowana'), null);
                callback();
            });
        }
    }

    /**
     * Wysłanie zamówienia przez klienta zalogowanego na podstawie oferty
     * @param  {Object}   offer       Oferta
     * @param  {function} callback    Callback
     */
    function quickSendOrder(offer, callback) {
        OffersService.update(
            offer.tmp_id,
            angular.extend(offer, {
                dealer_status: '3', // Zamówiona
                mail_message: {
                    type: 'order',
                    to: ['klient', 'dealer', 'dealerh'],
                    title: $filter('translate')('OFFER|Nowe zamówienie w systemie IC COMPLEX'),
                },
            })
        ).then(() => {
            $rootScope.showInfo($filter('translate')('OFFER|Zamówiono ofertę'), null);
            callback();
        });
    }

    /**
     * Wysłanie zapytania ofertowego przez klienta zalogowanego
     * @param  {Object}   offer       Oferta
     * @param  {function} callback    Callback
     */
    function quickSendInquiry(offer, callback) {
        $rootScope.loader = true;

        OffersService.update(
            offer.tmp_id,
            angular.extend(offer, {
                dealer_status: '0', // U dealera
                status: '1', // Nowa
                mail_message: {
                    type: 'inquiry',
                    to: ['klient', 'dealer', 'dealerh'],
                    title: $filter('translate')('OFFER|Nowe zapytanie w systemie IC COMPLEX'),
                },
            })
        ).then(() => {
            $rootScope.showInfo($filter('translate')('OFFER|Zapytanie zostało wysłane'), null);
            callback();
        });
    }

    /**
     * Wysyłanie zapytania z pobraniem danych klienta
     * @param  {Object}     offer           Oferta
     * @param  {function}   callback        Callback
     * @param  {function}   beforecallback  Beforecallback
     */
    function sendClientInquiry(Client, offer, beforecallback, aftercallback) {
        if (angular.isUndefined(Client.Dealer)) {
            $rootScope.showInfo($filter('translate')('OFFER|Wybierz najbliższego dealera'), null);
        } else if (!Client.company_email && ~~Client.company) {
            $rootScope.showInfo(
                $filter('translate')('INTERFACE|Uzupełnij pole:')
                + ' '
                + $filter('translate')('CLIENT|Adres e-mail'),
                null
            );
        } else if (!Client.company_name && ~~Client.company) {
            $rootScope.showInfo(
                $filter('translate')('INTERFACE|Uzupełnij pole:')
                + ' '
                + $filter('translate')('CLIENT|Nazwa firmy'),
                null
            );
        } else if (!Client.name && !~~Client.company) {
            $rootScope.showInfo(
                $filter('translate')('INTERFACE|Uzupełnij pole:')
                + ' '
                + $filter('translate')('CLIENT|Imię'),
                null
            );
        } else if (!Client.surname && !~~Client.company) {
            $rootScope.showInfo(
                $filter('translate')('INTERFACE|Uzupełnij pole:')
                + ' '
                + $filter('translate')('CLIENT|Nazwisko'),
                null
            );
        } else if (!Client.email && !~~Client.company) {
            $rootScope.showInfo(
                $filter('translate')('INTERFACE|Uzupełnij pole:')
                + ' '
                + $filter('translate')('CLIENT|Adres e-mail'),
                null
            );
        } else if (!Client.consent_to_process_personal_data) {
            $rootScope.showInfo($filter('translate')('INTERFACE|Zaznacz checkbox'), null);
        } else {
            $rootScope.loader = true;
            const email = ~~Client.company ? Client.company_email : Client.email;
            const oldEmail = Client.old_email;
            delete Client.old_email;
            if (oldEmail !== email) {
                if (~~Client.company) {
                    Client = {
                        Dealer: Client.Dealer,
                        company: Client.company,
                        company_name: Client.company_name,
                        nip: Client.nip,
                        company_email: Client.company_email,
                        company_tel: Client.company_tel,
                    };
                } else {
                    Client = {
                        Dealer: Client.Dealer,
                        company: Client.company,
                        name: Client.name,
                        surname: Client.surname,
                        email: Client.email,
                        phone: Client.phone,
                    };
                }
            }
            const client = angular.extend(Client, {
                reference: 'CLIENT',
                dealer_id: Client.Dealer.id,
                seller_id: Client.Dealer.seller_id,
            });

            if (Client.tmp_id) {
                beforecallback();
                ClientsFactory.updateClient(Client._id, client).then(() => {
                    sendClientInquiryUpdateOffer(Client.tmp_id, Client, offer, aftercallback);
                });
            } else {
                beforecallback();
                client.synced = true;
                ClientsFactory.addClient(client).then(c => {
                    localStorage.setItem('client', Core.stringJson(c));
                    sendClientInquiryUpdateOffer(c.tmp_id, Client, offer, aftercallback);
                });
            }
        }
    }

    /**
     * Uaktualnianie oferty po dodaniu klienta
     * @param  {string} id         dodany klient
     * @param  {object} Client     dane klienta z formularza
     * @param  {object} offer      dane oferty
     * @param  {function} callback funkcja po zakonczeniu
     */
    function sendClientInquiryUpdateOffer(id, client, offer, callback) {
        EventBusService.post({
            key: 'updatedClient',
            value: null,
        });
        user = UserService.get();
        // var reference = data.data.rows[0].reference;
        if (~~client.company) {
            OffersService.update(
                offer.tmp_id,
                angular.extend(offer, {
                    client_id: id || null,
                    dealer_id: client.Dealer.id,
                    seller_id: client.Dealer.seller_id,
                    companyname: client.company_name || null,
                    dealer_status: '0', // U dealera
                    status: '1', // Nowa
                    name: client.company_name,
                    number: offer.dealer_id ? offer.number : 0,
                    key: offer.dealer_id
                        ? offer.key
                        : OfferKeyService.keyGen({
                            order: offer.order,
                            user,
                            client,
                            dealerId: client.Dealer.id,
                            number: 0,
                            version: offer.version,
                            enova: offer.enova,
                            offerNumber: offer.offers_number,
                            offerVersion: offer.offers_version,
                            subOffer: false,
                            b2cOfferScheme: true,
                            b2cOfferSource: offer.b2c_offer_source,
                            offlineNumber: isNative && !OnlineStatusService.getStatus(),
                            customKeyValue: offer.custom_key_value,
                        }).key,
                    mail_message: {
                        type: 'inquiry',
                        to: ['klient', 'dealer', 'dealerh'],
                        title: $filter('translate')('OFFER|Nowe zapytanie w systemie IC COMPLEX'),
                    },
                })
            ).then(() => {
                $rootScope.loader = false;
                callback(offer);
            });
        } else {
            OffersService.update(
                offer.tmp_id,
                angular.extend(offer, {
                    client_id: id || null,
                    dealer_id: client.Dealer.id,
                    seller_id: client.Dealer.seller_id,
                    clientname: client.name || null,
                    clientsurname: client.surname || null,
                    dealer_status: '0', // U dealera
                    status: '1', // Nowa
                    name: client.name + ' ' + client.surname,
                    number: offer.dealer_id ? offer.number : 0,
                    key: offer.dealer_id
                        ? offer.key
                        : OfferKeyService.keyGen({
                            order: offer.order,
                            user,
                            client,
                            dealerId: client.Dealer.id,
                            number: 0,
                            version: offer.version,
                            enova: offer.enova,
                            offerNumber: offer.offers_number,
                            offerVersion: offer.offers_version,
                            subOffer: false,
                            b2cOfferScheme: true,
                            b2cOfferSource: offer.b2c_offer_source,
                            offlineNumber: isNative && !OnlineStatusService.getStatus(),
                            customKeyValue: offer.custom_key_value,
                        }).key,
                    mail_message: {
                        type: 'inquiry',
                        to: ['klient', 'dealer', 'dealerh'],
                        title: $filter('translate')('OFFER|Nowe zapytanie w systemie IC COMPLEX'),
                    },
                })
            ).then(() => {
                $rootScope.loader = false;
                callback(offer);
            });
        }
    }

    /**
     * Pobieranie pierwszej całej listy dealerów do zapytania klienta niezalogowanego
     * @param  {@function}   callback        Callback
     */
    function getFirstDealersList(callback) {
        $http
            .get((EnvConfig.remoteHost || window.location.origin) + '/dealer/dealers/index.json', {
                params: { market: $rootScope.user.market },
            })
            .then(data => {
                callback(
                    data.data.dealers.filter(
                        e =>
                            ~~localStorage.banner_dealer_id == ~~e.Dealer.id
                            || !~~localStorage.banner_dealer_id
                    ),
                    ~~localStorage.banner_dealer_id > 0
                );
            })
            .catch(error => {
                logger.error(error);
            });
    }

    /**
     * Pobieranie listy handlowców dealera
     * @param  {number}      dealerId        Id dealera
     * @param  {@function}   callback        Callback
     */
    function getDealerSellersList(dealerId, callback) {
        $http
            .get(
                (EnvConfig.remoteHost || window.location.origin)
                + '/dealer/dealer_sellers/index.json',
                { params: { dealerId } }
            )
            .then(data => callback(data.data.dealersSellers));
    }

    /**
     * Pobieranie listy dealerów do zapytania klienta niezalogowanego
     * @param  {@object}   address        Adres
     * @param  {@function}   callback        Callback
     */
    function getDealersList(address, callback) {
        // poszukaj najpierw w bazie lokalnej czy jest pozycja kodu pocztowego
        $http
            .post(
                `${EnvConfig.remoteHost
                || window.location.origin}/distance/distance_postcodes/get.json`,
                { address, country: $rootScope.user.country || 'PL' }
            )
            .then(res => {
                let data = res.data;
                if (data.distance === null || data.distance.length === 0) {
                    // jeżeli nie ma w bazie kodu pocztowego, szukaj jego polozenia
                    $http
                        .get(
                            'https://maps.googleapis.com/maps/api/geocode/json?sensor=false&components=postal_code:'
                            + address
                            + '|country:'
                            + ($rootScope.user.country || 'PL')
                            + '&key=AIzaSyA9m09Y0B87Ak_gcPur4c3kHL3n3TxsEgk'
                        )
                        .then(res2 => {
                            data = res2.data;
                            var filtered = [];
                            if (
                                data.results
                                && data.results.length > 0
                                && data.results[0].address_components
                            ) {
                                filtered = data.results[0].address_components.filter(
                                    e => e.types.indexOf('postal_code') >= 0
                                );
                            }

                            if (data.status === 'ZERO_RESULTS' || !filtered.length) {
                                // nie da sie określić położenia
                                $rootScope.showInfo(
                                    $filter('translate')(
                                        'OFFER|Nie znaleziono takiego położenia, spróbuj podać sąsiedni kod'
                                    ),
                                    null
                                );
                            } else {
                                address = filtered[0].short_name;

                                // dodanie znalezionego położenia do bazy
                                $http
                                    .post(
                                        `${EnvConfig.remoteHost
                                        || window.location
                                            .origin}/distance/distance_postcodes/add.json`,
                                        {
                                            latitude: data.results[0].geometry.location.lat,
                                            longitude: data.results[0].geometry.location.lng,
                                            postcode: filtered[0].short_name,
                                            country: $rootScope.user.country || 'PL',
                                        }
                                    )
                                    .then(() => {
                                        // ponownie szukanie w bazie lokalnej odleglości
                                        $http
                                            .get(
                                                (EnvConfig.remoteHost || window.location.origin)
                                                + '/dealer/dealers/index.json',
                                                {
                                                    params: {
                                                        market: $rootScope.user.market,
                                                        latitude:
                                                            data.results[0].geometry.location.lat,
                                                        longitude:
                                                            data.results[0].geometry.location.lng,
                                                        post_code: address,
                                                    },
                                                }
                                            )
                                            .then(res3 => {
                                                callback(res3.data.dealers, address);
                                            });
                                    });
                            }
                        });
                } else {
                    $http
                        .get(
                            (EnvConfig.remoteHost || window.location.origin)
                            + '/dealer/dealers/index.json',
                            {
                                params: {
                                    market: $rootScope.user.market,
                                    latitude: data.distance.DistancePostcode.latitude,
                                    longitude: data.distance.DistancePostcode.longitude,
                                    post_code: address,
                                },
                            }
                        )
                        .then(res2 => {
                            callback(res2.data.dealers, address);
                        });
                }
            });
    }

    /**
     * Czyści cała oferte
     * @param  {object}   offer    Oferta do wyczyszczenia
     * @param  {Function} callback Odswiezenie zawartosci
     */
    function clearOffer(offer, callback) {
        if (confirm($filter('translate')('OFFER|Czy na pewno usunąć wszystkie pozycje?'))) {
            PositionsFactory.removeAllPositions(offer).then(() => {
                callback();
            });
        }
    }

    /**
     * Otwiera strone serwisowa
     * @param  {object} offer Ofera/zamowienie
     */
    function openServicePage(offer) {
        var id = offer.tmp_id.substring(10);
        $location.path('/app/landing/' + id + '/' + Core.decToHex(Core.crc32(id)));
    }

    /**
     * Dodanie podoferty
     * @param {string} offerId Id oferty
     */
    function addSubOffer(offerId) {
        OffersFactory.addSubOffer(offerId);
    }
    /**
     * Dodanie podoferty
     * @param {string} offerId Id oferty
     */
    function copyDemoOffer(offerId) {
        OffersFactory.addSubOffer(offerId, false, false, true);
    }

    /**
     * Dodanie podzamowienia
     * @param {string} offerId Id oferty
     * @param {array}  sellers Lista producentow
     * @param {object} offer   Oferta
     */
    function addSubOrder(offerId, sellers, offer) {
        setOrderStatus(offer, 11, sellers, function afterSetOrderStatus() {
            OffersFactory.addSubOffer(offerId, true, findProducerName(sellers, offer));
        });
    }

    /**
     * Odświeżanie oferty po zmianie rabatu
     * @param  {object}   offer    Oferta
     * @param  {Function} callback Funkcja po zapisie
     */
    function refreshDiscounts(offer, callback) {
        var newOffer = offer;
        var groupDiscounts = OfferDiscountsService.groupDiscounts(
            offer.group_discounts,
            offer.dealer_price_before_discount,
            $rootScope.user,
            newOffer
        );
        OffersService.update(
            newOffer._id,
            angular.extend(newOffer, {
                group_discounts: groupDiscounts,
                dealer_price: groupDiscounts.length
                    ? groupDiscounts[groupDiscounts.length - 1].price
                    : 0,
            })
        ).then(function afterOfferUpdate() {
            callback();
        });
    }

    /**
     * Dodanie ręcznie rabatu prze handlowca
     * Odświeżanie oferty po zmianie rabatu
     * @param  {object}   offer    Oferta
     * @param  {object}   discount Nowy rodzaj rabatu
     * @param  {Function} callback Funkcja po zapisie
     */
    function addNewOfferDiscount(offer, discount, callback) {
        var newOffer = offer;
        var groupDiscounts = offer.group_discounts;

        if (angular.isDefined(discount.key)) {
            groupDiscounts[discount.key] = angular.extend(groupDiscounts[discount.key], {
                name: discount.name,
                description: discount.description,
                discount: discount.discount,
                key: undefined,
            });
        } else {
            groupDiscounts.push(
                angular.extend(discount, {
                    auto: true,
                    date_from: null,
                    date_to: null,
                    custom: true,
                    key: undefined,
                })
            );
        }
        groupDiscounts = OfferDiscountsService.groupDiscounts(
            groupDiscounts,
            offer.dealer_price_before_discount,
            $rootScope.user,
            newOffer
        );

        OffersService.update(
            newOffer._id,
            angular.extend(newOffer, {
                group_discounts: groupDiscounts,
                dealer_price: groupDiscounts.length
                    ? groupDiscounts[groupDiscounts.length - 1].price
                    : 0,
            })
        ).then(function afterOfferUpdate() {
            callback();
        });
    }

    /**
     * Usuwanie rabatu z oferty
     * @param  {object}   offer    Oferta
     * @param  {number}   key      Indeks rabatu
     * @param  {Function} callback Funkcja po zapisie
     */
    function deleteOfferDiscount(offer, key, callback) {
        var newOffer = offer;
        if (
            newOffer.group_discounts[key].custom
            && confirm($filter('translate')('OFFER|Czy na pewno usunć rabat?'))
        ) {
            newOffer.group_discounts.splice(key, 1);

            var groupDiscounts = OfferDiscountsService.groupDiscounts(
                newOffer.group_discounts,
                offer.dealer_price_before_discount,
                $rootScope.user,
                newOffer
            );
            OffersService.update(
                newOffer._id,
                angular.extend(newOffer, {
                    group_discounts: groupDiscounts,
                    dealer_price: groupDiscounts.length
                        ? groupDiscounts[groupDiscounts.length - 1].price
                        : 0,
                })
            ).then(function afterOfferUpdate() {
                callback();
            });
        }
    }

    /**
     * Aktualizacja offerty z nagłówka
     * @param  {object}   offer                 Oferta
     * @param  {object}   offerHeader           Dane z nagłówka zamówienia
     * @param  {object}   transportCostPosition Pozycja kosztów transportu
     * @param  {number}   transportCost         Koszt trancportu
     * @param  {Function} callback              Funkcja po zapisie
     */
    function updateOfferHeader(offer, offerHeader, transportCostPosition, transportCost, callback) {
        var tmpOffer = offer;
        var tmpOfferHeader = Core.copy(offerHeader);
        transportCost = transportCost * offer.currency.value;
        tmpOfferHeader.advance = tmpOfferHeader.advance * offer.currency.value;
        tmpOfferHeader.deadline =
            tmpOfferHeader.deadline !== null
                ? moment(tmpOfferHeader.deadline).format('YYYY-MM-DD')
                : null;
        tmpOfferHeader.delivery_date =
            tmpOfferHeader.delivery_date !== null
                ? moment(tmpOfferHeader.delivery_date).format('YYYY-MM-DD')
                : null;
        tmpOfferHeader.advance_date =
            tmpOfferHeader.advance_date !== null
                ? moment(tmpOfferHeader.advance_date).format('YYYY-MM-DD')
                : tmpOfferHeader.advance_date;
        tmpOfferHeader.warranty_date =
            tmpOfferHeader.warranty_date !== null
                ? moment(tmpOfferHeader.warranty_date).format('YYYY-MM-DD')
                : tmpOfferHeader.warranty_date;
        tmpOfferHeader.transport_cost = tmpOfferHeader.transport_cost * offer.currency.value;
        user = UserService.get();
        if (
            IccConfig.Offer.enovaNumber
            && angular.isDefined(tmpOffer.enova)
            && tmpOffer.enova !== null
        ) {
            // mail po uzupełnieniu numeru zamówienia
            if (tmpOffer.enova !== tmpOfferHeader.enova && Number(tmpOffer.enova) > 0) {
                tmpOfferHeader.mail_message = {
                    type: 'dealerorder',
                    to: ['dealer', 'dealerh', 'producent'],
                    title: $filter('translate')('OFFER|Nowe zamówienie w systemie ICC'),
                };
            }

            // mail po zmianie statusu na 'przyjęty do realizacji' lub 'aneksowane'
            if (
                tmpOffer.status !== tmpOfferHeader.status
                && [3, 11].indexOf(Number(tmpOfferHeader.status)) !== -1
            ) {
                tmpOfferHeader.mail_message = {
                    type: 'editorder_enova',
                    to: ['dealer', 'dealerh', 'producent'],
                    title: $filter('translate')('OFFER|Zmodyfikowano zamówienie w systemie ICC'),
                };
            }

            tmpOfferHeader.enova = tmpOffer.enova;
            tmpOfferHeader.key = OfferKeyService.keyGen({
                order: tmpOffer.order,
                user,
                client: {},
                dealerId: tmpOffer.dealer_id,
                number: tmpOffer.number,
                version: tmpOffer.version,
                enova: tmpOffer.enova,
                offerNumber: OffersFactory.elemInOrderNumber(tmpOffer.key, 'O_NUM'),
                offerVersion:
                    OffersFactory.elemInOrderNumber(tmpOffer.key, 'O_V_DIGIT')
                    || (OffersFactory.elemInOrderNumber(tmpOffer.key, 'O_V_NUMBER') == 0
                        || OffersFactory.elemInOrderNumber(tmpOffer.key, 'O_V_NUMBER') == ''
                        ? 0
                        : OffersFactory.elemInOrderNumber(tmpOffer.key, 'O_V_NUMBER')
                            .toLowerCase()
                            .charCodeAt(0) - 96),
                subOffer: !~~tmpOffer.order && tmpOffer.parent_id && tmpOffer.version,
                originalOfferDate: tmpOffer.original_offer_date,
                b2cOfferScheme: IccConfig.Offer.B2C.differentOfferSchema && tmpOffer.client_offer,
                def: {
                    O_ALL: OffersFactory.elemInOrderNumber(tmpOffer.key, 'O_ALL'),
                    K_DEALER: OffersFactory.elemInOrderNumber(tmpOffer.key, 'K_DEALER'),
                    K_CLIENT: OffersFactory.elemInOrderNumber(tmpOffer.key, 'K_CLIENT'),
                },
                b2cOfferSource: false,
                offlineNumber: false,
                customKeyValue: tmpOffer.custom_key_value,
            }).key;
        } else if (IccConfig.Order.mailOnUpdate) {
            tmpOfferHeader.mail_message = {
                type: 'editorder',
                to: ['dealer', 'dealerh', 'producent'],
                title: $filter('translate')('OFFER|Zmodyfikowano zamówienie w systemie IC COMPLEX'),
            };
        }
        if (angular.isObject(transportCostPosition)) {
            transportCostPosition.client_price = transportCost;
            transportCostPosition.client_price_before_discount = transportCost;
            transportCostPosition.dealer_price = transportCost;
            transportCostPosition.dealer_price_before_discount = transportCost;
            PositionsFactory.update(
                transportCostPosition.tmp_id,
                transportCostPosition,
                tmpOffer
            ).then(function afterUpdatePositionsFactory() {
                OffersService.get(tmpOffer.tmp_id).then(function afterGetOffersFactory(res) {
                    tmpOffer = angular.extend(res, tmpOfferHeader);
                    OffersService.update(tmpOffer._id, tmpOffer).then(
                        function afterUpdateOffersFactory() {
                            callback();
                        }
                    );
                });
            });
        } else if (transportCost) {
            PositionsFactory.add({
                configuration: {
                    type: 'transport_cost',
                    Quantity: 1,
                    Name: $filter('translate')('OFFER|Koszt transportu'),
                },
                details: {
                    type: 'transport_cost',
                    quantity: 1,
                    name: $filter('translate')('OFFER|Koszt transportu'),
                },
                offer: tmpOffer,
                standard: true,
                price: transportCost,
            }).then(function afterAddPositionsFactory() {
                OffersService.get(tmpOffer.tmp_id).then(function afterGetOffersFactory(res) {
                    tmpOffer = angular.extend(res, tmpOfferHeader);
                    OffersService.update(tmpOffer._id, tmpOffer).then(
                        function afterUpdateOffersFactory() {
                            callback();
                        }
                    );
                });
            });
        } else if (
            ['logistic-minimum', 'm2-cost', 'weight'].includes(IccConfig.Offer.transportCostType)
            && tmpOffer.transport_from_producent
            && tmpOffer.transport_cost !== tmpOfferHeader.transport_cost
            && Number(tmpOffer.status) !== 10
        ) {
            const EditPosition = DatabaseManager.get('Position');
            OffersService.get(tmpOffer.tmp_id)
                .then(res => {
                    tmpOffer = angular.extend(res, tmpOfferHeader);
                    const searched = OfferSequenceService.keysFromSequence(res.sequence);
                    return ManyPositionsService.listById(searched, res.tmp_id);
                })
                .then(positions => {
                    const allDiscounts = {
                        buy: DiscountsAndMultipliersService.getBuyDiscounts(),
                        sale: DiscountsAndMultipliersService.getSaleDiscounts(),
                    };
                    const positionsData = positions.pos.map(p => p.doc);
                    const result = OfferTransportCostService.splitTransportCost(
                        positionsData,
                        tmpOffer,
                        IccConfig,
                        UserService.getDealer(),
                        allDiscounts,
                        user
                    );
                    return Promise.all([
                        OffersService.update(result.offer._id, result.offer),
                        EditPosition.createMany(result.positions),
                    ]);
                })
                .then(callback);
        } else {
            OffersService.get(tmpOffer.tmp_id).then(function afterGetOffersFactory(res) {
                tmpOffer = angular.extend(res, tmpOfferHeader);
                OffersService.update(tmpOffer._id, tmpOffer).then(
                    function afterUpdateOffersFactory() {
                        callback();
                    }
                );
            });
        }
    }

    /**
     * Uaktualnienie rabatu dla wszystkich pozycji
     * @param  {number}   discount Rabata
     * @param  {object}   offer    Oferta
     * @param  {string}   type     Typ rabatu
     * @param  {Function} callback Funkcja po zapisie
     */
    function updateOfferPositionsDiscount(discount, offer, type, callback) {
        var sequenceKeys = OfferSequenceService.keysFromSequence(offer.sequence);
        var i = 0;
        var tmpOffer = offer;
        var EditPosition;
        var discounts = {};

        discount = Core.roundPrice(discount);

        EditPosition = DatabaseManager.get('Position');

        if (type == 'client') {
            discounts.client_discount_position = discount;
        } else if (type == 'dealer') {
            discounts.dealer_discount_producer = discount;
        }

        ManyPositionsService.listById(sequenceKeys, tmpOffer.tmp_id)
            .then(function getPositionsSuccess(res) {
                var positions = [];
                var position = {};
                for (i = 0; i < res.pos.length; i++) {
                    if (
                        angular.isObject(res.pos[i].doc.group_discounts)
                        && res.pos[i].doc.group_discounts.length > 0
                    ) {
                        if (type == 'client') {
                            res.pos[i].doc.group_discounts[0].client.discount = discount;
                        } else if (type == 'dealer') {
                            res.pos[i].doc.group_discounts[0].dealer.discount = discount;
                        }
                        position = PositionsFactory.recalculatePrices(res.pos[i].doc);
                        position = PositionsFactory.stringPositionValues(
                            position,
                            position.groupCode
                        );
                        positions.push(position);
                    }
                }
                EditPosition.createMany(positions).then(function afterAddMultiPositions() {
                    angular.extend(tmpOffer, discounts);
                    OfferSummaryService.autoUpdateOffer(
                        tmpOffer.tmp_id,
                        null,
                        null,
                        tmpOffer.sequence,
                        false,
                        null,
                        discounts
                    ).then(function afterAutoUpdateOffer() {
                        PositionsFactory.addColorCostPosition(tmpOffer.tmp_id).then(() => {
                            callback();
                        });
                    });
                });
            });
    }

    /**
     * Wycenianie pozycji przez producenta
     * @param  {object}   valuatedPositions Wycenione pozycje
     * @param  {object}   offer             Oferta
     * @param  {number}   status            Status
     * @param  {number}   market            Kod rynku
     * @param  {Function} callback          Funkcja po zapisie
     */
    function valuate(valuatedPositions, offer, status = null, market = null, callback) {
        var positionsIds = Object.keys(valuatedPositions);
        var tmpOffer = offer;
        var valuated = true;

        if (positionsIds.length) {
            if (IccConfig.Offer.producentValuationEmail) {
                const key = core.parseJson(tmpOffer.key).join('');
                tmpOffer.mail_sent = null;
                tmpOffer.mail_message = {
                    to: ['dealer', 'dealerh'],
                    type: 'producent_valuation',
                    title: $filter('translate')(
                        'OFFER|Oferta o numerze {key} została wyceniona w systemie IC COMPLEX',
                        { key }
                    ),
                };
            }
            ManyPositionsService.listById(positionsIds, tmpOffer.tmp_id)
                .then(function getPositionsSuccess(res) {
                    var positions = [];
                    var deferred = $q.defer();
                    var promises = res.pos.map(function positionsMap(pos) {
                        var deferPosition = $q.defer();
                        if (
                            (valuatedPositions[pos.doc.tmp_id].price !== null
                                && valuatedPositions[pos.doc.tmp_id].price >= 0)
                            || valuatedPositions[pos.doc.tmp_id].materialsCost > 0
                            || valuatedPositions[pos.doc.tmp_id].points > 0
                            || valuatedPositions[pos.doc.tmp_id].varnishedPoints > 0
                        ) {
                            PositionsFactory.valuate(
                                pos.doc,
                                tmpOffer.currency,
                                market,
                                valuatedPositions[pos.doc.tmp_id],
                                tmpOffer
                            ).then(function afterValuate(position) {
                                position = angular.extend(position, {
                                    group_discounts: Core.stringJson(position.group_discounts),
                                });
                                positions.push(position);
                                deferPosition.resolve(position);
                            });
                        } else {
                            valuated = false;
                            deferPosition.resolve();
                        }
                        return deferPosition.promise;
                    });
                    $q.all(promises).then(() => deferred.resolve(positions));
                    return deferred.promise;
                })
                .then(function afterUpdatePrices(positions) {
                    if (positions.length) {
                        DatabaseManager.get('Position')
                            .createMany(positions)
                            .then(function afterAddMultiPositions() {
                                tmpOffer.valuation = valuated ? 0 : 1;
                                tmpOffer.status = status ? status : valuated ? '3' : '1';
                                OffersService.update(tmpOffer._id, tmpOffer).then(
                                    function afterUpdateOffersFactory() {
                                        OfferSummaryService.autoUpdateOffer(
                                            tmpOffer.tmp_id,
                                            null,
                                            null,
                                            tmpOffer.sequence
                                        ).then(() => {
                                            PositionsFactory.addColorCostPosition(
                                                tmpOffer.tmp_id
                                            ).then(() => {
                                                callback();
                                            });
                                        });
                                    }
                                );
                            });
                    } else {
                        tmpOffer.valuation = 1;
                        tmpOffer.status = status ? status : '1';
                        OffersService.update(tmpOffer._id, tmpOffer).then(
                            function afterUpdateOffersFactory() {
                                OfferSummaryService.autoUpdateOffer(
                                    tmpOffer.tmp_id,
                                    null,
                                    null,
                                    tmpOffer.sequence
                                ).then(() => {
                                    PositionsFactory.addColorCostPosition(tmpOffer.tmp_id).then(
                                        () => {
                                            callback();
                                        }
                                    );
                                });
                            }
                        );
                    }
                });
        } else {
            tmpOffer.status = '3';
            OffersService.update(tmpOffer._id, tmpOffer).then(() => callback());
        }
    }

    /**
     * Wyliczenie ceny przed rabatem z ceny po rabacie
     * @param  {object} position     Pozycja
     * @param  {object} offer        Oferta
     * @param  {number} clientPrice  Nowa cena
     */
    function countPricesFromClient(position, offer, clientPrice, callback) {
        position.client_price = clientPrice * offer.currency.value;
        position.client_price_before_discount = Core.round(
            position.client_price / (1 - parseFloat(offer.client_discount_position) / 100)
        );
        position.dealer_price_before_discount =
            position.confType === 'other' ? position.client_price_before_discount : 0;
        position.dealer_price =
            position.confType === 'other' ? position.client_price_before_discount : 0;
        position.group_discounts[0].client.price = position.client_price_before_discount;
        position.group_discounts[0].client.value =
            (1 / 100)
            * (offer.client_discount_position || 0)
            * (position.client_price_before_discount || 0);
        position.group_discounts[0].dealer.price = position.dealer_price_before_discount;
        position.group_discounts[0].dealer.value = 0;

        position = PositionsFactory.recalculatePrices(position);
        PositionsFactory.update(position._id, position, offer).then(function afterUpdatePosition() {
            callback();
        });
    }

    /**
     * Wyliczenie ceny przed rabatem z ceny po rabacie
     * @param  {object} position     Pozycja
     * @param  {object} offer        Oferta
     * @param  {number} clientPrice  Nowa cena
     */
    function countPricesFromDealer(position, offer, dealerPrice, callback) {
        const dealerMargin = offer ? offer.dealer_margin * 1 : 0;

        position.dealer_price = dealerPrice * offer.currency.value;
        position.dealer_price_before_discount = Core.round(
            position.dealer_price / (1 - parseFloat(offer.dealer_discount_producer) / 100)
        );
        position.client_price_before_discount = position.dealer_price_before_discount;
        position.client_price_before_discount *= 1 + dealerMargin / 100;
        position.client_price = Core.round(
            position.client_price_before_discount
            - (1 / 100)
            * parseFloat(offer.client_discount_position)
            * position.client_price_before_discount
        );
        position.price = position.client_price;
        position.group_discounts[0].client.price = position.client_price_before_discount;
        position.group_discounts[0].client.value =
            (1 / 100)
            * (offer.client_discount_position || 0)
            * (position.client_price_before_discount || 0);
        position.group_discounts[0].dealer.price = position.dealer_price_before_discount;
        position.group_discounts[0].dealer.value =
            (1 / 100)
            * (offer.dealer_discount_producer || 0)
            * (position.dealer_price_before_discount || 0);

        position = PositionsFactory.recalculatePrices(position);
        PositionsFactory.update(position._id, position, offer).then(function afterUpdatePosition() {
            callback();
        });
    }

    /**
     * Potwierdzenie zmiany zamowienia i zapisanie jego stanu
     * @param  {object}   offer    Edytowane zamowienie
     */
    function confirmOrderEdit(offer) {
        return angular.extend(offer, {
            mail_message: {
                type: 'editorder',
                to: ['producent', 'dealer', 'dealerh'],
                title: $filter('translate')('OFFER|Zmodyfikowano zamówienie w systemie IC COMPLEX'),
            },
            update_transport: true,
        });
    }

    /**
     * Zmiana statusu zamowianie z zapisam historii
     * @param {object}   offer    Oferta
     * @param {number}   status   Nowy status
     * @param {array}    sellers  Lista producentow
     * @param {Function} callback Funckja po zmianie
     */
    function setOrderStatus(offer, status, sellers, callback) {
        const newOffer = offer;
        newOffer.history.unshift({
            status,
            date: moment().format('YYYY-MM-DD HH:mm:ss'),
            user: $rootScope.user.name + ' ' + $rootScope.user.surname,
            seller: findProducerName(sellers, newOffer),
        });
        newOffer.status = status;
        if (status === 8) {
            newOffer.mail_message = {
                type: 'order_cancel',
                to: ['producent', 'dealer'],
                title: $filter('translate')('OFFER|Anulowano zamówienie w systemie IC COMPLEX'),
            };
        }

        if (
            newOffer.update_transport
            && ['logistic-minimum', 'm2-cost', 'weight'].includes(
                IccConfig.Offer.transportCostType
            )
            && newOffer.transport_from_producent
            && newOffer.transport_cost
        ) {
            delete newOffer.update_transport;
            const EditPosition = DatabaseManager.get('Position');
            const searched = OfferSequenceService.keysFromSequence(newOffer.sequence);

            ManyPositionsService.listById(searched, newOffer.tmp_id)
                .then(positions => {
                    const allDiscounts = {
                        buy: DiscountsAndMultipliersService.getBuyDiscounts(),
                        sale: DiscountsAndMultipliersService.getSaleDiscounts(),
                    };
                    const positionsData = positions.pos.map(p => p.doc);
                    const result = OfferTransportCostService.splitTransportCost(
                        positionsData,
                        newOffer,
                        IccConfig,
                        UserService.getDealer(),
                        allDiscounts,
                        $rootScope.user
                    );
                    return Promise.all([
                        OffersService.update(result.offer._id, result.offer),
                        EditPosition.createMany(result.positions),
                    ]);
                })
                .then(callback);
        } else {
            delete newOffer.update_transport;
            OffersService.update(newOffer._id || newOffer.tmp_id, newOffer).then(
                function afterOfferSave() {
                    callback();
                }
            );
        }
    }

    /**
     * Zmiana statusu oferty
     * @param {object}   offer    Oferta
     * @param {number}   status   Nowy status
     * @param {Function} callback Funckja po zmianie
     */
    function setOfferStatus(offer, status, callback) {
        offer.status = status;

        OffersService.update(offer._id || offer.tmp_id, offer).then(function afterOfferSave() {
            callback();
        });
    }

    /**
     * Tworzenie nazwy producenta
     * @param  {array}  sellers Lista producentow
     * @param  {object} offer   Oferta
     * @return {string}         Nazwa
     */
    function findProducerName(sellers, offer) {
        var seller = (sellers || []).filter(function filterSeller(e) {
            return ~~e.Seller.id === ~~offer.seller_id;
        })[0];
        return angular.isObject(seller) && angular.isObject(seller.User)
            ? seller.User.firstname + ' ' + seller.User.surname
            : '';
    }

    /**
     * Pokazuje okno z historia zamówień
     * @param  {string} offer   Zamwienie źrdłowe
     * @param  {string} current Zamwienie bieżce
     */
    function openModalHistory(offer, current) {
        $rootScope.loader = true;

        $http
            .get(
                (EnvConfig.remoteHost || window.location.origin)
                + '/dealer/sellers/history/'
                + offer
                + '.json'
            )
            .then(function successGetComments(res) {
                $uibModal.open({
                    templateUrl: 'modalOfferHistory.html',
                    controller: 'ModalOfferHistoryCtrl as mhistory',
                    resolve: {
                        offers: () => res.data.orders || [],
                        current: () => current,
                    },
                });
                $rootScope.loader = false;
            })
            .catch(function successGetComments() {
                $uibModal.open({
                    templateUrl: 'modalOfferHistory.html',
                    controller: 'ModalOfferHistoryCtrl as mhistory',
                    resolve: { offers: () => [] },
                });
                $rootScope.loader = false;
            });
    }

    /**
     * Pobieranie załącznikow do oferty
     * @param  {string} offer Id oferty
     * @return {object} Promise
     */
    function getAttachments(offer) {
        const attachments = {};
        const deferred = $q.defer();

        OfferAttachmentsFactory.getForOffer(offer.tmp_id, undefined, false)
            .then(
                res => {
                    attachments[offer.tmp_id] = res;
                    return $q.resolve();
                },
                () => {
                    attachments[offer.tmp_id] = null;
                    return $q.resolve();
                }
            )
            .then(() => PositionAttachmentsFactory.getForOffer(offer.tmp_id, undefined))
            .then(res => {
                for (let i = 0; i < res.length; i++) {
                    if (!angular.isDefined(attachments[res[i].doc.dealer_offer_position_id])) {
                        attachments[res[i].doc.dealer_offer_position_id] = [];
                    }
                    attachments[res[i].doc.dealer_offer_position_id].push(res[i].doc);
                }
                deferred.resolve(attachments);
            });

        return deferred.promise;
    }

    /**
     * Pobieranie dealerów dla handlowca
     * @return {object} Promise
     */
    async function getDealers() {
        let users = await DatabaseManager.get('Users').get();
        users = users && users.data ? core.parseJson(users.data).users || {} : {};
        return users.dealers;
    }

    /**
     * Pobieranie rodzajów klientów
     * @return {object} Promise
     */
    async function getClientsTypes() {
        let users = await DatabaseManager.get('Users').get();
        users = users && users.data ? core.parseJson(users.data).users || {} : {};
        return users.clientsTypes;
    }

    /**
     * Pobieranie innych handlowców u handlowca
     * @return {object} Promise
     */
    function getSellers() {
        const deferred = $q.defer();
        $http
            .get((EnvConfig.remoteHost || window.location.origin) + '/dealer/sellers/index.json')
            .then(data => {
                deferred.resolve(data.data.sellers);
            })
            .catch(error => {
                logger.error(error);
            });
        return deferred.promise;
    }

    /**
     * Pobieranie stawek VAT
     */
    function getTaxRates() {
        return new Promise((resolve, reject) => {
            $http
                .get((EnvConfig.remoteHost || window.location.origin) + '/tax_rates/index.json')
                .then(data => {
                    resolve(data.data.taxes);
                })
                .catch(error => {
                    logger.error(error);
                    reject(error);
                });
        });
    }

    /**
     * Pobieranie szczegółów oferty
     */
    /**
     * Pobieranie szczegółów oferty
     * @param  {string}  id          Id oferty
     * @param  {Boolean} forceOnline Czy wymusić online
     * @return {object}              Dane
     */
    function getOffer(id, existingData = null) {
        const deferred = $q.defer();
        const groups = [];
        let offerLoaded = false;
        let notFoundPositions = false;
        let pos = [];
        clearTimeout(syncTimeout);
        let offerPromise;
        if (existingData) {
            offerPromise = Promise.resolve(existingData.offer);
        } else {
            offerPromise = OffersService.get(id, true);
        }
        offerPromise.then(
            offer => {
                if (
                    offer.demo_target
                    && moment(offer.demo_offer_expiration).format('YYYY-MM-DD HH:mm:ss')
                    < moment().format('YYYY-MM-DD HH:mm:ss')
                ) {
                    StateService.setKey('offer_id', undefined);
                    sessionStorage.setItem(
                        'text',
                        $filter('translate')(
                            'OFFER|Wybrana oferta nie jest już dostępna, skontaktuj się z nami, aby otrzymać aktualną ofertę.'
                        )
                    );
                    $rootScope.showInfo(
                        $filter('translate')(
                            'OFFER|Wybrana oferta nie jest już dostępna, skontaktuj się z nami, aby otrzymać aktualną ofertę.'
                        ),
                        null
                    );
                    logger.userLog(
                        $filter('translate')(
                            'OFFER|Wybrana oferta nie jest już dostępna, skontaktuj się z nami, aby otrzymać aktualną ofertę.'
                        )
                    );
                    $location.path('/');
                    $rootScope.loader = false;
                    return;
                }

                if (
                    $rootScope.user.access === 'dealerh'
                    && !$rootScope.user.see_all_documents
                    && offer.dealer_seller_id != $rootScope.user.dealersellerid
                ) {
                    StateService.setKey('offer_id', undefined);
                    $rootScope.showInfo($filter('translate')('OFFER|Nie znaleziono oferty'), null);
                    logger.userLog($filter('translate')('OFFER|Nie znaleziono oferty'));
                    $location.path('/app/offers');
                    $rootScope.loader = false;
                    return;
                }

                $rootScope.offer = offer;
                const sequenceKeys = OfferSequenceService.keysFromSequence(offer.sequence);
                const priceType = OfferPriceService.getPriceType(
                    offer,
                    offerOptions.orderPreview,
                    $rootScope.user.access
                );

                let positionsPromise;

                if (existingData) {
                    positionsPromise = Promise.resolve({
                        pos: existingData.positions,
                        seq: sequenceKeys,
                    });
                } else {
                    positionsPromise = ManyPositionsService.listById(sequenceKeys, id, false, true);
                }
                positionsPromise.then(positions => {
                    ({ offerLoaded, notFoundPositions } = checkIsLoadedAllPositions(
                        offer,
                        positions.pos.length
                    ));

                    const parsedPositions = PositionsFactory.parsePositions(positions.pos, offer);
                    const dataRequiredToUpdate = {
                        profiles: ConfiguratorsDataService.data.profiles,
                        windowAccessories: ConfiguratorsDataService.data.windowAccessories,
                        windowAccessoriesCategories:
                            ConfiguratorsDataService.data.windowAccessoriesCategories,
                        profilesIdMap: ConfiguratorsDataService.data.profilesIdMap,
                        garageGuides: ConfiguratorsDataService.data.garageGuides,
                        rollerShutterProfilesMap:
                            ConfiguratorsDataService.data.rollerShutterProfilesMap,
                        rollerShutterColorGroupsMap:
                            ConfiguratorsDataService.data.rollerShutterColorGroupsMap,
                        rollerShutterColorsMap:
                            ConfiguratorsDataService.data.rollerShutterColorsMap,
                    };
                    parsedPositions.newPositions = parsedPositions.newPositions.map(e => {
                        if (
                            [
                                'custom',
                                'transport_cost',
                                'colors_cost',
                                'colors_waste_cost',
                                'other',
                                'additional',
                            ].indexOf(e.doc.confType) === -1
                        ) {
                            if (angular.isString(e.doc.details) && e.doc.details != '') {
                                e.doc.details = Core.parseJson(e.doc.details);
                            }
                            if (e.doc.details === null || !e.doc.details.$version) {
                                if (['window', 'door', 'hs'].includes(e.doc.configuration.type)) {
                                    e.doc.configuration.$version = 3;
                                }
                                e.doc.details = ConfigurationsService.createSimpleConfiguration(
                                    Core.parseJson(e.doc.configuration),
                                    dataRequiredToUpdate
                                );
                            } else {
                                e.doc.details = ConfigurationsService.createSimpleConfiguration(
                                    Core.parseJson(e.doc.details),
                                    dataRequiredToUpdate
                                );
                            }
                            if (e.doc.details && !e.doc.details.priceParts) {
                                e.doc.invalid = true;
                                offerOptions.discounts = false;
                                offerOptions.oldVersion = true;
                            } else {
                                offerOptions.discounts = true;
                                offerOptions.oldVersion = false;
                            }

                            if (!e.doc.configuration.drawData) {
                                e.doc.configuration.drawData = DrawService.getData(e.doc.details);
                            }

                            const { area, glazingArea, circuit, size } = PositionsService.getDimensions(e.doc, IccConfig);

                            e.doc.area = area;
                            e.doc.glazing_area = glazingArea;
                            e.doc.circuit = circuit;
                            e.doc.size = size;
                        }

                        return e;
                    });
                    getTranslatedData(parsedPositions.newPositions, LangService.getLang())
                    .then(translatedData => {
                        positions = new PositionsDetailsService(
                            IccConfig,
                            parsedPositions.newPositions,
                            offer,
                            $rootScope.user.access,
                            priceType
                            && IccConfig.Offer.showPricesInPositionDetails
                            && offerOptions.discounts,
                            priceType,
                            $filter('translate'),
                            false,
                            translatedData,
                            $rootScope.showInfo,
                        );

                        for (let i = 0; i < offer.sequence.length; i++) {
                            for (var key in offer.sequence[i]) {
                                pos = positions.filter(
                                    e =>
                                        offer.sequence[i][key].indexOf(e.doc.tmp_id) > -1
                                        && !e.doc.coupled_position_id
                                );
                                if (pos.length) {
                                    groups.push({
                                        groupCode: pos[0].doc.groupCode,
                                        confType: pos[0].doc.confType,
                                        rows: pos,
                                        groupData:
                                            offer.positions_groups_data[pos[0].doc.groupCode],
                                        groupEdition: 0,
                                    });
                                }
                            }
                        }

                        deferred.resolve({
                            offer,
                            groups,
                            offerLoaded,
                            notFoundPositions,
                            transportCost: parsedPositions.transportCost,
                            transportCostPos: parsedPositions.transportCostPosition,
                            discounts: parsedPositions.discounts,
                            translatedData,
                            emptyOffer: parsedPositions.newPositions.length === 0
                        });
                    });
                });
            },
            () => {
                $rootScope.showInfo($filter('translate')('OFFER|Nie znaleziono oferty'), null);
                logger.userLog($filter('translate')('OFFER|Nie znaleziono oferty'));
                $location.path('/app/offers');
                $rootScope.loader = false;
            }
        );

        return deferred.promise;
    }

    async function getTranslatedData(pos, lang, isGroup = false) {
        const translatedData = {};
        let dataToTranslate = [];
        dataToTranslate = TranslateService.extractDataToTranslate(pos, lang, isGroup);
        if (Common.isDefined(dataToTranslate[0])) {
            let i18nData = await DatabaseManager.get('Translations').get();
            i18nData =
                i18nData && i18nData.data ? core.parseJson(i18nData.data).translations || [] : [];
            i18nData = i18nData.filter(
                row =>
                    dataToTranslate.indexOf(
                        `("${row.model}", "${Number(row.foreign_key)}", "${row.locale}")`
                    ) > -1
            );
            for (let i = 0; i < i18nData.length; i++) {
                const row = i18nData[i];
                if (Common.isUndefined(translatedData[row.model])) {
                    translatedData[row.model] = {};
                }
                if (Common.isUndefined(translatedData[row.model][row.foreign_key])) {
                    translatedData[row.model][row.foreign_key] = {};
                }
                translatedData[row.model][row.foreign_key][row.field] = row.content;
            }
        }
        return translatedData;
    }

    function mergeTranslatedData(existingTranslatedData, newTranslatedData) {
        for (const model in newTranslatedData) {
            if (Common.isObject(newTranslatedData[model])) {
                for (const foreignKey in newTranslatedData[model]) {
                    if (Common.isObject(newTranslatedData[model][foreignKey])) {
                        for (const field in newTranslatedData[model][foreignKey]) {
                            if (Common.isUndefined(existingTranslatedData[model])) {
                                existingTranslatedData[model] = {};
                            }
                            if (Common.isUndefined(existingTranslatedData[model][foreignKey])) {
                                existingTranslatedData[model][foreignKey] = {};
                            }
                            if (Common.isUndefined(existingTranslatedData[model][foreignKey][field])) {
                                existingTranslatedData[model][foreignKey][field] = newTranslatedData[model][foreignKey][field];
                            }
                        }
                    }
                }
            }
        }
        return existingTranslatedData;
    }

    function checkIsLoadedAllPositions(offer, positionsLength) {
        let offerLoaded = false;
        let notFoundPositions = false;
        const sequenceKeys = OfferSequenceService.keysFromSequence(offer.sequence);
        if (sequenceKeys.length != positionsLength && !OnlineStatusService.getStatus()) {
            $rootScope.showInfo(
                $filter('translate')('OFFER|Nie można pobrać oferty ponieważ jesteś offline.'),
                null
            );
            $location.path('/app/offers');
        } else {
            if (sequenceKeys.length != positionsLength) {
                $rootScope.showInfo(
                    $filter('translate')('OFFER|Trwa pobieranie pozycji oferty.'),
                    null
                );
                logger.userLog(
                    $filter('translate')(
                        'OFFER|Nie pobrano jeszcze wszystkich pozycji oferty z serwera.'
                    )
                );
                offerLoaded = true;
                notFoundPositions = true;
                syncTimeout = setTimeout(() => {
                    if ($location.url().includes('/app/offers_view/')) {
                        logger.userLog(
                            $filter('translate')('OFFER|Ponowne pobieranie oferty z serwera.')
                        );
                        SynchronizeService.synchronizeOffers(true, 'down');
                    }
                }, 30000);
            }
        }
        return { offerLoaded, notFoundPositions };
    }

    function redirectToStoreCart(offer) {
        const address = `${EnvConfig.remoteHost || window.location.origin}/api/carts/redirect/${
            offer.tmp_id
            }`;
        if (OnlineStatusService.getStatus()) {
            $rootScope.loader = true;
            $http
                .get(address, { withCredentials: true })
                .then(response => {
                    if (IccConfig.GTM.allowTags) {
                        EventBusService.post({
                            key: 'googleTags',
                            value: 'redirectToStoreCart',
                        });
                    }
                    $window.location = response.data.redirect;
                })
                .catch(error => {
                    $rootScope.showInfo(
                        $filter('translate')('OFFER|Oferta nie została przekazana do koszyka'),
                        null
                    );
                    logger.error(error);
                    $rootScope.loader = false;
                });
        }
    }

    function saveCustomKeyValue(offer, callback) {
        const tmpOffer = offer;
        var key = OfferKeyService.keyGen({
            order: tmpOffer.order,
            user,
            client: {},
            dealerId: tmpOffer.dealer_id,
            number: tmpOffer.number,
            version: tmpOffer.version,
            enova: tmpOffer.enova,
            offerNumber: OffersFactory.elemInOfferNumber(tmpOffer.key, 'O_NUM'),
            offerVersion:
                OffersFactory.elemInOfferNumber(tmpOffer.key, 'O_V_DIGIT')
                || (OffersFactory.elemInOfferNumber(tmpOffer.key, 'O_V_NUMBER') == 0
                    || OffersFactory.elemInOfferNumber(tmpOffer.key, 'O_V_NUMBER') == ''
                    ? 0
                    : OffersFactory.elemInOfferNumber(tmpOffer.key, 'O_V_NUMBER')
                        .toLowerCase()
                        .charCodeAt(0) - 96),
            subOffer: !~~tmpOffer.order && tmpOffer.parent_id && tmpOffer.version,
            originalOfferDate: tmpOffer.original_offer_date,
            b2cOfferScheme: IccConfig.Offer.B2C.differentOfferSchema && tmpOffer.client_offer,
            def: {
                O_ALL: OffersFactory.elemInOfferNumber(tmpOffer.key, 'O_ALL'),
                K_DEALER: OffersFactory.elemInOfferNumber(tmpOffer.key, 'K_DEALER'),
                K_CLIENT: OffersFactory.elemInOfferNumber(tmpOffer.key, 'K_CLIENT'),
                K_CUSTOM: offer.custom_key_value,
            },
            b2cOfferSource: tmpOffer.b2c_offer_source,
            offlineNumber: false,
        }).key;
        StateService.setKey('key', key);

        OffersService.update(
            tmpOffer._id,
            angular.extend(tmpOffer, {
                key,
            })
        ).then(() => {
            offer.synced = false;
            $rootScope.showInfo($filter('translate')('OFFER|Zmieniono kod oferty'), null);
            callback();
        });
    }

    function updateOfferMargin(offer) {
        DiscountsAndMultipliersService.getOfferDiscountAsync().then(offerDiscountsAll => {
            let offerDiscounts;
            var customDiscounts = (angular.copy(offer.group_discounts) || []).filter(
                function filterDiscounts(e) {
                    return e.custom;
                }
            );
            var dealerDiscounts = (offerDiscountsAll || []).filter(function filterDiscounts(e) {
                return e.dealer_id == $rootScope.user.dealerid || !e.dealer_id;
            });
            offerDiscounts = OfferDiscountsService.groupDiscounts(
                dealerDiscounts.concat(customDiscounts),
                offer.dealer_price_before_discount,
                $rootScope.user,
                offer
            );
            offer.group_discounts = offerDiscounts || [];
            OffersService.update(offer._id, offer).then(() =>
                PositionsFactory.multiUpdatePrices(offer.tmp_id, false)
            );
        });
    }

    function updateOfferCurrency(offer) {
        if (!$rootScope.user.market_update_currency || ~~offer.order || !offer.currency) {
            return Promise.resolve();
        }

        return new Promise((resolve, reject) => {
            ConfiguratorsDataService.dataAsync().then(data => {
                const currency = data.currencies[offer.currency.currency];
                if (offer.currency.value === currency.value) {
                    return resolve();
                }

                if (offer.dealer_status == 2) {
                    const dealer = UserService.getDealer();
                    if (!dealer) {
                        return resolve();
                    }

                    const expirationDate = moment(
                        $rootScope.IccConfig.Offer.expirationDateModification
                            ? offer.modified
                            : offer.created
                    )
                        .add(dealer.offer_expire || 14, 'd')
                        .format();

                    if (moment().format() < expirationDate) {
                        return resolve();
                    }
                }

                InfoFactory.openModal({
                    message: $filter('translate')('OFFER|Oferta przeliczona po nowym kursie waluty'),
                    title: $filter('translate')('INTERFACE|Informacja'),
                    callback: () => {
                        $rootScope.loader = true;
                        OffersService.update(offer.tmp_id, angular.extend(offer, { currency }))
                            .then(resolve)
                            .catch(reject);
                    }
                });
            }).catch(reject);
        }).then(() => {
            $rootScope.loader = false;
        }).catch(error => {
            logger.error(error);
            $rootScope.loader = false;
        });
    }
}
