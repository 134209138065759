import { core } from 'helpers';
import { Profile } from './configurations/parts/window';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import ProfilesService from 'profiles.service';
import { EventBusService } from 'event-bus.service';
import { Injectable } from '@angular/core';
import { FramesService } from 'layout/frames.service';

@Injectable()
export default abstract class GlazingBeadsService {
    protected GLAZING_BEAD_TOLERANCE = 1;

    protected glazingBeads: Profile[] = [];

    constructor(
        protected profilesService: ProfilesService,
        protected eventBusService: EventBusService
    ) {}

    setDefaultGlazingBeadInSashes(conf: WindowActiveConfiguration) {
        conf.Sashes.forEach(sash => {
            this.setDefaultGlazingBeadInSash(sash, sash, conf, conf);
            sash.intSashes.forEach(intSash => {
                this.setDefaultGlazingBeadInSash(intSash, sash, conf, conf);
            });
        });
    }

    setDefaultGlazingBeadInSash(field, sash, conf: WindowActiveConfiguration, defaultConf) {
        const defaultGlazingBead = this.getDefaultGlazingBeadInSash(field, sash, conf, defaultConf);
        this.setGlazingBeadInSash(field, defaultGlazingBead, true);
    }

    setGlazingBeadInSash(sash, glazingBead: Profile, isDefault = false) {
        if (!glazingBead || !glazingBead.id) {
            return;
        }
        sash.glazingBead = {
            isDefault,
            profileId: glazingBead.id,
        };
        if (sash.intSashes.length === 1) {
            this.setGlazingBeadInSash(sash.intSashes[0], glazingBead, isDefault);
        }
        this.eventBusService.post({
            key: 'setGlazingBeadInSash',
            value: {
                glazingBeadId: glazingBead.id,
                sashId: sash,
                profile: glazingBead,
            },
        });
    }

    checkSingleGlazingBeads(conf: WindowActiveConfiguration, defaultConf?: any) {
        let isSingleGlazingBead = true;
        let isSingleGlazingBeadInFixes = true;
        let isSingleGlazingBeadInSashes = true;
        let firstGlazingBeadId = null;
        let firstGlazingBeadIdInFixes = null;
        let firstGlazingBeadIdInSashes = null;

        isSingleGlazingBead = conf.Sashes.every((sash, i) => {
            let isSingleGlazingBeadInSash = true;
            let firstGlazingBeadIdInSash = sash.glazingBead.profileId;

            isSingleGlazingBeadInSash = sash.intSashes.every((intSash, j) => {
                if (j === 0) {
                    firstGlazingBeadIdInSash = intSash.glazingBead.profileId;
                }
                return firstGlazingBeadIdInSash === intSash.glazingBead.profileId;
            });

            if (i === 0) {
                firstGlazingBeadId = firstGlazingBeadIdInSash;
            }

            if (sash.type.type === 'F') {
                if (!firstGlazingBeadIdInFixes) {
                    firstGlazingBeadIdInFixes = firstGlazingBeadIdInSash;
                }
                isSingleGlazingBeadInFixes = isSingleGlazingBeadInFixes
                        && isSingleGlazingBeadInSash
                        && firstGlazingBeadIdInFixes === firstGlazingBeadIdInSash;
            } else {
                if (!firstGlazingBeadIdInSashes) {
                    firstGlazingBeadIdInSashes = firstGlazingBeadIdInSash;
                }
                isSingleGlazingBeadInSashes = isSingleGlazingBeadInSashes
                        && isSingleGlazingBeadInSash
                        && firstGlazingBeadIdInSashes === firstGlazingBeadIdInSash;
            }

            sash.oneGlazingBead = firstGlazingBeadIdInSash;
            return isSingleGlazingBeadInSash && firstGlazingBeadId === firstGlazingBeadIdInSash;
        });

        conf.OneGlazingBead = isSingleGlazingBead;
        if (isSingleGlazingBead && defaultConf) {
            defaultConf.GlazingBead = firstGlazingBeadId;
        }
        conf.OneGlazingBeadSash = {
            fix: false,
            sashes: false
        };
        if (isSingleGlazingBeadInFixes) {
            conf.OneGlazingBeadSash.fix = firstGlazingBeadIdInFixes;
        }
        if (isSingleGlazingBeadInSashes) {
            conf.OneGlazingBeadSash.sashes = firstGlazingBeadIdInSashes;
        }
    }

    abstract getMatchingFillingThicknessInSash(field, sash, conf: WindowActiveConfiguration);

    abstract loadGlazingBeads(conf: WindowActiveConfiguration);

    protected getDefaultGlazingBeadInSash(
        field,
        sash,
        conf: WindowActiveConfiguration,
        defaultConf?
    ): Profile {
        const matchedBeads = this.getMatchingGlazingBeadsInSash(field, sash, conf);
        const matchingBeadsToShape = matchedBeads.filter(
            bead => conf.GlazingBeadType && bead.profileShapeId === conf.GlazingBeadType
        );
        const matchingBeadsToProfileSetShape = matchedBeads.filter(
            bead =>
                conf.ProfileSet
                && conf.ProfileSet.glazingBeadShape
                && bead.profileShapeId === conf.ProfileSet.glazingBeadShape
        );
        if (
            defaultConf
            && defaultConf.GlazingBead
            && matchedBeads.filter(bead => bead.id === defaultConf.GlazingBead).length > 0
        ) {
            return this.profilesService.getProfile(defaultConf.GlazingBead);
        } else if (matchingBeadsToProfileSetShape.length > 0) {
            return matchingBeadsToProfileSetShape[0];
        } else if (matchingBeadsToShape.length > 0) {
            return matchingBeadsToShape[0];
        } else if (matchedBeads.length > 0) {
            return matchedBeads[0];
        }
        return null;
    }

    protected getMatchingGlazingBeadsInSash(field, sash, conf: WindowActiveConfiguration) {
        let sashProfile;
        if (sash.type.type !== 'F') {
            sashProfile = this.profilesService.getProfile(sash.frame.bottom.profileId);
        } else {
            const frame = FramesService.getFrameProfilesForSash(sash, conf);
            sashProfile = this.profilesService.getProfile((frame[1] || frame[0]).profileId);
        }
        const fieldFillingThickness = Math.round(Number(field.glazing.thickness_mm));
        const matchedBeads = this.glazingBeads.filter(
            profile =>
                profile.systems.indexOf(Number(conf.System.id)) > -1
                && profile.width >= sashProfile.glazingBeadMinHeight
                && profile.width <= sashProfile.glazingBeadMaxHeight
                && sashProfile.glazingRebate
                    - sashProfile.repairGaskets
                    - fieldFillingThickness
                    - profile.decreaseGasketSqueeze
                    <= profile.depth
                && sashProfile.glazingRebate
                    - sashProfile.repairGaskets
                    - fieldFillingThickness
                    + profile.increaseGasketSqueeze
                    >= profile.depth
        );
        return matchedBeads;
    }
}
