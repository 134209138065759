import { Injectable, Inject } from '@angular/core';
import { Profile } from 'configurations/parts/window';
import WindowActiveConfiguration from 'configurations/WindowActiveConfiguration';
import ProfilesService from 'profiles.service';
import { EventBusService } from 'event-bus.service';
import StepFactory, { ChangedStepValue } from 'configurator/steps/StepFactory';
import { APP_CONFIG, AppConfig } from 'config';
import { logger, core } from 'helpers';
import { IssuesService, IssueLevel } from 'issues.service';
import { ValidationService } from 'configurators/validation.service';
import GlazingBeadsService from 'glazing-beads.service';
import { FramesService } from 'layout/frames.service';
import { TranslateService } from 'translate.service';

@Injectable()
export default class BrowserGlazingBeadsService extends GlazingBeadsService {
    constructor(
        protected eventBusService: EventBusService,
        protected profilesService: ProfilesService,
        private stepFactory: StepFactory,
        @Inject('$uibModal') private $uibModal: ng.ui.bootstrap.IModalService,
        @Inject(APP_CONFIG) private config: AppConfig,
        private issuesService: IssuesService,
        private validationService: ValidationService,
        private translateService: TranslateService,
    ) {
        super(profilesService, eventBusService);
        this.eventBusService.subscribe('initializedConfigurator', data => {
            try {
                this.loadGlazingBeads(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });
        this.eventBusService.subscribe('setSystem', data => {
            try {
                this.loadGlazingBeads(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('loadedProfiles', data => {
            try {
                this.loadGlazingBeads(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('setSealColor', data => {
            try {
                this.loadGlazingBeads(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('validatedFillings', data => {
            try {
                this.validateGlazingBeadsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('loadedGlazingBeads', data => {
            try {
                this.validateGlazingBeadsAndFixIssues(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('setGlazingBeadInSash', data => {
            try {
                this.checkSingleGlazingBeads(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
                this.checkSingleGlazingBeadsShape(
                    data.activeConfiguration as WindowActiveConfiguration,
                    data.defaultConfiguration as WindowActiveConfiguration
                );
                this.validateGlazingBeads(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });

        this.eventBusService.subscribe('setProfileSet', data => {
            try {
                this.setDefaultsFromSet(data.activeConfiguration as WindowActiveConfiguration);
            } catch (err) {
                logger.error(err);
            }
        });
    }

    setGlazingBeadInAllSashes(
        conf: WindowActiveConfiguration,
        glazingBead: Profile,
        isDefault = false
    ) {
        const pauseId = this.eventBusService.pause(['setGlazingBeadInSash']);
        try {
            conf.Sashes.forEach(sash => {
                this.setGlazingBeadInSash(sash, glazingBead, isDefault);
                sash.intSashes.forEach(intSash => {
                    this.setGlazingBeadInSash(intSash, glazingBead, isDefault);
                });
            });
        } finally {
            this.eventBusService.resume(['setGlazingBeadInSash'], pauseId);
        }
    }

    setGlazingBeadInFilteredSashes(conf: WindowActiveConfiguration, glazingBead: Profile, isDefault = false, filter: (sash) => boolean = () => true) {
        const pauseId = this.eventBusService.pause(['setGlazingBeadInSash']);
        conf.Sashes.filter(filter).forEach(sash => {
            this.setGlazingBeadInSash(sash, glazingBead, isDefault);
            sash.intSashes.forEach(intSash => {
                this.setGlazingBeadInSash(intSash, glazingBead, isDefault);
            });
        });
        this.eventBusService.resume(['setGlazingBeadInSash'], pauseId);
    }

    setDefaultGlazingBeadInSashFromSet(field, sash, conf: WindowActiveConfiguration) {
        const defaultGlazingBead = this.getDefaultGlazingBeadInSash(field, sash, conf);
        this.setGlazingBeadInSash(field, defaultGlazingBead, true);
    }

    loadGlazingBeads(conf: WindowActiveConfiguration) {
        this.validationService.indeterminate(conf, 'loadedGlazingBeads');
        const requiredElements = ['system', 'loadedProfiles'];
        if (this.config.IccConfig.Configurators.glazingBeadSealColor) {
            requiredElements.push('sealColor');
        }
        if (this.validationService.isValidElements(conf, requiredElements)) {
            this.glazingBeads = this.profilesService
                .getFilteredProfiles(conf, 'glazing_bead')
                .filter(
                    profile =>
                        (this.config.IccConfig.Configurators.glazingBeadSealColor
                            && profile.windowSealColorId === Number(conf.SealColor.id))
                        || !this.config.IccConfig.Configurators.glazingBeadSealColor
                );
            if (this.glazingBeads.length === 0) {
                this.validationService.invalid(conf, 'loadedGlazingBeads');
                this.issuesService.registerDataProblem(
                    'no-glazing-beads',
                    'Nie ma listew przyszybowych',
                    conf,
                    {
                        level: IssueLevel.FATAL,
                        extra: {
                            sealColorId: conf.SealColor && Number(conf.SealColor.id),
                            systemId: conf.System && conf.System.id
                        }
                    }
                );
            } else {
                this.validationService.valid(conf, 'loadedGlazingBeads');
                this.issuesService.unregister('no-glazing-beads', conf);
                this.eventBusService.post({ key: 'loadedGlazingBeads', value: this.glazingBeads });
            }
        }
    }

    validateGlazingBeadsAndFixIssues(conf: WindowActiveConfiguration, defaultConf) {
        if (
            this.validationService.isValidElements(conf, [
                'frameProfiles',
                'sashesProfiles',
                'fillings',
                'loadedGlazingBeads',
            ])
        ) {
            const pauseId = this.eventBusService.pause(['setGlazingBeadInSash']);
            try {
                conf.Sashes.forEach(sash => {
                    if (!this.validGlazingBeadInSash(sash, sash, conf)) {
                        this.setDefaultGlazingBeadInSash(sash, sash, conf, defaultConf);
                    }
                    sash.intSashes.forEach(intSash => {
                        if (!this.validGlazingBeadInSash(intSash, sash, conf)) {
                            this.setDefaultGlazingBeadInSash(intSash, sash, conf, defaultConf);
                        }
                    });
                });
            } finally {
                this.eventBusService.resume(['setGlazingBeadInSash'], pauseId);
            }
        }
    }

    validateGlazingBeads(conf: WindowActiveConfiguration) {
        this.validationService.indeterminate(conf, 'glazingBeads');
        if (
            this.validationService.isValidElements(conf, [
                'frameProfiles',
                'sashesProfiles',
                'fillings',
                'loadedGlazingBeads',
            ])
        ) {
            let valid = true;
            valid = conf.Sashes.every(
                sash =>
                    this.validGlazingBeadInSash(sash, sash, conf)
                    && sash.intSashes.every(intSash =>
                        this.validGlazingBeadInSash(intSash, sash, conf)
                    )
            );
            if (!valid) {
                this.validationService.invalid(conf, 'glazingBeads');
                this.issuesService.simpleRegister(
                    'invalid-glazing-beads',
                    'Niepoprawne listwy przyszybowe',
                    this.translateService.instant('WINDOW|Niepoprawne listwy przyszybowe'),
                    conf,
                    {
                        level: IssueLevel.ERROR,
                        logLevel: IssueLevel.INFO,
                        blockStepsAfter: 'glazing',
                    }
                );
            } else {
                this.validationService.valid(conf, 'glazingBeads');
                this.issuesService.unregister('invalid-glazing-beads', conf);
            }
        }
    }

    validGlazingBeadInSash(field, sash, conf: WindowActiveConfiguration) {
        let sashProfile;
        if (sash.type.type !== 'F') {
            sashProfile = this.profilesService.getProfile(sash.frame.bottom.profileId);
        } else {
            const frame = FramesService.getFrameProfilesForSash(sash, conf);
            sashProfile = this.profilesService.getProfile((frame[1] || frame[0]).profileId);
        }
        const fieldFillingThickness = Math.round(Number(field.glazing.thickness_mm));
        if (!field.glazingBead || !field.glazingBead.profileId) {
            return false;
        }
        if (
            this.glazingBeads.filter(bead => bead.id === field.glazingBead.profileId).length === 0
        ) {
            return false;
        }

        const glazingBead = this.profilesService.getProfile(field.glazingBead.profileId);

        return (
            glazingBead.width >= sashProfile.glazingBeadMinHeight
            && glazingBead.width <= sashProfile.glazingBeadMaxHeight
            && sashProfile.glazingRebate
                - sashProfile.repairGaskets
                - fieldFillingThickness
                - glazingBead.decreaseGasketSqueeze
                <= glazingBead.depth
            && sashProfile.glazingRebate
                - sashProfile.repairGaskets
                - fieldFillingThickness
                + glazingBead.increaseGasketSqueeze
                >= glazingBead.depth
            && glazingBead.systems.indexOf(Number(conf.System.id)) > -1
        );
    }

    setDefaultsFromSet(conf: WindowActiveConfiguration) {
        const pauseId = this.eventBusService.pause(['setGlazingBeadInSash']);
        try {
            conf.Sashes.forEach(sash => {
                this.setDefaultGlazingBeadInSashFromSet(sash, sash, conf);
                sash.intSashes.forEach(intSash => {
                    this.setDefaultGlazingBeadInSashFromSet(intSash, sash, conf);
                });
            });
        } finally {
            this.eventBusService.resume(['setGlazingBeadInSash'], pauseId);
        }
    }

    checkSingleGlazingBeadsShape(
        conf: WindowActiveConfiguration,
        defaultConf: WindowActiveConfiguration
    ) {
        let isSingleGlazingBeadShape = true;
        let firstGlazingBeadShape = null;

        isSingleGlazingBeadShape = conf.Sashes.every((sash, i) => {
            let isSingleGlazingBeadShapeInSash = true;
            let firstGlazingBeadShapeInSash = this.getGlazingBeadShape(sash.glazingBead.profileId);

            isSingleGlazingBeadShapeInSash = sash.intSashes.every((intSash, j) => {
                if (j === 0) {
                    firstGlazingBeadShapeInSash = this.getGlazingBeadShape(
                        intSash.glazingBead.profileId
                    );
                }
                return (
                    firstGlazingBeadShapeInSash
                    === this.getGlazingBeadShape(intSash.glazingBead.profileId)
                );
            });

            if (i === 0) {
                firstGlazingBeadShape = firstGlazingBeadShapeInSash;
            }

            return (
                isSingleGlazingBeadShapeInSash
                && firstGlazingBeadShape === firstGlazingBeadShapeInSash
            );
        });

        if (isSingleGlazingBeadShape) {
            conf.GlazingBeadType = firstGlazingBeadShape;
            defaultConf.GlazingBeadType = firstGlazingBeadShape;
            if (
                this.config.IccConfig.Configurators.profileSetSelect
                && conf.ProfileSet.glazingBeadShape !== firstGlazingBeadShape
            ) {
                conf.ProfileSet.glazingBeadShape = firstGlazingBeadShape;
                conf.ProfileSet.id = null;
                conf.ProfileSet.isDefault = false;
                defaultConf.ProfileSet.glazingBeadShape = firstGlazingBeadShape;
                defaultConf.ProfileSet.id = null;
                defaultConf.ProfileSet.isDefault = false;
            }
        } else {
            conf.GlazingBeadType = false;
            defaultConf.GlazingBeadType = false;
        }
    }

    /**
     * Funkcja okna modal listwy szyby
     * @param  {object} sash Skrzydło
     */
    openModalGlazingBead(conf: WindowActiveConfiguration, field?, sash?) {
        let glazingBeads = this.getMatchingGlazingBeads(conf);
        let selectedGlazingBead;
        let sashFilter: (sash: any) => boolean = (sash) => true;
        if (field === 'fix') {
            sashFilter = (s) => s.type.type === 'F';
            const fix = conf.Sashes.find(sashFilter);
            if (fix) {
                glazingBeads = this.getMatchingGlazingBeadsInSash(fix, fix, conf);
            }
            selectedGlazingBead = {
                profileId: conf.OneGlazingBeadSash.fix
            };
        } else if (field === 'sashes') {
            sashFilter = (s) => s.type.type !== 'F';
            sash = conf.Sashes.find(sashFilter);
            if (sash) {
                const fieldSash = sash.intSashes[0];
                glazingBeads = this.getMatchingGlazingBeadsInSash(fieldSash, sash, conf);
            }
            selectedGlazingBead = {
                profileId: conf.OneGlazingBeadSash.sashes
            };
        } else if (field) {
            selectedGlazingBead = field.glazingBead;
            if (sash) {
                glazingBeads = this.getMatchingGlazingBeadsInSash(field, sash, conf);
            }
        } else {
            selectedGlazingBead = conf.Sashes[0].glazingBead;
        }

        const modalInstance = this.$uibModal.open({
            templateUrl: 'modalGlazingBead.html',
            controller: 'ModalGlazingBeadCtrl as mgb',
            resolve: {
                beads: () => glazingBeads,
                selBead: () => selectedGlazingBead,
            },
        });

        modalInstance.result.then(selectedData => {
            if (selectedData) {
                if (field === 'fix' || field === 'sashes') {
                    this.setGlazingBeadInFilteredSashes(conf, selectedData.glazingBead, undefined, sashFilter);
                } else if (field) {
                    this.setGlazingBeadInSash(field, selectedData.glazingBead);
                } else {
                    this.setGlazingBeadInAllSashes(conf, selectedData.glazingBead);
                }
            }
        });

        modalInstance.closed.then(() => {
            if (this.config.IccConfig.Configurators.tutorialAvailable) {
                this.eventBusService.post({
                    key: 'tutorialSteps',
                    value: 'getStepImg',
                });
            }
        });

        if (IccConfig.Configurators.tutorialAvailable) {
            this.eventBusService.post({
                key: 'tutorialSteps',
                value: 'glazingBeads',
            });
        }
    }

    getMatchingFillingThickness(conf: WindowActiveConfiguration) {
        let matchingFillingThickness = null;
        conf.Sashes.forEach(sash => {
            sash.intSashes.forEach(intSash => {
                matchingFillingThickness = this.getCommonGlazingBeadsInSash(
                    matchingFillingThickness,
                    intSash,
                    sash,
                    conf
                );
            });
            if (sash.intSashes.length === 0) {
                matchingFillingThickness = this.getCommonGlazingBeadsInSash(
                    matchingFillingThickness,
                    sash,
                    sash,
                    conf
                );
            }
        });
        return matchingFillingThickness;
    }

    getMatchingFillingThicknessInSash(field, sash, conf: WindowActiveConfiguration) {
        const matchedBeads = this.glazingBeads;
        let sashProfile;
        if (sash.type.type !== 'F') {
            sashProfile = this.profilesService.getProfile(sash.frame.bottom.profileId);
        } else {
            const frame = FramesService.getFrameProfilesForSash(sash, conf);
            sashProfile = this.profilesService.getProfile((frame[1] || frame[0]).profileId);
        }

        const [allRanges] = matchedBeads.reduce(([ranges, indices], cur) => {
            const minDepthBead = Number(cur.depth) - Number(cur.increaseGasketSqueeze);
            const maxDepthBead = Number(cur.depth) + Number(cur.decreaseGasketSqueeze);
            const min =
                Number(sashProfile.glazingRebate)
                - maxDepthBead
                - Number(sashProfile.repairGaskets);
            const max =
                Number(sashProfile.glazingRebate)
                - minDepthBead
                - Number(sashProfile.repairGaskets);
            if (indices.indexOf(`${min}_${max}`) === -1) {
                indices.push(`${min}_${max}`);
                ranges.push({
                    min,
                    max,
                });
            }
            return [ranges, indices];
        }, [[], []]);
        const normalizedRanges = this.normalizeRanges(allRanges);

        return normalizedRanges;
    }

    private normalizeRanges(ranges) {
        const newRanges = ranges.slice(0);
        newRanges.sort((a, b) => a.min - b.min);
        let oldLength;
        do {
            oldLength = newRanges.length;
            for (let i = newRanges.length - 1; i >= 0; i--) {
                if (i > 0 && newRanges[i - 1].max >= newRanges[i].min) {
                    newRanges.splice(i - 1, 2, this.mergeRanges(newRanges[i - 1], newRanges[i]));
                }
            }
        } while (oldLength !== newRanges.length);
        return newRanges;
    }

    private mergeRanges(range1, range2) {
        return {
            min: range1.min,
            max: range2.max > range1.max ? range2.max : range1.max,
        };
    }

    private getMatchingGlazingBeads(conf: WindowActiveConfiguration) {
        let matchingGlazingBeads = [];
        conf.Sashes.forEach(sash => {
            sash.intSashes.forEach(intSash => {
                matchingGlazingBeads = this.getCommonGlazingBeadsInSash(
                    matchingGlazingBeads,
                    intSash,
                    sash,
                    conf
                );
            });
            if (sash.intSashes.length === 0) {
                matchingGlazingBeads = this.getCommonGlazingBeadsInSash(
                    matchingGlazingBeads,
                    sash,
                    sash,
                    conf
                );
            }
        });
        return matchingGlazingBeads;
    }

    private getCommonGlazingBeadsInSash(
        matchingGlazingBeads,
        field,
        sash,
        conf: WindowActiveConfiguration
    ) {
        if (matchingGlazingBeads.length === 0) {
            matchingGlazingBeads = this.getMatchingGlazingBeadsInSash(field, sash, conf);
        } else {
            matchingGlazingBeads = this.getCommonGlazingBeads(
                matchingGlazingBeads,
                this.getMatchingGlazingBeadsInSash(field, sash, conf)
            );
        }
        return matchingGlazingBeads;
    }

    private getCommonFillingThicknessInSash(
        matchingFillingThickness,
        field,
        sash,
        conf: WindowActiveConfiguration
    ) {
        if (!matchingFillingThickness) {
            matchingFillingThickness = this.getMatchingFillingThicknessInSash(field, sash, conf);
        } else {
            matchingFillingThickness = this.getCommonFillingThickness(
                matchingFillingThickness,
                this.getMatchingFillingThicknessInSash(field, sash, conf)
            );
        }
        return matchingFillingThickness;
    }

    private getCommonGlazingBeads(list1: Profile[], list2: Profile[]) {
        return list1.filter(function(a) {
            return this.has(a.id);
        }, list2.reduce((hash, b) => hash.add(b.id), new Set()));
    }

    private getCommonFillingThickness(range1, range2) {
        const mergedRanges = [...range1, ...range2];
        return this.normalizeRanges(mergedRanges);
    }

    private getGlazingBeadShape(profileId: number) {
        const profile = this.profilesService.getProfile(profileId);
        if (profile) {
            return profile.profileShapeId;
        }
        return null;
    }
}
